/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {all, call, fork, select, put} from 'redux-saga/effects';
import {getOrgId} from 'containers/User/UserState';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {RedirectError} from 'errors';
import {hrefUtils} from 'utils';
import {getLabelGroupSummary, getLabelGroupDetail} from './LabelGroupItemState';
import {handleSagaError, getMembers} from './LabelGroupItemUtils';
import gridSaga from 'components/Grid/GridSaga';
import {memberOfGridSettings, memberOfFilterMap} from './MemberOf/LabelGroupMemberOfConfig';
import {memberGridSettings, memberFilterMap} from './Members/LabelGroupMembersConfig';
import {isAPIAvailable} from 'api/apiUtils';

export function* fetchVersions({params} = {}) {
  const usage = true;
  const orgId = yield select(getOrgId);

  const pversion = Number(params.pversion);
  const isOldVersion = !_.isNaN(pversion) && pversion > 0;

  const [{data: active}, {data: draft}, oldPversionObj, oldPrevPversionObj] = yield all([
    call(apiSaga, 'label_groups.get_instance', {
      ignoreCodes: [404],
      query: {usage},
      params: {pversion: 'active', label_group_id: params.id, xorg_id: orgId},
    }),
    call(apiSaga, 'label_groups.get_instance', {
      ignoreCodes: [404],
      query: {usage},
      params: {pversion: 'draft', label_group_id: params.id, xorg_id: orgId},
    }),
    ...(isOldVersion
      ? [
          call(apiSaga, 'label_groups.get_instance', {
            ignoreCodes: [404],
            query: {usage},
            params: {pversion, label_group_id: params.id, xorg_id: orgId},
          }),
        ]
      : []),
    ...(isOldVersion && pversion > 1
      ? [
          call(apiSaga, 'label_groups.get_instance', {
            ignoreCodes: [404],
            query: {usage},
            params: {pversion: pversion - 1, label_group_id: params.id, xorg_id: orgId},
          }),
        ]
      : []),
  ]);

  // If both draft and active are not valid, redirect to the list page
  // draft and active is undefined for deleted policy object
  // Redirect to list page if pversion is invalid
  const validItem = oldPversionObj || oldPrevPversionObj || draft || active;
  const validPversion = isOldVersion || ['draft', 'active'].includes(params.pversion);

  if (!validItem || !validPversion || (isOldVersion && !oldPversionObj && !oldPrevPversionObj)) {
    throw new RedirectError({to: 'labelGroups.list', proceedFetching: true, thisFetchIsDone: true});
  }

  const cached = yield select(getLabelGroupDetail);

  const data = {
    detail: {
      active,
      draft,
      ...(oldPversionObj ? {oldPversionObj: oldPversionObj?.data} : {}),
      ...(oldPrevPversionObj ? {oldPrevPversionObj: oldPrevPversionObj?.data} : {}),
    },
  };

  if (
    !cached.detail ||
    active !== cached.detail.active ||
    draft !== cached.detail.draft ||
    oldPversionObj?.data !== cached.detail.oldPversionObj ||
    oldPrevPversionObj?.data !== cached.detail.oldPrevPversionObj
  ) {
    yield put({type: 'LABEL_GROUPS_GET_DETAIL', data});
  }

  return data;
}

export function* fetchLabelGroupItem({name, params}) {
  const isEditPage = name.endsWith('.edit');
  const isDraftPage = params.pversion === 'draft';

  const data = yield call(fetchVersions, {params});

  // If there is no real draft (i.e. no pending changes), redirect to active version
  if (isDraftPage && !isEditPage && !data.detail.draft?.update_type) {
    throw new RedirectError({
      params: {id: params.id, pversion: 'active'},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  return data;
}

export function* fetchLabelGroupSummary({params}, refetch = false) {
  const {versions} = yield select(getLabelGroupSummary);
  const pversion = params.pversion;
  const isOldVersion = !_.isNaN(Number(pversion));
  const labelGroup = isOldVersion ? versions.pversionObj : versions[pversion];

  yield fork(fetchSelectiveUsers, [labelGroup.created_by, labelGroup.updated_by], refetch);
}

export function* fetchMemberOf({name = '', filter, params, force = false}) {
  if (name.endsWith('add') && !isAPIAvailable('label_group.update')) {
    throw new RedirectError({
      to: 'labelGroups.item.members.view',
      params: {id: params.id, pversion: params.pversion},
      thisFetchIsDone: true,
    });
  }

  return yield call(apiSaga, 'label_group.member_of', {
    params: {
      pversion: params.pversion || 'draft',
      label_group_id: params.id,
    },
    cache: !force,
    *onDone({data}) {
      let filteredData = data;

      if (filter && filter.label_groups) {
        filteredData = filteredData.filter(item => filter.label_groups.includes(item.name));
      }

      const count = {
        matched: data.length,
        total: data.length,
      };

      yield put({
        type: 'LABEL_GROUP_MEMBER_OF_LIST',
        data: {
          list: filteredData,
          count,
          static: data,
        },
      });

      return {data};
    },
  });
}

export function* fetchMemberOfList(route, refetch = false) {
  yield call(gridSaga, {
    route,
    settings: memberOfGridSettings,
    filterMap: memberOfFilterMap(),
    *onSaga({filterParams}) {
      const {data} = yield call(fetchMemberOf, {
        force: refetch,
        usage: true,
        params: route.params,
        filter: filterParams.isEmpty ? undefined : filterParams.valid,
      });

      return data.length;
    },
  });
}

export function* fetchMemberList(route, refetch = false) {
  yield call(gridSaga, {
    route,
    settings: memberGridSettings,
    filterMap: memberFilterMap(),
    *onSaga({filterParams}) {
      if (refetch) {
        yield call(fetchLabelGroupItem, route, refetch);
      }

      const {
        versions: {pversionObj, prevPversionObj},
      } = yield select(getLabelGroupSummary);
      const members = getMembers(pversionObj, prevPversionObj, route.params.pversion);
      let list;

      if (filterParams.isEmpty) {
        list = members;
      } else {
        const {labels, label_groups: labelGroups} = filterParams.valid;

        list = [];

        if (labels) {
          list.push(...members.filter(item => labels.includes(item.value) && item.href.includes('labels')));
        }

        if (labelGroups) {
          list.push(...members.filter(item => labelGroups.includes(item.name) && item.href.includes('label_groups')));
        }
      }

      yield put({
        type: 'LABEL_GROUP_MEMBER_LIST',
        data: {
          static: members,
          list,
          count: {matched: list.length, total: members.length},
        },
      });

      return members.length;
    },
  });
}

export function* updateLabelGroup({params, data} = {}) {
  try {
    yield call(apiSaga, 'label_group.update', {
      params,
      data,
    });
    apiCachedResponses.removeByMethodName('label_groups.get_instance');
    apiCachedResponses.removeByMethodName('label_groups.get_collection');
    apiCachedResponses.removeByMethodName('label_group.member_of');
  } catch (err) {
    err.errors = handleSagaError(err);
    throw err;
  }
}

export function* createLabelGroup({params, data} = {}) {
  let labelGroupData;

  try {
    labelGroupData = yield call(apiSaga, 'label_groups.create', {
      params,
      data,
    });

    apiCachedResponses.removeByMethodName('label_groups.get_collection');
  } catch (err) {
    err.errors = handleSagaError(err);
    throw err;
  }

  return labelGroupData;
}

export function* removeLabelGroup({href, pversion = 'draft'}) {
  try {
    yield call(apiSaga, 'label_group.delete', {
      params: {pversion, label_group_id: hrefUtils.getId(href)},
    });

    apiCachedResponses.removeByMethodName('label_groups.get_instance');
    apiCachedResponses.removeByMethodName('label_groups.get_collection');
  } catch (err) {
    err.errors = handleSagaError(err);
    throw err;
  }
}

export function* fetchLabelGroupEdit({name = '', params: {id, pversion}}) {
  const {detail} = yield select(getLabelGroupDetail);
  const draft = detail?.draft;
  const isOldVersion = !_.isNaN(Number(pversion));

  if (name.endsWith('create') && !isAPIAvailable('label_groups.create')) {
    throw new RedirectError({to: 'labelGroups.list', proceedFetching: true, thisFetchIsDone: true});
  }

  if (
    (name.endsWith('edit') && !isAPIAvailable('label_group.update')) ||
    (draft && draft.update_type === 'delete') ||
    isOldVersion
  ) {
    throw new RedirectError({
      to: 'labelGroups.item.summary.view',
      params: {id, pversion},
      thisFetchIsDone: true,
      proceedFetching: true,
    });
  }
}
