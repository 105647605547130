/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {Button, MenuItem} from 'components';
import styles from './GridManager.css';

export default class GridPaginator extends PureComponent {
  static propTypes = {
    grid: PropTypes.object,
    count: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const {
      grid: {
        capacity,
        totalRows,
        page,
        settings: {paginationTextType},
      },
      count,
      onChange,
    } = this.props;
    const theme = composeThemeFromProps(styles, this.props);

    let type;
    let total;

    if (count) {
      if (count.matched !== count.total) {
        type = 'matched';
        total = count.matched;
      } else {
        total = count.total;
      }
    }

    total = Number(total || totalRows);

    // Take minimum of total and totalRows to determine number of pages
    // For e.g. "create new rule" action in outbound policy adds an editor row,
    // however, this row is not added towards total count and we don't want to paginate in this case
    const totalForPagination = Math.min(total, totalRows);

    const numPages = Math.ceil(totalForPagination / capacity);
    const lastPage = Math.max(1, numPages);

    const paginationString =
      total === 0
        ? ''
        : intl(
            'Common.Pagination',
            {
              total,
              type: paginationTextType ?? type ?? 'total',
              plus: Boolean(count && total > 100_000),
              notZero: Math.min(totalForPagination, capacity * page) !== 0,
              min: 1 + capacity * (page - 1),
              max: Math.min(totalForPagination, capacity * page),
            },
            {html: true},
          );

    const buttonProps = {
      color: 'standard',
      noFill: true,
      disabled: !total,
      theme,
      themePrefix: 'dropdown-',
      text: (
        <span data-tid="comp-pagination-counts" className={theme.count}>
          {paginationString}
        </span>
      ),
    };

    return (
      <div className={theme.paginator} data-tid="comp-pagination">
        {total ? (
          <Button.Menu
            {...buttonProps}
            menu={[
              <MenuItem
                text={intl('Common.PageFirst')}
                disabled={page === 1}
                onSelect={_.partial(onChange, _, {page: 1})}
                tid="first"
              />,
              <MenuItem
                text={intl('Common.PageLast')}
                disabled={page === lastPage}
                onSelect={_.partial(onChange, _, {page: lastPage, lastPage})}
                tid="last"
              />,
            ]}
          />
        ) : (
          <Button {...buttonProps} />
        )}
        <Button
          color="standard"
          noFill
          onClick={_.partial(onChange, _, {page: Math.max(page - 1, 1), lastPage})}
          disabled={page <= 1}
          icon="back"
          tid="previous"
          theme={theme}
          themePrefix="paginator-"
        />
        <Button
          color="standard"
          noFill
          onClick={_.partial(onChange, _, {page: Math.max(page + 1, 1), lastPage})}
          disabled={page >= numPages}
          icon="next"
          tid="next"
          theme={theme}
          themePrefix="paginator-"
        />
      </div>
    );
  }
}
