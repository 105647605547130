/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {Motion, TransitionMotion} from 'react-motion';
import type {OpaqueConfig, PlainStyle, TransitionPlainStyle, TransitionStyle} from 'react-motion';
import {Component, type ReactElement} from 'react';
import MenuItems, {type MenuItemsProps} from './MenuItems';
import {items as itemsMotions, type MotionDirection} from './motions';
import type {Merge} from 'type-fest';

type MotionStyle = {width: number | OpaqueConfig; height: number | OpaqueConfig};

export type MenuItemsContainerProps = Merge<
  Partial<MenuItemsProps>,
  {
    dir?: MotionDirection;
    theme: Record<string, string>;

    saveItemsRef(menuItems: MenuItems): void;

    parentFocusedItem?: boolean;
    dropdownMoving?: boolean;
    lastRender?: boolean;
  }
>;

type MenuItemsContainerState = {
  itemsKey: number;

  /**
   * Cache to save key and rectangle (width, height) values for each items list
   * Map<children:{key, rect}>
   */
  itemsCacheMap: Map<MenuItemsProps['children'], {key: string; rect: {width: number; height: number}}>;

  /**
   * Items that are currently rendered (have dom elements) to track really active items list
   * Map<children:itemListInstance>
   */
  showingItemsListsMap: Map<MenuItemsProps['children'], MenuItems>;
  itemsContainerMotionConfig: MotionStyle;
  itemsTransitionMotionConfig: TransitionStyle[];
  children: MenuItemsContainerProps['children'];
  parentFocusedItem?: MenuItemsContainerProps['parentFocusedItem'];
};

export default class MenuItemsContainer extends Component<MenuItemsContainerProps, MenuItemsContainerState> {
  transitionMotionForDimention: ReactElement | null = null;

  constructor(props: MenuItemsContainerProps) {
    super(props);

    // Items list key as simple increasing sequence number
    const itemsKey = 1;

    this.state = {
      itemsKey,

      itemsCacheMap: new Map(),

      showingItemsListsMap: new Map(),

      // By default container size is zero, it will be calculated after first items list render
      itemsContainerMotionConfig: {width: 0, height: 0},

      // At the first show children should be in opened position
      itemsTransitionMotionConfig: [
        {
          key: String(itemsKey),
          data: props.children,
          style: {y: 0, opacity: 1},
        },
      ],

      children: props.children,
    };

    this.saveItemsRef = this.saveItemsRef.bind(this);

    this.itemsEnter = this.itemsEnter.bind(this);
    this.itemsLeave = this.itemsLeave.bind(this);
    this.interpolateContainerMotion = this.interpolateContainerMotion.bind(this);
    this.interpolateTransitionMotion = this.interpolateTransitionMotion.bind(this);
    this.interpolateTransitionWrapper = this.interpolateTransitionWrapper.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<MenuItemsContainerProps>, prevState: MenuItemsContainerState) {
    const newState: Partial<MenuItemsContainerState> = {parentFocusedItem: nextProps.parentFocusedItem};
    let children;

    if (
      nextProps.parentFocusedItem === prevState.parentFocusedItem &&
      (nextProps.dropdownMoving || nextProps.lastRender)
    ) {
      // Important optimization.
      // Postpone children rerendering if it's the same parent element but rerendering caused by dropdown animation,
      // to avoid double rerendering in motions in this container
      children = prevState.children;
    } else {
      children = nextProps.children;
    }

    if (children !== prevState.children) {
      const itemsCache = prevState.itemsCacheMap.get(children);

      newState.children = children;

      if (itemsCache) {
        // If we know dimensions of new children (they were rendered sometime before current children),
        // set this height as target rigth away (don't need to wait saveItemsRef)
        // and use its key to return list back if it's in closing animation state
        newState.itemsContainerMotionConfig = itemsMotions.containerSizes(itemsCache.rect);

        newState.itemsTransitionMotionConfig = [
          {
            data: children,
            key: itemsCache.key,
            style: itemsMotions.listActive,
          },
        ];
      } else if (nextProps.parentFocusedItem === prevState.parentFocusedItem) {
        // If parent's focused item is the same, that means menu is being rerendered (and now there is not dropdown animation),
        // and we just need to update children with the same motion style
        newState.itemsTransitionMotionConfig = [
          {
            data: children,
            key: prevState.itemsTransitionMotionConfig[0].key,
            style: prevState.itemsTransitionMotionConfig[0].style,
          },
        ];
      } else {
        // If we are going to render new children for the first time,
        // stop height animation (for instance, if width/height is being animated in different direction),
        // by assigning plain values to motion config and wait for the new children's dimensions in saveItemsRef
        const currentConfig = prevState.itemsContainerMotionConfig;

        newState.itemsContainerMotionConfig = {
          width: typeof currentConfig.width === 'object' ? currentConfig.width.val : currentConfig.width,
          height: typeof currentConfig.height === 'object' ? currentConfig.height.val : currentConfig.height,
        };

        newState.itemsTransitionMotionConfig = [
          {
            data: children,
            key: String(++prevState.itemsKey),
            style: itemsMotions.listActive,
          },
        ];
      }
    }

    return newState;
  }

  componentDidUpdate() {
    // Need to check if active list has been changed
    // It can happen when user's pointer hovers previously hovered element before its items list is destroyed.
    // For instance, user move pointer forth and back over items with children as quickly as transition motion from
    // one item list to another is not finished and there will be no saveItemsRef on returned items list
    this.notifyUpperDropdownOnRefChange();
  }

  private saveItemsRef(itemList: MenuItems) {
    const incoming = Boolean(itemList.listElement);

    if (incoming) {
      // Add rendered items list to map
      this.state.showingItemsListsMap.set(itemList.props.children, itemList);

      if (!this.state.itemsCacheMap.get(itemList.props.children)) {
        const {rect} = itemList;

        const dim = {width: rect?.width ?? 0, height: rect?.height ?? 0};

        // Render one more time with new dimensions
        // Update invokes render on next tick, after componentDidMount/DidUpdate
        this.setState(prevState => ({
          // If it's a first render, simply assign plain values to avoid container size animation,
          // if subsequent render - animate size transition
          itemsContainerMotionConfig: prevState.itemsCacheMap.size
            ? itemsMotions.containerSizes(dim)
            : {
                width: dim.width,
                height: dim.height,
              },

          // Save items list dimensions for transitioning to it next time without waiting for the ref
          itemsCacheMap: prevState.itemsCacheMap.set(itemList.props.children, {
            key: prevState.itemsTransitionMotionConfig[0].key,
            rect: dim,
          }),
        }));
      }
    } else {
      // Delete unmounted items list from map
      this.state.showingItemsListsMap.delete(itemList.props.children);
    }

    this.notifyUpperDropdownOnRefChange();
  }

  private notifyUpperDropdownOnRefChange() {
    // It is called when component itself or ref for underlying itemList have been updated
    // to notify upper dropdown component that active items list probably has been changed
    const activeItemList = this.state.showingItemsListsMap.get(this.props.children);

    if (activeItemList) {
      this.props.saveItemsRef(activeItemList);
    }
  }

  private itemsEnter() {
    // Items list starting position
    return itemsMotions.listEnter(this.props.dir);
  }

  private itemsLeave() {
    // Items list unmounting position
    return itemsMotions.listLeave(this.props.dir);
  }

  private interpolateTransitionWrapper(interpolatedStyles: TransitionPlainStyle[]) {
    return (
      <div className={this.props.theme.itemsExtender}>{interpolatedStyles.map(this.interpolateTransitionMotion)}</div>
    );
  }

  private interpolateTransitionMotion(config: TransitionPlainStyle) {
    const {
      props: {dir, dropdownMoving, children, saveItemsRef, lastRender, parentFocusedItem, ...menuItemsProps},
    } = this;

    return (
      <MenuItems
        {...menuItemsProps}
        key={config.key}
        active={config.key === this.state.itemsTransitionMotionConfig[0].key}
        style={config.style as MenuItemsProps['style']}
        saveRef={this.saveItemsRef}
      >
        {config.data}
      </MenuItems>
    );
  }

  private interpolateContainerMotion(config: PlainStyle) {
    return (
      <div
        className={this.props.theme.itemsContainer}
        style={{width: `${config.width}px`, height: `${config.height}px`}}
      >
        <TransitionMotion
          willEnter={this.itemsEnter}
          willLeave={this.itemsLeave}
          styles={this.state.itemsTransitionMotionConfig}
        >
          {this.interpolateTransitionWrapper}
        </TransitionMotion>
      </div>
    );
  }

  render() {
    return <Motion style={this.state.itemsContainerMotionConfig}>{this.interpolateContainerMotion}</Motion>;
  }
}
