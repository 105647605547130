/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useMemo} from 'react';
import {AttributeList, Diff, Badge, Link} from 'components';
import {UserName} from 'containers';
import styles from '../RulesetItem.css';
import stylesUtils from 'utils.css';
import attributeListStyles from 'components/AttributeList/AttributeList.css';
import {isEntityEdited} from 'utils/dataValidation';

const breakpoints = [{props: {className: attributeListStyles.sizeS}}];

export default function RulesetSummary({summaryObj, isAdditionPending}) {
  const title = useMemo(() => {
    let title = intl('Rulesets.Summary');

    const {summary, oldSummary} = summaryObj;

    if (!oldSummary) {
      return title;
    }

    if (
      summary.name !== oldSummary.name ||
      summary.description !== oldSummary.description ||
      summary.enabled !== oldSummary.enabled ||
      summary.external_data_set !== oldSummary.external_data_set ||
      summary.external_data_reference !== oldSummary.external_data_reference
    ) {
      title += ` (${intl('Provision.Modified')})`;
    }

    return title;
  }, [summaryObj]);

  const rulesetSummary = useMemo(() => {
    const {summary, oldSummary} = summaryObj;

    return (
      <AttributeList breakpoints={breakpoints} valueColumnWidth="auto" keyColumnWidth="fit-content(40%)" theme={styles}>
        {[
          {
            tid: 'name',
            key: intl('Common.Name'),
            value: (
              <Diff.Text
                dark
                noTooltip
                type="sidebyside"
                value={summary.name}
                oldValue={oldSummary?.name}
                noDiff={!oldSummary}
              />
            ),
          },
          {
            tid: 'description',
            key: intl('Common.Description'),
            value: (
              <Diff.Text
                type="sidebyside"
                dark
                noTooltip
                value={summary.description}
                oldValue={oldSummary?.description}
                noDiff={!oldSummary}
              />
            ),
          },
          {
            tid: 'status',
            key: intl('Common.Status'),
            value: (
              <Diff.Option
                dark
                type="sidebyside"
                noTooltip
                value={summary.enabled === true ? intl('Common.Enabled') : intl('Common.Disabled')}
                oldValue={oldSummary?.enabled === true ? intl('Common.Enabled') : intl('Common.Disabled')}
                noDiff={!oldSummary}
              />
            ),
          },
          {
            tid: 'created',
            key: intl('Common.Created'),
            value: intl.utils.format.dateAtTimeBy(
              summary.created_at,
              <UserName user={summary.createdBy} display="full_name" linkProps={{theme: styles}} />,
              null,
              {jsx: true},
            ),
          },
          isAdditionPending
            ? null
            : {
                tid: 'modified',
                key: intl('Common.LastModified'),
                value: intl.utils.format.dateAtTimeBy(
                  summary.updated_at,
                  <UserName user={summary.updatedBy} display="full_name" linkProps={{theme: styles}} />,
                  null,
                  {jsx: true},
                ),
              },
          summary.external_data_set || oldSummary?.external_data_set
            ? {
                tid: 'extdataset',
                key: intl('Common.ExternalSet'),
                value: (
                  <Diff.Option
                    value={summary.external_data_set}
                    oldValue={oldSummary?.external_data_set}
                    noDiff={!oldSummary}
                  />
                ),
              }
            : null,
          summary.external_data_reference || oldSummary?.external_data_reference
            ? {
                tid: 'extdataref',
                key: intl('Common.ExternalReference'),
                value: isEntityEdited(summary.external_data_reference) ? (
                  <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapHorizontalWrap}`}>
                    <Badge type="updated" style={{lineHeight: 'var(--21px)'}}>
                      {intl('Common.Edited')}
                    </Badge>
                    {summary.external_data_reference}
                  </div>
                ) : (
                  <Diff.Option
                    value={summary.external_data_reference}
                    oldValue={oldSummary?.external_data_reference}
                    noDiff={!oldSummary}
                  />
                ),
              }
            : null,
        ]}
      </AttributeList>
    );
  }, [summaryObj, isAdditionPending]);

  return (
    <Link
      tid="ruleset-summary-tooltip"
      tooltip={rulesetSummary}
      tooltipProps={{
        trigger: 'click',
        maxWidth: 'calc(min(96vw, 640px))',
        interactive: true,
        noSingleton: true,
        theme: styles,
      }}
    >
      {title}
    </Link>
  );
}
