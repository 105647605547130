/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import {Button} from 'components';
import {Selector} from 'containers';
import stylesUtils from 'utils.css';
import {getRulesFilterCategories} from './RulesGridFilterUtils';

export default function RulesGridFilter(props) {
  const {pversion, onFilterChange, intraScopeRulesRows, extraScopeRulesRows, ipTablesRulesRows} = props;
  const [showFilter, setShowFilter] = useState(false);
  const prevPversionRef = useRef(pversion);

  const handleShowFilter = useCallback(() => setShowFilter(true), []);
  const handleCancelFilter = useCallback(() => {
    setShowFilter(false);
    onFilterChange(new Map());
  }, [onFilterChange]);

  const rows = useMemo(
    () => [...intraScopeRulesRows, ...extraScopeRulesRows, ...ipTablesRulesRows],
    [intraScopeRulesRows, extraScopeRulesRows, ipTablesRulesRows],
  );

  const categories = useMemo(() => getRulesFilterCategories(rows), [rows]);

  useEffect(() => {
    if (pversion !== prevPversionRef.current) {
      prevPversionRef.current = pversion;
      handleCancelFilter();
    }
  }, [pversion, handleCancelFilter]);

  return (
    <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontal)}>
      {showFilter ? (
        <>
          <Selector
            hideClearAll
            tid="rule-filter-selector"
            noActiveIndicator
            closeDropdownOnSelection
            placeholder={intl('Rulesets.Rules.FilterRules')}
            categories={categories}
            onSelectionChange={onFilterChange}
          />
          <Button
            color="standard"
            tid="rule-filter-cancel"
            noFill
            size="small"
            icon="cancel"
            onClick={handleCancelFilter}
          />
        </>
      ) : (
        <Button color="standard" tid="rule-filter" noFill size="small" icon="filter" onClick={handleShowFilter} />
      )}
    </div>
  );
}
