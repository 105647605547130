/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import apiSaga from 'api/apiSaga';
import {select, call} from 'redux-saga/effects';
import {Pill} from 'components';
import {getParameterizedPathLoosely} from 'api/apiUtils';
import {getOrgId} from 'containers/User/UserState';
import LabelEdit from 'containers/Label/Edit/LabelEdit';
import LabelGroupEdit from 'containers/LabelGroup/Edit/LabelGroupEdit';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {typesName, typePrefixRegexp} from 'components/Pill/Label/LabelUtils';
import {getQuery, sortOptions, getQueryAndKeyword} from './SelectorUtils';
import styleUtils from 'utils.css';
import styles from './Selector.css';

export const ADD_NEW_LABEL_ID = 'ADD_NEW_LABEL_ID';
export const ADD_NEW_LABEL_GROUP_ID = 'ADD_NEW_LABEL_GROUP_ID';

const allLabels = [
  {href: 'all_role', value: intl('Common.AllRoleLabels'), key: 'role'},
  {href: 'all_app', value: intl('Common.AllApplicationLabels'), key: 'app'},
  {href: 'all_env', value: intl('Common.AllEnvironmentLabels'), key: 'env'},
  {href: 'all_loc', value: intl('Common.AllLocationLabels'), key: 'loc'},
];

const getNoLabelHref = (orgId, key) =>
  getParameterizedPathLoosely({
    orgId,
    params: {key, exists: false},
    path: '/orgs/:xorg_id/labels?key=:key&exists=:exists',
  });

const getNoLabels = orgId => [
  {href: getNoLabelHref(orgId, 'role'), value: intl('Common.NoRoleLabel'), key: 'role'},
  {href: getNoLabelHref(orgId, 'app'), value: intl('Common.NoApplicationLabel'), key: 'app'},
  {href: getNoLabelHref(orgId, 'env'), value: intl('Common.NoEnvironmentLabel'), key: 'env'},
  {href: getNoLabelHref(orgId, 'loc'), value: intl('Common.NoLocationLabel'), key: 'loc'},
];

export const reshuffleLabels = (evt, args) => {
  const {value, values, resource} = args;
  const objType = resource.id;

  if (values.has(objType)) {
    // Selected labels needs to be re-shuffled:
    // 1) truncate the already selected labels of the same type and
    // 2) append the selections at the end of the list along with new selection
    const [labelsInSelectedType, labelsInRemainingTypes] = _.partition(
      values.get(objType),
      ({key}) => key === value.key,
    );

    values.delete(objType); // Remove the key from values Map so that new entry can be appended at the end of the list

    const newValuesInSelectedType = resource.optionProps.allowMultipleSelection
      ? [...labelsInSelectedType, value]
      : [value];

    values.set(objType, [...labelsInRemainingTypes, ...newValuesInSelectedType]);
  } else {
    values.set(objType, [value]);
  }

  return values;
};

export const CategoryPresets = {
  labelsAndLabelGroups: {
    id: 'labelsAndLabelGroups',
    name: intl('Rulesets.Rules.LabelAndLabelGroups'),
    resources: {
      labelsAndLabelGroups: {
        queryKeywordsRegex: typePrefixRegexp,
        *dataProvider(apiOptions) {
          const query = apiOptions.query;

          query.max_results = 25;

          const params = apiOptions.params;

          const {data: {matches: labels = []} = {}} = yield call(apiSaga, 'labels.autocomplete', {params, query});
          let {data: labelGroups} = yield call(apiSaga, 'label_groups.autocomplete', {
            params: {...params, pversion: 'draft'},
            query,
          });

          labelGroups = labelGroups.matches?.map(labelGroup => ({...labelGroup, value: labelGroup.name})) ?? [];

          const options = [...labels, ...labelGroups];

          const orgId = yield select(getOrgId);

          const noLabels = getNoLabels(orgId);

          let allOrNoLabels = [...allLabels, ...noLabels];

          if (query.key || query.exclude_keys) {
            allOrNoLabels = allOrNoLabels.filter(
              ({key}) => (!query.key || query.key === key) && !query.exclude_keys?.includes(key),
            );
          }

          return [...allOrNoLabels, ...sortOptions(options, query.query)];
        },
        apiArgs: {query: {getQuery}},
        optionProps: {
          isPill: true,
          format: ({option: label, fomattedOption}) => {
            if (label.isCreate) {
              return fomattedOption;
            }

            return (
              <Pill.Label insensitive type={label.key} group={label.href.includes('label_groups')}>
                {label.value ?? label.name}
              </Pill.Label>
            );
          },
          allowMultipleSelection: true,
          tooltipProps: {
            content: ({option: label}) => typesName[label.key],
          },
          hint: option => typesName[option.key],
        },
        selectedProps: {
          valueJoiner: 'and',
          hideResourceName: true,
          isPill: false,
          pillPropsResource: {theme: styles, themePrefix: 'joinerPill-'},
          formatValueText: label => (
            <>
              <Pill.Icon position="before" name={label.key} group={label.href.includes('label_groups')} />
              {label.value || label.name}
            </>
          ),
          formatResource: ({valuesInResource, formatContent, resource: {selectedProps}, theme}) => {
            const labelsByTypeArray = Object.values(_.groupBy(valuesInResource, 'key'));

            return labelsByTypeArray.map((labelsByType, index) => {
              return (
                <div key={index} className={cx(styleUtils.gapInline, styleUtils.gapHorizontal)}>
                  {index > 0 && selectedProps.resourceJoiner && (
                    <div className={theme.joiner}>{selectedProps.resourceJoiner}</div>
                  )}
                  {formatContent(labelsByType)}
                </div>
              );
            });
          },
        },
        allowCreateOptions: (query, exactMatches) => {
          const showLabelGroupCreate = !exactMatches.some(({href}) => href.includes('label_groups'));
          const showLabelCreate = !exactMatches.some(({href}) => href.includes('labels'));

          return [
            ...(showLabelCreate
              ? [{id: ADD_NEW_LABEL_ID, value: `${query} (${intl('Labels.New')})`, isCreate: true}]
              : []),
            ...(showLabelGroupCreate
              ? [{id: ADD_NEW_LABEL_GROUP_ID, value: `${query} (${intl('LabelGroups.New')})`, isCreate: true}]
              : []),
          ];
        },
        onCreateEnter: ({id}) => (id === ADD_NEW_LABEL_ID ? 'labelForm' : 'labelGroupForm'),
        onSelect: reshuffleLabels,
      },
      labelForm: {
        type: 'container',
        enableFocusLock: true,
        selectIntoResource: 'labelsAndLabelGroups',
        container: LabelEdit,
        unsavedWarningData: {...getDiscardChangesModalProps('label')},
        hidden: true,
        containerProps: {
          controlled: true,
          buttonAlign: 'bottom',
          formProps: {id: 'labelForm'},
          getContainerProps: ({query, onDone, onCancel}) => ({
            label: {value: getQueryAndKeyword(query, typePrefixRegexp).query},
            onDone,
            onCancel,
          }),
        },
      },
      labelGroupForm: {
        type: 'container',
        enableFocusLock: true,
        selectIntoResource: 'labelsAndLabelGroups',
        container: LabelGroupEdit,
        unsavedWarningData: {...getDiscardChangesModalProps('label_group')},
        hidden: true,
        containerProps: {
          controlled: true,
          buttonAlign: 'bottom',
          formProps: {id: 'labelGroupForm'},
          getContainerProps: ({query, onDone, onCancel}) => ({
            labelGroup: {
              detail: {draft: {name: getQueryAndKeyword(query, typePrefixRegexp).query}},
            },
            onDone,
            onCancel,
          }),
        },
      },
    },
  },
};
