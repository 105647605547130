/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PureComponent, createRef} from 'react';
import type {MutableRefObject, KeyboardEvent, KeyboardEventHandler, ComponentPropsWithoutRef} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {tidUtils} from 'utils';
import {Icon, Link, Tooltip} from 'components';
import type {LinkProps, LinkLikeProp, TooltipProps, LinkClass} from 'components';
import PillIcon from './Icon/PillIcon';
import PillDiff from './PillDiff';
import Endpoint from './Endpoint/Endpoint';
import Group from './Group/Group';
import GroupsDiff from './Group/GroupsDiff';
import IPListDiff from './IPList/IPListDiff';
import IPList from './IPList/IPList';
import LabelDiff from './Label/LabelDiff';
import Label from './Label/Label';
import Service from './Service/Service';
import ServiceDiff from './Service/ServiceDiff';
import VirtualServer from './VirtualServer/VirtualServer';
import VirtualService from './VirtualService/VirtualService';
import Workload from './Workload/Workload';
import type {WithElement} from 'utils/react';
import styles from './Pill.css';
import type {MouseEventLike, MouseEventLikeHandler} from 'utils/dom';
import type {ReactStrictNode} from 'utils/types';
import type {PillIconName, UpdateTypeWithTooltip} from './PillUtils';

type PillPropsBase = {
  icon?: PillIconName;
  hideIcon?: boolean;
  // Content of pill. Will also be used for title attribute if children is a text and title property is 'true'
  // Status will appear a colored dot in front of the label content
  status?: 'added' | 'modified' | 'allowed' | 'potentiallyBlocked' | 'blocked' | 'unknown';

  // Link parameters, if label is clickable to navigate
  link?: LinkLikeProp;

  // Useful to indicate policy object update type in top-right corner
  hideUpdateType?: boolean;

  children?: ReactStrictNode;
  // String of boolean which controls 'title' attribute
  title?: string; // Text that should be added to title attribute instead of children content

  // If pill displays a group (icon will have 'cloned' background)
  group?: boolean;
  // If extraScope is provided then pill is wrapped with a rounded rectangle and extrascope icon
  extraScope?: boolean;
  // If pill should have pin icon
  pinned?: boolean;
  // Makes pill not interactable (not clickable, not tabbable)
  insensitive?: boolean;

  // Mutually exclusive statuses: created - green, deleted - red crossed out, disabled - insensitive and apply disabled styles
  created?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  exclusion?: boolean;
  // ...mutuallyExclusiveTruePropsSpread('created', 'deleted', 'disabled', 'error'),

  onClick?: MouseEventLikeHandler;
  onClose?: MouseEventLikeHandler;

  onKeyUp?: KeyboardEventHandler;
  onKeyDown?: KeyboardEventHandler;

  // Additional tid that will be added to default one
  // For instance, if icon is 'role' and tid is 'added': 'comp-pill comp-pill-role comp-pill-added'
  tid?: string;

  // tooltip props
  tooltip?: TooltipProps['content'];
  tooltipProps?: TooltipProps;
};

export type PillProps = PillPropsBase &
  Omit<Partial<LinkProps> & ComponentPropsWithoutRef<'div'>, keyof PillPropsBase> &
  UpdateTypeWithTooltip &
  ThemeProps;

export default class Pill extends PureComponent<PillProps> implements WithElement {
  static Icon = PillIcon;
  static Diff = PillDiff;
  static Endpoint = Endpoint;
  static Group = Group;
  static GroupsDiff = GroupsDiff;
  static Label = Label;
  static LabelDiff = LabelDiff;
  static IPList = IPList;
  static IPListDiff = IPListDiff;
  static Service = Service;
  static ServiceDiff = ServiceDiff;
  static VirtualServer = VirtualServer;
  static VirtualService = VirtualService;
  static Workload = Workload;

  link?: LinkClass;
  element: HTMLElement | null = null;

  elementRef: MutableRefObject<HTMLElement | null>;

  constructor(props: PillProps) {
    super(props);

    this.elementRef = createRef(); // Separate ref object to pass down to Tooltip

    this.saveRef = this.saveRef.bind(this);
    this.saveLinkRef = this.saveLinkRef.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getPillTitle(): string | undefined {
    const {icon, children, title, extraScope} = this.props;

    let name = icon && Icon.getTitle(icon);
    let desc: string | undefined;

    if (extraScope) {
      name = `Extra Scope ${name}`;
    }

    if (typeof title === 'string') {
      desc = title;
    } else if (typeof children === 'string') {
      desc = children;
    }

    if (desc && name && name !== desc) {
      return `${name}: ${desc}`;
    }

    return name || desc;
  }

  private saveLinkRef(link: LinkClass) {
    this.link = link; // Save reference of the Link instance
    this.saveRef(link?.element ?? null); // Pass dom element further into saveRef
  }

  private saveRef(element: HTMLElement | null) {
    this.element = element;
    this.elementRef.current = element;
  }

  private handleKeyDown(evt: KeyboardEvent) {
    if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  private handleKeyUp(evt: KeyboardEvent) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }

    if (evt.key === ' ' || evt.key === 'Enter') {
      // Emulate click on Space and Enter
      this.props.onClick?.(evt);
    }
  }

  private handleClose(evt: MouseEventLike) {
    evt.stopPropagation(); // To stop click event on whole label

    this.props.onClose?.(evt);
  }

  render() {
    const {
      icon,
      hideIcon,
      status,
      updateType,
      updateTypeTooltip,
      // Note: updateType feature is disabled, set the default value of hideUpdateType to false to enable this feature
      hideUpdateType = true,
      title,
      link,
      children,
      group,
      extraScope,
      pinned,
      insensitive,
      created,
      deleted,
      disabled,
      error,
      warning,
      exclusion,
      onClose,
      tooltip,
      tooltipProps,
      theme,
      tid,
      ...rest
    } = mixThemeWithProps(styles, this.props);

    const isInsensitive = insensitive || disabled;

    const tids = tidUtils.getTid(group ? 'comp-pill-group' : 'comp-pill', [icon, tid]);

    const classes = cx(theme.pill, {
      [theme.extraScoped]: extraScope,
      [theme[status!]]: Boolean(status),
      [theme.created]: created,
      [theme.deleted]: deleted,
      [theme.disabled]: disabled,
      [theme.exclusion]: exclusion,
      [theme.error]: error,
      [theme.warning]: warning && !error,
      [theme.pinned]: pinned,
    });

    const sharedProps = {
      ...rest,
      'aria-label': typeof tooltip === 'string' ? tooltip : this.getPillTitle(),
      'data-tid': tids,
    };

    const child = (
      <>
        {extraScope && <Icon name="map" theme={theme} themePrefix="extraScoped-" />}
        <div className={theme.content}>
          {!hideUpdateType && updateType && (
            <Icon name="online" tooltip={updateTypeTooltip} theme={theme} themePrefix={`${updateType}-`} />
          )}
          {status && <Icon name="online" theme={theme} themePrefix="status-" />}
          {!hideIcon && icon && <PillIcon name={icon} group={group} theme={theme} themePrefix="pillIcon-" />}
          {children ? (
            <span className={theme.text} data-tid="elem-text">
              {children}
            </span>
          ) : null}

          {onClose ? (
            <div onClick={this.handleClose} className={theme.close} role="button">
              <Icon name="close" theme={theme} themePrefix="close-" />
              {pinned && <Icon name="pin" theme={theme} themePrefix="pinned-" />}
            </div>
          ) : null}
        </div>
      </>
    );

    let pill;

    if (link && !isInsensitive) {
      // If Pill is a Link, assign classes string to .link theme and link object properties to route properties.
      // It will become focusable and activatable by space/enter automatically
      const props = {
        ...sharedProps,
        ref: this.saveLinkRef,
        theme: Link.getLinkTheme(`${classes} ${theme.clickable}`),
        to: typeof link === 'string' ? link : undefined,
      };

      if (typeof link !== 'string') {
        Object.assign(props, link);
      }

      pill = <Link {...props}>{child}</Link>;
    } else {
      const interactive = rest.onClick && !isInsensitive;

      // If Pill is not a link but has onClick handler, make it focusable (tabIndex) and control keys manually
      pill = (
        <div
          {...sharedProps}
          ref={this.saveRef}
          className={cx(classes, {[theme.clickable]: interactive})}
          {...(interactive
            ? {
                role: 'button',
                tabIndex: rest.tabIndex || 0,
                onKeyUp: this.handleKeyUp,
                onKeyDown: this.handleKeyDown,
              }
            : undefined)}
        >
          {child}
        </div>
      );
    }

    return (
      <>
        {pill}
        {tooltip ? <Tooltip content={tooltip} reference={this.elementRef} {...tooltipProps} /> : null}
      </>
    );
  }
}
