/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import gridSaga from 'components/Grid/GridSaga';
import {RedirectError} from 'errors';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getSelectorSettings, gridSettings} from './NetworkListConfig';
import {getNetworks, getNetworksCount} from './NetworkListState';
import {isNetworkEnabled} from '../NetworkState';
import {isEdge} from 'containers/App/AppState';

export function* fetchNetworks({filter, force = false} = {}) {
  const query = {max_results: 500};

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        query[name] = value;
      }
    }
  }

  return yield call(apiSaga, 'networks.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getNetworks)) || count !== (yield select(getNetworksCount))) {
        yield put({type: 'NETWORK_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchNetworkList(route, refetch = false) {
  const networkIsEnabled = yield select(isNetworkEnabled);
  const edgeEnabled = yield select(isEdge);
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (__ANTMAN__ || !networkIsEnabled || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: gridSettings,
    filterMap: selectorSettingsObject.filterMap,
    *onSaga({filterParams}) {
      yield all([
        call(fetchNetworks, {
          filter: filterParams.isEmpty ? undefined : filterParams.valid,
          force: refetch,
        }),
        call(fetchAllUsers, {force: refetch}), // user name
      ]);
    },
  });
}
