/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {HorizontalTrain} from 'components';
import styles from './OnboardingGuide.css';
import {useSelector} from 'react-redux';
import {getOnboardingGuideStep} from '../OnboardingSteps';
import {getOnboardingState, hideOnboarding} from '../OnboardingStepsState';

const text = [
  {text: intl('Illumio.Welcometitle'), linkTo: 'landing'},
  {text: intl('Antman.Onboarding.AddWorkloads'), linkTo: 'workloads.list'},
  intl('Antman.Onboarding.LabelWorkloads'),
  intl('Antman.Onboarding.CreateSegmentationRule'),
];

export default function OnboardingGuide(): JSX.Element | null {
  const hideOnboardingGuide = useSelector(hideOnboarding);
  const props = useSelector(getOnboardingState);

  return hideOnboardingGuide ? null : (
    <div className={styles.wrapper}>
      <HorizontalTrain
        text={text}
        color="secondary"
        themePrefix="train-"
        currentStep={getOnboardingGuideStep(props.currentStep)}
        numSteps={text.length}
      />
    </div>
  );
}
