/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {createSelector} from 'reselect';
import {call} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {isKubernetesSupported} from 'containers/App/AppState';
import {Button, PillIcon, TypedMessages, Pill, Modal, Icon} from 'components';
import LabelEdit from 'containers/Label/Edit/LabelEdit';
import LabelGroupEdit from 'containers/LabelGroup/Edit/LabelGroupEdit';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {validatePortAndOrProtocol, lookupRegexPortProtocol} from 'containers/Service/ServiceUtils';
import {fetchAllServicesHref} from 'containers/Service/List/ServiceListSaga';
import {fetchAnyIPList} from 'containers/IPList/Item/IPListItemSaga';
import {typesName, labelTypes, typePrefixRegexp} from 'components/Pill/Label/LabelUtils';
import {sortOptions, getQuery, categorySuggestionRegex} from 'containers/Selector/SelectorUtils';
import tooltipStyles from 'components/Tooltip/Tooltip.css';
import {fetchPolicyServices, fetchConsumerPolicyServices} from '../RulesetItemSaga';
import ServiceEdit from 'containers/Service/Item/Edit/ServiceEdit';
import styles from '../RulesetItem.css';

const ADD_NEW_LABEL_ID = 'ADD_NEW_LABEL_ID';
const ADD_NEW_LABEL_GROUP_ID = 'ADD_NEW_LABEL_GROUP_ID';

const selectIntoIncludeResources = labelTypes.map(type => `${type}_include`);
const selectIntoExcludeResources = labelTypes.map(type => `${type}_exclude`);
export const selectIntoLabelResources = [...selectIntoIncludeResources, ...selectIntoExcludeResources];

const resourceNames = createSelector([], () => ({
  labels_include: intl('Rulesets.Rules.LabelAndLabelGroups'),
  labels_exclude: `${intl('PolicyGenerator.Excluded')} ${intl('Rulesets.Rules.LabelAndLabelGroups')}`,
  ip_list: intl('Common.IPLists'),
  workload: intl('Common.Workloads'),
  ...(isKubernetesSupported && {
    virtual_service: intl('Common.VirtualServices'),
    usesVirtualServicesAndWorkloads: `'${intl('Common.UsesVirtualServicesWorkloads')}'`,
    usesVirtualServicesOnly: `'${intl('Common.UsesVirtualServices')}'`,
  }),
  ...(!__ANTMAN__ && {virtual_server: intl('Common.VirtualServers')}),
  use_workload_subnets: `'${intl('Rulesets.Rules.UseWorkloadSubnets')}'`,
  container_host: `'${intl('Common.ContainerHost')}'`,
  consuming_security_principals: intl('Common.UserGroups'),
  allWorkloads: `'${intl('Workloads.All')}'`,
  anyIp: `'${intl('IPLists.Any')}'`,
  services: intl('Services.PolicyServices'),
  ports: intl('Rulesets.Rules.PortOrPortRange'),
  allServices: `'${intl('Common.AllServices')}'`,
}));

export const redundantResources = {
  labels_include: ['allWorkloads', 'container_host'],
  labels_exclude: [
    'allWorkloads',
    'workload',
    'container_host',
    ...(isKubernetesSupported ? ['virtual_service', 'usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
    ...(!__ANTMAN__ ? ['virtual_server'] : []),
  ],
  allWorkloads: [
    ...selectIntoIncludeResources,
    ...selectIntoExcludeResources,
    'workload',
    'container_host',
    ...(isKubernetesSupported ? ['virtual_service'] : []),
    ...(!__ANTMAN__ ? ['virtual_server'] : []),
  ],
  anyIp: [
    'ip_list',
    'use_workload_subnets',
    'container_host',
    'consuming_security_principals',
    ...(isKubernetesSupported ? ['usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
  ],
  ...(isKubernetesSupported && {
    usesVirtualServicesAndWorkloads: [
      ...selectIntoExcludeResources,
      'workload',
      'ip_list',
      'anyIp',
      'use_workload_subnets',
      'container_host',
      'usesVirtualServicesAndWorkloads',
    ],
    usesVirtualServicesOnly: [
      ...selectIntoExcludeResources,
      'workload',
      'ip_list',
      'anyIp',
      'use_workload_subnets',
      'container_host',
      'usesVirtualServicesAndWorkloads',
    ],
  }),
  ip_list: [
    'anyIp',
    'use_workload_subnets',
    'container_host',
    'consuming_security_principals',
    ...(isKubernetesSupported ? ['usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
  ],
  workload: [
    ...selectIntoExcludeResources,
    'allWorkloads',
    'labels_exclude',
    'use_workload_subnets',
    'container_host',
    ...(isKubernetesSupported ? ['usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
  ],
  ...(isKubernetesSupported && {
    virtual_service: [
      'usesVirtualServicesAndWorkloads',
      'use_workload_subnets',
      ...selectIntoExcludeResources,
      'container_host',
    ],
  }),
  ...(!__ANTMAN__ && {
    virtual_server: [
      'usesVirtualServicesAndWorkloads',
      'use_workload_subnets',
      ...selectIntoExcludeResources,
      'container_host',
    ],
  }),
  use_workload_subnets: [
    'workload',
    'ip_list',
    'anyIp',
    'container_host',
    'consuming_security_principals',
    ...(isKubernetesSupported ? ['virtual_service', 'usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
    ...(!__ANTMAN__ ? ['virtual_server'] : []),
  ],
  services: ['allServices'],
  ports: ['allServices'],
  allServices: ['services', 'ports'],
};

const stickyResourceConfig = {
  sticky: true,
  optionProps: {
    isPill: true,
    noFilter: true,
  },
  selectedProps: {hideResourceName: true},
};

const endpointHistoryProps = {
  enableHistory: true,
  historyKey: 'endpoint',
};

// eslint-disable-next-line no-underscore-dangle
const getParentTippyInstance = evt => evt.target.closest(`.${tooltipStyles.tooltip}`).parentElement._tippy;

const handleDoneTooltip = (evt, {onSelect, option, resource}) => {
  const confirmedValue = {...(typeof option === 'string' ? {value: option} : option), confirmed: true};

  const tippyInstance = getParentTippyInstance(evt);

  tippyInstance?.hide();
  onSelect(evt, {resourceId: resource.id, value: confirmedValue});
};

const handleCancelTooltip = evt => {
  // eslint-disable-next-line no-underscore-dangle
  const tippyInstance = evt.target.closest(`.${tooltipStyles.tooltip}`).parentElement._tippy;

  tippyInstance?.hide();
};

const getWarningContent = (message, {option, resource, onSelect}) => (
  <div>
    <TypedMessages>
      {[
        {
          icon: 'warning',
          content: message,
        },
      ]}
    </TypedMessages>
    <div className={styles.buttons}>
      <Button size="small" text="Yes" onClick={_.partial(handleDoneTooltip, _, {onSelect, resource, option})} />
      <Button color="standard" size="small" text="No" onClick={handleCancelTooltip} />
    </div>
  </div>
);

const tooltipProps = {
  trigger: 'click',
  visible: undefined,
  interactive: true,
  flipBehavior: ['right', 'left', 'top', 'bottom'],
  onShow: ({popper}) => {
    popper.focus();
    setTimeout(() => popper.querySelector('button')?.focus(), 500);
  },
  content: options => {
    const {option, values, resource} = options;
    const resourceId = option.resourceId ?? resource.id;
    const redundantSelections = redundantResources[resourceId];

    if (!redundantSelections?.length) {
      return null;
    }

    const replaceResources = [
      ...redundantSelections.reduce((result, id) => {
        if (values.has(id)) {
          if (selectIntoLabelResources.includes(id)) {
            // reduce to return labels_include or labels_exclude and then read name from resource name
            result.add(id.includes('include') ? 'labels_include' : 'labels_exclude');
          } else {
            result.add(id);
          }
        }

        return result;
      }, new Set()),
    ];

    return replaceResources.length
      ? getWarningContent(
          intl('Rulesets.Rules.SelectingReplacesConfirmation', {
            resourceName: resourceNames()[resourceId],
            selectedResourcesNames: intl.list(replaceResources.map(id => resourceNames()[id])),
          }),
          options,
        )
      : null;
  },
};

const labelSelectedProps = {
  hideResourceName: true,
  valueJoiner: 'or',
  isPill: false,
  formatValueText: label => (
    <>
      <PillIcon position="before" name={label.key} group={label.href.includes('label_groups')} />
      {label.value || label.name}
    </>
  ),
  pillPropsResource: {theme: styles, themePrefix: 'joinerPill-'},
};

const confirmationOnSelect = (evt, {value, values, resource}) => {
  const redundantSelections = redundantResources[resource.id]?.filter(id => values.has(id));

  if (redundantSelections?.length) {
    if (value.confirmed) {
      redundantSelections.forEach(id => values.delete(id));
    } else {
      return values;
    }
  }
};

const forms = {
  labelForm: {
    type: 'container',
    historyKey: 'labels.autocomplete',
    enableFocusLock: true,
    container: LabelEdit,
    containerProps: {
      controlled: true,
      buttonAlign: 'bottom',
      formProps: {id: 'labelForm'},
    },
    unsavedWarningData: {...getDiscardChangesModalProps('label')},
    hidden: true,
  },
  labelGroupForm: {
    type: 'container',
    historyKey: 'label_groups.autocomplete',
    controlled: true,
    buttonAlign: 'bottom',
    enableFocusLock: true,
    container: LabelGroupEdit,
    containerProps: {
      controlled: true,
      buttonAlign: 'bottom',
      formProps: {id: 'labelGroupForm'},
    },
    unsavedWarningData: {...getDiscardChangesModalProps('label_group')},
    hidden: true,
  },
};

const resources = createSelector([], () => ({
  labelsResource: {
    enableHistory: true,
    queryKeywordsRegex: typePrefixRegexp,
    *dataProvider(apiOptions) {
      const query = apiOptions.query;

      query.max_results = 25;
      query.resource_type = 'rule_sets';

      const params = apiOptions.params;

      const {data: labels} = yield call(apiSaga, 'labels.autocomplete', {params, query});
      let {data: labelGroups} = yield call(apiSaga, 'label_groups.autocomplete', {
        params: {...params, pversion: 'draft'},
        query,
      });

      labelGroups = labelGroups.matches?.map(labelGroup => ({...labelGroup, value: labelGroup.name})) ?? [];

      const options = [...(labels?.matches ?? []), ...(labelGroups ?? [])];

      return sortOptions(options, query.query);
    },
    apiArgs: {query: {getQuery}},
    optionProps: {
      isPill: true,
      format: ({option: label, fomattedOption, resource}) => {
        if (label.isCreate) {
          return fomattedOption;
        }

        return (
          <Pill.Label
            insensitive
            type={label.key}
            group={label.href.includes('label_groups')}
            exclusion={resource.id.includes('exclude')}
          >
            {label.name ?? label.value}
          </Pill.Label>
        );
      },
      allowMultipleSelection: true,
      tooltipProps: {
        content: ({option: label}) => typesName[label.key],
      },
      hint: option => typesName[option.key],
    },
  },
  allWorkloads: {
    sticky: true,
    statics: [intl('Workloads.All')],
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps,
    },
    selectedProps: {hideResourceName: true},
    onSelect: confirmationOnSelect,
  },
  anyIp: {
    sticky: true,
    *dataProvider() {
      const {data} = yield call(fetchAnyIPList);

      return [{href: data.href, name: intl('IPLists.Any')}];
    },
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps,
    },
    selectedProps: {hideResourceName: true},
    onSelect: confirmationOnSelect,
  },
  ...(isKubernetesSupported && {
    usesVirtualServicesOnly: {
      sticky: true,
      statics: [intl('Common.UsesVirtualServices')],
      optionProps: {
        isPill: true,
        noFilter: true,
        tooltipProps,
      },
      selectedProps: {hideResourceName: true},
      onSelect: confirmationOnSelect,
    },
    usesVirtualServicesAndWorkloads: {
      sticky: true,
      statics: [intl('Common.UsesVirtualServicesWorkloads')],
      optionProps: {
        isPill: true,
        noFilter: true,
        tooltipProps,
      },
      selectedProps: {hideResourceName: true},
      onSelect: confirmationOnSelect,
    },
  }),
  use_workload_subnets: {
    ...stickyResourceConfig,
    statics: [intl('Rulesets.Rules.UseWorkloadSubnets')],
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps,
    },
    selectedProps: {hideResourceName: true},
    onSelect: confirmationOnSelect,
  },
  container_host: {
    ...stickyResourceConfig,
    statics: [intl('Common.ContainerHost')],
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps: {
        ...tooltipProps,
        content: options => {
          if (options.values.size > 0) {
            return getWarningContent(intl('Rulesets.Rules.SelectingContainerHostConfirmation'), options);
          }
        },
      },
    },
    selectedProps: {hideResourceName: true},
    onSelect: (evt, {value, values}) => {
      if (values.size > 0) {
        if (value.confirmed) {
          values.clear();
        } else {
          return values;
        }
      }
    },
  },
  ip_list: {
    dataProvider: 'ip_lists.autocomplete',
    apiArgs: {query: {max_results: 25}, params: {pversion: 'draft'}},
    optionProps: {
      filterOption: (option, values) =>
        option.href?.includes('ip_lists') &&
        option.name !== intl('IPLists.Any') &&
        !values.get('ip_list')?.some(({href}) => href === option.href),
      allowMultipleSelection: true,
      isPill: true,
      pillProps: value => ({icon: value.fqdn ? 'domain' : 'allowlist'}),
      tooltipProps,
    },
    onSelect: confirmationOnSelect,
  },
}));

const getEndpointLabelModifier = advanced => ({
  ...endpointHistoryProps,
  onSelect: (evt, options = {}) => {
    const redundantResourceResult = confirmationOnSelect(evt, options);

    if (redundantResourceResult) {
      return redundantResourceResult;
    }

    // Explicitly replace specific include/exclude keys
    const {values, resource} = options;
    const {confirmed, ...value} = options.value;

    const isInclude = resource.id.includes('include');

    const selectIntoResource = `${value.key}_${isInclude ? 'include' : 'exclude'}`;
    const replaceResource = `${value.key}_${isInclude ? 'exclude' : 'include'}`;

    if (values.has(replaceResource)) {
      if (confirmed) {
        values.delete(replaceResource);
      } else {
        return values;
      }
    }

    const selectedInType = values.get(selectIntoResource) ?? [];

    values.delete(selectIntoResource);

    values.set(selectIntoResource, [...selectedInType, value]);

    return values;
  },
  optionProps: {
    filterOption: (option, values, {id}) => {
      const isInclude = id.includes('include');

      if (!advanced && !isInclude) {
        // Exclude labels should not show in simplified mode
        return false;
      }

      const selectedValues = values.get(`${option.key}_${isInclude ? 'include' : 'exclude'}`);

      return option.href.includes('label') && !selectedValues?.some(({href}) => href === option.href);
    },
    tooltipProps: {
      ...tooltipProps,
      content: options => {
        const {option, values, resource} = options;
        const resourceId = resource.id === 'combined' ? option.resourceId : resource.id;
        const selectingInclude = resourceId.includes('include');

        if (values.has(`${option.key}_${selectingInclude ? 'exclude' : 'include'}`)) {
          return getWarningContent(
            intl('Rulesets.Rules.SelectingLabelConfirmation', {selectingInclude, type: option.key}),
            options,
          );
        }

        return tooltipProps.content(options);
      },
    },
  },
});

const formatSelectedLabel = ({value: label, resource, disabled, warning, theme, onRemove, highlighted, onClick}) => (
  <Pill.Label
    theme={theme}
    themePrefix={highlighted ? 'pillHighlighted-' : undefined}
    onClick={disabled ? undefined : onClick}
    onClose={disabled ? undefined : onRemove}
    type={label.key}
    group={label.href.includes('label_groups')}
    exclusion={resource.id.includes('exclude')}
    warning={warning}
  >
    {label.value ?? label.name}
  </Pill.Label>
);

const getEndpointSelectedLabelModifier = ({type, warnings = {}, exclusion = false}) => ({
  ...endpointHistoryProps,
  selectedProps: {
    ...labelSelectedProps,
    ...(exclusion ? {valueJoiner: 'and'} : {}),
    joinerIsPill: false, // set it to false, joiner pill formatting is added in formatResource prop below
    formatResource: ({theme, resource, valuesInResource, formatContent, onClick, onRemove}) => {
      if (valuesInResource.length === 1) {
        return formatSelectedLabel({
          value: valuesInResource[0],
          warning: Boolean(warnings[type]),
          resource,
          theme,
          onRemove: _.partial(onRemove, _, valuesInResource[0].href),
          onClick,
        });
      }

      return (
        <Pill theme={styles} themePrefix="joinerPill-" exclusion={exclusion} warning={Boolean(warnings[type])}>
          {exclusion && `${intl('Labels.Excluded', {type})} `}
          {formatContent(valuesInResource)}
        </Pill>
      );
    },
  },
});

export const advancedResourcesIds = [
  ...labelTypes.map(type => `${type}_exclude`),
  ...(!__ANTMAN__ ? ['virtual_server'] : []),
  'consuming_security_principals',
  'virtual_service',
  'usesVirtualServicesAndWorkloads',
  'use_workload_subnets',
];

const advancedCategoriesIds = ['excludeLabels', 'virtual_service', 'virtual_server', 'consuming_security_principals'];

export const useAdvancedOptionInfoPanel = ({query, categories}) => {
  const parts = categorySuggestionRegex.test(query) ? query.split(categorySuggestionRegex) : [];
  const categoryHintText = parts[2]?.trimStart();

  if (categoryHintText) {
    const matchingCategoryId = categories.find(({name}) =>
      name?.toLowerCase().includes(categoryHintText.toLowerCase()),
    )?.id;

    // Show an info text to search in advanced options
    if (advancedCategoriesIds.includes(matchingCategoryId)) {
      return intl('Rulesets.Rules.UseAdvancedOptions');
    }
  }
};

const getEndpointLabelsResources = ({type, advanced, warnings, exclusion}) => ({
  infoPanel: advanced ? null : useAdvancedOptionInfoPanel,
  hidden: exclusion && !advanced,
  resources: {
    [`labels_${type}`]: getEndpointLabelModifier(advanced),
    ...labelTypes.reduce(
      (result, key) => ({
        ...result,
        [`${key}_${type}`]: getEndpointSelectedLabelModifier({type: key, warnings, exclusion}),
      }),
      {},
    ),
  },
});

const getLabelResources = type => ({
  [`labels_${type}`]: resources().labelsResource,
  ...labelTypes.reduce((result, key) => ({...result, [`${key}_${type}`]: {hidden: true}}), {}),
  [`labelForm_${type}`]: {...forms.labelForm, selectIntoResource: ({value}) => `${value.key}_${type}`},
  [`labelGroupForm_${type}`]: {
    ...forms.labelGroupForm,
    selectIntoResource: ({value}) => `${value.key}_${type}`,
  },
});

const getLabelsCategory = (exclusion = false, mergeProps) => {
  return _.merge(
    _.cloneDeep({
      id: exclusion ? 'excludeLabels' : 'includeLabels',
      name: exclusion ? intl('Rulesets.Rules.LabelAndLabelGroupsExcept') : intl('Rulesets.Rules.LabelAndLabelGroups'),
      resources: getLabelResources(exclusion ? 'exclude' : 'include'),
    }),
    mergeProps,
  );
};

export const getEndpointCategories = ({
  advanced = false,
  showUserGroups,
  showContainerHost,
  showVirtualServers,
  warnings,
  isDenyRule = false,
}) => {
  const {
    allWorkloads,
    anyIp,
    usesVirtualServicesAndWorkloads,
    usesVirtualServicesOnly,
    use_workload_subnets,
    ip_list,
    container_host,
  } = resources();

  return [
    {
      id: 'stickyCategory',
      sticky: true,
      resources: {
        allWorkloads,
        anyIp,
        usesVirtualServicesAndWorkloads: {
          ...(!isDenyRule && usesVirtualServicesAndWorkloads),
          hidden: !advanced || (!isKubernetesSupported && !isDenyRule),
        },
        usesVirtualServicesOnly: {
          ...(!isDenyRule && usesVirtualServicesOnly),
          hidden: !advanced || (!isKubernetesSupported && !isDenyRule),
        },
        use_workload_subnets: {...use_workload_subnets, hidden: !advanced},
        ...(showContainerHost && {container_host: {...container_host, hidden: !advanced}}),
      },
    },
    getLabelsCategory(false, {
      resources: {
        labels_include: getEndpointLabelModifier(advanced),
        ...labelTypes.reduce((result, type) => {
          result[`${type}_include`] = getEndpointSelectedLabelModifier({type, warnings});

          return result;
        }, {}),
      },
    }),
    ...(!__ANTMAN__
      ? [
          getLabelsCategory(true, {
            resources: {
              labels_exclude: getEndpointLabelModifier(advanced),
              ...labelTypes.reduce((result, type) => {
                result[`${type}_exclude`] = getEndpointSelectedLabelModifier({type, warnings, exclusion: true});

                return result;
              }, {}),
            },
          }),
        ]
      : []),
    {
      id: 'ip_list',
      name: intl('Common.IPLists'),
      infoPanel: advanced ? null : useAdvancedOptionInfoPanel,
      resources: {
        ip_list: {
          ...endpointHistoryProps,
          ...ip_list,
          selectedProps: {
            valueJoiner: 'or',
            pillPropsResource: {warning: Boolean(warnings?.ip_list)},
          },
        },
      },
    },
    {
      id: 'workload',
      hidden: __ANTMAN__ && isDenyRule,
      name: intl('Common.Workloads'),
      infoPanel: advanced ? null : useAdvancedOptionInfoPanel,
      resources: {
        workload: {
          ...endpointHistoryProps,
          dataProvider: 'workloads.autocomplete',
          apiArgs: {query: {max_results: 25}},
          optionProps: {
            filterOption: (option, values) =>
              option.href?.includes('workloads') && !values.get('workload')?.some(({href}) => href === option.href),
            allowMultipleSelection: true,
            isPill: true,
            pillProps: {icon: 'workload'},
            tooltipProps,
          },
          selectedProps: {valueJoiner: 'or'},
          onSelect: confirmationOnSelect,
        },
      },
    },
    ...(showUserGroups
      ? [
          {
            id: 'consuming_security_principals',
            name: intl('Common.UserGroups'),
            hidden: !advanced,
            resources: {
              consuming_security_principals: {
                ...endpointHistoryProps,
                dataProvider: 'security_principals.autocomplete',
                apiArgs: {query: {max_results: 25}},
                optionProps: {
                  filterOption: option => advanced && option.href?.includes('security_principals'),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'org'},
                  tooltipProps,
                },
                selectedProps: {valueJoiner: 'or'},
                onSelect: confirmationOnSelect,
              },
            },
          },
        ]
      : []),
    ...(isKubernetesSupported && !isDenyRule
      ? [
          {
            id: 'virtual_service',
            name: intl('Common.VirtualServices'),
            hidden: !advanced,
            resources: {
              virtual_service: {
                ...endpointHistoryProps,
                dataProvider: 'virtual_services.autocomplete',
                apiArgs: {query: {max_results: 25}, params: {pversion: 'draft'}},
                optionProps: {
                  filterOption: option => advanced && option.href?.includes('virtual_services'),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'virtual-service'},
                  tooltipProps,
                },
              },
            },
          },
        ]
      : []),
    ...(!__ANTMAN__ && showVirtualServers
      ? [
          {
            id: 'virtual_server',
            name: intl('Common.VirtualServers'),
            hidden: !advanced,
            resources: {
              virtual_server: {
                ...endpointHistoryProps,
                dataProvider: 'virtual_servers.autocomplete',
                apiArgs: {query: {max_results: 25}, params: {pversion: 'draft'}},
                optionProps: {
                  filterOption: option => advanced && option.href?.includes('virtual_servers'),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'virtual-server'},
                  tooltipProps,
                },
                selectedProps: {valueJoiner: 'or'},
                onSelect: confirmationOnSelect,
              },
            },
          },
        ]
      : []),
  ];
};

const getPortOptions = query => validatePortAndOrProtocol(undefined, query);

const handleServiceSave = (options, evt, service) => {
  const {onSelect, setCategories, onReturnFocusToInput} = options;

  onSelect(evt, {value: service, resourceId: 'services'});

  setCategories({policyServices: {active: true}});
  onReturnFocusToInput({force: true});
};

const getPolicyServicesCategory = (type, confirmationOnSelect, tooltipProps) => {
  const keyOrId = !__ANTMAN__
    ? 'policyServices'
    : type === 'providers'
    ? 'providerPolicyServices'
    : 'consumerPolicyServices';

  return {
    id: keyOrId,
    name:
      !__ANTMAN__ || type === 'providers'
        ? intl('Services.PolicyServices')
        : intl('Antman.Services.SourceProcessServices'),
    resources: {
      services: {
        enableHistory: true,
        historyKey: !__ANTMAN__ ? 'rulePolicyServices' : keyOrId,
        dataProvider: type === 'providers' ? fetchPolicyServices : fetchConsumerPolicyServices,
        optionProps: {
          allowMultipleSelection: true,
          format: ({option}) => <Pill.Service value={option} showPorts={type === 'providers'} insensitive />,
          tooltipProps,
          filterOption: option =>
            type === 'providers' || type === 'consumers' ? option.name !== intl('Common.AllServices') : option,
        },
        onSelect: confirmationOnSelect,
        selectedProps: {
          pillPropsValue: {icon: 'service'},
          hideResourceName: true,
        },
      },
    },
  };
};

const getResourceForm = (type, handleServiceSave) => {
  return {
    id: 'serviceForm',
    displayResourceAsCategory: true,
    resources: {
      serviceForm: {
        noEmptyBanner: true,
        statics: [intl('Services.AddNew')],
        optionProps: {
          noFilter: true,
          format: options => (
            <Modal.PageInvoker
              edit
              notScrollable
              dontRestrainChildren
              title={intl('Edge.ServiceSelection.AddNewService')}
              container={ServiceEdit}
              onClose={_.partial(options.onReturnFocusToInput, {force: true})}
              onDone={_.partial(handleServiceSave, options)}
              containerProps={{controlled: true, isConsumer: type === 'consumers'}}
            >
              {({handleOpen}) => (
                <span className={styles.addNewServiceText} onClick={handleOpen}>
                  <Icon position="before" name="add" />
                  {options.option}
                </span>
              )}
            </Modal.PageInvoker>
          ),
        },
        onSelect: (evt, {values}) => values,
      },
    },
  };
};

export const providerServiceCategories = createSelector([], () => {
  const categories = [
    getPolicyServicesCategory('providers', confirmationOnSelect, tooltipProps),
    {
      id: 'ports',
      name: intl('Rulesets.Rules.PortOrPortRange'),
      resources: {
        ports: {
          enableHistory: true,
          historyKey: 'rulePorts',
          statics: getPortOptions,
          selectedProps: {hideResourceName: true},
          optionProps: {
            filterOption: ({value} = {}) => {
              if (lookupRegexPortProtocol('protocolOnly').test(value?.toUpperCase())) {
                return false;
              }

              return !value.includes(intl('Protocol.ICMP'));
            },
            isPill: true,
            allowMultipleSelection: true,
            tooltipProps,
          },
          onSelect: confirmationOnSelect,
          validate: query => {
            if (query && getPortOptions(query).length === 0) {
              throw new Error(intl('Port.InvalidPortPortRange'));
            }
          },
        },
      },
    },
    getResourceForm('providers', handleServiceSave),
    {
      id: 'allServices',
      resources: {
        allServices: {
          sticky: true,
          *dataProvider() {
            const allServicesHref = yield call(fetchAllServicesHref);

            return [{href: allServicesHref, name: intl('Common.AllServices')}];
          },
          optionProps: {
            isPill: true,
            noFilter: true,
            tooltipProps,
          },
          selectedProps: {hideResourceName: true},
          onSelect: confirmationOnSelect,
        },
      },
    },
  ];

  if (__ANTMAN__) {
    [categories[0], categories[1]] = [categories[1], categories[0]];
  }

  return categories;
});

export const consumerServicesCategories = createSelector([], () => [
  getPolicyServicesCategory('consumers', confirmationOnSelect, tooltipProps),
  getResourceForm('consumers', handleServiceSave),
]);

const scopeHistoryProps = {
  enableHistory: true,
  historyKey: 'rulesetScope',
};

const scopeLabelModifier = {
  allowCreateOptions: (query, exactMatches) => {
    const showLabelGroupCreate = !exactMatches.some(({href}) => href.includes('label_groups'));
    const showLabelCreate = !exactMatches.some(({href}) => href.includes('labels'));

    return [
      ...(showLabelCreate ? [{id: ADD_NEW_LABEL_ID, value: `${query} (${intl('Labels.New')})`, isCreate: true}] : []),
      ...(showLabelGroupCreate
        ? [{id: ADD_NEW_LABEL_GROUP_ID, value: `${query} (${intl('LabelGroups.New')})`, isCreate: true}]
        : []),
    ];
  },
  onCreateEnter: ({id}, {id: resourceId}) => {
    const resourceString = resourceId.includes('exclude') ? 'exclude' : 'include';

    return id === ADD_NEW_LABEL_ID ? `labelForm_${resourceString}` : `labelGroupForm_${resourceString}`;
  },
};

const scopeSelectIntoResourceModifier = {
  ...scopeHistoryProps,
  selectedProps: {formatValue: formatSelectedLabel, isPill: false},
  onSelect: (evt, {value, values}) => {
    const typeAlreadySelectedInResourceId = values.has(`${value.key}_include`)
      ? `${value.key}_include`
      : values.has(`${value.key}_exclude`)
      ? `${value.key}_exclude`
      : null;

    if (typeAlreadySelectedInResourceId) {
      values.delete(typeAlreadySelectedInResourceId);
    }
  },
};

const getScopeResources = type => ({
  resources: {
    [`labels_${type}`]: {
      ...scopeLabelModifier,
      ...scopeHistoryProps,
      selectIntoResource: ({value}) => `${value.key}_${type}`,
    },
    ...labelTypes.reduce((result, key) => ({...result, [`${key}_${type}`]: scopeSelectIntoResourceModifier}), {}),
  },
});

export const scopeCategories = [
  getLabelsCategory(false, getScopeResources('include')),
  ...(!__ANTMAN__ ? [getLabelsCategory(true, getScopeResources('exclude'))] : []),
];

const ruleOptionsIcons = createSelector([], () => ({
  [intl('Common.SecureConnect')]: 'secure-connect',
  [intl('Common.Stateless')]: 'deny',
  [intl('Common.MachineAuthentication')]: 'machine-auth',
  ...(!__ANTMAN__ && {
    //Both 'non_brn' and 'all' network type uses the same icon
    [intl('Rulesets.Rules.NonCorporateNetworks')]: 'non-brn',
    [intl('Rulesets.Rules.AllNetworks')]: 'non-brn',
  }),
}));

const redundantRuleOptions = {
  ...(!__ANTMAN__ && {
    [intl('Rulesets.Rules.NonCorporateNetworks')]: [
      intl('Rulesets.Rules.AllNetworks'),
      intl('Common.MachineAuthentication'),
      intl('Common.SecureConnect'),
    ],
    [intl('Rulesets.Rules.AllNetworks')]: [
      intl('Rulesets.Rules.NonCorporateNetworks'),
      intl('Common.MachineAuthentication'),
      intl('Common.SecureConnect'),
    ],
  }),
  [intl('Common.SecureConnect')]: !__ANTMAN__
    ? [intl('Rulesets.Rules.NonCorporateNetworks'), intl('Rulesets.Rules.AllNetworks'), intl('Common.Stateless')]
    : [intl('Common.Stateless')],
  [intl('Common.MachineAuthentication')]: !__ANTMAN__
    ? [intl('Rulesets.Rules.NonCorporateNetworks'), intl('Rulesets.Rules.AllNetworks'), intl('Common.Stateless')]
    : [intl('Common.Stateless')],
  [intl('Common.Stateless')]: [intl('Common.SecureConnect'), intl('Common.MachineAuthentication')],
};

export const ruleOptionsCategories = createSelector([], () => {
  return [
    {
      id: 'ruleOptions',
      resources: {
        ruleOptions: {
          statics: [
            intl('Common.SecureConnect'),
            intl('Common.Stateless'),
            intl('Common.MachineAuthentication'),
            ...(!__ANTMAN__ ? [intl('Rulesets.Rules.NonCorporateNetworks'), intl('Rulesets.Rules.AllNetworks')] : []),
          ],
          optionProps: {
            allowMultipleSelection: true,
            isPill: true,
            pillProps: option => ({
              icon: ruleOptionsIcons()[option],
            }),
            tooltipProps: {
              ...tooltipProps,
              content: options => {
                const {option, values} = options;

                const selectedValues = values.get('ruleOptions') ?? [];
                const redundantOptions = redundantRuleOptions[option];
                const replacesValues = selectedValues.filter(value => redundantOptions.includes(value));

                return replacesValues.length
                  ? getWarningContent(
                      intl('Rulesets.Rules.SelectingReplacesConfirmation', {
                        resourceName: `'${option}'`,
                        selectedResourcesNames: intl.list(replacesValues.map(value => `'${value}'`)),
                      }),
                      options,
                    )
                  : null;
              },
            },
          },
          selectedProps: {
            hideResourceName: true,
            pillPropsValue: value => ({
              icon: ruleOptionsIcons()[value],
            }),
          },
          onSelect: (evt, {value, values}) => {
            const selectedValues = values.get('ruleOptions') ?? [];
            const redundantOptions = redundantRuleOptions[value.value ?? value];

            if (selectedValues.some(value => redundantOptions.includes(value))) {
              if (value.confirmed) {
                values.set('ruleOptions', [
                  ...selectedValues.filter(value => !redundantOptions.includes(value)),
                  value.value,
                ]);
              }

              return values;
            }
          },
        },
      },
    },
  ];
});

export const getReceiverCategories = ({warnings}) => [
  getLabelsCategory(false, getEndpointLabelsResources({type: 'include', warnings, advanced: true})),
  {
    id: 'stickyCategory',
    sticky: true,
    resources: {
      allWorkloads: resources().allWorkloads,
    },
  },
  {
    id: 'workload',
    name: intl('Common.Workloads'),
    resources: {
      workload: {
        ...endpointHistoryProps,
        dataProvider: 'workloads.autocomplete',
        apiArgs: {query: {max_results: 25}},
        optionProps: {
          allowMultipleSelection: true,
          isPill: true,
          pillProps: {icon: 'workload'},
          tooltipProps,
        },
        selectedProps: {valueJoiner: 'or'},
        onSelect: confirmationOnSelect,
      },
    },
  },
];
