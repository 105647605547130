/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Icon} from 'components';
import {createRef, PureComponent} from 'react';
import type {RefObject, MouseEvent, KeyboardEvent} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './OptionItem.css';
import {tidUtils} from 'utils';

const defaultSelectorTid = 'comp-field-selector';

export interface Item {
  name: string | number;
  value: string | number;
  notSelectable?: boolean;
  subLabel?: string;
  label: string;
}

interface OptionItemProps extends ThemeProps {
  item: Item;
  onChange: (evt: MouseEvent, item: Item) => void;
  onKeyDown: (evt: KeyboardEvent, item: Item) => void;
  labelIsActive?: boolean;
  tid: string;
}

const defaultProps = {
  labelIsActive: false,
};

type DefaultProps = Required<Pick<OptionItemProps, keyof typeof defaultProps>>;
type OptionItemPropsIn = OptionItemProps & DefaultProps;

export default class OptionItem extends PureComponent<OptionItemPropsIn> {
  itemElement: RefObject<HTMLLIElement>;

  static defaultProps: DefaultProps = defaultProps;

  constructor(props: OptionItemPropsIn) {
    super(props);
    this.itemElement = createRef();

    this.handleItemOnChange = this.handleItemOnChange.bind(this);
    this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  handleItemOnChange(evt: MouseEvent): void {
    this.props.onChange(evt, this.props.item);
  }

  handleOnKeyDown(evt: KeyboardEvent): void {
    this.props.onKeyDown(evt, this.props.item);
  }

  handleOnMouseMove(): void {
    const {current} = this.itemElement;

    if (current) {
      current.focus();
    }
  }

  render(): JSX.Element {
    const {labelIsActive, item, tid} = this.props;
    const theme = composeThemeFromProps(styles, this.props);

    return (
      <li
        ref={this.itemElement}
        className={[theme.options, item.notSelectable ? theme.notSelectable : ''].join(' ')}
        data-tid={tidUtils.getTid(defaultSelectorTid, tid ? `list-${tid}` : `${item.name}-${item.value}`)}
        onClick={item.notSelectable ? undefined : this.handleItemOnChange}
        onKeyDown={item.notSelectable ? undefined : this.handleOnKeyDown}
        onMouseMove={item.notSelectable ? undefined : this.handleOnMouseMove}
        tabIndex={0 /* use to allow onMouseMove focus */}
      >
        {!item.notSelectable && (
          <div className={labelIsActive ? theme.checkShow : theme.checkHide}>
            <Icon name="check" />
          </div>
        )}

        <div>
          {/* label component */}
          <div className={theme.dropdownLabel}>{item.label}</div>
          {/* subLabel component */}
          {typeof item.subLabel === 'string' && (
            <div className={cx(theme.dropdownLabel, theme.optionDescription)}>{item.subLabel}</div>
          )}
        </div>
      </li>
    );
  }
}
