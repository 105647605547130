/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import type {ComponentPropsWithoutRef, ComponentPropsWithRef, KeyboardEvent} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {Icon, Link, Tooltip} from 'components';
import {hrefUtils, tidUtils} from 'utils';
import {roundNumber, getVulnerabilityByScore} from './VulnerabilityUtils';
import styles from './Vulnerability.css';
import type {LinkProps, LinkLikeProp} from 'components/Link/Link';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {MouseEventLikeHandler} from 'utils/dom';
import type {ReactStrictNode} from 'utils/types';

type VulnerabilityProps = {
  children: ReactStrictNode;

  severityScore: number;
  vulnerabilityExposureScore: number;
  internetExposure?: boolean;
  exposureApplicable?: boolean;

  title?: string;

  // Link parameters, if pill is clickable to navigate
  link: LinkLikeProp;
  // Alternatively, instead of 'link' you can just specify id or href of workload with vulnerability,
  // like id "85914bd3-2075-4d39-8b17-150d09e0cfe7" or href "/orgs/2/workloads/85914bd3-2075-4d39-8b17-150d09e0cfe7",
  // In that case pill will automatically create link to {{to: 'workloads.item.vulnerabilities', params: {id}}},
  id: string;
  href: string;

  // If it's not a link, you can specify custom onClick
  // On click callback - currently used to remove focus (blur) from the div wrapper in Menu from parent Button
  onClick?: MouseEventLikeHandler;

  onKeyDown: (evt: KeyboardEvent) => void;
  onKeyUp: (evt: KeyboardEvent) => void;

  // tooltip props
  tooltip: ReactStrictNode;
  tooltipProps: TooltipProps;
} & ThemeProps &
  (ComponentPropsWithoutRef<'div'> | LinkProps);

export default class Vulnerability extends PureComponent<VulnerabilityProps> {
  element: HTMLElement | undefined | null = null;

  constructor(props: VulnerabilityProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  private saveRef(element: {element: HTMLAnchorElement | null} | HTMLElement | null) {
    if (element && 'element' in element) {
      this.element = element.element;
    } else {
      this.element = element;
    }
  }

  private handleKeyDown(evt: KeyboardEvent) {
    if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  private handleKeyUp(evt: KeyboardEvent) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }

    if (evt.key === ' ' || evt.key === 'Enter') {
      // Emulate click on Space and Enter
      this.props.onClick?.(evt);
    }
  }

  render() {
    const {
      link,
      id,
      href,
      theme,
      tooltip,
      tooltipProps,
      title = intl('Vulnerability.TotalVEScore'),
      severityScore,
      vulnerabilityExposureScore,
      internetExposure = false,
      exposureApplicable,
      ...elementProps
    } = mixThemeWithProps(styles, this.props);

    const severity = getVulnerabilityByScore(severityScore)!.severity;
    const exposureState = exposureApplicable ? (
      <Icon name="syncing" theme={theme} themePrefix="syncing-" />
    ) : (
      intl('Common.NA')
    );
    const renderVulnerabilityContent = (
      <>
        {vulnerabilityExposureScore === null ? exposureState : roundNumber(vulnerabilityExposureScore)}
        {internetExposure && <Icon name="internet" theme={theme} themePrefix="internet-" />}
      </>
    );

    let classes = theme[severity];
    let vulnerability: JSX.Element;

    elementProps['data-tid'] = tidUtils.getTid('comp-vulnerability', severity);
    elementProps['aria-label'] = typeof tooltip === 'string' ? tooltip : title;

    if (link || id || href) {
      // If Label is a Link, assign classes string to .link theme and link object properties to route properties.
      // It will become focusable and activatable by space/enter automatically
      const linkProps = elementProps as LinkProps;

      if (link) {
        if (typeof link === 'string') {
          linkProps.to = link;
        } else {
          Object.assign(linkProps, link);
        }
      } else {
        linkProps.to = 'workloads.item.vulnerabilities';
        linkProps.params = {id: id || hrefUtils.getId(href)};
      }

      vulnerability = (
        <Link ref={this.saveRef} {...linkProps} theme={Link.getLinkTheme(`${classes} ${theme.clickable}`)}>
          {renderVulnerabilityContent}
        </Link>
      );
    } else {
      const divProps = elementProps as ComponentPropsWithRef<'div'>;

      if (divProps.onClick) {
        // If pill is not a link but has onClick handler, make it focusable (tabIndex) and control keys manually
        classes += ` ${theme.clickable}`;
        divProps.tabIndex = elementProps.tabIndex || 0;

        divProps.onKeyUp = this.handleKeyUp;
        divProps.onKeyDown = this.handleKeyDown;
      }

      divProps.className = classes;

      vulnerability = (
        <div ref={this.saveRef} {...divProps}>
          {renderVulnerabilityContent}
        </div>
      );
    }

    return tooltip ? <Tooltip {...{content: tooltip, ...tooltipProps}}>{() => vulnerability}</Tooltip> : vulnerability;
  }
}
