/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import intl from 'intl';
import {formatUtils} from 'utils';
import focusScope from 'a11y-focus-scope';
import focusStore from 'a11y-focus-store';
import {
  Icon,
  Button,
  DateTimePicker,
  DateTimeCustomPickerInput,
  DateTimeCustomPickerInputWithPresets,
} from 'components';
import styles from './DateTimeCustomPicker.css';

const initTo = new Date();
const initFrom = new Date(initTo);

initFrom.setDate(initFrom.getDate() - 1);

const defaultProps = {
  onClose: _.noop,
  onSave: _.noop,
  format: 'l_HH_mm',
  initFrom,
  initTo,
  enableFutureTimeSelection: false,
  disablePastTimeSelection: false,
};

function setFocusOn(applicationElement, element) {
  focusStore.storeFocus();

  if (applicationElement) {
    applicationElement.setAttribute('aria-hidden', 'true');
  }

  focusScope.scopeFocus(element);
}

function resetFocus(applicationElement) {
  focusScope.unscopeFocus();

  if (applicationElement) {
    applicationElement.removeAttribute('aria-hidden');
  }

  focusStore.restoreFocus();
}

export default class DateTimeCustomPicker extends PureComponent {
  static propTypes = {
    format: PropTypes.string, // one of the date format strings in intl/formats.js
    onClose: PropTypes.func, // this is provided by selector
    onSave: PropTypes.func, // this is provided by selector
    categoryKey: PropTypes.string, // category key for the selector category that this custom picker is for
    initFrom: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // initial from date or name of the initial selected preset
    initTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // initial to date or name of the initial selected preset
    fromPresets: PropTypes.array,
    toPresets: PropTypes.array,
    enableFutureTimeSelection: PropTypes.bool,
    disablePastTimeSelection: PropTypes.bool,
  };

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    const initFromIsPresetName = typeof this.props.initFrom === 'string';
    const initToIsPresetName = typeof this.props.initTo === 'string';

    this.state = {
      from: initFromIsPresetName ? undefined : this.props.initFrom,
      to: initToIsPresetName ? undefined : this.props.initTo,
      selectedPresetFrom: initFromIsPresetName ? this.props.initFrom : 'custom',
      selectedPresetTo: initToIsPresetName ? this.props.initTo : 'custom',
      activeInput: 'from',
      fromShowDropdown: false,
      toShowDropdown: false,
    };

    this.saveCustomPickerRef = this.saveCustomPickerRef.bind(this);
    this.setDropdown = this.setDropdown.bind(this);
    this.setPreset = this.setPreset.bind(this);
    this.setActive = this.setActive.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.removeDateTimeValue = this.removeDateTimeValue.bind(this);
    this.formatInputValue = this.formatInputValue.bind(this);
    this.calcFinalValues = this.calcFinalValues.bind(this);
  }

  componentDidMount() {
    setFocusOn(document.querySelector('#root'), this.customPicker);
  }

  componentWillUnmount() {
    resetFocus(document.querySelector('#root'));
  }

  setDropdown(type) {
    if (type === 'from') {
      this.setState({fromShowDropdown: true, toShowDropdown: false});
    } else if (type === 'to') {
      this.setState({fromShowDropdown: false, toShowDropdown: true});
    } else if (type === 'none') {
      this.setState({fromShowDropdown: false, toShowDropdown: false});
    }
  }

  setPreset(type, val) {
    if (type === 'from') {
      this.setState({selectedPresetFrom: val});
    } else {
      this.setState({selectedPresetTo: val});
    }
  }

  setActive(type) {
    this.setDropdown('none');
    this.setState({activeInput: type});
  }

  saveCustomPickerRef(element) {
    this.customPicker = element;
  }

  handleKeyDown(evt) {
    evt.stopPropagation();

    if (evt.key === 'Escape') {
      const {fromShowDropdown, toShowDropdown} = this.state;

      if (!fromShowDropdown && !toShowDropdown) {
        this.props.onClose();
      }
    }
  }

  handleClick() {
    const {fromShowDropdown, toShowDropdown} = this.state;

    if (fromShowDropdown || toShowDropdown) {
      this.setDropdown('none');
    }
  }

  handleCancel() {
    this.props.onClose();
  }

  handleApply() {
    const finalValues = this.calcFinalValues();

    this.props.onSave(finalValues);
  }

  handleDateTimeChange(val) {
    const {activeInput} = this.state;

    this.setState({[activeInput]: val});
  }

  removeDateTimeValue(type) {
    this.setState({[type]: null, activeInput: type});
  }

  formatInputValue(value) {
    return intl.date(new Date(value), this.props.format);
  }

  calcFinalValues() {
    const {from, to, selectedPresetFrom, selectedPresetTo} = this.state;
    const {fromPresets, toPresets} = this.props;
    const hasFromPresets = fromPresets && fromPresets.length;
    const hasToPresets = toPresets && toPresets.length;
    const fromPresetObject = hasFromPresets && fromPresets.find(preset => preset.name === selectedPresetFrom);
    const toPresetObject = hasToPresets && toPresets.find(preset => preset.name === selectedPresetTo);
    const fromPresetValue = fromPresetObject && fromPresetObject.value;
    const toPresetValue = toPresetObject && toPresetObject.value;

    let formattedFrom = from ? intl.date(new Date(from), 'l_HH_mm') : intl('DateTimeInput.Anytime');

    if (hasFromPresets && selectedPresetFrom !== 'custom') {
      formattedFrom = fromPresetValue;
    }

    let formattedTo = to ? intl.date(new Date(to), 'l_HH_mm') : intl.date(new Date(), 'l_HH_mm');

    if (hasToPresets && selectedPresetTo !== 'custom') {
      formattedTo = toPresetValue;
    }

    const formattedValue = formatUtils.getRangeExpression(formattedFrom, formattedTo);

    // Pass formatted value to item so Selector can render
    // Also pass the raw 'from' and 'to' values so other pages can use to send to API
    const item = {
      from: !hasFromPresets || selectedPresetFrom === 'custom' ? from : fromPresetValue,
      to: !hasToPresets || selectedPresetTo === 'custom' ? to : toPresetValue,
      value: formattedValue,
    };

    if (this.props.categoryKey) {
      item[name] = this.props.categoryKey;
    }

    return item;
  }

  render() {
    const {fromPresets, toPresets, enableFutureTimeSelection, disablePastTimeSelection} = this.props;
    const {
      from,
      to,
      activeInput,
      fromShowDropdown,
      toShowDropdown,
      selectedPresetFrom,
      selectedPresetTo,
      hoveredPresetFrom,
      hoveredPresetTo,
    } = this.state;

    const {from: finalFrom, to: finalTo} = this.calcFinalValues();
    const showRangeError =
      finalFrom && finalTo && (finalFrom !== 'Anytime' || finalTo !== 'Anytime') && finalFrom > finalTo;
    const hasFromPresets = fromPresets && fromPresets.length;
    const hasToPresets = toPresets && toPresets.length;
    const disableApply =
      (hasFromPresets && !selectedPresetFrom) || (hasToPresets && !selectedPresetTo) || Boolean(showRangeError);
    const showDateTimePicker =
      activeInput === 'from'
        ? !hasFromPresets || selectedPresetFrom === 'custom'
        : !hasToPresets || selectedPresetTo === 'custom';

    return (
      <div
        className={styles.customPicker}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onClick={this.handleClick}
        ref={this.saveCustomPickerRef}
      >
        <div className={styles.inputHolder}>
          <div className={styles.inputLabel}>{intl('DateTimeInput.From')}</div>
          {hasFromPresets ? (
            <DateTimeCustomPickerInputWithPresets
              focused={activeInput === 'from'}
              showDropdown={fromShowDropdown}
              type="from"
              value={from}
              presets={fromPresets}
              selectedPreset={selectedPresetFrom}
              hoveredPreset={hoveredPresetFrom}
              placeholder={intl('DateTimeInput.SelectDateAndTime')}
              formatValue={this.formatInputValue}
              setDropdown={this.setDropdown}
              setPreset={this.setPreset}
              setActive={this.setActive}
              removeDateTimeValue={this.removeDateTimeValue}
              onDateTimeChange={this.handleDateTimeChange}
            />
          ) : (
            <DateTimeCustomPickerInput
              focused={activeInput === 'from'}
              type="from"
              value={from}
              placeholder={intl('DateTimeInput.SelectFromPlaceholder')}
              formatValue={this.formatInputValue}
              setActive={this.setActive}
              removeDateTimeValue={this.removeDateTimeValue}
            />
          )}
        </div>
        <div className={styles.inputHolder}>
          <div className={styles.inputLabel}>{intl('DateTimeInput.To')}</div>
          {hasToPresets ? (
            <DateTimeCustomPickerInputWithPresets
              focused={activeInput === 'to'}
              showDropdown={toShowDropdown}
              type="to"
              value={to}
              presets={toPresets}
              selectedPreset={selectedPresetTo}
              hoveredPreset={hoveredPresetTo}
              placeholder={intl('DateTimeInput.SelectDateAndTime')}
              formatValue={this.formatInputValue}
              setDropdown={this.setDropdown}
              setPreset={this.setPreset}
              setActive={this.setActive}
              removeDateTimeValue={this.removeDateTimeValue}
              onDateTimeChange={this.handleDateTimeChange}
            />
          ) : (
            <DateTimeCustomPickerInput
              focused={activeInput === 'to'}
              value={to}
              type="to"
              placeholder={intl('DateTimeInput.SelectToPlaceholder')}
              formatValue={this.formatInputValue}
              setActive={this.setActive}
              removeDateTimeValue={this.removeDateTimeValue}
            />
          )}
        </div>
        {showRangeError && (
          <div className={styles.errorMessage}>
            <Icon name="error" size="medium" />
            <span className={styles.errorText}>{intl('DateTimeInput.InvalidTimeRange')}</span>
          </div>
        )}
        <DateTimePicker
          type={activeInput}
          active={showDateTimePicker}
          date={activeInput === 'from' ? from : to}
          onDateChange={this.handleDateTimeChange}
          enableFutureTimeSelection={enableFutureTimeSelection}
          disablePastTimeSelection={disablePastTimeSelection}
        />
        <div className={styles.actions}>
          <Button noFill text={intl('Common.Cancel')} onClick={this.handleCancel} />
          <Button text={intl('Common.Apply')} disabled={disableApply} onClick={this.handleApply} />
        </div>
      </div>
    );
  }
}
