/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Component, Children} from 'react';
import type {MouseEvent, FocusEvent, ReactElement, ReactNode} from 'react';
import {TransitionMotion, type TransitionPlainStyle, type TransitionStyle} from 'react-motion';
import {isClickInBrowsingContext, type MouseEventLike} from 'utils/dom';
import {tidUtils} from 'utils';
import MenuItemsContainer, {type MenuItemsContainerProps} from './MenuItemsContainer';
import {dropdownHorizontal as dropdownMotions, type MotionDirection} from './motions';
import styles from './Menu.css';
import MenuItems from './MenuItems';
import {MenuItem} from '..';
import type {Selection} from 'd3-selection';
import intl from 'intl';
import {isReactElementOf} from 'utils/react';
import type {MenuItemProps} from './MenuItem';
import Tooltip from 'components/Tooltip/Tooltip';

const {close: closePosition, open: openPosition} = dropdownMotions.positions;
const slope = (ax: number, ay: number, bx: number, by: number) => (by - ay) / (bx - ax);
const slopeToleranceTop = 10;
const slopeToleranceBottom = 50;

type FocusItem = 'first' | 'last' | false;

export type DropdownTransitionStyle = {
  opacity: number;
  x: number;
  y: number;
};

interface MenuDropdownProps {
  children?: MenuItemsContainerProps['children'];

  dir?: MotionDirection;
  style: DropdownTransitionStyle;
  subDropdown?: boolean;
  active?: boolean;
  animating?: boolean;
  lastRender?: boolean;
  parentFocusedItem?: MenuItemsContainerProps['parentFocusedItem'];

  onClose(a?: boolean): void;
  onScope?(): void;
  saveSubDropdownItemsRef?(items: MenuItems): void;
  focusItemOnOpen?: FocusItem;

  theme: Record<string, string>;

  tid?: string;
}

type MenuDropdownState = {
  showSubDropdown: boolean;
};

export default class MenuDropdown extends Component<MenuDropdownProps, MenuDropdownState> {
  subDropdownConfig: TransitionStyle[];
  items: MenuItem[];
  itemList: MenuItems | null;
  subDropdownItemsList: MenuItems | null;

  focusedItem?: MenuItem | null;
  mouseLastX = 0;
  mouseLastY = 0;
  mouseLastItem?: MenuItem | null;
  focuseScoped?: boolean;

  dropdown?: HTMLElement | null;
  subDropdown?: MenuDropdown | null;

  mouseHoverSlopeTimeout?: number;

  savedFocusedElement?: HTMLElement;
  focusChildrenChangeDirection?: -1 | 0 | 1;
  // initialize with NaN so that comparison operation with it is always false
  focusChildrenIndex = NaN;

  stateIntentionTimeout?: number;

  subEntering?: boolean;
  subLeaving?: boolean;

  // these are for debug purpose, see the last section of this file
  lineUp?: null | Selection<SVGLineElement, unknown, HTMLElement, unknown>;
  lineDown?: null | Selection<SVGLineElement, unknown, HTMLElement, unknown>;

  constructor(props: MenuDropdownProps) {
    super(props);

    this.state = {showSubDropdown: false};
    this.subDropdownConfig = [];
    this.items = [];
    this.itemList = null;
    this.subDropdownItemsList = null;

    this.renderSubDropdown = this.renderSubDropdown.bind(this);

    this.saveRef = this.saveRef.bind(this);
    this.saveItemsRef = this.saveItemsRef.bind(this);
    this.saveSubDropdownRef = this.saveSubDropdownRef.bind(this);
    this.saveSubDropdownItemsRef = this.saveSubDropdownItemsRef.bind(this);

    this.subEnter = this.subEnter.bind(this);
    this.subLeave = this.subLeave.bind(this);

    this.focusItem = this.focusItem.bind(this);
    this.applyStateIntention = this.applyStateIntention.bind(this);

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleItemFocus = this.handleItemFocus.bind(this);
    this.handleItemMouse = this.handleItemMouse.bind(this);
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);

    this.handleSubDropdownClose = this.handleSubDropdownClose.bind(this);
    this.handleSubDropdownScope = this.handleSubDropdownScope.bind(this);
  }

  componentDidMount() {
    if (!this.props.subDropdown) {
      if (this.props.focusItemOnOpen) {
        // Primary dropdown should set focus on itself automatically
        this.scopeFocus(this.props.focusItemOnOpen);
      }

      // Only primary dropdown should listen to mouse click outside of dropdown to close itself and subdropdowns
      this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
      document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    const focusedChild = this.items.find(
      item =>
        item.props.initiallyFocused ||
        traverseMenuItems(item.props.children, child => Boolean(child.props.initiallyFocused)),
    );

    if (focusedChild) {
      this.focusItem(focusedChild);
    }
  }

  componentWillUnmount() {
    this.items = [];
    this.resetScopeFocus(false);

    if (!this.props.subDropdown) {
      this.restoreFocusOnSavedElement();
      document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }
  }

  private saveRef(dropdown: HTMLElement | null) {
    this.dropdown = dropdown;
  }

  private saveSubDropdownRef(dropdown: MenuDropdown | null) {
    this.subDropdown = dropdown;

    if (!dropdown) {
      this.subDropdownItemsList = null;
    }
  }

  private saveItemsRef(itemList: MenuItems) {
    this.itemList = itemList;
    this.items = itemList.items;

    if (this.props.subDropdown) {
      this.props.saveSubDropdownItemsRef?.(itemList);
    }
  }

  private saveSubDropdownItemsRef(itemList: MenuItems) {
    this.subDropdownItemsList = itemList;
  }

  private handleDocumentMouseDown(evt: Event) {
    if ((evt.target as HTMLElement).closest(`[${Tooltip.InteractiveDataAttribute}='true']`)) {
      return;
    }

    // If clicking outside of dropdown
    if (evt.target !== this.dropdown && !this.dropdown?.contains(evt.target as Node)) {
      // Reset focus scoping right away to allow setting focus into other elements (input, etc) by this click
      this.resetScopeFocus(true);
      // Close menu
      this.props.onClose();
    }
  }

  private handleDocumentKeyDown(evt: KeyboardEvent) {
    let {key} = evt;

    if (evt.key === 'Tab') {
      key = evt.shiftKey ? 'ArrowUp' : 'ArrowDown';
    }

    switch (key) {
      case 'Escape':
        evt.stopPropagation();
        // Closing by pressing Esc should focus trigger button if no elements had been focused before opening menu
        this.props.onClose(true);
        break;
      case 'ArrowUp':
        evt.preventDefault();
        this.focusPreviousItem();
        break;
      case 'ArrowRight':
        evt.preventDefault();

        if (this.subDropdown && this.subDropdownConfig.length) {
          this.resetScopeFocus(false);
          this.subDropdown.scopeFocus('first');
        } else {
          this.focusNextItem();
        }

        break;
      case 'ArrowLeft':
        evt.preventDefault();

        if (this.props.subDropdown) {
          this.resetScopeFocus(true, true);
        } else {
          this.focusPreviousItem();
        }

        break;
      case 'ArrowDown':
        evt.preventDefault();
        this.focusNextItem();
        break;
      // no default
    }
  }

  private handleItemFocus(evt: FocusEvent, item: MenuItem) {
    evt.stopPropagation();
    this.focusItem(item);
  }

  private handleItemClick(evt: MouseEventLike, item: MenuItem) {
    // Close menu after click. If it is a link, close only if user wants to open it in the same browser context
    // Don't close if user pressed Ctrl, Cmd, etc.
    if (
      !item.props.noCloseOnClick &&
      !item.props.notSelectable &&
      (!item.props.link ||
        isClickInBrowsingContext(evt, typeof item.props.link !== 'string' ? item.props.link.target : undefined))
    ) {
      this.props.onClose();
    }
  }

  private handleItemMouse(evt: MouseEvent, item: MenuItem) {
    evt.stopPropagation();
    this.clearMouseSlopeTimeout();

    if (!this.subDropdownItemsList || !this.focusedItem || !this.focusedItem.props.children) {
      this.focusItem(item);
    } else if (item !== this.focusedItem || item !== this.mouseLastItem) {
      const rect = this.subDropdownItemsList.calcRect();

      if (rect !== null) {
        const decreasingSlope = slope(evt.pageX, evt.pageY, rect.left, rect.top - slopeToleranceTop);
        const increasingSlope = slope(evt.pageX, evt.pageY, rect.left, rect.bottom + slopeToleranceBottom);
        const prevDecreasingSlope = slope(this.mouseLastX, this.mouseLastY, rect.left, rect.top - slopeToleranceTop);
        const prevIncreasingSlope = slope(
          this.mouseLastX,
          this.mouseLastY,
          rect.left,
          rect.bottom + slopeToleranceBottom,
        );

        if (decreasingSlope > prevDecreasingSlope || increasingSlope < prevIncreasingSlope) {
          // If slope is decreasing, focus new item
          this.focusItem(item);
        } else {
          // Do nothing If slope is increasing,
          // but set timeout to focus on new item if user stops moving mouse suddenly, before reaching subdropdown
          this.mouseHoverSlopeTimeout = window.setTimeout(this.focusItem, 100, item);
        }
      }
    }

    this.mouseLastX = evt.pageX;
    this.mouseLastY = evt.pageY;
    this.mouseLastItem = item;
  }

  private handleSubDropdownClose(val?: boolean) {
    this.props.onClose(val);
  }

  private handleSubDropdownScope() {
    this.resetScopeFocus(false);
  }

  scopeFocus(focusOnItem: FocusItem): void {
    if (this.focuseScoped) {
      return;
    }

    this.focuseScoped = true;

    // Handle keys events
    document.addEventListener('keydown', this.handleDocumentKeyDown);

    // Save focus on parent dropdown's item if it is currently focused, to restore focus on it by pressing left arrow
    // If none is focused (that's mean <body> is activeElement),
    // then pressing Esc will focus trigger button by default and click on outside of dropdown will focus something else
    if (
      this.props.subDropdown &&
      document.activeElement &&
      document.activeElement.classList.contains(styles.item) &&
      this.dropdown &&
      !this.dropdown.contains(document.activeElement)
    ) {
      this.savedFocusedElement = document.activeElement as HTMLElement;
    }

    //console.log('SCOPE', focusOnItem, this.items.length, this.dropdown);
    if (focusOnItem) {
      this.focusItem(this.items[focusOnItem === 'first' ? 0 : this.items.length - 1]);
    } else if (focusOnItem !== false) {
      // If focusOnItem has not been set, we need to focus on dropdown itself
      // But if dropdown is taller than screen, browser's top will jump to dropdown's top,
      // to avoid this we creaded empty element 'focuser' in the very beginning of dropdown
      this.dropdown!.querySelector<HTMLElement>(`.${styles.focuser}`)!.focus();
      this.focusedItem = null;
    }

    this.props.onScope?.();
  }

  private resetScopeFocus(restoreFocus: boolean, cascadeDown?: boolean) {
    if (this.focuseScoped) {
      this.focusedItem = null;
      this.mouseLastX = 0;
      this.mouseLastY = 0;
      this.mouseLastItem = null;
      this.focuseScoped = false;

      //console.log('RESET SCOPE', this.dropdown, restoreFocus);
      // Remove listeners
      document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    if (cascadeDown) {
      this.clearStateIntention();
      this.subDropdownConfig = [];

      if (this.subDropdown) {
        this.subDropdown.resetScopeFocus(false, cascadeDown);
      }

      if (this.state.showSubDropdown) {
        this.setState({showSubDropdown: false});
      }
    }

    this.clearMouseSlopeTimeout();

    if (restoreFocus) {
      this.restoreFocusOnSavedElement();
    }
  }

  private restoreFocusOnSavedElement() {
    // Reset focus on element that was focused before current dropdown
    if (this.savedFocusedElement && typeof this.savedFocusedElement.focus === 'function') {
      this.savedFocusedElement.focus();
    }
  }

  private clearMouseSlopeTimeout() {
    if (this.mouseHoverSlopeTimeout) {
      window.clearTimeout(this.mouseHoverSlopeTimeout);
      this.mouseHoverSlopeTimeout = undefined;
    }
  }

  focusItem(item: MenuItem, fromKeyPress?: boolean): void {
    if (!this.focuseScoped) {
      if (this.subDropdown) {
        this.subDropdown.resetScopeFocus(false, true);
      }

      if (this.props.subDropdown) {
        this.props.onScope?.();
      }

      this.scopeFocus(false);
    }

    if (!item) {
      return;
    }

    if (item !== this.focusedItem) {
      this.focusedItem = item;

      if (this.subDropdownConfig.length) {
        this.subDropdownConfig[0].data.setParentFocus(false);
      }

      if (item.props.children) {
        const focusChildrenIndex = this.items.indexOf(this.focusedItem);

        if (focusChildrenIndex > this.focusChildrenIndex) {
          this.focusChildrenChangeDirection = 1;
        } else if (focusChildrenIndex < this.focusChildrenIndex) {
          this.focusChildrenChangeDirection = -1;
        } else {
          this.focusChildrenChangeDirection = 0;
        }

        this.focusChildrenIndex = focusChildrenIndex;

        this.subDropdownConfig = [
          {
            key: 'dropdown',
            data: this.focusedItem,
            style: dropdownMotions.open(item.offsetTop),
          },
        ];

        this.focusedItem.setParentFocus(true);

        this.declareStateIntention({showSubDropdown: true}, fromKeyPress ? 0 : undefined);
      } else if (this.subDropdownConfig.length) {
        this.subDropdownConfig = [];
        this.declareStateIntention({showSubDropdown: false}, fromKeyPress ? 0 : undefined);
      }

      if (item.props.onOriginFocus) {
        item.props.onOriginFocus();
      }
    }

    item.focus();
  }

  focusNextItem(): void {
    const {items, focusedItem} = this;
    let index = focusedItem ? items.indexOf(focusedItem) : -1;
    let nextItem;

    if (index === -1) {
      // index: -1 : no item is focused when all items are disabled
      return;
    }

    do {
      index = index < items.length - 1 ? index + 1 : 0;
      nextItem = items[index];
    } while ((nextItem.props.disabled || nextItem.props.notSelectable) && nextItem !== focusedItem);

    if (nextItem !== focusedItem) {
      this.focusItem(nextItem, true);
    }
  }

  focusPreviousItem(): void {
    const {items, focusedItem} = this;
    let index = focusedItem ? items.indexOf(focusedItem) : -1;
    let previousItem;

    if (index === -1) {
      // index: -1 : no item is focused when all items are disabled
      return;
    }

    do {
      index = index > 0 ? index - 1 : items.length - 1;

      previousItem = items[index];
    } while ((previousItem.props.disabled || previousItem.props.notSelectable) && previousItem !== focusedItem);

    if (previousItem !== focusedItem) {
      this.focusItem(previousItem, true);
    }
  }

  private declareStateIntention(intentionalState: MenuDropdownState, delay = 100) {
    this.clearStateIntention();

    if (delay) {
      this.stateIntentionTimeout = window.setTimeout(this.applyStateIntention, delay, intentionalState);
    } else {
      this.applyStateIntention(intentionalState);
    }
  }

  private clearStateIntention() {
    if (this.stateIntentionTimeout) {
      window.clearTimeout(this.stateIntentionTimeout);
      this.stateIntentionTimeout = undefined;
    }
  }

  private applyStateIntention(intentionalState: MenuDropdownState) {
    this.setState(intentionalState);
  }

  private subEnter({style}: TransitionStyle) {
    this.subEntering = true;
    this.subLeaving = false;

    return dropdownMotions.enter(typeof style.y === 'number' ? style.y : style.y.val);
  }

  private subLeave({style}: TransitionStyle) {
    this.subEntering = false;
    this.subLeaving = true;

    return dropdownMotions.leave(style.y);
  }

  private renderSubDropdown(interpolatedStyles: TransitionPlainStyle[]) {
    return (
      <>
        {interpolatedStyles.map(config => {
          const {key, style, data: focusedItem} = config as typeof config & {style: DropdownTransitionStyle};
          const children = focusedItem !== null ? focusedItem.props.children : undefined;
          let lastRender = false;

          if (this.subEntering && style.x === openPosition.x && style.opacity === openPosition.opacity) {
            this.subEntering = false;
          } else if (this.subLeaving && style.x === closePosition.x && style.opacity === closePosition.opacity) {
            this.subLeaving = false;
            lastRender = true;
          }

          const animating =
            this.subEntering ||
            this.subLeaving ||
            (focusedItem !== null && typeof focusedItem.offsetTop === 'number' && style.y !== focusedItem.offsetTop);

          return (
            <MenuDropdown
              key={key}
              subDropdown
              active={this.state.showSubDropdown}
              parentFocusedItem={focusedItem}
              animating={animating}
              lastRender={lastRender}
              style={style}
              theme={this.props.theme}
              dir={this.focusChildrenChangeDirection}
              ref={this.saveSubDropdownRef}
              saveSubDropdownItemsRef={this.saveSubDropdownItemsRef}
              onScope={this.handleSubDropdownScope}
              onClose={this.handleSubDropdownClose}
            >
              {children}
            </MenuDropdown>
          );
        })}
      </>
    );
  }

  render() {
    const {
      props,
      props: {theme, subDropdown, style, active, animating, lastRender, parentFocusedItem, tid, dir = 0},
    } = this;

    const dropdownProps = {
      'ref': this.saveRef,
      'className': cx(theme.dropdown, subDropdown ? theme.subDropdown : theme.dropdownWithArrow, {
        [theme.dropdownActive]: active,
      }),
      'style': {
        opacity: style.opacity,
        transform: `translate3d(${style.x || 0}px, ${style.y || 0}px, 0)`,
      },
      'data-tid': tidUtils.getTid('comp-menu-dropdown', tid),
    };

    return (
      <div {...dropdownProps}>
        {/*tabIndex='-1' removed due to chrome bug when inside sticky header*/}
        {!props.focusItemOnOpen && <span className={styles.focuser} />}

        <MenuItemsContainer
          saveItemsRef={this.saveItemsRef}
          dir={dir}
          theme={theme}
          parentFocusedItem={parentFocusedItem}
          dropdownMoving={animating}
          lastRender={lastRender}
          onItemClick={this.handleItemClick}
          onItemFocus={this.handleItemFocus}
          onItemMouse={this.handleItemMouse}
          aria-label={subDropdown ? intl('Common.SubDropdownMenu') : intl('Common.MainDropdownMenu')}
        >
          {this.props.children}
        </MenuItemsContainer>

        <TransitionMotion willEnter={this.subEnter} willLeave={this.subLeave} styles={this.subDropdownConfig}>
          {this.renderSubDropdown}
        </TransitionMotion>
      </div>
    );
  }
}

function traverseMenuItems(
  children: ReactNode | undefined,
  predicate: (item: ReactElement<MenuItemProps>) => boolean,
): boolean {
  if (!children) {
    return false;
  }

  return Children.toArray(children).some(
    child =>
      isReactElementOf(child, MenuItem) && (predicate(child) || traverseMenuItems(child.props.children, predicate)),
  );
}

// Set to true if you want to see forgiving mouse movement paths
// eslint-disable-next-line no-constant-condition, no-constant-binary-expression -- Set to true if you want to see forgiving mouse movement paths
if (false && __DEV__ && typeof window === 'object') {
  import('d3-selection').then(({select}) => {
    const svg = select('body')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('style', 'position:absolute;top:0;left:0;pointer-events:none;z-index:99999');

    // @ts-ignore
    const handleItemMouseOrigin = MenuDropdown.prototype.handleItemMouse;
    // @ts-ignore
    const saveSubDropdownRefOrigin = MenuDropdown.prototype.saveSubDropdownRef;
    const componentWillUnmountOrigin = MenuDropdown.prototype.componentWillUnmount;

    // @ts-ignore
    MenuDropdown.prototype.handleItemMouse = function (...args) {
      if (this.subDropdownItemsList && this.mouseLastX) {
        const rect = this.subDropdownItemsList.calcRect();

        this.lineUp = this.lineUp || svg.append('line').style('stroke', 'green');
        this.lineDown = this.lineDown || svg.append('line').style('stroke', 'green');

        this.lineUp
          .attr('x1', this.mouseLastX)
          .attr('y1', this.mouseLastY)
          .attr('x2', rect?.left ?? 0)
          .attr('y2', (rect?.top ?? 0) - slopeToleranceTop);
        this.lineDown
          .attr('x1', this.mouseLastX)
          .attr('y1', this.mouseLastY)
          .attr('x2', rect?.left ?? 0)
          .attr('y2', (rect?.bottom ?? 0) + slopeToleranceBottom);
      }

      handleItemMouseOrigin.apply(this, args);
    };

    MenuDropdown.prototype.componentWillUnmount = function (...args) {
      if (this.lineUp) {
        this.lineUp.remove();
      }

      if (this.lineDown) {
        this.lineDown.remove();
      }

      componentWillUnmountOrigin.apply(this, args);
    };

    // @ts-ignore
    MenuDropdown.prototype.saveSubDropdownRef = function (dropdown) {
      if (!dropdown) {
        if (this.lineUp) {
          this.lineUp.remove();
          this.lineUp = null;
        }

        if (this.lineDown) {
          this.lineDown.remove();
          this.lineDown = null;
        }
      }

      saveSubDropdownRefOrigin.call(this, dropdown);
    };
  });
}
