/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Pill} from 'components';
import {typesName, prefixMap, typePrefixRegexp} from 'components/Pill/Label/LabelUtils';
import {getId} from 'utils/href';
import {acceptedKeys} from '../RulesetItemUtils';
import {sortOptions, getQueryAndKeyword} from 'containers/Selector/SelectorUtils';
import {isKubernetesSupported} from 'containers/App/AppState';

const doesArrIncludesObj = (arr, obj) => arr.some(o => getId(o.href) === getId(obj.href));
const doesArrIncludesAllServices = (services = []) =>
  services.some(service => service.name === intl('Common.AllServices'));

const getResourcesOptions = rows =>
  rows.reduce((result, row) => {
    const {rule, oldRule = {}} = row.data;

    // Sticky options resources
    if (rule.stateless || oldRule.stateless) {
      result.stateless = [intl('Common.Stateless')];
    }

    if (rule.machine_auth || oldRule.machine_auth) {
      result.machine_auth = [intl('Common.MachineAuthentication')];
    }

    if (rule.sec_connect || oldRule.sec_connect) {
      result.sec_connect = [intl('Common.SecureConnect')];
    }

    if (!__ANTMAN__ && (rule.network_type === 'non_brn' || oldRule.network_type === 'non_brn')) {
      result.network_type = result.network_type ?? [];

      if (!result.network_type.includes(intl('Rulesets.Rules.NonCorporateNetworks'))) {
        result.network_type.push(intl('Rulesets.Rules.NonCorporateNetworks'));
      }
    }

    if (!__ANTMAN__ && (rule.network_type === 'all' || oldRule.network_type === 'all')) {
      result.network_type = result.network_type ?? [];

      if (!result.network_type.includes(intl('Rulesets.Rules.AllNetworks'))) {
        result.network_type.push(intl('Rulesets.Rules.AllNetworks'));
      }
    }

    if (rule.use_workload_subnets?.length > 0 || oldRule.use_workload_subnets?.length > 0) {
      result.use_workload_subnets = [intl('Rulesets.Rules.UseWorkloadSubnets')];
    }

    if (
      (!isKubernetesSupported && _.get(rule, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services') ||
      _.get(oldRule, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services'
    ) {
      result.consumerUsesVirtualServicesOnly = [`${intl('Common.Consumers')} ${intl('Common.UsesVirtualServices')}`];
    }

    if (
      !isKubernetesSupported &&
      (_.get(rule, 'resolve_labels_as.consumers', []).sort().join(',') === 'virtual_services,workloads' ||
        _.get(oldRule, 'resolve_labels_as.consumers', []).sort().join(',') === 'virtual_services,workloads')
    ) {
      result.consumerUsesVirtualServicesAndWorkloads = [
        `${intl('Common.Consumers')} ${intl('Common.UsesVirtualServicesWorkloads')}`,
      ];
    }

    if (
      !isKubernetesSupported &&
      (_.get(rule, 'resolve_labels_as.providers', []).join(',') === 'virtual_services' ||
        _.get(oldRule, 'resolve_labels_as.providers', []).join(',') === 'virtual_services')
    ) {
      result.providerUsesVirtualServicesOnly = [`${intl('Common.Providers')} ${intl('Common.UsesVirtualServices')}`];
    }

    if (
      !isKubernetesSupported &&
      (_.get(rule, 'resolve_labels_as.providers', []).sort().join(',') === 'virtual_services,workloads' ||
        _.get(oldRule, 'resolve_labels_as.providers', []).sort().join(',') === 'virtual_services,workloads')
    ) {
      result.providerUsesVirtualServicesAndWorkloads = [
        `${intl('Common.Providers')} ${intl('Common.UsesVirtualServicesWorkloads')}`,
      ];
    }

    if (doesArrIncludesAllServices(rule.ingress_services)) {
      result.allServices = [intl('Common.AllServices')];
    }

    result.policyServices = result.policyServices ?? [];

    [...(rule.ingress_services ?? []), ...(rule.egress_services ?? [])].forEach(service => {
      if (service.href && !doesArrIncludesObj(result.policyServices, service)) {
        result.policyServices.push({...service, value: service.name});
      }
    });

    result.userGroups = result.userGroups ?? [];

    rule.consuming_security_principals?.forEach(userGroup => {
      if (!doesArrIncludesObj(result.userGroups, userGroup)) {
        result.userGroups.push({...userGroup, value: userGroup.name});
      }
    });

    const entities = [
      ...(rule.consumers ?? []),
      ...(rule.providers ?? []),
      ...(rule.actors ?? []),
      ...(oldRule.consumers ?? []),
      ...(oldRule.providers ?? []),
      ...(oldRule.actors ?? []),
    ];

    if (entities.some(entity => entity.actors === 'container_host')) {
      result.container_host = [intl('Common.ContainerHost')];
    }

    if (entities.some(entity => entity.actors === 'ams')) {
      result.allWorkloads = [intl('Workloads.All')];
    }

    if (entities.some(entity => entity.ip_list?.name === intl('IPLists.Any'))) {
      result.anyIp = [intl('IPLists.Any')];
    }

    const pversionRuleEntities = [...(rule.consumers ?? []), ...(rule.providers ?? []), ...(rule.actors ?? [])];

    pversionRuleEntities.forEach(obj => {
      const key = Object.keys(obj)[0];
      const valueObj = obj[key];

      result[key] = result[key] ?? [];

      if (acceptedKeys.includes(key) && !doesArrIncludesObj(result[key], valueObj)) {
        result[key].push({...valueObj, value: valueObj.value ?? valueObj.name ?? valueObj.hostname});
      }
    });

    return result;
  }, {});

const stickyResourceConfig = {
  sticky: true,
  optionProps: {
    isPill: true,
    noFilter: true,
  },
  selectedProps: {hideResourceName: true},
};

export const getRulesFilterCategories = rows => {
  const {
    label = [],
    label_group = [],
    workload = [],
    virtual_service = [],
    ip_list = [],
    virtual_server = [],
    userGroups = [],
    policyServices = [],
    ...rest
  } = getResourcesOptions(rows);

  return [
    {
      id: 'labels',
      name: intl('Rulesets.Rules.LabelAndLabelGroups'),
      resources: {
        labels: {
          emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'labels'}),
          statics: query => {
            let options = [...label, ...label_group];

            const {keyword, query: value} = getQueryAndKeyword(query, typePrefixRegexp);
            const key = prefixMap[keyword?.trim().toLowerCase()];

            if (query) {
              options = options.reduce((result, labelObj) => {
                if (key && labelObj.key !== key) {
                  return result;
                }

                if (value) {
                  if (labelObj.value.toLowerCase().includes(value.trim().toLowerCase())) {
                    result.push(labelObj);
                  }

                  return result;
                }

                result.push(labelObj);

                return result;
              }, []);
            }

            return sortOptions(options, value);
          },
          optionProps: {
            isPill: true,
            pillProps: label => ({
              icon: label.key,
              group: label.href.includes('label_groups'),
            }),
            allowMultipleSelection: true,
            tooltipProps: option => ({content: typesName[option.key]}),
          },
          selectedProps: {
            hideResourceName: true,
            pillPropsValue: label => ({
              icon: label.key,
              group: label.href.includes('label_groups'),
            }),
          },
        },
      },
    },
    {
      id: 'ip_list',
      name: intl('Common.IPLists'),
      resources: {
        ip_list: {
          emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'iplists'}),
          statics: query => {
            const options = query ? ip_list.filter(({name}) => name.includes(query)) : ip_list;

            return sortOptions(options, query);
          },
          optionProps: {
            filterOption: option => option.name !== intl('IPLists.Any'),
            allowMultipleSelection: true,
            isPill: true,
            pillProps: {icon: 'allowlist'},
          },
        },
      },
    },
    {
      id: 'workload',
      name: intl('Common.Workloads'),
      resources: {
        workload: {
          emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'workloads'}),
          statics: query => {
            const options = query
              ? workload.filter(({name, hostname}) => (name ?? hostname).includes(query))
              : workload;

            return sortOptions(options, query);
          },
          optionProps: {
            allowMultipleSelection: true,
            isPill: true,
            pillProps: {icon: 'workload'},
          },
        },
      },
    },
    {
      id: 'consuming_security_principals',
      name: intl('Common.UserGroups'),
      resources: {
        consuming_security_principals: {
          emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'userGroups'}),
          statics: query => {
            const options = query ? userGroups.filter(({name}) => name.includes(query)) : userGroups;

            return sortOptions(options, query);
          },
          optionProps: {
            allowMultipleSelection: true,
            isPill: true,
            pillProps: {icon: 'org'},
          },
        },
      },
    },
    ...(!__ANTMAN__
      ? [
          {
            id: 'virtual_service',
            name: intl('Common.VirtualServices'),
            resources: {
              virtual_service: {
                emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'virtualServices'}),
                statics: query => {
                  const options = query ? virtual_service.filter(({name}) => name.includes(query)) : virtual_service;

                  return sortOptions(options, query);
                },
                optionProps: {
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'virtual-service'},
                },
              },
            },
          },
          {
            id: 'virtual_server',
            name: intl('Common.VirtualServers'),
            resources: {
              virtual_server: {
                emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'virtualServers'}),
                statics: query => {
                  const options = query ? virtual_server.filter(({name}) => name.includes(query)) : virtual_server;

                  return sortOptions(options, query);
                },
                optionProps: {
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'virtual-server'},
                },
              },
            },
          },
        ]
      : []),
    {
      id: 'policyServices',
      name: intl('Services.PolicyServices'),
      resources: {
        services: {
          emptyBannerContent: intl('Rulesets.Rules.NoEntityUsed', {object: 'services'}),
          statics: query => {
            const options = query ? policyServices.filter(({name}) => name.includes(query)) : policyServices;

            return sortOptions(options, query);
          },
          optionProps: {
            filterOption: option => option.name !== intl('Common.AllServices'),
            allowMultipleSelection: true,
            format: ({option}) => <Pill.Service value={option} showPorts insensitive />,
          },
        },
      },
    },
    ...(_.isEmpty(rest)
      ? []
      : [
          {
            id: 'stickyCategory',
            resources: Object.keys(rest).reduce((result, resourceId) => {
              result[resourceId] = {
                ...stickyResourceConfig,
                statics: rest[resourceId],
                optionProps: {allowMultipleSelection: true},
              };

              return result;
            }, {}),
          },
        ]),
  ];
};

export const filterIpTableRules = (rows, valuesMap) => {
  const filterHrefs = [...valuesMap.values()]
    .flat()
    .map(({href} = {}) => href)
    .filter(Boolean);

  return valuesMap.size === 0
    ? rows
    : rows.reduce((result, row) => {
        if (row.isInEditMode) {
          result.push(row);

          return result;
        }

        const hasResourcesOtherThanReceivers = [...valuesMap.keys()].some(
          resourceId => !resourceId.includes('workload') && !resourceId.includes('label'),
        );

        if (hasResourcesOtherThanReceivers) {
          return result;
        }

        const {rule, oldRule} = row.data;
        const entities = [...rule.actors, ...(oldRule?.actors ?? [])];

        let matched = true;

        if (valuesMap.get('allWorkloads')) {
          matched = matched && entities.some(entity => entity.actors === 'ams');
        }

        if (filterHrefs.length) {
          const pversionEntitiesHrefs = rule.actors?.map(entity => Object.values(entity)[0]?.href) ?? [];

          matched = matched && filterHrefs.every(href => pversionEntitiesHrefs.includes(href));
        }

        if (matched) {
          result.push(row);
        }

        return result;
      }, []);
};

export const filterIntraExtraScopeRules = (rows = [], valuesMap) => {
  const filterHrefs = [...valuesMap.values()]
    .flat()
    .map(({href} = {}) => href)
    .filter(Boolean);

  return valuesMap.size === 0
    ? rows
    : rows.reduce((result, row) => {
        if (row.isInEditMode) {
          result.push(row);

          return result;
        }

        const {rule, oldRule} = row.data;
        const entities = [
          ...rule.consumers,
          ...rule.providers,
          ...(oldRule?.consumers ?? []),
          ...(oldRule?.providers ?? []),
        ];

        const ingressServices = [...rule.ingress_services, ...(oldRule?.ingress_services ?? [])];

        let matched = true;

        if (valuesMap.get('container_host')) {
          matched = matched && entities.some(entity => entity.actors === 'container_host');
        }

        if (valuesMap.get('allWorkloads')) {
          matched = matched && entities.some(entity => entity.actors === 'ams');
        }

        if (valuesMap.get('allServices')) {
          matched = matched && doesArrIncludesAllServices(ingressServices);
        }

        if (valuesMap.get('anyIp')) {
          matched = matched && entities.some(entity => entity.ip_list?.name === intl('IPLists.Any'));
        }

        if (isKubernetesSupported && valuesMap.get('providerUsesVirtualServicesAndWorkloads')) {
          matched =
            matched &&
            (_.get(rule, 'resolve_labels_as.providers', []).join(',') === 'virtual_services,workloads' ||
              _.get(oldRule ?? {}, 'resolve_labels_as.providers', []).join(',') === 'virtual_services,workloads');
        }

        if (isKubernetesSupported && valuesMap.get('providerUsesVirtualServicesOnly')) {
          matched =
            matched &&
            (_.get(rule, 'resolve_labels_as.providers', []).join(',') === 'virtual_services' ||
              _.get(oldRule ?? {}, 'resolve_labels_as.providers', []).join(',') === 'virtual_services');
        }

        if (isKubernetesSupported && valuesMap.get('consumerUsesVirtualServicesAndWorkloads')) {
          matched =
            matched &&
            (_.get(rule, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services,workloads' ||
              _.get(oldRule ?? {}, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services,workloads');
        }

        if (isKubernetesSupported && valuesMap.get('consumerUsesVirtualServicesOnly')) {
          matched =
            matched &&
            (_.get(rule, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services' ||
              _.get(oldRule ?? {}, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services');
        }

        if (valuesMap.get('use_workload_subnets')) {
          matched = matched && (rule.use_workload_subnets?.length > 0 || oldRule?.use_workload_subnets?.length > 0);
        }

        if (valuesMap.get('stateless')) {
          matched = matched && (rule.stateless === true || oldRule?.stateless === true);
        }

        if (valuesMap.get('machine_auth')) {
          matched = matched && (rule.machine_auth === true || oldRule?.machine_auth === true);
        }

        if (valuesMap.get('sec_connect')) {
          matched = matched && (rule.sec_connect === true || oldRule?.sec_connect === true);
        }

        if (!__ANTMAN__ && valuesMap.has('network_type')) {
          const networkTypes = valuesMap.get('network_type');

          networkTypes.forEach(networkType => {
            // Only all or non_brn network types are available as filter options, no need to check for brn
            const type = networkType === intl('Rulesets.Rules.NonCorporateNetworks') ? 'non_brn' : 'all';

            matched = matched && (rule.network_type === type || oldRule?.network_type === type);
          });
        }

        if (filterHrefs.length) {
          const pversionEntities = [...rule.consumers, ...rule.providers];

          const entitiesHrefs = pversionEntities.map(entity => Object.values(entity)[0]?.href);

          matched =
            matched &&
            filterHrefs.every(
              href =>
                entitiesHrefs.includes(href) ||
                rule.consuming_security_principals?.some(userGroup => userGroup.href === href) ||
                rule.ingress_services?.some(service => service.href === href) ||
                rule.egress_services?.some(service => service.href === href),
            );
        }

        if (matched) {
          result.push(row);
        }

        return result;
      }, []);
};
