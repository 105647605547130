/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useSelector} from 'react-redux';
import {fetchVenOperations} from './VenOperationsSaga';
import IKECertificatesReducers, {getVenOperationsPage} from './VenOperationsState';
import {HeaderProps} from 'containers';
import {AttributeList, StatusIcon, ToolBar, ToolGroup, Button} from 'components';
import styles from './VenOperations.css';

VenOperations.reducers = IKECertificatesReducers;
VenOperations.prefetch = fetchVenOperations;

export default function VenOperations() {
  const {ikeCertificate, interval, edgeEnabled} = useSelector(getVenOperationsPage);

  return (
    <>
      <HeaderProps title={__ATR__ ? intl('VEN.VENOperation') : intl('Settings.IKECertificates')} />
      <>
        <ToolBar justify="left">
          <ToolGroup>
            <Button.Link icon="edit" tid="edit" text={intl('Common.Edit')} link="venoperations.edit" />
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            {title: intl('Settings.IKECertificates')},
            {
              value: (
                <StatusIcon
                  status="info"
                  tid="certIssuer"
                  label={intl('Settings.VensIKEAuthenticationInfo')}
                  noTextColor
                />
              ),
            },
            {
              value: (
                <AttributeList>
                  {[
                    {
                      key: intl('Settings.CertificateIssuer'),
                      value: ikeCertificate,
                    },
                  ]}
                </AttributeList>
              ),
            },
            __ATR__ && !edgeEnabled ? {divider: true} : null,
            __ATR__ && !edgeEnabled && {title: intl('Settings.VensRecoveryTitle')},
            __ATR__ &&
              !edgeEnabled && {
                value: (
                  <StatusIcon
                    status="info"
                    tid="rotationInterval"
                    label={intl('Settings.VensRecoveryInfo')}
                    noTextColor
                  />
                ),
              },
            __ATR__ &&
              !edgeEnabled && {
                value: (
                  <AttributeList theme={styles}>
                    {[
                      {
                        key: intl('Settings.RotationInterval'),
                        value: intl('TrafficParameters.Days', {count: interval}),
                      },
                    ]}
                  </AttributeList>
                ),
              },
          ]}
        </AttributeList>
      </>
    </>
  );
}
