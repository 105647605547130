/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useCallback, useState, useContext, useRef} from 'react';
import {useSelector} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {fetchVenOperations, updateIKECertificate, setRotationInterval} from './VenOperationsSaga';
import IKECertificatesReducer, {getVenOperationsPage} from './VenOperationsState';
import {HeaderProps} from 'containers';
import {AttributeList, StatusIcon, ToolBar, ToolGroup, Button, Form, Modal, TypedMessages, InfoCard} from 'components';
import {object, mixed, number} from 'yup';
import stylesUtils from 'utils.css';
import styles from './VenOperations.css';

// yup schema
const schemas = object({
  certIssuer: mixed(),
  rotationInterval: number()
    .typeError(intl('Settings.VensRotationIntervalIntegerError'))
    .positive(intl('Settings.VensRotationIntervalIntegerError'))
    .integer(intl('Settings.VensRotationIntervalIntegerError'))
    .required(intl('Settings.VensRotationIntervalError'))
    .min(7, intl('Settings.VensRotationIntervalError')),
});

VenOperationsEdit.prefetch = fetchVenOperations;
VenOperationsEdit.reducers = IKECertificatesReducer;

export default function VenOperationsEdit() {
  const {fetcher, navigate} = useContext(AppContext);
  const {ikeCertificate, interval, edgeEnabled} = useSelector(getVenOperationsPage);
  const [error, setError] = useState(null);
  const formikOptions = useRef(null);
  const infoCardIconRef = useRef(null);

  const handleSave = useCallback(async () => {
    const {
      values: {certIssuer, rotationInterval},
      setSubmitting,
      resetForm,
    } = formikOptions.current;

    setSubmitting(true);

    try {
      if (certIssuer !== ikeCertificate) {
        await fetcher.spawn(updateIKECertificate, certIssuer);
      }

      if (interval !== Number(rotationInterval)) {
        await fetcher.spawn(setRotationInterval, Number(rotationInterval));
      }

      setSubmitting(false);
      resetForm({certIssuer, rotationInterval}); // reset to prevent page dirty notification on navigation
    } catch (error) {
      setSubmitting(false);
      setError(error);
    }
  }, [fetcher, ikeCertificate, interval]);

  const handleErrorClose = useCallback(() => {
    setError(null);
  }, []);

  const renderErrorModal = useCallback(
    () => (
      <Modal.Alert
        title={intl('Common.Error')}
        onClose={handleErrorClose}
        buttonProps={{tid: 'ok', text: intl('Common.OK')}}
      >
        <TypedMessages>{[{icon: 'error', content: error.message}]}</TypedMessages>
      </Modal.Alert>
    ),
    [error, handleErrorClose],
  );

  const handleSaveDone = useCallback(() => {
    if (!error) {
      navigate('venoperations');
    }
  }, [navigate, error]);

  return (
    <>
      <HeaderProps
        title={__ATR__ ? intl('VEN.VENOperation') : intl('Settings.IKECertificates')}
        label={`(${intl('Common.Edit')})`}
      />
      <Form
        enableReinitialize
        schemas={schemas}
        initialValues={{certIssuer: ikeCertificate, rotationInterval: interval}}
      >
        {options => {
          formikOptions.current = options;

          return (
            <>
              <ToolBar justify="left">
                <ToolGroup>
                  <Button
                    icon="save"
                    tid="save"
                    type="submit"
                    text={intl('Common.Save')}
                    progressCompleteWithCheckmark
                    progress={options.isSubmitting}
                    onClick={handleSave}
                    onProgressDone={handleSaveDone}
                    progressError={Boolean(error)}
                    disabled={
                      (ikeCertificate === options.values.certIssuer &&
                        interval === Number(options.values.rotationInterval)) ||
                      options.errors.hasOwnProperty('rotationInterval')
                    }
                  />
                  <Button.Link
                    color="standard"
                    icon="cancel"
                    tid="cancel"
                    text={intl('Common.Cancel')}
                    link="venoperations"
                  />
                </ToolGroup>
              </ToolBar>
              <AttributeList>
                {[
                  {title: intl('Settings.IKECertificates')},
                  {
                    value: (
                      <StatusIcon
                        tid="certIssuer"
                        status="info"
                        label={intl('Settings.VensIKEAuthenticationInfo')}
                        noTextColor
                      />
                    ),
                  },
                  {
                    value: (
                      <AttributeList>
                        {[
                          {
                            key: (
                              <Form.Label
                                name="certIssuer"
                                title={intl('Settings.CertificateIssuer')}
                                lineHeight="var(--30px)"
                              />
                            ),
                            value: (
                              <>
                                <Form.Input
                                  tid="certIssuer"
                                  name="certIssuer"
                                  placeholder={intl('Settings.CertificateExample')}
                                />
                                <InfoCard trigger={infoCardIconRef} initiallyOpened>
                                  {intl('Settings.ValidCertificateValues', {className: stylesUtils.bold}, {html: true})}
                                </InfoCard>
                              </>
                            ),
                            secondary: intl('Settings.CertificateSecondaryText'),
                            valueGap: 'gapMedium',
                            icon: <InfoCard.Icon ref={infoCardIconRef} />,
                          },
                        ]}
                      </AttributeList>
                    ),
                  },
                  __ATR__ && !edgeEnabled ? {divider: true} : null,
                  __ATR__ && !edgeEnabled && {title: intl('Settings.VensRecoveryTitle')},
                  __ATR__ &&
                    !edgeEnabled && {
                      value: (
                        <StatusIcon
                          status="info"
                          tid="rotationInterval"
                          label={intl('Settings.VensRecoveryInfo')}
                          noTextColor
                        />
                      ),
                    },
                  __ATR__ &&
                    !edgeEnabled && {
                      value: (
                        <AttributeList theme={styles}>
                          {[
                            {
                              key: (
                                <Form.Label
                                  name="rotationInterval"
                                  title={intl('Settings.RotationInterval')}
                                  lineHeight="var(--30px)"
                                />
                              ),
                              hint: intl('Common.Days'),
                              value: <Form.Input tid="rotationInterval" name="rotationInterval" placeholder="30" />,
                              secondary: intl('Settings.IntervalSecondaryText'),
                            },
                          ]}
                        </AttributeList>
                      ),
                    },
                ]}
              </AttributeList>
              {error && renderErrorModal()}
            </>
          );
        }}
      </Form>
    </>
  );
}
