/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteCurrent} from 'containers/App/AppState';
import {isAPIAvailable} from 'api/apiUtils';
import {getVenLibraries, getFilters} from 'containers/Ven/Library/VenLibraryState';
import {getScopeItems} from '../List/VenListState';

export default {
  targets(state = [], action) {
    // intended set of vens to upgrade
    switch (action.type) {
      case 'VENS_GET_TARGETS':
        return action.data.list;
      default:
        return state;
    }
  },
  targetCount(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_TARGETS':
        return action.data.count;
      default:
        return state;
    }
  },
  errors(state = [], action) {
    switch (action.type) {
      case 'VENS_UPGRADE': // dry_run?
        return action.data.errors;
      default:
        return state;
    }
  },

  endpointVENCount(state = 0, action) {
    switch (action.type) {
      case 'VENS_VEN_TYPE_ENDPOINT':
        return action.data;
      default:
        return state;
    }
  },

  // there are no batches/jobs. status is pieced together from any number of upgrade calls
  conditionTimeout(state = 0, action) {
    switch (action.type) {
      case 'VENS_CONDITION_AGENT_UPGRADE_TIME_OUT':
        return action.data;
      default:
        return state;
    }
  },
  conditionMissingHeartbeats(state = 0, action) {
    switch (action.type) {
      case 'VENS_CONDITION_AGENT_MISSING_HEARTBEATS_AFTER_UPGRADE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVensIntended = state => state.ven.targets;
export const getVensIntendedCount = state => state.ven.targetCount;

export const getErrors = state => state.ven.errors;
export const getVensUpgradeRequestedTrue = state => state.ven.requested;
export const getVensConditionTimeout = state => state.ven.conditionTimeout;
export const getVensMissingHeartbeats = state => state.ven.conditionMissingHeartbeats;

export const getVensVenTypeEndpoint = state => state.ven.endpointVENCount;

export const getVenUpgrade = createSelector(
  [getRouteCurrent, getScopeItems, getFilters, getVenLibraries, getVensIntended, getVensIntendedCount],
  (route, scopeItems, calculatedFilterMap, libraries, targets, targetCount) => ({
    scopeItems,
    calculatedFilterMap,
    route,
    libraries,
    targets,
    targetCount,
    dispatchEvent: false,
    isVensUpgradeAvailable: isAPIAvailable('vens.upgrade'),
  }),
);

export const getVenUpgradeErrors = createSelector([getErrors], errors => ({errors}));

export const getVenUpgradeStatus = createSelector([getVensUpgradeRequestedTrue], requested => ({...requested}));
