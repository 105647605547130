/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {RedirectError} from 'errors';
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {getSelectorSettings, gridSettings} from './ContainerWorkloadListConfig';
import {
  getContainerWorkloads,
  getContainerWorkloadsCount,
  getContainerWorkloadsIgnoreScope,
  getCalculatedStaticValues,
  getCalculatedRouteFilters,
} from './ContainerWorkloadListState';
import gridSaga from 'components/Grid/GridSaga';
import {fetchValidScopeLabels} from 'containers/Selectors/SelectorSaga';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {fetchHealth} from 'containers/Health/HealthSaga';
import {isEdge} from 'containers/App/AppState';

//TODO: Consider another VEN representation to avoid this set of API calls. /api/v2/orgs/1/vens/:id?representation=ven_labels_workloads
// see: https://stash.ilabs.io/projects/EYE/repos/thor/pull-requests/7242/overview?commentId=93819
function* fetchContainerWorkloads({ignoreScope = false, containerCluster, ven, filter, scope, force = false} = {}) {
  const staticValues = yield select(getCalculatedStaticValues);
  const query = {max_results: 500};
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (containerCluster) {
    query.container_clusters = JSON.stringify([{href: containerCluster}]);
  } else if (scope && !ignoreScope) {
    // no scope for detail page. ignoreScope: omit scope for Ven/container workloads tab
    query.xxxlabels = [scope.scope.map(obj => obj.href)];
  } else if (ven) {
    query.ven = ven;
  }

  if (filter) {
    const workloadQuery = {};
    const containerClusterQuery = {};

    for (const [name, [value]] of Object.entries(filter)) {
      if (staticValues.hasOwnProperty(name)) {
        // If there is more than one parameter to make up the query
        if (Array.isArray(selectorSettingsObject.staticValues[name][value])) {
          staticValues[name][value].forEach(filter => {
            query[filter.name] = filter.value;
          });
        } else {
          query[name] = selectorSettingsObject.staticValues[name][value];
        }
      } else if (selectorSettingsObject.scopeMap.hasOwnProperty(name)) {
        // labels are the only supported type at this moment
        if (ignoreScope || !scope) {
          // otherwise xxxlabels are already generated
          if (query.xxxlabels && Array.isArray(query.xxxlabels[0])) {
            query.xxxlabels[0].push(value.href);
          } else {
            query.xxxlabels = [[value.href]];
          }
        }
      } else if (name === 'workload') {
        // this query parameter is for workloads
        workloadQuery.name = value;
      } else if (name === 'hostname') {
        // this query parameter is for workloads
        workloadQuery.hostname = value;
      } else if (name === 'hostip') {
        // this query parameter is for workloads
        workloadQuery.ip_address = value;
      } else if (name === 'container_clusters') {
        // this query parameter is for container clusters
        query.container_clusters = JSON.stringify([value.href]);
      } else {
        query[name] = value;
      }
    }

    if (!_.isEmpty(workloadQuery)) {
      const {data} = yield call(apiSaga, 'workloads.get_collection', {
        query: workloadQuery,
        cache: !force,
      });

      query.container_hosts = JSON.stringify(data.map(workload => workload.href));
    } else if (!_.isEmpty(containerClusterQuery)) {
      const {data} = yield call(apiSaga, 'container_clusters.get_collection', {
        query: containerClusterQuery,
        cache: !force,
      });

      query.container_clusters = JSON.stringify(data.map(containerCluster => containerCluster.href));
    }
  }

  const [collection] = yield all([
    call(apiSaga, 'container_workloads.get_collection', {
      query,
      cache: !force,
      *onDone({data: list, count}) {
        if (
          force ||
          list !== (yield select(getContainerWorkloads)) ||
          count !== (yield select(getContainerWorkloadsCount)) ||
          ignoreScope !== (yield select(getContainerWorkloadsIgnoreScope))
        ) {
          yield put({type: 'CONTAINER_WORKLOAD_GET_LIST', data: {list, count, ignoreScope}});
        }

        return {list, count, ignoreScope};
      },
    }),
    call(fetchHealth, {force}),
  ]);

  return collection;
}

export function* fetchContainerWorkloadList(route, refetch = false, options = {}) {
  const {params} = route;
  const {getGrid = gridSettings, getFilters = getCalculatedRouteFilters, containerCluster, ven, ignoreScope} = options;
  const edgeEnabled = yield select(isEdge);

  if (__ANTMAN__ || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGrid,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const scopeParams = yield select(getUrlScopeValue, params);
      const scope = scopeParams.isEmpty ? undefined : scopeParams.valid;

      yield call(fetchValidScopeLabels, scope);

      const {list} = yield call(fetchContainerWorkloads, {
        containerCluster,
        ven,
        ignoreScope,
        params,
        filter: filterParams.isEmpty ? undefined : filterParams.valid,
        scope,
        force: refetch,
      });

      return list.length;
    },
  });
}
