/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {call, delay, select, put, spawn, debounce, all} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {fetchKvpairs, updateKvpairs} from 'containers/User/Settings/SettingsSaga';
import {getSearchResult, getQueryAndKeyword, getOptionText, getMigratedData} from './SelectorUtils';
import {getUserSelectorHistory} from 'containers/User/UserState';
import {getHrefMapByObjType, getValidSelectorRecents} from 'containers/Selector/SelectorState';
import {getId, isProperHref} from 'utils/href';

export function* fetchMatches(dataProvider, ...args) {
  if (typeof dataProvider === 'string') {
    const {data} = yield call(apiSaga, dataProvider, ...args);

    return data;
  }

  return yield call(dataProvider, ...args);
}

export function* fetchResource({resource, apiOptions} = {}) {
  try {
    const {dataProvider, statics, queryKeywordsRegex, searchIndex} = resource;
    const {query = ''} = apiOptions.query;

    if (query) {
      yield delay(700);
    }

    const result = {};

    if (dataProvider) {
      result.dataProviderOptions = yield call(fetchMatches, dataProvider, apiOptions);
    }

    if (searchIndex || statics) {
      result.staticsOptions =
        query && searchIndex
          ? getSearchResult(searchIndex, query)
          : typeof statics === 'function'
          ? statics(query, resource)
          : statics;
    }

    const strippedQuery = getQueryAndKeyword(query, queryKeywordsRegex).query;

    if (strippedQuery.trim()) {
      const {createHint, allowCreateOptions, allowPartial, name, optionProps: {textPath} = {}} = resource;

      if (allowPartial) {
        result.partialOption = {id: `PARTIAL_ID_${resource.id}`, value: strippedQuery, isPartial: true};
      } else {
        const {staticsOptions, dataProviderOptions} = result;

        const filterableOptions = [
          ...((Array.isArray(staticsOptions) ? staticsOptions : staticsOptions?.matches) ?? []),
          ...((Array.isArray(dataProviderOptions) ? dataProviderOptions : dataProviderOptions?.matches) ?? []),
        ];

        const exactMatches = filterableOptions.filter(
          option => getOptionText(option, textPath).trim().toLowerCase() === strippedQuery.trim().toLowerCase(),
        );

        const showCreateOptions = (() => {
          if (allowCreateOptions === false || !strippedQuery.trim()) {
            return false;
          }

          if (allowCreateOptions === true || Array.isArray(allowCreateOptions)) {
            return exactMatches.length ? false : allowCreateOptions;
          }

          if (typeof allowCreateOptions === 'function') {
            return allowCreateOptions(strippedQuery, exactMatches, filterableOptions);
          }

          return;
        })();

        if (showCreateOptions) {
          result.createOptions = Array.isArray(showCreateOptions)
            ? showCreateOptions
            : [
                {
                  id: `ADD_NEW_ID_${resource.id}`,
                  value: `${strippedQuery} ${`(${createHint ?? `${intl('Common.New')} ${name}`})`}`,
                  isCreate: true,
                },
              ];
        }
      }
    }

    return result;
  } catch (error) {
    error.message = _.get(error, 'data[0].message', error.message);

    throw error;
  }
}

export function* watchUpdateSelectorHistory() {
  yield debounce(2000, 'SELECTOR_UPDATE_HISTORY', updateKvpairs);
}

export function* updateSelectorHistory({data, dispatch = true, merge = true} = {}) {
  let recents;
  const history = yield select(getUserSelectorHistory);

  if (merge) {
    recents = {...history, ...data};
  } else {
    recents = data;
  }

  if (!_.isEqual(recents, history)) {
    if (dispatch) {
      // Immediately update recents in redux store
      yield put({type: 'SELECTOR_GET_HISTORY', data: recents});
    }

    // dispatch to debounce selector history update
    yield put({type: 'SELECTOR_UPDATE_HISTORY', key: 'selector_recent_history', data: recents});
  }
}

const nonProvisionableObjTypes = ['workloads', 'security_principals', 'labels'];

// Take objects from store or make parallel calls to fetch needed object
export function* fetchSelectiveObjects(hrefsArr = [], force = false) {
  const hrefMapByObjType = yield select(getHrefMapByObjType);

  const resultMap = new Map();

  yield all(
    hrefsArr.reduce((result, obj) => {
      if (!obj) {
        return result;
      }

      if (isProperHref(obj.href)) {
        const objType = obj.href.split('/').at(-2);

        if (!force && hrefMapByObjType[objType]?.[obj.href]) {
          // If object already exists in store, just return it
          resultMap.set(obj.href, hrefMapByObjType[objType][obj.href]);
        } else {
          // Otherwise, fetch this object and put it into the store
          result.push(
            call(function* () {
              try {
                yield call(apiSaga, `${objType}.get_instance`, {
                  cache: !force,
                  params: {
                    [objType === 'security_principals' ? 'sid' : `${objType.slice(0, -1)}_id`]: getId(obj.href),
                    ...(nonProvisionableObjTypes.includes(objType) ? {} : {pversion: 'draft'}),
                  },
                  *onDone({data}) {
                    yield put({type: `${objType.toUpperCase()}_GET_INSTANCE`, data});
                  },
                });

                const objs = yield select(getHrefMapByObjType);

                resultMap.set(obj.href, objs[objType]?.[obj.href]);
              } catch {
                resultMap.set(obj.href, null);
              }
            }),
          );
        }
      }

      return result;
    }, []),
  );

  return resultMap;
}

export function* fetchValidRecents() {
  const {recents} = yield select(getUserSelectorHistory) ?? {};

  yield call(fetchSelectiveObjects, Object.values(recents)?.flat());

  const validRecents = yield select(getValidSelectorRecents);

  // Spawn updateSelectorHistory
  // this saga performs an equality check and, if needed it will update the store and also make api call to update kvPairs with valid entries
  yield spawn(updateSelectorHistory, {data: {recents: validRecents}});

  return validRecents;
}

export function* fetchSelectorHistory({force = false} = {}) {
  const data = yield call(fetchKvpairs, {
    key: 'selector_recent_history',
    force,
  });

  // Migrate selector recents data structure
  const migratedData = getMigratedData(data);

  if (_.isEqual(migratedData, data)) {
    yield put({type: 'SELECTOR_GET_HISTORY', data});
  } else {
    // PUT migrated data in store and also make api call to update kvPairs
    yield spawn(updateSelectorHistory, {data: migratedData, merge: false});
  }
}
