/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import intl from 'intl';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {object} from 'yup';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {AttributeList, Banner, Form, Button, MenuItem, Modal} from 'components';
import styles from './VenUpgrade.css';
import {fetchTargetedVens, upgradeVens} from './VenUpgradeSaga';
import {getVenUpgrade} from 'containers/Ven/Upgrade/VenUpgradeState';

let releases = [];

const getInitialValues = (props, releases) => {
  const initialValues = {};

  initialValues.release = releases[0];

  return initialValues;
};

const getInitialState = props => {
  const {libraries} = props;

  // format as needed by UI + API
  releases = libraries
    ?.sort((a, b) => (a.release < b.release ? 1 : -1))
    .map(r => ({label: `${r.release} ${r.default ? intl('CoreServices.Default') : ''}`, value: r.release}));

  return {
    showMenu: false,
    showModal: false,
    calculating: true,
    inclusionMode: 'all',
    errors: [],
    releases,
    initialValues: getInitialValues(props, releases), // formik init
    predictedCount: 0,
  };
};

/**
 * Parameters
 * @enableMenu - boolean - whether to show the Ven set selection menu. false if no choices (use on detail page).
 * @enableFilters - boolean - whether to use url scoped filters
 * @venSet - Set - selectedKeySet OR arbitrary (e.g. Set of 1 on detail page)
 * @gridSettings - object - only needed for filter params/context
 * @disabled - boolean - whether to disable the VenUpgrade button
 * @filterItems - object -selector.filterItems that produced the current list
 * @vensInView - number - current number of Vens in view, which could be selected.
 */
@connect(getVenUpgrade)
export default class VenUpgrade extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = getInitialState(props);

    this.schemas = object({
      release: object().nullable().required(Form.EmptyMessage),
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // ven release list becomes available
    if (prevState.releases.length !== nextProps.libraries.length) {
      return getInitialState(nextProps);
    }

    return null;
  }

  handleButtonClick = () => {
    if (this.props.enableMenu) {
      this.setState({showMenu: true});
    } else {
      this.handleBeginUpgrade();
    }
  };

  handleCancel = () => {
    this.handleClose();
  };

  handleClose = () => {
    //TODO: cancel saga promises
    this.setState({
      showMenu: false,
      showModal: false,
      calculating: true,
      inclusionMode: 'all',
      errors: [],
    });
  };

  /* determine designated set of Ven instances to upgrade, in compact form */
  setTargets = async () => {
    const {dispatch, route, scopeItems, filterItems, venSet} = this.props;
    const {fetcher} = this.context;
    const {inclusionMode} = this.state;

    let targets;

    if (inclusionMode === 'all' || inclusionMode === 'filtered') {
      const options = {route, scopeItems, filterItems};
      // get a list of Vens and persist them in redux

      await fetcher.fork(fetchTargetedVens, route, false, options);
      targets = this.props.targets;
    } else {
      targets = Array.from(venSet).map(href => ({href}));
    }

    dispatch({type: 'VENS_GET_TARGETS', data: {list: targets, count: targets.length}});
  };

  getUpgrade = async dryRun => {
    const {fetcher} = this.context;
    const {release} = this.formik?.values ?? {};
    const {targets, dispatch} = this.props;

    const response = await fetcher.fork(upgradeVens, release.value, targets, Boolean(dryRun));

    this.setPredictedOutcomes(response);

    const {predictedCount} = this.state;

    if (!dryRun && predictedCount) {
      // loads data into VenUpgradeRequestedNotice
      const {errors} = response;

      dispatch({type: 'VENS_UPGRADE_REQUESTED', data: {errors, started: Date.now(), count: predictedCount}});
    }
  };

  handleConfirm = async () => {
    this.getUpgrade(false);
    this.handleClose();
  };

  handleBeginUpgrade = inclusionMode => {
    this.setState({inclusionMode, showModal: true, showMenu: false}, this.handleRefresh);
  };

  renderErrors = () => {
    const {errors} = this.state;

    if (!errors.length) {
      return '';
    }

    const formatted = errors.map((err, i) => {
      const message = intl(`ErrorsAPI.err:${err.token}`) || err.message || '';

      return <p key={i}>{`${err.hrefs?.length || ''} ${message}`}</p>; // if no hrefs, message applies to all
    });

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{formatted}</>
    );
  };

  setPredictedOutcomes = response => {
    const matched = this.props.targetCount;
    const {errors, predictZeroUpgrades} = response;

    if (predictZeroUpgrades === true) {
      // an API error occurred; predict 100% failure. E.g. 'upgrade_path_not_found'
      this.setState({errors, predictedCount: 0, failCount: matched});

      return;
    }

    const failCount = errors?.reduce((sum, err) => (sum += err.hrefs ? err.hrefs.length : 1), 0);
    // predicted is target count, minus sum(error counts) = number that "will be upgraded".
    const predicted = matched - failCount;

    this.setState({errors, predictedCount: predicted, failCount});
  };

  handleRefresh = async (evt, item, name) => {
    if (this.formik) {
      const {setFieldValue} = this.formik;

      setFieldValue(name, item);
    }

    this.setState({calculating: true});
    await this.setTargets();
    await this.getUpgrade(true);
    this.setState({calculating: false}); // allows preview rendering when ven validation is done.
  };

  renderPreview = () => {
    const matched = this.props.targetCount;
    const {inclusionMode, predictedCount} = this.state;
    const intended = {n: matched};

    return (
      <>
        {matched > 0 && inclusionMode === 'selected' && <p>{intl('VEN.Upgrade.VensSelected', intended)}</p>}
        {matched > 0 && inclusionMode === 'filtered' && <p>{intl('VEN.Upgrade.VensMatchFilter', intended)}</p>}

        <p>{intl('VEN.Upgrade.VensWillBeUpgraded', {n: predictedCount})}</p>
        {this.renderErrors()}
      </>
    );
  };

  renderModal = () => {
    const {calculating, predictedCount} = this.state;

    return (
      <Modal tid="upgrade" onClose={this.handleClose} stretch maxWidth="calc(10 * var(--62px))">
        <Modal.Header title={intl('VEN.Upgrade.Title')} />
        <Form enableReinitialize schemas={this.schemas} initialValues={this.state.initialValues} allowLeaveOnDirty>
          {options => {
            this.formik = options;

            const {values} = options;
            const releaseStr = values?.release?.value;

            return (
              <>
                <Modal.Content notScrollable>
                  <AttributeList>
                    {[
                      {
                        content: (
                          <Form.Label
                            title={intl('Workloads.Summary.VENVersion')}
                            theme={{asteriskHidden: styles.asteriskHidden}}
                          />
                        ),
                      },
                      {
                        content: (
                          <Form.Selector
                            name="release"
                            options={releases}
                            onChange={this.handleRefresh}
                            tid="release"
                          />
                        ),
                      },
                      {
                        content: (
                          <Form.Label title={intl('Common.Preview')} theme={{asteriskHidden: styles.asteriskHidden}} />
                        ),
                      },
                      {
                        content: (
                          <div className={styles.preview}>
                            {calculating ? (
                              <Banner
                                type="progress"
                                subText={intl('VEN.Upgrade.VensThatQualify', {version: releaseStr})}
                              >
                                {intl('PolicyGenerator.CalculationInProgress')}
                              </Banner>
                            ) : (
                              this.renderPreview()
                            )}
                          </div>
                        ),
                      },
                    ]}
                  </AttributeList>
                </Modal.Content>

                <Modal.Footer>
                  <Button noFill text={intl('Common.Cancel')} tid="cancel" onClick={this.handleCancel} />
                  <Button
                    text={intl('VEN.Upgrade.ConfirmUpgrade')}
                    tid="confirm"
                    disabled={!predictedCount}
                    onClick={_.partial(this.handleConfirm, values)}
                  />
                </Modal.Footer>
              </>
            );
          }}
        </Form>
      </Modal>
    );
  };

  render() {
    const {
      props: {
        enableMenu,
        venSet,
        vensInView,
        scopeItems,
        filterItems,
        isVensUpgradeAvailable, // RBAC - is api available to this user?
        disableViaCaps, // detail views can disable via ven.caps
        showCounter,
      },
      state: {showModal},
      handleBeginUpgrade,
      handleButtonClick,
    } = this;
    const enableFilters = Boolean(scopeItems?.length || filterItems?.length);
    const theme = composeThemeFromProps(styles, this.props);
    const countSelected = venSet?.size;
    const disableUpgrade = !isVensUpgradeAvailable || disableViaCaps || vensInView === 0;
    const zeroVenBundles = releases.length < 1;
    const tooltip = zeroVenBundles && !disableUpgrade ? intl('VEN.Upgrade.ZeroVenReleaseBundles') : '';
    const buttonMenu = [
      <MenuItem
        text={intl('VEN.Upgrade.UpgradeAll')}
        onSelect={_.partial(handleBeginUpgrade, 'all')}
        disabled={enableFilters}
      />,
      <MenuItem
        text={intl('VEN.Upgrade.UpgradeSelected')}
        disabled={!countSelected}
        onSelect={_.partial(handleBeginUpgrade, 'selected')}
      />,
      <MenuItem
        text={intl('VEN.Upgrade.UpgradeFiltered')}
        disabled={!enableFilters}
        onSelect={_.partial(handleBeginUpgrade, 'filtered')}
      />,
    ];

    const buttonProps = {
      text: intl('Common.Upgrade'),
      tid: 'upgrade',
      tooltip,
      tooltipProps: {bottom: true},
      disabled: disableUpgrade || zeroVenBundles,
      onClick: handleButtonClick,
      color: 'standard',
      textIsHideable: true,
      counter: showCounter ? countSelected : undefined,
    };

    return (
      <>
        {enableMenu ? <Button.Menu menu={buttonMenu} {...buttonProps} /> : <Button {...buttonProps} />}
        {showModal && this.renderModal(theme)}
      </>
    );
  }
}
