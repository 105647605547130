/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import stringify from 'safe-stable-stringify';
import intl from 'intl';
import {Pill, Form} from 'components';
import {object, string, boolean} from 'yup';
import * as GridUtils from 'components/Grid/GridUtils';
import styleUtils from 'utils.css';
import styles from './SecuritySettings.css';
import attributeListStyles from 'components/AttributeList/AttributeList.css';
import {sortAndStringifyArray} from 'utils/general';
import {createSelector} from 'reselect';
import {edge} from 'api/apiUtils';

const pillsGap = 'gapXSmall gapHorizontalWrap';

const modifyScopeSchemasObject = createSelector([], () => ({
  role: object().shape({
    key: string(),
    href: string(),
    value: string().required(intl('Settings.Edit.ModifyScope.RoleRequired')),
  }),
  app: object().shape({
    key: string(),
    href: string(),
    value: string().required(intl('Settings.Edit.ModifyScope.AppRequired')),
  }),
  env: object().shape({
    key: string(),
    href: string(),
    value: string().required(intl('Settings.Edit.ModifyScope.EnvRequired')),
  }),
  loc: object().shape({
    key: string(),
    href: string(),
    value: string().required(intl('Settings.Edit.ModifyScope.LocRequired')),
  }),
}));
const baseProtectionSchemas = object({
  ipv6: Form.Radio.schema.oneOf(['allowed', 'blocked']).required(),
  ipv6_mode: Form.Radio.schema.oneOf(['policy_based', 'block_all']).required(),
});
const scopeAndLabelsSchemas = object({
  scopeKeysChanged: boolean().oneOf([true]),
});
const ikeAuthTypeSchemas = object({
  ipsecAuthority: Form.Radio.schema.oneOf(['psk', 'certificate']).required(),
});
const modifyScopeInitValues = {
  role: {},
  app: {},
  env: {},
  loc: {},
};

export const baseProtectionInitValues = {
  ipv6: 'allowed',
  ipv6_mode: 'policy_based',
};

export const scopeAndLabelsInitValues = {
  scopeKeysChanged: false,
};

export const ikeAuthTypeInitValues = {
  ipsecAuthority: 'psk',
};

export const policyStateOptions = createSelector([], () => [
  {value: 'all', label: intl('Common.All'), subText: intl('Firewall.Coexistence.BuildTestEnforced')},
  {value: 'enforced', label: intl('Common.Enforced')},
  {value: 'illuminated', label: intl('Common.Illuminated'), subText: intl('Firewall.Coexistence.BuildTest')},
]);

export const primaryFirewallOptions = createSelector([], () => [
  {value: 'yes', label: intl('Common.Yes'), subText: intl('Firewall.Coexistence.SetIllumioAsPrimaryFirewall')},
  {value: 'no', label: intl('Common.No'), subText: intl('Firewall.Coexistence.SetIllumioAsNotPrimaryFirewall')},
]);

export const sectionConfig = createSelector([], () => ({
  general: {text: intl('Common.General'), schemas: baseProtectionSchemas, initialValues: baseProtectionInitValues},
  ikeAuth: {
    text: intl('Settings.IKEAuthentication'),
    schemas: ikeAuthTypeSchemas,
    initialValues: ikeAuthTypeInitValues,
  },
  staticscopes: {
    text: intl('Policy.Configuration'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  firewallcoexistence: {
    text: intl('Firewall.Coexistence.Title'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object({
      ...modifyScopeSchemasObject(),
      policyState: object()
        .test('is-policy-state-option', intl('Settings.Edit.ModifyScope.Errors.PolicyStateOption'), item =>
          policyStateOptions().includes(item),
        )
        .nullable(),
      primaryFirewall: object()
        .test('is-primary-firewall', intl('Settings.Edit.ModifyScope.Errors.PrimaryFirewall'), item =>
          primaryFirewallOptions().includes(item),
        )
        .nullable()
        .required(intl('Settings.Edit.ModifyScope.PrimaryFirewallRequired')),
    }),
    scopeFormInitialValues: {
      ...modifyScopeInitValues,
      policyState: policyStateOptions()[0],
      primaryFirewall: primaryFirewallOptions()[0],
    },
  },
  loopbackinterfaces: {
    text: intl('Settings.LoopbackInterfaces.Title'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  blockaction: {
    text: intl('Common.BlockAction'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  containers: {
    text: intl('Common.Containers'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
  ipforwarding: {
    text: intl('Settings.IPForwarding.Title'),
    schemas: scopeAndLabelsSchemas,
    initialValues: scopeAndLabelsInitValues,
    modifyScopeSchemas: object(modifyScopeSchemasObject()),
    scopeFormInitialValues: modifyScopeInitValues,
  },
}));

export const allCategoryLabelsObject = createSelector([], () =>
  !edge
    ? {
        role: {key: 'role', value: intl('Common.AllRoles'), href: 'all_role/'},
        app: {key: 'app', value: intl('Common.AllApplications'), href: 'all_app/'},
        env: {key: 'env', value: intl('Common.AllEnvironments'), href: 'all_env/'},
        loc: {key: 'loc', value: intl('Common.AllLocations'), href: 'all_loc/'},
      }
    : {
        role: {key: 'role', value: intl('Common.AllGroups'), href: 'all_role/'},
      },
);

//All Roles, All Applications, All Locations, All Environments
export const allCategoryLabels = Object.values(allCategoryLabelsObject()).map(labelObj => (
  <Pill.Label type={labelObj.key} key={labelObj.href}>
    {labelObj.value}
  </Pill.Label>
));
const systemDefaultDisabledAllLabels = {
  key: intl('Settings.SystemDefaultDisabled'),
  tid: 'default',
  value: allCategoryLabels,
  valueGap: pillsGap,
};

const staticPolicyReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Policy.UpdateMode'),
    tid: 'updatemode',
    value: intl('Settings.Edit.PolicyConfiguration.PolicyUpdateModeDesc'),
  },
  {
    key: intl('Settings.Edit.PolicyConfiguration.SystemDefaultAdaptive'),
    tid: 'default',
    value: allCategoryLabels,
    valueGap: pillsGap,
  },
]);
const firewallCoexReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Firewall.Mode'),
    tid: 'updatemode',
    value: intl('Common.Exclusive'),
    secondary: <div className={styles.value}>{intl('Firewall.Coexistence.ModeDesc')}</div>,
  },
  {
    key: intl('Firewall.Coexistence.SystemDefault'),
    tid: 'default',
    value: (
      <>
        {allCategoryLabels}
        <Pill.Label>{intl('Firewall.Coexistence.PolicyStateAll')}</Pill.Label>
      </>
    ),
    valueGap: pillsGap,
  },
]);
const blockActionReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.BlockAction.BlockedConnectionAction'),
    tid: 'updatemode',
    value: intl('Settings.BlockAction.BlockedConnectionActionDesc'),
  },
  systemDefaultDisabledAllLabels,
]);
const loopbackReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.LoopbackInterfaces.Title'),
    tid: 'updatemodeloopback',
    value: <div className={styles.value}>{intl('Settings.LoopbackInterfaces.LoopbackInterfacesDescription')}</div>,
  },
  systemDefaultDisabledAllLabels,
]);
const containersReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.Containers.ContainerInheritHostPolicy'),
    tid: 'updatemode',
    value: <div className={styles.value}>{intl('Settings.Containers.ContainerInheritHostPolicyDesc')}</div>,
  },
  systemDefaultDisabledAllLabels,
]);
const ipforwardingReadOnlyAttributes = createSelector([], () => [
  {
    key: intl('Settings.IPForwarding.Title'),
    tid: 'updatemode',
    value: <div className={styles.value}>{intl('Settings.IPForwarding.Description')}</div>,
  },
  systemDefaultDisabledAllLabels,
]);

export const scopeReadOnlyAttributes = createSelector([], () => ({
  staticscopes: {
    startRows: staticPolicyReadOnlyAttributes(),
    scopeLabel: intl('Settings.Edit.ScopeUsingLabelsAndLabelGroups'),
    add: {
      title: intl('Labels.AddScopeForStaticPolicy'),
    },
    edit: {
      title: intl('Labels.EditScopeForStaticPolicy'),
    },
    instruction: intl('Firewall.PolicyStateInstruction'),
    duplicatedMsg: intl('Firewall.DuplicatedScopesAndLabelsForPolicyState'),
    errorMsg: intl('Firewall.PolicyStateAllError'),
  },
  firewallcoexistence: {
    startRows: firewallCoexReadOnlyAttributes(),
    scopeLabel: intl('Firewall.Coexistence.ScopesUsingLabels'),
    add: {
      title: intl('Firewall.Coexistence.AddScopeForFirewallCoexistence'),
    },
    edit: {
      title: intl('Firewall.Coexistence.EditScopeForFirewallCoexistence'),
    },
    instruction: intl('Firewall.CoexistenceInstruction'),
    duplicatedMsg: intl('Firewall.DuplicatedScopesAndLabelsForFirewallCoexistence'),
    errorMsg: intl('Firewall.CoexistenceAllError'),
    allConfirmationMsg: intl('Firewall.Coexistence.AllConfirmation'),
  },

  loopbackinterfaces: {
    startRows: loopbackReadOnlyAttributes(),
    scopeLabel: intl('Settings.Edit.ScopeUsingLabelsAndLabelGroups'),
    add: {
      title: intl('Settings.LoopbackInterfaces.Title'),
    },
    edit: {
      title: intl('Settings.LoopbackInterfaces.EditScopeForLoopbackInterfaces'),
    },
    instruction: intl('Settings.LoopbackInterfaces.ConfigInstruction'),
    duplicatedMsg: intl('Settings.LoopbackInterfaces.DuplicatedScopesAndLabels'),
    errorMsg: intl('Settings.LoopbackInterfaces.ConfigAllError'),
    emptyMsg: intl('Settings.LoopbackInterfaces.NoData'),
  },
  containers: {
    startRows: containersReadOnlyAttributes(),
    scopeLabel: intl('Settings.Edit.ScopeUsingLabelsAndLabelGroups'),
    add: {
      title: intl('Settings.Containers.AddScopeForContainerInheritHostPolicy'),
    },
    edit: {
      title: intl('Settings.Containers.EditScopeForContainerInheritHostPolicy'),
    },
    instruction: intl('Settings.Containers.ConfigInstruction'),
    duplicatedMsg: intl('Settings.Containers.DuplicatedScopesAndLabels'),
    errorMsg: intl('Settings.Containers.ConfigAllError'),
  },
  blockaction: {
    startRows: blockActionReadOnlyAttributes(),
    scopeLabel: intl('Settings.Edit.ScopeUsingLabelsAndLabelGroups'),
    add: {
      title: intl('Settings.BlockAction.AddScopeForRejectConnections'),
    },
    edit: {
      title: intl('Settings.BlockAction.EditScopeForRejectConnections'),
    },
    duplicatedMsg: intl('Settings.BlockAction.DuplicatedScopesAndLabels'),
    allConfirmationMsg: intl('Settings.BlockAction.AllConfirmation'),
  },
  ipforwarding: {
    startRows: ipforwardingReadOnlyAttributes(),
    scopeLabel: intl('Settings.Edit.ScopeUsingLabelsAndLabelGroups'),
    add: {
      title: intl('Settings.IPForwarding.AddScope'),
    },
    edit: {
      title: intl('Settings.IPForwarding.EditScope'),
    },
    instruction: intl('Settings.Containers.ConfigInstruction'),
    duplicatedMsg: intl('Settings.IPForwarding.DuplicatedScopesAndLabels'),
    allConfirmationMsg: intl('Settings.IPForwarding.AllConfirmation'),
    emptyMsg: intl('Settings.IPForwarding.NoData'),
  },
}));

/**
 * Get Page header subtitle e.g. header for policy configuration tab is 'Security - Policy Configuration'
 */
export const getHeaderSubTitle = section =>
  ({
    general: intl('Common.General'),
    staticscopes: intl('Common.StaticPolicy'),
    firewallcoexistence: intl('Firewall.Coexistence.Title'),
    secureconnect: intl('Settings.SecureConnect'),
    containers: intl('Settings.Containers.ContainersPolicy'),
    blockaction: intl('Common.BlockAction'),
    ipforwarding: intl('Settings.IPForwarding.Title'),
    loopbackinterfaces: intl('Settings.LoopbackInterfaces.Title'),
  }[section]);

/**
 * Generate Grid key and labels for the scope in static policy and firewall coexistence
 * Scope is an array of Label or Label Group objects such as {label: {href: '/orgs/1/labels/1', key: 'role', value: 'Web'}},
 * or {label_group: {href: '/orgs/1/labels/1', key: 'role', name: 'Web'}}
 * Api does not provide a scope label if its value is 'All'
 */
const generateStaticPolicyRow = scope => {
  //defaults label 'All' for role, app, env, and loc type, set group to false
  const defaultLabels = Object.values(allCategoryLabelsObject());

  scope.forEach(label => {
    const labelObj = label.label || label.label_group;
    const labelFromApi = {};

    labelFromApi.href = labelObj.href;
    labelFromApi.value = labelObj.value || labelObj.name;
    labelFromApi.key = labelObj.key;
    labelFromApi.group = !labelObj.value;

    //Update default label with the data received from API for scope type
    if (labelObj.key === 'role') {
      defaultLabels[0] = labelFromApi;
    } else if (labelObj.key === 'app') {
      defaultLabels[1] = labelFromApi;
    } else if (labelObj.key === 'env') {
      defaultLabels[2] = labelFromApi;
    } else if (labelObj.key === 'loc') {
      defaultLabels[3] = labelFromApi;
    }
  });

  return {
    key: Object.keys(defaultLabels)
      .map(label => defaultLabels[label].href)
      .join(','), //Key is ',' separated label hrefs
    selectable: true,
    data: {labels: GridUtils.getLabelsMap(defaultLabels)},
  };
};

/**
 * Generate Grid key and labels for the scope in static policy and firewall coexistence
 * Scope is an array of Label or Label Group objects such as {label: {href: '/orgs/1/labels/1', key: 'role', value: 'Web'}},
 * or {label_group: {href: '/orgs/1/labels/1', key: 'role', name: 'Web'}}
 * Api only provides label object for named labels
 */
const generateFirewallCoexistenceRow = item => {
  //defaults label 'All' for role, app, env, and loc type, set group to false
  const defaultLabels = Object.values(allCategoryLabelsObject());
  const state = item.workload_mode || 'all';

  item.scope.forEach(label => {
    //Update default label with the data received from API for scope type
    if (label.key === 'role') {
      defaultLabels[0] = label;
    } else if (label.key === 'app') {
      defaultLabels[1] = label;
    } else if (label.key === 'env') {
      defaultLabels[2] = label;
    } else if (label.key === 'loc') {
      defaultLabels[3] = label;
    }
  });

  const labelKeys = Object.keys(defaultLabels)
    .map(label => defaultLabels[label].href)
    .join(',');

  return {
    key: `${labelKeys},${state},${item.illumio_primary ? 'yes' : 'no'}`,
    selectable: true,
    data: {labels: GridUtils.getLabelsMap(defaultLabels), state, primaryFirewall: item.illumio_primary},
  };
};

export const getDataCenterDesc = ({type, html = false}) =>
  ({
    strict_brn: intl('Settings.WorkloadsHostedInCloud', {className: styleUtils.bold}, {html}),
    single_private_brn: intl('Settings.WorkloadsHostedInPrivate', {className: styleUtils.bold}, {html}),
    cloud_private_brn: intl('Settings.WorkloadsHostedInCloudWithPrivate', {className: styleUtils.bold}, {html}),
    single_brn: intl('Settings.WorkloadsHostedInPrivateOnly', {className: styleUtils.bold}, {html}),
    disabled: intl('Settings.OrgDataCenterIsDisabled', {className: styleUtils.bold}, {html}),
  }[type]);

export const formatNewScopeRow = ({values, section} = {}) => {
  const {role, app, env, loc, policyState, primaryFirewall} = values;
  const labels = [role, app, env, loc];
  const derivedLabelsKey = labels.map(label => label.href).join(','); //Key is ',' separated label hrefs
  let key;

  if (section === 'firewallcoexistence') {
    //Include primary firewall in key as two rows will be added with status (create, delete) if primary firewall is modified
    key = `${derivedLabelsKey},${policyState.value},${primaryFirewall.value}`;
  } else {
    key = derivedLabelsKey;
  }

  return {
    key,
    selectable: true,
    data: {
      labels: GridUtils.getLabelsMap(labels),
      status: 'create',
      highlightGreen: true,
      action: true,
      ...(section === 'firewallcoexistence' && {
        state: policyState.value,
        primaryFirewall: primaryFirewall.value === 'yes',
      }),
    },
  };
};

export const formatInitialItemByType = (labelObj, labelType) => {
  if (_.isEmpty(labelObj)) {
    return [];
  }

  const initialItem = {href: labelObj.href, key: labelType};

  if (labelObj.group) {
    initialItem.categoryName = intl('Labels.Groups');
    initialItem.categoryKey = 'label_groups';
    initialItem.name = labelObj.value;
  } else {
    initialItem.categoryName = intl('Common.Labels');
    initialItem.categoryKey = labelObj.href.includes('all_') ? 'all' : 'labels';
    initialItem.value = labelObj.value;
  }

  return [{...initialItem}];
};

export const formatLabelFromSelection = (type, labelObj) => {
  const isGroup = labelObj.categoryKey === 'label_groups';

  return {
    key: type,
    group: isGroup,
    href: labelObj.href || `all_${type}/`,
    value: isGroup ? labelObj.name : labelObj.value,
  };
};

export const formatStaticPolicyScopesFromGridRows = rows =>
  rows.reduce((result, row) => {
    // Omit passing any items with delete status to the BE; otherwise, two items (original and updated) will be sent.
    if (row.data.status === 'delete') {
      return result;
    }

    const labels = Object.keys(row.data.labels).reduce((scope, item) => {
      if (row.data.labels[item].group) {
        scope.push({label_group: {href: row.data.labels[item].href}});
      } else {
        if (row.data.labels[item].href.includes('all_')) {
          //Skip all labels in api payload
          return scope;
        }

        scope.push({label: {href: row.data.labels[item].href}});
      }

      return scope;
    }, []);

    result.push(labels);

    return result;
  }, []);

export const formatFirewallCoexistenceFromGridRows = rows =>
  rows.reduce((result, row) => {
    // Omit passing any items with delete status to the BE; otherwise, two items (original and updated) will be sent.
    if (row.data.status === 'delete') {
      return result;
    }

    const labels = Object.keys(row.data.labels).reduce((scope, item) => {
      if (row.data.labels[item].href.includes('all_')) {
        //Skip all labels in api payload
        return scope;
      }

      scope.push({href: row.data.labels[item].href});

      return scope;
    }, []);

    result.push({
      scope: labels,
      workload_mode: row.data.state === 'all' ? undefined : row.data.state,
      illumio_primary: row.data.primaryFirewall,
    });

    return result;
  }, []);

export const formatTabPanelParams = (section, mode) => ({to: 'securitysettings.section', params: {section, mode}});

export const hasRowsChanged = (initialRows, rows) =>
  initialRows.length !== rows.length ||
  initialRows
    .map(row => row.key + row.data.status)
    .sort()
    .join(',') !==
    rows
      .map(row => row.key)
      .sort()
      .join(',');

export const modifyScopeFormBreakpoints = [
  {props: {className: attributeListStyles.sizeS}},
  {maxWidth: 510, props: {className: attributeListStyles.sizeXS}},
];

/**
 * Stable stringify nested array in scope labels are received in different orders from backend
 */
const stringifyScope = scopeRows =>
  sortAndStringifyArray(
    scopeRows?.map(scopeLabels =>
      sortAndStringifyArray(scopeLabels.map(labelObj => (labelObj.label || labelObj.label_group)?.href)),
    ),
  );

/**
 * Stable stringify nested array and objects in firewallCoex as scope labels are received in different orders from backend
 */
const stringifyFirewallCoexElements = firewallRows =>
  firewallRows
    .map(item =>
      stringify({
        ...item,
        scope: item.scope.map(scopeItem => stringify(scopeItem)).sort(),
      }),
    )
    .sort()
    .join(',');

const getSettingsByVersion = settings =>
  Object.entries(settings).reduce((result, [key, value]) => {
    //remove null, href, update_type and updated_at for comparision between versions
    if (value !== null && key !== 'href' && key !== 'update_type' && key !== 'updated_at') {
      if (key === 'firewall_coexistence') {
        //object with array of array prop
        result[key] = stringifyFirewallCoexElements(value);
      } else {
        result[key] = value;
      }
    }

    return result;
  }, {});

export const hasDraft = settings =>
  settings && stringify(getSettingsByVersion(settings.draft)) !== stringify(getSettingsByVersion(settings.active));

const getInitialRowsByMode = (mode, activeRows = [], draftRows = []) => {
  switch (mode) {
    case 'edit':
    case 'draft': //Merge Active and Draft rows with 'Addition Pending'/'Deletion Pending' provision status
      const scopes = [];
      const draftItems = {};
      const activeItems = {};

      for (const draftRow of draftRows) {
        draftItems[draftRow.key] = draftRow;
      }

      for (const activeRow of activeRows) {
        activeItems[activeRow.key] = activeRow;
      }

      for (const draftRow of draftRows) {
        const scope = {...draftRow};

        scope.data = {...scope.data, action: true};

        if (!activeItems[scope.key]) {
          // If the draft static policy scope is not there in the active
          // collection of static policy scopes, it is "Addition Pending"
          scope.data.status = 'create';
        }

        scopes.push(scope);
      }

      for (const activeRow of activeRows) {
        // Add all the rows which are in the active collection of static policy
        // scopes, but not in the draft collection. These are "Deletion Pending"
        if (!draftItems[activeRow.key]) {
          scopes.push({
            ...activeRow,
            selectable: false,
            data: {...activeRow.data, status: 'delete', action: false},
          });
        }
      }

      return scopes;
    default:
      return activeRows;
  }
};

const getStaticScopeInitRows = (mode, {active, draft} = {}) => {
  const activeStaticPolicy = active ? active.static_policy_scopes.map(scope => generateStaticPolicyRow(scope)) : [];
  const draftStaticPolicy = draft ? draft.static_policy_scopes.map(scope => generateStaticPolicyRow(scope)) : [];

  return getInitialRowsByMode(mode, activeStaticPolicy, draftStaticPolicy);
};

const getFirewallCoexInitRows = (mode, {active, draft} = {}) => {
  const activeFirewall = active ? active.firewall_coexistence.map(item => generateFirewallCoexistenceRow(item)) : [];
  const draftFirewall = draft ? draft.firewall_coexistence.map(item => generateFirewallCoexistenceRow(item)) : [];

  return getInitialRowsByMode(mode, activeFirewall, draftFirewall);
};

const getLoopbackInterfacesInitRows = (mode, {active, draft} = {}) => {
  const activeLoopback = active
    ? active.loopback_interfaces_in_policy_scopes.map(item => generateStaticPolicyRow(item))
    : [];
  const draftLoopback = draft
    ? draft.loopback_interfaces_in_policy_scopes.map(item => generateStaticPolicyRow(item))
    : [];

  return getInitialRowsByMode(mode, activeLoopback, draftLoopback);
};

const getContainersInitRows = (mode, {active, draft} = {}) => {
  const activeContainer = active
    ? active.containers_inherit_host_policy_scopes &&
      active.containers_inherit_host_policy_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];
  const draftContainer = draft
    ? draft.containers_inherit_host_policy_scopes &&
      draft.containers_inherit_host_policy_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];

  return getInitialRowsByMode(mode, activeContainer, draftContainer);
};

const getBlockActionInitRows = (mode, {active, draft} = {}) => {
  const activeBlockAction = active
    ? active.blocked_connection_reject_scopes &&
      active.blocked_connection_reject_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];
  const draftBlockAction = draft
    ? draft.blocked_connection_reject_scopes &&
      draft.blocked_connection_reject_scopes.map(scope => generateStaticPolicyRow(scope))
    : [];

  return getInitialRowsByMode(mode, activeBlockAction, draftBlockAction);
};

const getIPForwardingInitRows = (mode, {active, draft} = {}) => {
  const activeRows = active?.ip_forwarding_enabled_scopes?.map(scope => generateStaticPolicyRow(scope)) ?? [];
  const draftRows = draft?.ip_forwarding_enabled_scopes?.map(scope => generateStaticPolicyRow(scope)) ?? [];

  return getInitialRowsByMode(mode, activeRows, draftRows);
};

export const getInitialRows = (mode, section, securitySettings) => {
  if (!securitySettings) {
    return [];
  }

  switch (section) {
    case 'staticscopes':
      return getStaticScopeInitRows(mode, securitySettings);
    case 'firewallcoexistence':
      return getFirewallCoexInitRows(mode, securitySettings);
    case 'containers':
      return getContainersInitRows(mode, securitySettings);
    case 'loopbackinterfaces':
      return getLoopbackInterfacesInitRows(mode, securitySettings);
    case 'blockaction':
      return getBlockActionInitRows(mode, securitySettings);
    case 'ipforwarding':
      return getIPForwardingInitRows(mode, securitySettings);
    default:
      return [];
  }
};

export const getModifiedSections = ({active, draft} = {}) => {
  const modified = {};

  if (active && draft) {
    if (draft.allow_ipv6 !== active.allow_ipv6) {
      modified.ip = true;
    }

    if (draft.ipv6_mode !== active.ipv6_mode) {
      modified.ipv6Mode = true;
    }

    if (draft.network_detection_mode !== active.network_detection_mode) {
      modified.connectivity = true;
    }

    if (stringifyScope(draft.static_policy_scopes) !== stringifyScope(active.static_policy_scopes)) {
      modified.staticPolicy = true;
    }

    if (
      stringifyFirewallCoexElements(active.firewall_coexistence) !==
      stringifyFirewallCoexElements(draft.firewall_coexistence)
    ) {
      modified.firewallCoexistence = true;
    }

    if (
      stringifyScope(active.loopback_interfaces_in_policy_scopes) !==
      stringifyScope(draft.loopback_interfaces_in_policy_scopes)
    ) {
      modified.loopbackInterfaces = true;
    }

    if (
      stringifyScope(draft.containers_inherit_host_policy_scopes) !==
      stringifyScope(active.containers_inherit_host_policy_scopes)
    ) {
      modified.containers = true;
    }

    if (
      stringifyScope(draft.blocked_connection_reject_scopes) !== stringifyScope(active.blocked_connection_reject_scopes)
    ) {
      modified.blockaction = true;
    }

    if (stringifyScope(draft.ip_forwarding_enabled_scopes) !== stringifyScope(active.ip_forwarding_enabled_scopes)) {
      modified.ipforwarding = true;
    }

    if (draft.ike_authentication_type !== active.ike_authentication_type) {
      modified.ikeAuth = true;
    }
  }

  return modified;
};

const allScopeKeys = Object.values(allCategoryLabelsObject())
  .map(labelObj => labelObj.href)
  .join(',');
export const areScopesDefault = key =>
  key === allScopeKeys || key === `${allScopeKeys},all,yes` || key === `${allScopeKeys},all,no`;

/**
 * This method checks for duplicate scopes or scopes that contain 'all' default options.
 *
 * The two sections on Security Settings that use it are: Policy Configuration (staticscopes) and Firewall
 * Coexistence (firewallcoexistence).
 *
 * For staticscopes the check is fairly trivial since the permutations are minimal. firewallcoexistence on the otherhand has edge-cases
 * to be mindful of such as the following:
 *
 * - If 'all' options are selected assign true to result.all so that we display a confirmation modal for the 'all' selection which will
 * prevent any more scopes to be added.
 *
 * - If 'all' is selected for Policy State on a scope, do not allow the same base scopes to be added since 'all' overrides 'enforced' and
 * 'illuminated'.
 */
export const isScopeDuplicateOrAll = ({rows, newRow, section, initialValues, isEdit}) => {
  const result = {};

  let initialRow;

  if (isEdit && section === 'firewallcoexistence') {
    initialRow = formatNewScopeRow({values: initialValues, section});
  }

  result.dupe = rows.some(row => {
    if (row.key === newRow.key) {
      return true;
    }

    // handle special all case for firewallcoexistance and blockaction
    if (section === 'firewallcoexistence') {
      // avoid checking against the inital row on edit, since it's base values will be the same
      if (initialRow && initialRow.key === row.key) {
        return false;
      }

      // key is in format of 'all_role/,/orgs/1/labels/31,/orgs/1/labels/7,all_loc/,all,yes'
      const halfRowKey = row.key.slice(0, row.key.lastIndexOf(',') + 1);
      const halfNewRowKey = newRow.key.slice(0, newRow.key.lastIndexOf(',') + 1);

      if (halfRowKey === halfNewRowKey) {
        return true;
      }

      if (row.data.state === 'all') {
        if (
          halfRowKey.replace(',all,', ',enforced,') === halfNewRowKey ||
          halfRowKey.replace(',all,', ',illuminated,') === halfNewRowKey
        ) {
          return true;
        }
      } else if (newRow.data.state === 'all') {
        if (
          halfNewRowKey.replace(',all,', ',enforced,') === halfRowKey ||
          halfNewRowKey.replace(',all,', ',illuminated,') === halfRowKey
        ) {
          return true;
        }
      }
    }

    return false;
  });

  if (!['blockaction', 'ipforwarding'].includes(section)) {
    //When all scope is selected in policy config, show error message
    //When all scope is selected in firewall config with policyState as enforced/illuminated, show error message
    result.all =
      section === 'firewallcoexistence'
        ? newRow.key.startsWith(`${allScopeKeys},enforced`) || newRow.key.startsWith(`${allScopeKeys},illuminated`)
        : newRow.key === allScopeKeys;
  }

  return result;
};

export const getNetworkDetectionModeValue = networkDetectionMode => {
  if (!networkDetectionMode) {
    return intl('Settings.NotNetworkDetectionMode');
  }

  return networkDetectionMode === 'single_private_brn'
    ? intl('Settings.PrivateDataCenter')
    : intl('Settings.PublicCloudWithSNAT');
};
