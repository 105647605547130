/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {useCallback, useState, useRef, useContext, useEffect} from 'react';
import {Button, ButtonGroup, Card, Pill, StatusIcon} from 'components';
import stylesUtils from 'utils.css';
import ScopePicker from './ScopePicker';
import {fetchRulesetItem} from '../RulesetItemSaga';
import {AppContext} from 'containers/App/AppUtils';
import {isScopeModified} from './RulesetScopeUtils';
import {getRowStatusTooltipMessage, getErrorMessage} from '../RulesetItemUtils';
import {useAutoHideTooltip} from 'containers/Selector/SelectorUtils';
import styles from '../RulesetItem.css';

export default function ScopeWidget(props) {
  const {
    id,
    isInEdit,
    scope,
    pversion = 'draft',
    actionButtonsDisabled,
    excludeKeys,
    invokeDiscardChanges,
    publishFormIsDirty,
    hideRemove,
    onSave,
    onRemove,
    onCancel,
    onValidate,
    onEditClick,
    setErrors,
  } = props;

  const {fetcher} = useContext(AppContext);

  const saveIsEnabledRef = useRef(false);
  const editorScopeRef = useRef();

  if (!editorScopeRef.current) {
    editorScopeRef.current = scope;
  }

  const [validStatus, setValidStatus] = useState();
  const [progressObj, setProgressObj] = useState({}); // {progress, onProgressDone}
  const [showTippy, setShowTippy, setSkipAutoHide] = useAutoHideTooltip(3000);

  const handleMouseEnter = useCallback(() => {
    setShowTippy(true);
    setSkipAutoHide(true);
  }, [setShowTippy, setSkipAutoHide]);
  const handleMouseLeave = useCallback(() => {
    setShowTippy(false);
    setSkipAutoHide(false);
  }, [setShowTippy, setSkipAutoHide]);

  const handleScopeChange = useCallback(
    newScope => {
      const scopeIsModified = isScopeModified(newScope, scope);

      editorScopeRef.current = newScope;

      const validStatus = scopeIsModified ? onValidate(id, newScope) : {isValid: true};

      setValidStatus(validStatus);

      if (validStatus.message) {
        setShowTippy(true);
      }

      saveIsEnabledRef.current = validStatus.isValid && scopeIsModified;

      publishFormIsDirty({dirty: scopeIsModified});
    },
    [id, onValidate, setShowTippy, scope, publishFormIsDirty],
  );

  const handleSave = useCallback(async () => {
    try {
      setProgressObj({progress: true});
      await onSave(id, editorScopeRef.current);
      await new Promise(onProgressDone =>
        setProgressObj({
          onProgressDone,
          progress: false,
        }),
      );

      publishFormIsDirty({dirty: false});
      onEditClick(null);

      await fetcher.spawn(fetchRulesetItem.refetch);
    } catch (error) {
      setErrors([getErrorMessage(error)]);
    }
  }, [id, fetcher, onSave, setErrors, onEditClick, publishFormIsDirty]);

  const handleRemove = useCallback(async () => {
    const abortAction = await invokeDiscardChanges();

    if (abortAction) {
      return;
    }

    try {
      setProgressObj({progress: true});
      await onRemove(id);
      await new Promise(onProgressDone =>
        setProgressObj({
          onProgressDone,
          progress: false,
        }),
      );
      await fetcher.spawn(fetchRulesetItem.refetch);
    } catch (error) {
      setErrors([getErrorMessage(error)]);
    }
  }, [id, onRemove, fetcher, invokeDiscardChanges, setErrors]);

  useEffect(() => {
    // Clean up on unmount
    return () => {
      setValidStatus(null);
      setProgressObj(null);
    };
  }, []);

  const {progress, onProgressDone} = progressObj;

  return (
    <Card theme={styles} themePrefix={isInEdit ? 'addNew-' : 'view-'} tid="scope">
      <div className={styles.widget}>
        {validStatus?.message && (
          <StatusIcon
            tid="scope-error"
            status={validStatus.message.type}
            position="after"
            tooltip={getRowStatusTooltipMessage(validStatus.message)}
            tooltipProps={{
              visible: showTippy,
              maxWidth: '96vw',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
        {isInEdit ? (
          <ScopePicker scope={scope} excludeKeys={excludeKeys} onScopeChange={handleScopeChange} />
        ) : (
          <div className={cx(stylesUtils.gapXSmall, stylesUtils.gapHorizontalWrap)}>
            {scope.map(({label, label_group, exclusion}) => (
              <Pill.Label
                key={label?.href ?? label_group?.href}
                type={label?.key ?? label_group?.key}
                href={label?.href ?? label_group?.href}
                group={Boolean(label_group)}
                pversion={pversion}
                exclusion={exclusion}
              >
                {label?.value ?? label_group?.name}
              </Pill.Label>
            ))}
          </div>
        )}
        {pversion === 'draft' && (
          <ButtonGroup color="standard" size="small" noFill>
            {isInEdit ? (
              <>
                <Button
                  tid="scope-save"
                  icon="save"
                  progress={progress}
                  onClick={handleSave}
                  onProgressDone={onProgressDone}
                  disabled={!saveIsEnabledRef.current}
                />
                <Button tid="cancel" icon="cancel" onClick={onCancel} />
              </>
            ) : (
              <>
                <Button
                  tid="scope-edit"
                  icon="edit"
                  color="standard"
                  size="small"
                  onClick={_.partial(onEditClick, id)}
                  disabled={actionButtonsDisabled}
                />
                {!hideRemove && (
                  <Button
                    tid="scope-remove"
                    icon="delete"
                    progress={progress}
                    onClick={handleRemove}
                    onProgressDone={onProgressDone}
                    disabled={actionButtonsDisabled}
                  />
                )}
              </>
            )}
          </ButtonGroup>
        )}
      </div>
    </Card>
  );
}
