/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import apiSaga from 'api/apiSaga';
import {all, call, select, put, spawn} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getSelectorSettings} from './RulesetListConfig';
import {getRulesets, getRulesetsCount, getGridSettings} from './RulesetListState';
import {hrefUtils} from 'utils';
import {cachedResponses} from 'api/apiCache';
import gridSaga from 'components/Grid/GridSaga';
import {fetchValidScopeLabels} from 'containers/Selectors/SelectorSaga';
import {fetchPendingObjectList} from 'containers/Provisioning/Pending/List/PendingListSaga';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {isUserWithReducedScope} from 'containers/User/UserState';
import {RedirectError} from 'errors';
import {isEdge} from 'containers/App/AppState';

/**
 *
 * @param {{
 *  filter?: Record<string, string[]>;
 *  representation?: boolean;
 *  pversion?: import('illumio').PVersion;
 *  scope?: {scope: {key: string}[]};
 *  force?: boolean
 * }} options
 * @returns {import('typed-redux-saga').SagaGenerator<{list: unknown[]; count: {matched: number, total: number}}>}
 */
export function* fetchRulesets({
  filter,
  representation = 'rule_set_scopes',
  pversion = 'draft',
  scope,
  force = false,
} = {}) {
  const query = {max_results: 500, representation};
  const selectorSettingsObject = yield select(getSelectorSettings);

  if (scope) {
    const xxxlabels = scope.scope.filter(({key}) => key !== 'role').map(({href}) => href);

    query.xxxlabels = xxxlabels.length ? [xxxlabels] : undefined;
  }

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        if (selectorSettingsObject.staticValues.hasOwnProperty(name)) {
          query.update_type = selectorSettingsObject.staticValues[name][value];
        } else {
          query[name] = value;
        }
      }
    }
  }

  return yield call(apiSaga, 'rule_sets.get_collection', {
    query,
    params: {pversion},
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getRulesets) || count !== (yield select(getRulesetsCount)))) {
        yield put({type: 'RULESET_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removeRuleset({hrefs, pversion = 'draft'}) {
  yield call(apiSaga, 'rule_sets.delete', {
    params: {pversion},
    data: {rule_sets: hrefs.map(href => ({href}))},
    hrefs,
    *onDone() {
      yield spawn(fetchPendingObjectList, {force: true});

      cachedResponses.removeByMethodName('rule_sets.get_collection');
    },
  });
}

export function* updateRulesetStatus({hrefs, enabled, pversion = 'draft'}) {
  const success = [];
  const errors = new Map();

  yield all(
    hrefs.map(function* (href) {
      try {
        yield call(apiSaga, 'rule_set.update', {
          params: {rule_set_id: hrefUtils.getId(href), pversion},
          data: {enabled},
        });

        success.push(href);
      } catch (err) {
        const errData = _.get(err, 'data[0]');
        const message = (errData && errData.message) || err.message;
        const hrefs = errors.get(message) || [];

        hrefs.push(href);
        errors.set(message, hrefs);
      }
    }),
  );

  return {success, errors};
}

export function* fetchRulesetList(route, refetch = false) {
  const {params} = route;
  const userIsWithReducedScope = yield select(isUserWithReducedScope);
  const edgeEnabled = yield select(isEdge);
  const selectorSettingsObject = yield select(getSelectorSettings);

  // Users with limited scoped read are not allowed to access this component.
  if (userIsWithReducedScope || edgeEnabled) {
    throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: selectorSettingsObject.filterMap,
    *onSaga({filterParams}) {
      const scopeParams = yield select(getUrlScopeValue, params);
      const scope = scopeParams.isEmpty ? undefined : scopeParams.valid;

      yield call(fetchValidScopeLabels, scope);

      const [{list}] = yield all([
        call(fetchRulesets, {
          filter: filterParams.isEmpty ? undefined : filterParams.valid,
          scope,
          force: refetch,
          ...(__ANTMAN__ && params.pversion && {pversion: params.pversion}),
        }),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list.length;
    },
  });
}
