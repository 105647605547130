/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {cloneElement, createElement, Component, type ReactElement, type ComponentPropsWithoutRef} from 'react';
import {isReactElementOf, unwrapChildren} from 'utils/react';
import MenuItem, {type MenuItemProps} from './MenuItem';
import Delimiter from './MenuDelimiter';
import type {DropdownTransitionPlainStyle} from './motions';
import type {MouseEventLike} from 'utils/dom';
import type {Merge} from 'type-fest';
import _ from 'lodash';

export type MenuItemsProps = Merge<
  ComponentPropsWithoutRef<'ul'>,
  {
    children?: ReactElement | ReactElement[] | null | (() => ReactElement | ReactElement[] | null);
    theme: Record<string, string>;

    style: DropdownTransitionPlainStyle;
    active?: boolean;

    saveRef(items: MenuItems): void; // Pass item instance upwards
    onItemClick?(event?: MouseEventLike, item?: MenuItem): void;
    onItemFocus?: MenuItemProps['onFocus'];
    onItemMouse?: MenuItemProps['onMouse'];
  }
>;

export default class MenuItems extends Component<MenuItemsProps> {
  rect: DOMRect | null;
  items: MenuItem[];

  listElement: HTMLUListElement | null = null;

  constructor(props: MenuItemsProps) {
    super(props);

    this.rect = null;
    this.items = [];

    this.saveRef = this.saveRef.bind(this);
    this.saveItemRef = this.saveItemRef.bind(this);
    // memoize the function so that it doesn't trigger MenuItem rerender
    this.handleItemClick = _.memoize(this.handleItemClick).bind(this);
  }

  private saveRef(element: HTMLUListElement | null) {
    this.listElement = element;
    this.calcRect();
    this.props.saveRef(this);
  }

  private saveItemRef(item: MenuItem, add: boolean) {
    if (add) {
      this.items.push(item);
    } else {
      const itemIndex = this.items.indexOf(item);

      if (itemIndex > -1) {
        this.items.splice(itemIndex, 1);
      }
    }
  }

  private handleItemClick(onOriginClick: MenuItemProps['onClick']) {
    return (evt: MouseEventLike, item: MenuItem) => {
      if (!item.props.notSelectable) {
        if (onOriginClick) {
          onOriginClick(evt, item);
        }

        this.props.onItemClick?.(evt, item);
      }
    };
  }

  calcRect(): DOMRect | null {
    let rect = null;

    if (this.listElement) {
      rect = this.listElement.getBoundingClientRect();

      // Make sure element is not hidden (display: none)
      if (!rect.width && !rect.height) {
        rect = null;
      }
    }

    this.rect = rect;

    return this.rect;
  }

  render() {
    const {active, theme, style, children, onItemFocus, onItemMouse, onItemClick, saveRef, ...ulProps} = this.props;

    const props = {
      ...ulProps,
      ref: this.saveRef,
      // FIXME: RTL doesn't assign name based on 'aria-label' to 'menu' roles, but 'menu' is the idea role here
      // role: 'menu',
      className: cx(theme.itemsList, {
        [theme.itemsListActive]: active,
      }),
      style: {
        opacity: style.opacity,
        transform: `translate3d(${style.x || 0}px, ${style.y || 0}px, 0)`,
      },
    };
    const items = unwrapChildren(children).map(item => {
      if (isReactElementOf(item, MenuItem)) {
        // Item constructor has a second parameter context, making it not assignable to JSXElementConstructor
        // Item becomes not selectable if there is no corresponding handler, no link and no subitems.
        const notSelectable =
          typeof item.props.notSelectable === 'boolean'
            ? item.props.notSelectable
            : !item.props.link && !item.props.onSelect && !item.props.onClick && !item.props.children;

        return cloneElement(item, {
          ...mixThemeWithProps(theme, item.props),
          themePrefix: undefined,
          themeCompose: undefined,
          themeNoCache: undefined,
          themeNoParseComposes: undefined,
          tabIndex: -1, // To make each item focusable
          notSelectable,
          onRef: this.saveItemRef,
          onClick: this.handleItemClick(item.props.onClick),
          onFocus: onItemFocus,
          onOriginFocus: item.props.onFocus,
          onMouse: onItemMouse,
        });
      }

      if (isReactElementOf(item, Delimiter)) {
        return cloneElement(item, {
          ...mixThemeWithProps(theme, item.props),
          // cloneElement will preserve theme* props, since mixThemeWithProps doesn't return them, so reset them manually
          themePrefix: undefined,
          themeCompose: undefined,
          themeNoCache: undefined,
          themeNoParseComposes: undefined,
        });
      }

      if (__DEV__) {
        console.error("Prop 'children' supplied to 'MenuItems' can contain MenuItem, MenuDelimiter or null");
      }

      return item;
    });

    return createElement('ul', props, ...items);
  }
}
