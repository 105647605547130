/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import {useDeepCompareMemo} from 'utils/react';
import * as IPListEditorUtils from './IPListEditorUtils';
import {setStartEditorStateOffset} from '../../DraftJSUtils';
import ListEditor from '../ListEditor/ListEditor';
import IpPlugin from '../../Plugins/IP/IP';
import styles from './IPListEditor.css';
import {ipUtils} from 'utils';
import _ from 'lodash';

IPListEditor.propTypes = {
  initialValue: PropTypes.array, // initial ip initialValue
  plugins: PropTypes.array, // DraftJS plugins
  maxAllowedIP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Max Allowed IP
  tooltipProps: PropTypes.object, // Tooltips
  decoratorComponents: PropTypes.array, // DraftJS components from plugins
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disableErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string, // Placeholder for default text
  showModified: PropTypes.bool, // Indicate if we need to highlight changed rows with blue dot, for example on a page in edit mode
  disableValidation: PropTypes.bool, // Flag to determine when to validate IPs
  isInterface: PropTypes.bool, // Flag that the ips are interface ips to bypass CIDR non-interface check
  ...ipUtils.parseAddressChecksPropTypes(),
};

// Assign utils as static prop to be able to easily use it, like `IPListEditor.Utils.getIpMap`
IPListEditor.Utils = IPListEditorUtils;

function IPListEditor(props = {}) {
  const {
    maxAllowedIP = '',
    errorMessage = '',
    showModified = false,
    initialValue = [],
    onChange,
    disableValidation = false,
    onFocus,
    isInterface,
    ...iplistProps
  } = props;

  const parseAddressChecks = _.pick(iplistProps, Object.keys(ipUtils.parseAddressChecks));

  // Use useDeepCompareMemo to compare deep nested value to avoid calling useMemo() again when reference changes but data is the same.
  // e.g. {instance: true} is a new object reference each time thus a re-render and invoking useMemo()
  //      <IPListEditor tooltipProps={{instant: true}} />
  const tooltipPropsMemo = useDeepCompareMemo(props.tooltipProps ?? {});
  const pluginsMemo = useDeepCompareMemo(props.plugins ?? []);
  const decoratorComponentsMemo = useDeepCompareMemo(props.decoratorComponents ?? []);
  const parseAddressChecksMemo = useDeepCompareMemo(parseAddressChecks ?? []);

  const prevDisabledValidation = useRef(disableValidation);
  const initialDisableValidationRef = useRef(disableValidation);

  const IPListPlugins = useMemo(() => {
    // Current default plugins
    const IPListPlugins = IpPlugin({
      maxAllowedIP,
      showModified,
      tooltipPropsMemo,
      disableValidation: initialDisableValidationRef.current,
      ...parseAddressChecksMemo,
    });
    const {DecoratedErrorCounter} = IPListPlugins;

    return {
      plugins: [IPListPlugins, ...pluginsMemo],
      decoratorComponents: [DecoratedErrorCounter, ...decoratorComponentsMemo],
    };
  }, [maxAllowedIP, pluginsMemo, decoratorComponentsMemo, tooltipPropsMemo, parseAddressChecksMemo, showModified]);

  const editorStateInitialMount = useRef(null);
  const [editorState, setEditorState] = useState(() => IPListEditorUtils.createEditorState(initialValue));
  const placeholder = props.placeholder;
  const listEditorRef = useRef(null);

  const disableErrorMessage = props.disableErrorMessage ?? disableValidation;
  const borderMainError = !disableErrorMessage && (errorMessage === undefined || typeof errorMessage === 'string');

  const className = cx(styles.borderMain, {
    [styles.borderMainError]: borderMainError,
  });

  const handleOnChange = useCallback(
    (editorState, forceParse = false) => {
      const newEditorState = IPListEditorUtils.getUpdatedContentValidationState({
        editorState,
        parseAddressChecks: parseAddressChecksMemo,
        disableValidation,
        forceParse,
        isInterface,
      });

      // Save this reference during first mount from <EditorState/> which is needed to
      // set the position for touch logic
      if (!editorStateInitialMount.current) {
        editorStateInitialMount.current = newEditorState;
      }

      // Update the editorState
      setEditorState(newEditorState);

      onChange?.(newEditorState);
    },
    [onChange, parseAddressChecksMemo, disableValidation, isInterface],
  );

  const handleFocus = useCallback(
    evt => {
      listEditorRef.current?.setFocus();

      onFocus?.(evt);
    },
    [listEditorRef, onFocus],
  );

  // Run this only once during initial mount
  useEffect(() => {
    // Create a new editor state for touch logic for forcing position
    const newEditorState = setStartEditorStateOffset(editorStateInitialMount.current);

    setEditorState(newEditorState);
  }, []);

  /** Use to toggle when User clicks disableValidation */
  useEffect(() => {
    if (prevDisabledValidation.current !== disableValidation) {
      handleOnChange(editorState, true);
      prevDisabledValidation.current = disableValidation;
    }
  }, [disableValidation, handleOnChange, editorState]);

  return (
    <div className={className} tabIndex="0" onFocus={handleFocus} onBlur={props.handleBlur}>
      <ListEditor
        name="editorState"
        placeholder={placeholder}
        plugins={IPListPlugins.plugins}
        value={editorState}
        ref={listEditorRef}
        onChange={handleOnChange}
      />
      {IPListPlugins.decoratorComponents.map((Component, index) => (
        <Component key={index} />
      ))}
    </div>
  );
}

export default IPListEditor;
