/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {HeaderProps} from 'containers';
import {TabPanel} from 'components';
import {getLoadBalancerItemPage} from './LoadBalancerItemState';
import {fetchLoadBalancerItem} from 'containers/LoadBalancer/LoadBalancerSaga';

@connect(getLoadBalancerItemPage)
export default class LoadBalancerItem extends PureComponent {
  static prefetch = fetchLoadBalancerItem;

  render() {
    const {currentRouteParams, loadBalancer} = this.props;

    return (
      <>
        <HeaderProps
          title={intl('VirtualServers.Detail.ServerLoadBalancers')}
          subtitle={loadBalancer.name}
          up="loadBalancers"
        />
        <TabPanel color="primary">
          {[
            {
              link: 'loadBalancers.item.view',
              text: intl('Common.Summary'),
              params: {id: currentRouteParams.id},
              tid: 'slb-summary',
            },
            {
              link: 'loadBalancers.item.servers',
              text: intl('Common.VirtualServers'),
              params: {id: currentRouteParams.id},
              tid: 'slb-virtualservers',
            },
          ]}
        </TabPanel>
        {this.props.children}
      </>
    );
  }
}
