/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import ScopeWidget from './ScopeWidget';
import {newScopeId, getScopeKey} from './RulesetScopeUtils';

export default function RulesetScope(props) {
  const {scopeInEdit, rulesetId, onEditClick, onCancel, onSetScopeInEdit, ...restProps} = props;

  const hasAddWidget = scopeInEdit === newScopeId;
  const scopes = hasAddWidget && props.scopes?.[0].length > 0 ? [...props.scopes, []] : props.scopes;

  return (
    <>
      {scopes.map((scope, index, {length}) => {
        const id = hasAddWidget && index === length - 1 ? newScopeId : getScopeKey(scope);

        return (
          <ScopeWidget
            key={id}
            id={id}
            isInEdit={scopeInEdit === id}
            scope={scope}
            onEditClick={onEditClick}
            onCancel={onCancel}
            {...restProps}
          />
        );
      })}
    </>
  );
}
