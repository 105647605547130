/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {useDeepCompareMemo} from 'utils/react';
import {useCallback, useMemo} from 'react';
import Selector from './Selector';
import {ADD_NEW_LABEL_ID} from './Presets';

const defaultSelectorProps = {
  noActiveIndicator: true,
  noCombinedCategory: true,
  alwaysShowPlaceholder: true,
  placeholder: intl('Common.SelectLabelsLabelGroups'),
};

LabelSelector.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  group: PropTypes.bool,
  excludeKeys: PropTypes.array,
  dontAllowCreate: PropTypes.bool, // disable allowCreate based on user role
  allowMultipleSelection: PropTypes.bool,
};

export default function LabelSelector({
  value: labels,
  onChange,
  group,
  dontAllowCreate,
  excludeKeys: excludeKeysProp,
  allowMultipleSelection = false,
  ...selectorProps
}) {
  const excludeKeys = useDeepCompareMemo(excludeKeysProp);

  const categories = useMemo(() => {
    const apiArgs = {query: {exclude_keys: JSON.stringify(excludeKeys)}};

    return [
      _.merge(_.cloneDeep(Selector.categoryPresets.labelsAndLabelGroups), {
        resources: {
          labelsAndLabelGroups: {
            apiArgs,
            ...(dontAllowCreate
              ? {allowCreateOptions: false}
              : group
              ? {}
              : {
                  allowCreateOptions: (query, exactMatches) => {
                    const showLabelCreate = !exactMatches.some(({href}) => href.includes('labels'));

                    return showLabelCreate
                      ? [{id: ADD_NEW_LABEL_ID, value: `${query} (${intl('Labels.New')})`, isCreate: true}]
                      : [];
                  },
                }),
            optionProps: {
              allowMultipleSelection,
              filterOption: option =>
                !option.href.includes('all') &&
                (group || !option.href?.includes('label_groups')) &&
                !option.href.includes('exists'),
            },
          },
          labelForm: {
            containerProps: {excludeKeys}, // Pass excludeKeys to Form to hide these types in option selector,
          },
          labelGroupForm: {
            hidden: !group,
            containerProps: {excludeKeys},
          },
        },
      }),
    ];
  }, [excludeKeys, dontAllowCreate, group, allowMultipleSelection]);

  const handleSelectionChange = useCallback(
    valuesMap => {
      const labels = valuesMap.get(Selector.categoryPresets.labelsAndLabelGroups.id) ?? [];

      onChange?.(labels);
    },
    [onChange],
  );

  const selectorValues = new Map([['labelsAndLabelGroups', labels]]);

  Object.assign(selectorProps, {
    values: selectorValues,
    onSelectionChange: handleSelectionChange,
    categories,
  });

  return <Selector {...defaultSelectorProps} {...selectorProps} />;
}
