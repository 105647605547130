/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {getId} from 'utils/href';
import {typesName, prefixMap, typePrefixRegexp} from 'components/Pill/Label/LabelUtils';
import {sortOptions, getQueryAndKeyword} from 'containers/Selector/SelectorUtils';
import stylesUtils from 'utils.css';

export const newScopeId = 'NEW_SCOPE_ID';
export const getEmptyScopeText = (excludeKeys, isCreatedByRuleBuilder) => {
  if (isCreatedByRuleBuilder) {
    return intl('Rulesets.Scopes.ScopesCreatedByRuleBuilder');
  }

  const availableLabels = _.difference(['app', 'env', 'loc'], excludeKeys);

  return availableLabels.length === 0
    ? intl('Rulesets.Scopes.ScopesCannotBeAdded')
    : intl(
        'Rulesets.Scopes.EmptyScopeSubtitle',
        {
          labelNames: intl.list(
            availableLabels.map(type => typesName[type]),
            {style: 'narrow'},
          ),
          className: stylesUtils.bold,
        },
        {html: true},
      );
};

export const getScopeSubtitle = (value, excludeKeys, matched) => {
  if (value[0]?.length > 0) {
    return intl(
      'Rulesets.Scopes.ScopesCount',
      {
        matched: Boolean(matched),
        count: matched ? matched.length : value.length,
        labelNames: intl.list(
          value[0].map(({label, label_group}) => typesName[label?.key ?? label_group.key]),
          {style: 'narrow'},
        ),
        className: stylesUtils.bold,
      },
      {html: true},
    );
  }

  return getEmptyScopeText(excludeKeys);
};

export const getScopeKey = scope =>
  scope
    .map(({label, label_group, exclusion}) => {
      const id = getId(label?.href ?? label_group.href);

      return exclusion ? `${id}_exclusion` : id;
    })
    .sort()
    .join('.');

export const isScopeModified = (newScope, oldScope) =>
  newScope.length !== oldScope.length || getScopeKey(newScope) !== getScopeKey(oldScope);

export const getScopeFilterCategories = scopes => [
  {
    id: 'scope',
    resources: {
      scope: {
        statics: query => {
          const {keyword, query: value} = getQueryAndKeyword(query, typePrefixRegexp);
          const key = prefixMap[keyword?.trim().toLowerCase()];
          // Scopes: [[{label: {}}, {label_group: {}}, {label: {}}], [], [],...],
          // We need to flatten and dedupe this two-dimensional array
          // Set label/labelgroup object by unique href in a Map data structure
          // and then iterate over its values which is an array of label/labelgroup objects
          const options = [
            ...new Map(scopes.flat().map(scope => [scope.label?.href ?? scope.label_group.href, scope])).values(),
          ].reduce((result, {label, label_group}) => {
            const labelObj = label ?? label_group;

            if (key && labelObj.key !== key) {
              return result;
            }

            if (value) {
              if ((labelObj.value ?? labelObj.name).toLowerCase().includes(value.trim().toLowerCase())) {
                result.push({...labelObj, value: labelObj.value ?? labelObj.name});
              }

              return result;
            }

            result.push(labelObj);

            return result;
          }, []);

          return sortOptions(options, value);
        },
        optionProps: {
          isPill: true,
          pillProps: label => ({
            icon: label.key,
            group: label.href.includes('label_groups'),
          }),
          allowMultipleSelection: true,
          tooltipProps: option => ({content: typesName[option.key]}),
        },
        selectedProps: {
          hideResourceName: true,
          valueJoiner: 'or',
          joinerIsPill: false,
          pillPropsValue: label => ({
            icon: label.key,
            group: label.href.includes('label_groups'),
          }),
        },
      },
    },
  },
];

export const getScopeDiff = (value, oldValue) => {
  if (!oldValue) {
    //No diff
    return {added: [], unchanged: value, removed: []};
  }

  const valueMap = value.reduce((map, scope) => map.set(getScopeKey(scope), scope), new Map());

  const {unchanged, removed} = oldValue.reduce(
    (acc, scope) => {
      const scopeKey = getScopeKey(scope);
      const scopeValue = valueMap.get(scopeKey);

      if (scopeValue) {
        valueMap.delete(scopeKey);
        acc.unchanged.push(scope);
      } else {
        acc.removed.push(scope);
      }

      return acc;
    },
    {unchanged: [], removed: []},
  );

  const added = Array.from(valueMap.values());

  return {added, unchanged, removed};
};

export const getDuplicateScopeMessage = scope =>
  intl('Rulesets.Scopes.DuplicateScopeText', {
    labelNames: intl.list(scope.map(({label, label_group}) => typesName[label?.key ?? label_group.key])),
  });

export const filterScopes = (scopes, scopesFilterMap) => {
  const filterLabels = scopesFilterMap.get('scope') ?? [];

  return filterLabels.length
    ? scopes.filter(scope =>
        scope.some(({label, label_group}) => filterLabels.some(({href}) => href === (label?.href ?? label_group.href))),
      )
    : scopes;
};
