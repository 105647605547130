/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {
  getRouteParams,
  reverseProviderConsumer,
  getAdvancedRulesetDisplay,
  getAppGroupLabelTypes,
  getRoutePreviousParams,
} from 'containers/App/AppState';
import {getAllUsersMap, getUserSelectorHistory, isUserScoped} from 'containers/User/UserState';
import {isScopeRequired} from '../List/RulesetListState';
import {rulesGridSettings, ipTablesGridSettings} from './Configs/RulesetItemConfig';
import {fillPolicyObjUserInfo, getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {getId} from 'utils/href';
import {
  getRulesetSummaryObj,
  newRuleHref,
  populateRule,
  getPolicyGeneratorId,
  areActionButtonsDisabled,
} from './RulesetItemUtils';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_ITEM':
        return action.data;
      default:
        return state;
    }
  },
  pceUpgradeRuleset(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_PCE_UPGRADE_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
  ruleEditor(state = {}, action) {
    switch (action.type) {
      case 'RULESET_RULE_EDITOR':
        return action.data;
      default:
        return state;
    }
  },
  noteEditor(state = {}, action) {
    switch (action.type) {
      case 'RULESET_NOTE_EDITOR':
        return action.data;
      default:
        return state;
    }
  },
};

export const getRulesetDetail = state => state.ruleset.detail;
export const getPCEUpgradeRuleset = state => state.ruleset.pceUpgradeRuleset;

const getRuleEditor = state => state.ruleset.ruleEditor;
const getNoteEditor = state => state.ruleset.noteEditor;

const getIntraScopeEditRow = createSelector([getRouteParams, getRuleEditor], ({tab, pversion}, ruleEditorData) =>
  pversion === 'draft' && (__ANTMAN__ ? ruleEditorData?.type === 'allow' : tab === 'intrascope')
    ? ruleEditorData
    : null,
);

const getExtraScopeEditRow = createSelector([getRouteParams, getRuleEditor], ({tab, pversion}, ruleEditorData) =>
  pversion === 'draft' &&
  (__ANTMAN__ ? ruleEditorData?.type === 'deny' || ruleEditorData?.type === 'overrideDeny' : tab === 'extrascope')
    ? ruleEditorData
    : null,
);

export const getIpTablesEditRow = createSelector([getRouteParams, getRuleEditor], ({tab, pversion}, ruleEditorData) =>
  pversion === 'draft' && (__ANTMAN__ || tab === 'iptables') ? ruleEditorData : null,
);

export const getRulesetVersions = createSelector([getRulesetDetail, getRouteParams], (rulesetDetail, {pversion}) =>
  getPolicyVersions(rulesetDetail, pversion),
);

const getIntraScopeRulesRows = createSelector(
  [getRulesetVersions, getIntraScopeEditRow, getNoteEditor, getRouteParams],
  ({versions, isOldVersion}, ruleInEdit, noteEditorData, {pversion, tab}) => {
    const {pversionObj, prevPversionObj} = versions;
    let ruleNumber = 0;

    const rulesRows = (
      ruleInEdit?.href === newRuleHref ? [ruleInEdit, ...(pversionObj?.rules ?? [])] : pversionObj?.rules ?? []
    ).reduce((result, rule) => {
      if (pversionObj.scopes[0]?.length > 0 && rule.unscoped_consumers) {
        // In scoped ruleset skip extra scope rules
        return result;
      }

      result.push(
        populateRule({
          rule,
          ruleNumber,
          versions,
          pversion,
          tab,
          ruleInEdit,
          noteEditorData,
          ...(__ANTMAN__ && {type: 'allow'}),
        }),
      );

      ruleNumber++;

      return result;
    }, []);

    if (isOldVersion && prevPversionObj) {
      const deletedRules = prevPversionObj.rules.filter(
        ({href}) => !pversionObj.rules.some(rule => getId(rule.href) === getId(href)),
      );

      if (deletedRules.length > 0) {
        rulesRows.push(
          ...deletedRules.map(rule =>
            populateRule({
              rule: {...rule, update_type: 'delete'},
              ruleNumber: ruleNumber++,
              versions,
              pversion,
              tab,
              ruleInEdit,
              noteEditorData,
            }),
          ),
        );
      }
    }

    return rulesRows;
  },
);

const getExtraScopeRulesRows = createSelector(
  [getRulesetVersions, getExtraScopeEditRow, getNoteEditor, getRouteParams],
  ({versions, isOldVersion}, ruleInEdit, noteEditorData, {pversion, tab}) => {
    const {pversionObj, prevPversionObj} = versions;
    let ruleNumber = 0;
    let overrideRuleNumber = 0;

    const rules = (__ANTMAN__ ? pversionObj?.deny_rules : pversionObj?.rules) ?? [];

    const rulesRows = (ruleInEdit?.href === newRuleHref ? [ruleInEdit, ...rules] : rules).reduce(
      (result, rule) => {
        if (__ANTMAN__ || rule.unscoped_consumers || rule.href === newRuleHref) {
          if (__ANTMAN__ && (rule.override === true || rule.type === 'overrideDeny')) {
            result.overrideDenyRulesRows.push(
              populateRule({
                rule,
                ruleNumber: overrideRuleNumber,
                versions,
                pversion,
                tab,
                ruleInEdit,
                noteEditorData,
                ...(__ANTMAN__ && {type: 'overrideDeny'}),
              }),
            );
            overrideRuleNumber++;
          } else {
            result.extraScopeRulesRows.push(
              populateRule({
                rule,
                ruleNumber,
                versions,
                pversion,
                tab,
                ruleInEdit,
                noteEditorData,
                ...(__ANTMAN__ && {type: 'deny'}),
              }),
            );
            ruleNumber++;
          }
        }

        return result;
      },
      {extraScopeRulesRows: [], overrideDenyRulesRows: []},
    );

    if (isOldVersion && prevPversionObj) {
      const deletedRules = prevPversionObj.rules.filter(
        rule => rule.unscoped_consumers && !pversionObj.rules.some(({href}) => getId(rule.href) === getId(href)),
      );

      if (deletedRules.length > 0) {
        deletedRules.forEach(rule => {
          const populatedRule = populateRule({
            rule: {...rule, update_type: 'delete'},
            versions,
            pversion,
            tab,
            ruleInEdit,
            noteEditorData,
          });

          if (rule.override) {
            rulesRows.overrideDenyRulesRows.push(populatedRule);
          } else {
            rulesRows.extraScopeRulesRows.push(populatedRule);
          }
        });
      }
    }

    return rulesRows;
  },
);

const getIpTablesRulesRows = createSelector(
  [getRulesetVersions, getIpTablesEditRow, getNoteEditor, getRouteParams],
  ({versions, isOldVersion}, ruleInEdit, noteEditorData, {pversion, tab}) => {
    const {pversionObj, prevPversionObj} = versions;
    const rulesRows = (
      ruleInEdit?.href === newRuleHref
        ? [ruleInEdit, ...(pversionObj?.ip_tables_rules ?? [])]
        : pversionObj?.ip_tables_rules ?? []
    ).map(rule => populateRule({rule, versions, pversion, tab, ruleInEdit, noteEditorData}));

    if (isOldVersion && prevPversionObj) {
      const deletedRules = prevPversionObj.ip_tables_rules.filter(
        rule => !pversionObj.rules.some(({href}) => getId(rule.href) === getId(href)),
      );

      if (deletedRules.length > 0) {
        rulesRows.push(
          ...deletedRules.map(rule =>
            populateRule({
              rule: {...rule, update_type: 'delete'},
              versions,
              pversion,
              tab,
              ruleInEdit,
              noteEditorData,
            }),
          ),
        );
      }
    }

    return rulesRows;
  },
);

export const getRulesGridSettingss = createSelector([rulesGridSettings], gridSettings => {
  const columns = {...gridSettings.columns};

  if (!__ANTMAN__) {
    columns.consumingServices.disabled = true;
  }

  return {...gridSettings, columns};
});

export const getRulesetItem = createSelector(
  [
    getIntraScopeRulesRows,
    getExtraScopeRulesRows,
    getIpTablesRulesRows,
    getRulesGridSettingss,
    ipTablesGridSettings,
    getRulesetVersions,
    getRouteParams,
    getAllUsersMap,
    reverseProviderConsumer,
    getAdvancedRulesetDisplay,
    getRuleEditor,
    isUserScoped,
    isScopeRequired,
    getAppGroupLabelTypes,
    getRoutePreviousParams,
    getUserSelectorHistory,
  ],
  (
    intraScopeRulesRows,
    {extraScopeRulesRows, overrideDenyRulesRows},
    ipTablesRulesRows,
    rulesGridSettings,
    ipTablesGridSettings,
    {versions, pversionObjIsDeleted, isOldVersion},
    {pversion, tab},
    usersMap,
    isReverseProviderConsumer,
    advancedRulesetDisplay,
    ruleEditorProp,
    userIsScoped,
    scopeIsRequired,
    appGroupsType,
    prevRouteParams,
    selectorHistory,
  ) => {
    const provisionIsDisabled = !versions.draft?.caps.includes('provision');
    const rulesetId = getId(versions.pversionObj?.href);

    const versionsWithUserInfo = fillPolicyObjUserInfo(versions, usersMap);

    const policyGeneratorId = getPolicyGeneratorId(versions.pversionObj?.scopes[0], appGroupsType);
    const actionButtonsDisabled = areActionButtonsDisabled(versions);
    const {recents} = selectorHistory;

    return {
      intraScopeRulesRows,
      extraScopeRulesRows,
      ...(__ANTMAN__ && {overrideDenyRulesRows}),
      ipTablesRulesRows,
      rulesGridSettings,
      ipTablesGridSettings,
      versions: versionsWithUserInfo,
      pversionObjIsDeleted,
      pversion,
      tab,
      provisionIsDisabled,
      rulesetId,
      isOldVersion,
      isReverseProviderConsumer,
      summaryObj: getRulesetSummaryObj(versionsWithUserInfo),
      isAdditionPending: versions.draft?.update_type === 'create',
      advancedRulesetDisplay,
      ruleEditorProp,
      userIsScoped,
      scopeIsRequired,
      policyGeneratorId,
      prevRouteParams,
      actionButtonsDisabled,
      recents,
    };
  },
);
