/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {selectedGridSettings, dependencyGridSettings} from './ProvisionConfig';
import {getSecPolicyRows, getOutboundPolicyRow} from 'containers/Provisioning/Pending/List/PendingListState';
import {hasListenOnlyMember} from 'containers/Health/HealthState';
import {requireProvisionNote, isEdge, getRouteName} from 'containers/App/AppState';
import {getOutboundAllowRulesetId} from 'edge/containers/OutboundPolicy/OutboundPolicyState';
import {hrefUtils} from 'utils';
import {
  getProvisionCounts,
  formatCountsForTally,
  getProvisionCountsTotal,
  outboundOrgPolicyObjType,
} from '../ProvisioningUtils';

export default {
  // List of selected objects for provision/revert by user
  selection(state = {}, action) {
    switch (action.type) {
      case 'PROVISION_SELECTION':
        return action.data.selection;
      default:
        return state;
    }
  },
  // Sec policies dependencies list with query params (check saga)
  dependencies(state = [], action) {
    // Don't set if current and new are both empty
    if (action.type === 'PROVISION_GET_DEPENDENCIES' && (action.data.length || state.length)) {
      return action.data;
    }

    return state;
  },
  // Sec policies create result
  provisionCompleted(state = {}, action) {
    switch (action.type) {
      case 'PROVISION_COMPLETED':
        return action.data;
      default:
        return state;
    }
  },
};

export const getSelection = state => state.provisioning.selection;
export const getDependencies = state => state.provisioning.dependencies;
export const getProvisionCompleted = state => state.provisioning.provisionCompleted;

export const getListForGrid = createSelector(
  [getSelection, getDependencies, getSecPolicyRows, isEdge, getOutboundAllowRulesetId, getOutboundPolicyRow],
  (selection, dependencies, secPolicies, isEdge, outboundAllowRulesetId, outboundPolicyRow) => {
    let selectedList = [];
    let dependencyList = [];
    const changeSubset = selection.change_subset;

    if (!_.isEmpty(changeSubset)) {
      if (isEdge) {
        const hasOutboundPolicyChangeSet =
          Boolean(changeSubset.enforcement_boundaries) ||
          changeSubset.rule_sets?.find(({href}) => hrefUtils.getId(href) === outboundAllowRulesetId);

        if (hasOutboundPolicyChangeSet) {
          const secPolicy = secPolicies.find(secPolicy => secPolicy.data.type === outboundOrgPolicyObjType);

          if (secPolicy) {
            secPolicy.dependencies = [];

            selectedList.push(secPolicy);
          }
        }
      }

      Object.entries(changeSubset).forEach(([objType, items]) => {
        for (const item of items) {
          if (objType === 'rule_sets' && hrefUtils.getId(item.href) === outboundAllowRulesetId) {
            continue;
          }

          const secPolicy = secPolicies.find(secPolicy => secPolicy.key === item.href);

          if (secPolicy) {
            secPolicy.dependencies = [];

            selectedList.push(secPolicy);
          }
        }
      });

      //Each object in dependencies array (a row in dependency grid) contains the list of objects that requires it
      dependencies.forEach(obj => {
        const objType = Object.keys(obj.dependency)[0]; //type of object is the first prop of dependency object
        const alreadySelected = selectedList.findIndex(o => o.key === obj.dependency[objType].href);

        if (alreadySelected !== -1) {
          return;
        }

        const dependencySecPolicy = secPolicies.find(secPolicy => secPolicy.key === obj.dependency[objType].href);

        if (dependencySecPolicy) {
          dependencySecPolicy.requiredBy = [];

          if (isEdge && dependencySecPolicy.key?.includes('enforcement_boundaries')) {
            dependencySecPolicy.data = outboundPolicyRow.data;
          }

          if (obj.required_by) {
            Object.keys(obj.required_by).forEach(objType => {
              if (isEdge && objType === 'enforcement_boundaries') {
                // All enforcement boundaries rules dependencies should point to outbound policy as requiredBy
                // No need to loop for each enforcement boundaries rules to determine the associated requiredBy objects
                // as these are rolled up to a single outbound policy row
                const selectedListObjIndex = selectedList.findIndex(
                  selectedObj => selectedObj.data.type === outboundOrgPolicyObjType,
                );

                if (selectedListObjIndex !== -1) {
                  selectedList[selectedListObjIndex].dependencies.push(dependencySecPolicy.key);

                  const requiredByObj = selectedList[selectedListObjIndex];

                  dependencySecPolicy.requiredBy.push(requiredByObj);
                }

                return;
              }

              return obj.required_by[objType].forEach(item => {
                const selectedListObjIndex = selectedList.findIndex(o => o.key === item.href);

                if (selectedListObjIndex !== -1) {
                  selectedList[selectedListObjIndex].dependencies.push(dependencySecPolicy.key);
                }

                const requiredByObj = secPolicies.find(secPolicy => secPolicy.key === item.href);

                if (!dependencySecPolicy.requiredBy.some(({key}) => key === requiredByObj.key)) {
                  dependencySecPolicy.requiredBy.push(requiredByObj);
                }
              });
            });
          }

          //Ensure all requiredBy fields into single outbound requiredBy property so duplicate outbound rows are not created
          if (isEdge) {
            if (
              dependencySecPolicy.data.type !== 'outbound' ||
              (dependencySecPolicy.data.type === 'outbound' &&
                !dependencyList.some(item => item.data.type === 'outbound'))
            ) {
              dependencyList.push(dependencySecPolicy);
            } else {
              dependencyList = dependencyList.reduce((result, item) => {
                if (
                  item.data.type === 'outbound' &&
                  !item.requiredBy.some(
                    item => hrefUtils.getId(item.key) === hrefUtils.getId(dependencySecPolicy.requiredBy[0].key),
                  )
                ) {
                  item.requiredBy.push(dependencySecPolicy.requiredBy[0]);
                }

                result.push(item);

                return result;
              }, []);
            }
          } else {
            dependencyList.push(dependencySecPolicy);
          }
        }
      });
    }

    if (isEdge) {
      //Ensure single outbound row is pushed into provision grid.
      selectedList = selectedList.reduce((result, item) => {
        if (
          item.data.type !== 'outbound' ||
          (item.data.type === 'outbound' && !result.some(item => item.data.type === 'outbound'))
        ) {
          result.push(item);
        }

        return result;
      }, []);
    }

    return {selectedList, dependencyList};
  },
);

const getSelectedGridRows = createSelector(getListForGrid, list => list.selectedList);
const getDependencyGridRows = createSelector(getListForGrid, list => list.dependencyList);

const getSelectedGrid = state =>
  getGridSelector(state, {
    settings: selectedGridSettings,
    rows: getSelectedGridRows,
  });

const getDependencyGrid = state =>
  getGridSelector(state, {
    settings: dependencyGridSettings,
    rows: getDependencyGridRows,
  });

export const getProvisionPage = createSelector(
  [
    getSelectedGrid,
    getDependencyGrid,
    isUserReadOnly,
    hasListenOnlyMember,
    requireProvisionNote,
    isEdge,
    getOutboundAllowRulesetId,
  ],
  (
    selectedGrid,
    dependencyGrid,
    userIsReadOnly,
    hasListenOnlyMember,
    requireProvisionNote,
    edgeEnabled,
    outboundAllowRulesetId,
  ) => {
    const counts = getProvisionCounts([...selectedGrid.rows, ...dependencyGrid.rows], outboundAllowRulesetId);
    const tallyItems = [
      {children: 'Total : ', count: getProvisionCountsTotal(counts)},
      ...formatCountsForTally(counts),
    ];

    return {
      selectedGrid,
      dependencyGrid,
      tallyItems,
      userIsReadOnly,
      hasListenOnlyMember,
      requireProvisionNote,
      edgeEnabled,
    };
  },
);

export const getProvisionPopupContent = createSelector(
  [
    getSelectedGridRows,
    getDependencyGridRows,
    isUserReadOnly,
    hasListenOnlyMember,
    requireProvisionNote,
    isEdge,
    selectedGridSettings,
    dependencyGridSettings,
    getOutboundAllowRulesetId,
    getRouteName,
  ],
  (
    selectedGridRows,
    dependencyGridRows,
    userIsReadOnly,
    hasListenOnlyMember,
    requireProvisionNote,
    edgeEnabled,
    selectedGridSettings,
    dependencyGridSettings,
    outboundAllowRulesetId,
    routeName,
  ) => {
    const counts = getProvisionCounts([...selectedGridRows, ...dependencyGridRows], outboundAllowRulesetId);
    const tallyItems = [
      {children: 'Total : ', count: getProvisionCountsTotal(counts)},
      ...formatCountsForTally(counts),
    ];

    // Remove any enforcement boundary rows(undefined typeLabel rows) to be provisioned from grid list.
    // Enf boundaries were added as part of secPolicyRows to show modal
    // with revert error modal when a deny rule is associated specific policy versions like service/iprange
    // in service edit and iprange edit pages in EDGE.
    // Refer: https://jira.illum.io/browse/EYE-79660

    if (edgeEnabled) {
      if (dependencyGridRows.length) {
        dependencyGridRows = dependencyGridRows.filter(
          row => row.data.typeLabel && row.data.typeLabel !== 'enforcement_boundaries',
        );
      }

      if (selectedGridRows.length) {
        selectedGridRows = selectedGridRows.filter(
          row => row.data.typeLabel && row.data.typeLabel !== 'enforcement_boundaries',
        );
      }
    }

    return {
      selectedGridSettings,
      dependencyGridSettings,
      selectedGridRows,
      dependencyGridRows,
      tallyItems,
      userIsReadOnly,
      hasListenOnlyMember,
      requireProvisionNote,
      edgeEnabled,
      routeName,
    };
  },
);
