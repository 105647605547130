/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {RedirectError} from 'errors/index';
import {call, fork, select, take} from 'redux-saga/effects';
import {fetchLabels} from 'containers/Label/List/LabelListSaga';
import {fetchAllPermissions} from 'containers/RBAC/PermissionSaga';
import {getAuthSecPrincipalByUserName} from 'containers/RBAC/AuthSecPrincipalState';
import {fetchAllAuthSecPrincipals} from 'containers/RBAC/AuthSecPrincipalSaga';
import {fetchUser} from 'containers/User/UserSagas';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import * as UserSelectors from 'containers/User/UserState';
import gridSaga from 'components/Grid/GridSaga';
import {gridSettings} from './LocalUserDetailConfig';
import {getLocalUserScopeRows} from './LocalUserDetailState';
import {fetchAccessRestrictions} from 'containers/RBAC/AccessRestriction/List/AccessRestrictionListSaga';

export function* updateUserProfile(userId, payloads) {
  yield call(apiSaga, 'user.update', {
    params: {id: userId},
    data: payloads,
    *afterFetch({data}) {
      apiCachedResponses.removeByMethodName('users.get_collection');
      apiCachedResponses.removeByMethodName('org_auth_security_principals.get_collection');
      apiCachedResponses.removeByMethodName('org_permissions.get_collection');

      // Update the state.user and state.users.all redux store
      return {data};
    },
  });
}

export function* deleteUserProfile(userId) {
  yield call(apiSaga, 'user_local_profile.delete', {
    params: {user_id: userId},
    *afterFetch({data}) {
      apiCachedResponses.removeByMethodName('users.get_collection');
      apiCachedResponses.removeByMethodName('org_auth_security_principals.get_collection');
      apiCachedResponses.removeByMethodName('org_permissions.get_collection');

      // Update the state.user and state.users.all redux store
      return {data};
    },
  });
}

export function* reinviteUserProfile(userId) {
  const {data} = yield call(apiSaga, 'user_local_profile.reinvite', {
    params: {user_id: userId},
    *afterFetch({data}) {
      // Update the state.user and state.users.all redux store
      return {data};
    },
  });

  return {data};
}

export function* changePassword() {
  yield take('CHANGE_PASSWORD');

  const user = yield select(UserSelectors.getUser);

  window.location = `${user.login_url}/users/password/change`;
}

export function* fetchLocalUser(route, refetch = false) {
  const id = Number(route.params.id);

  yield call(gridSaga, {
    route,
    settings: gridSettings,
    *onSaga() {
      const redirectToList = new RedirectError({
        to: 'rbac.users.local.list',
        proceedFetching: true,
        thisFetchIsDone: true,
      });

      if (!id) {
        throw redirectToList;
      }

      if (!__MSP__) {
        yield fork(fetchLabels, {force: refetch});
        yield fork(fetchAccessRestrictions, {force: refetch});
      }

      yield fork(fetchAllPermissions, {force: refetch});

      // If such user doesn't exist at all or we force refetch, then go get individual user
      if (!(yield select(UserSelectors.getUserById, id)) || refetch) {
        try {
          // if fail to fetch such user, redirect to the list
          yield call(fetchUser, {force: refetch, id});
        } catch {
          throw redirectToList;
        }
      }

      const user = yield select(UserSelectors.getUserById, id, 'local');

      // If such user still doesn't exist or it's type is not local,
      // meaning the user is deleted, redirect to user activity detail page
      if (!user) {
        throw new RedirectError({
          to: 'rbac.users.activity.detail',
          params: {id},
          proceedFetching: true,
          thisFetchIsDone: true,
        });
      }

      yield call(fetchAllAuthSecPrincipals, {force: refetch});

      // If a local user does not have an associated authSecPrincipal, it means
      // that it's not a valid local user. Hence, redirect to local users list.
      if (!(yield select(getAuthSecPrincipalByUserName, user.username))) {
        throw redirectToList;
      }

      // return to get count for pagination
      return (yield select(getLocalUserScopeRows)).length;
    },
  });
}
