/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import type {FormikProps} from 'formik';
import {parse} from 'csv-parse/browser/esm/sync';
import * as yup from 'yup';
import {AttributeList, Button, Cutout, Form, Spinner, Modal, Notifications} from 'components';
import FileUpload from 'components/Form/FileUpload/FileUpload';
import {useSetState, useFetcherCall} from './WorkloadCSVImportUtils';
import {uploadCSV} from './WorkloadCSVImportSaga';
import {getComputedCurrentStep} from 'antman/containers/Onboarding/OnboardingStepsState';
import {transitionStep} from 'antman/containers/Onboarding/OnboardingSaga';
import {delay} from 'utils/general';
import styles from './WorkloadCSVImport.css';
import styleUtils from 'utils.css';

const parseCsv: typeof parse = (input, options) => parse(input, {...options, trim: true});

interface FormValues {
  csvFile?: File;
  createLabels: boolean;
}

export interface WorkloadCSVImportProps {
  onApply?(): void | Promise<void>;
  onClose?(): void;
}

const schemas = yup.object({
  csvFile: yup
    .mixed<File>()
    .required(intl('Antman.Workloads.CSVMissing'))
    .test('csv', intl('Antman.Workloads.CSVInvalid'), async (value, context) => {
      if (value) {
        // TODO: what is the file size upper bound?
        if (value.size >= 1024 * 1024) {
          return context.createError({message: intl('Antman.Workloads.CSVTooLarge')});
        }

        let content: string[][];

        try {
          content = parseCsv(await value.text());
        } catch (error) {
          return context.createError({
            message: intl('Antman.Workloads.CSVParseError', {reason: (error as Error).message}),
          });
        }

        if (content.length > 1000) {
          return context.createError({message: intl('Antman.Workloads.CSVRowsExceed', {count: 1000})});
        }

        if (content.length <= 0) {
          return context.createError({message: intl('Antman.Workloads.CSVEmpty')});
        }

        const firstRow = content[0];

        // check the first row is header row
        if (
          !firstRow.includes('href') ||
          !(
            firstRow.includes('role') ||
            firstRow.includes('app') ||
            firstRow.includes('env') ||
            firstRow.includes('loc')
          )
        ) {
          return context.createError({message: intl('Antman.Workloads.CSVMissingHeader')});
        }

        return true;
      }

      return false;
    }),
});

const initialValues: FormValues = {
  createLabels: true,
};

export default function WorkloadCSVImport({onClose, onApply}: WorkloadCSVImportProps): JSX.Element {
  const currentStep = useSelector(getComputedCurrentStep);
  const [on, setOn] = useState(currentStep === 'ONBOARDING_CSV_IMPORT');

  useEffect(() => {
    setOn(currentStep === 'ONBOARDING_CSV_IMPORT');
  }, [setOn, currentStep]);

  const {error, loading, fetch: fetchUploadCSV} = useFetcherCall(uploadCSV, {mode: 'fork', autoReset: 4000});
  const {fetch: fetchTransitionStep} = useFetcherCall(transitionStep);

  const handleButtonClick = useCallback(() => {
    setOn(true);

    if (currentStep === 'ONBOARDING_CLICK_CSV_IMPORT') {
      fetchTransitionStep();
    }
  }, [setOn, currentStep, fetchTransitionStep]);
  const handleClose = useCallback(async () => {
    setOn(false);
    onClose?.();

    if (currentStep === 'ONBOARDING_CSV_IMPORT') {
      fetchTransitionStep({step: 'ONBOARDING_CLICK_CSV_IMPORT'});
    }
  }, [setOn, onClose, currentStep, fetchTransitionStep]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await fetchUploadCSV({
        content: await values.csvFile?.text()!,
        createLabels: values.createLabels,
      });
      await onApply?.();

      if (currentStep === 'ONBOARDING_CSV_IMPORT') {
        fetchTransitionStep();
      }

      setOn(false);
    },
    [setOn, fetchUploadCSV, fetchTransitionStep, onApply, currentStep],
  );

  return (
    <>
      <Cutout visible={currentStep === 'ONBOARDING_CLICK_CSV_IMPORT'}>
        <Button
          color="standard"
          onClick={handleButtonClick}
          icon="import"
          tooltip={
            <div className={cx(styleUtils.gapSmall, styleUtils.gapHorizontal, styleUtils.centerFlexAlign)}>
              <span>{intl('Antman.Onboarding.LabelYourWorkloads')}</span>
              <Button
                onClick={_.partial(fetchTransitionStep, {step: 'ONBOARDING_NAVIGATE_TO_RULESETS', skip: true})}
                size="small"
                color="primary"
                text={intl('Antman.Onboarding.Skip')}
              />
            </div>
          }
          tooltipProps={{
            noSingleton: true,
            visible: currentStep === 'ONBOARDING_CLICK_CSV_IMPORT',
            interactive: true,
          }}
          text={intl('Common.Import')}
        />
      </Cutout>
      {on && (
        <Modal onClose={handleClose} large notResizable>
          <Form schemas={schemas} onSubmit={handleSubmit} initialValues={initialValues}>
            {props => <WorkloadCSVForm formik={props} onClose={handleClose} error={error} loading={loading} />}
          </Form>
        </Modal>
      )}
    </>
  );
}

interface WorkloadCSVFormProps {
  onClose(): void;
  formik: FormikProps<FormValues>;
  error?: Error;
  loading?: boolean;
}

function WorkloadCSVForm({formik, onClose, error, loading}: WorkloadCSVFormProps) {
  const [state, setState] = useSetState({
    rowsProvided: false,
    rowsToBeUpdated: 0,
    rowsDuplicated: 0,
    isAnalysisComplete: false,
  });
  const [analyzing, setAnalyzing] = useState(false);

  const handleFileChange = useCallback(
    async (_fileName, _content, file: File) => {
      formik.setFieldValue('csvFile', file);
      formik.setFieldTouched('csvFile');

      setAnalyzing(true);

      await Promise.all([
        (async () => {
          // imperatively trigger validation to have the `isValidating` status available
          // https://github.com/jaredpalmer/formik/issues/1193#issuecomment-448207163
          const errors = await formik.validateForm();

          if (_.isEmpty(errors)) {
            const raw = await file.text();

            const contents = parseCsv(raw, {columns: true});

            // TODO: calls API to trigger analysis
            const distinctContentsMap = new Map();

            for (const item of contents) {
              if (!distinctContentsMap.has(item.href)) {
                distinctContentsMap.set(item.href, item);
              }
            }

            setState({
              rowsProvided: contents.length,
              rowsToBeUpdated: distinctContentsMap.size,
              rowsDuplicated: contents.length - distinctContentsMap.size,
            });
          }
        })(),
        delay(2000),
      ]);

      setAnalyzing(false);
    },
    [formik, setState, setAnalyzing],
  );

  const handleClose = useCallback(() => {
    formik.resetForm();

    onClose();
  }, [formik, onClose]);

  const {rowsProvided, rowsToBeUpdated, rowsDuplicated} = state;

  return (
    <>
      <Modal.Header title={intl('Antman.Workloads.CSVImportTitle')} />
      <Modal.Content>
        {error && <Notifications>{[{type: 'error', message: error?.message}]}</Notifications>}
        <div>
          <p>{intl('Antman.Workloads.CSVImport')}</p>
          <p>{intl('Antman.Workloads.CSVFormat')}</p>
          <p>{intl('Antman.Workloads.CSVColumns')}</p>
        </div>
        <AttributeList>
          {[
            {
              key: <Form.Label name="csvFile" title={intl('Antman.Workloads.CSVFile')} />,
              value: (
                <FileUpload
                  tid="csv-file-upload"
                  name="csvFile"
                  acceptedFiles={['.csv']}
                  onChange={handleFileChange}
                  errorText={analyzing ? undefined : formik.errors.csvFile?.toString()}
                />
              ),
            },
            {
              value: <Form.Checkbox name="createLabels" label={intl('Antman.Workloads.CSVCreateNewLabel')} />,
            },
          ]}
        </AttributeList>
        {analyzing && (
          <div className={styles.result}>
            {analyzing && (
              <>
                <Spinner size="large" position="before" color="dark" /> {intl('Antman.Common.Analyzing')}
              </>
            )}
          </div>
        )}
        {!analyzing && formik.isValid && (
          <div className={styles.analysis}>
            <span className={styles.analysisColor}>
              {intl('Antman.RowsProvided', {count: rowsProvided}, {html: true})}
            </span>
            <span>{intl('Antman.RowsUpdated', {count: rowsToBeUpdated}, {html: true})}</span>
            <span>{intl('Antman.RowsDuplicated', {count: rowsDuplicated}, {html: true})}</span>
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button color="standard" text={intl('Common.Cancel')} onClick={handleClose} />
        <Button
          type="submit"
          text={intl('Common.Apply')}
          disabled={analyzing || !formik.isValid}
          progress={loading}
          progressCompleteWithCheckmark
        />
      </Modal.Footer>
    </>
  );
}
