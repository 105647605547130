/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {Component} from 'react';
import SizeWatcher from 'react-size-watcher';
import {connect} from 'react-redux';
import {call, select} from 'redux-saga/effects';
import {RedirectError} from 'errors';
import {AppContext} from 'containers/App/AppUtils';
import {HeaderProps} from 'containers';
import {isUserReadOnly} from 'containers/User/UserState';
import {createLoadBalancerFiles} from './LoadBalancerEditSaga';
import {getLoadBalancerEditPage} from './LoadBalancerEditState';
import {fetchLoadBalancerItem, updateLoadBalancer} from '../../LoadBalancerSaga';
import {Button, Modal, ToolGroup, ToolBar, TypedMessages, Form, AttributeList} from 'components';
import {hrefUtils, reactUtils, ipUtils} from 'utils';
import stylesUtils from 'utils.css';
import {object, string, number, boolean, mixed} from 'yup';
import {isLoadBalancerEnabled} from '../../LoadBalancerState';
import {fetchDeviceTypes} from 'containers/LoadBalancer/List/LoadBalancerListSaga';

// Triggers responsiveness to Table (AttributeList) views.
const breakpoints = [{data: {isSmall: false}}, {maxWidth: 720, data: {isSmall: true}}];

const deviceNumberOptions = [
  {value: '1', label: intl('LoadBalancers.DeviceNumber', {count: 1})},
  {value: '2', label: intl('LoadBalancers.DeviceNumber', {count: 2})},
];

const getDeviceTypeOptions = (props, nfcHref) => {
  const {loadBalancer, deviceTypeMap} = props;
  const href = nfcHref || loadBalancer.nfc?.href;

  return deviceTypeMap[href] || [];
};

//Get formik's mandatory initialValues props for form setup
const getInitialValues = props => {
  const {loadBalancer, nenSelectorOptions} = props;
  const nfc =
    nenSelectorOptions?.length === 1
      ? nenSelectorOptions[0] // backwards-compatible: auto-select for only 1 choice
      : Form.Utils.findSelectedOption(nenSelectorOptions, loadBalancer.nfc?.href);
  const initialValues = {
    name: loadBalancer.name,
    description: loadBalancer.description || '',
    nfc,
    deviceType:
      Form.Utils.findSelectedOption(
        getDeviceTypeOptions(props, loadBalancer.nfc?.href || nfc?.value),
        loadBalancer?.device_type,
      ) || null,
    numberOfDevice: null,
    host1: '',
    port1: '',
    username1: '',
    password1: '',
    confirmpassword1: '',
    check1: false,
    host2: '',
    port2: '',
    username2: '',
    password2: '',
    confirmpassword2: '',
    check2: false,
    errors: false,
    href1: '',
    href2: '',
  };

  if (loadBalancer.name !== '') {
    const {devices} = loadBalancer;
    // Device One
    const {
      config: {host: host1 = '', port: port1 = '', username: username1 = '', check_certificate: check1 = ''},
      href: href1 = '',
    } = devices[0];

    if (loadBalancer.devices.length > 1) {
      // Device Two
      const {
        config: {host: host2 = '', port: port2 = '', username: username2 = '', check_certificate: check2 = ''},
        href: href2 = '',
      } = devices[1];

      Object.assign(initialValues, {
        numberOfDevice: Form.Utils.findSelectedOption(deviceNumberOptions, String(loadBalancer.devices.length)),
        host1,
        port1,
        username1,
        check1,
        host2,
        port2,
        username2,
        check2,
        errors: false,
        href1,
        href2,
      });
    } else {
      Object.assign(initialValues, {
        numberOfDevice: Form.Utils.findSelectedOption(deviceNumberOptions, String(loadBalancer.devices.length)),
        host1,
        port1,
        username1,
        check1,
        href1,
      });
    }
  }

  return initialValues;
};

// Initial State
const getInitialState = props => ({
  initialValues: getInitialValues(props),
  deviceTypeOptions: getDeviceTypeOptions(
    props,
    props.nenSelectorOptions.length === 1 ? props.nenSelectorOptions[0]?.value : null,
  ),
  saving: false,
  error: null,
});

const labelArray = ['slbDevice', 'slbHost', 'slbPort', 'slbUsername', 'slbPassword', 'slbComfirm', 'slbCheck'];

@connect(getLoadBalancerEditPage)
export default class LoadBalancerEdit extends Component {
  static contextType = AppContext;
  static prefetch = function* () {
    yield call(fetchDeviceTypes);

    const userIsReadOnly = yield select(isUserReadOnly);
    const loadBalancerIsEnabled = yield select(isLoadBalancerEnabled);

    // If the load balancers feature is not accessible to the user, redirect her/him to the landing page.
    if (!loadBalancerIsEnabled) {
      throw new RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }

    // Read only users cannot edit load balancers
    if (userIsReadOnly) {
      throw new RedirectError({
        to: 'loadBalancers.item',
        thisFetchIsDone: true,
      });
    }
  };

  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);
    this.handleNumDevicesChange = this.handleNumDevicesChange.bind(this);
    this.handleDeviceTypeChange = this.handleDeviceTypeChange.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.toggleLockCheckbox = this.toggleLockCheckbox.bind(this);
    this.handleNfcChange = this.handleNfcChange.bind(this);
    this.handleSubmit = _.noop;
    this.handleSave = this.handleSave.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.getAllHints = this.getAllHints.bind(this);
    this.getAllDevices = this.getAllDevices.bind(this);

    const initialSchema = {
      name: string().max(255, intl('Common.NameIsTooLong')).required(Form.emptyMessage),
      description: string().notRequired(),
      nfc: object().nullable().notRequired(),
      deviceType: object().nullable().required(Form.emptyMessage),
      numberOfDevice: object().nullable().required(Form.emptyMessage),
      port1: number().truncate().required(Form.emptyMessage).min(1).max(65_535),
      username1: string()
        .required(Form.emptyMessage)
        .max(255, intl('LoadBalancers.Create.UsernameTooLong', {len: 255})),
      check1: boolean(),
      host2: string()
        .when('numberOfDevice', {
          is: numberOfDevice => numberOfDevice && numberOfDevice.value === '2',
          then: string()
            .required(Form.emptyMessage)
            .max(255, intl('LoadBalancers.Create.HostTooLong', {len: 255}))
            .test('isValidIPAddressOrFQDN', intl('Common.AddValidIPAddressOrFQDN'), value => {
              if (!value) {
                return true;
              }

              return ipUtils.validateIPAddress(value) || ipUtils.isValidFqdn(value);
            }),
        })
        .test('match', intl('LoadBalancers.Create.ManagementSame'), function (host2) {
          return (!this.parent.host1 && !host2) || host2 !== this.parent.host1;
        })
        .max(255, intl('LoadBalancers.Create.HostTooLong', {len: 255})),
      port2: mixed().when('numberOfDevice', {
        is: numberOfDevice => numberOfDevice && numberOfDevice.value === '2',
        then: number().truncate().required(Form.emptyMessage).min(1).max(65_535),
      }),
      username2: string()
        .when('numberOfDevice', {
          is: numberOfDevice => numberOfDevice && numberOfDevice.value === '2',
          then: string().required(Form.emptyMessage),
        })
        .max(255, intl('LoadBalancers.Create.UsernameTooLong', {len: 255})),
      check2: boolean(),
    };

    if (props.routeName === 'app.loadBalancers.create') {
      Object.assign(initialSchema, {
        nfc: object().nullable().required(),
        host1: string()
          .required(Form.emptyMessage)
          .max(255, intl('LoadBalancers.Create.HostTooLong', {len: 255}))
          .test('isValidIPAddressOrFQDN', intl('Common.AddValidIPAddressOrFQDN'), value => {
            if (!value) {
              return true;
            }

            return ipUtils.validateIPAddress(value) || ipUtils.isValidFqdn(value);
          }),
        password1: string()
          .required(Form.emptyMessage)
          .max(128, intl('LoadBalancers.Create.PasswordTooLong', {len: 128})),
        confirmpassword1: string()
          .required(Form.emptyMessage)
          .test('match', intl('LoadBalancers.Create.PasswordNotSame'), function (confirmpassword1) {
            return !this.parent.password1 || !confirmpassword1 || confirmpassword1 === this.parent.password1;
          }),
        password2: string()
          .when('numberOfDevice', {
            is: numberOfDevice => numberOfDevice && numberOfDevice.value === '2',
            then: string().required(Form.emptyMessage),
          })
          .max(128, intl('LoadBalancers.Create.PasswordTooLong', {len: 128})),
        confirmpassword2: string().when('numberOfDevice', {
          is: numberOfDevice => numberOfDevice && numberOfDevice.value === '2',
          then: string()
            .required(Form.emptyMessage)
            .test('match', intl('LoadBalancers.Create.PasswordNotSame'), function (confirmpassword2) {
              return !this.parent.password2 || !confirmpassword2 || confirmpassword2 === this.parent.password2;
            }),
        }),
      });
    } else {
      Object.assign(initialSchema, {
        host1: string()
          .required(Form.emptyMessage)
          .max(255, intl('LoadBalancers.Create.HostTooLong', {len: 255}))
          .test('isValidIPAddressOrFQDN', intl('Common.AddValidIPAddressOrFQDN'), value => {
            if (!value) {
              return true;
            }

            return ipUtils.validateIPAddress(value) || ipUtils.isValidFqdn(value);
          })
          .when('numberOfDevice', {
            is: numberOfDevice => numberOfDevice && numberOfDevice.value === '2',
            then: string().test('match', intl('LoadBalancers.Create.ManagementSame'), function (host1) {
              return host1 !== this.parent.host2;
            }),
          }),
        password1: string().max(128, intl('LoadBalancers.Create.PasswordTooLong', {len: 128})),
        confirmpassword1: string().test(
          'match',
          intl('LoadBalancers.Create.PasswordNotSame'),
          function (confirmpassword1) {
            return !this.parent.password1 || !confirmpassword1 || confirmpassword1 === this.parent.password1;
          },
        ),
        password2: string().max(128, intl('LoadBalancers.Create.PasswordTooLong', {len: 128})),
        confirmpassword2: string().test(
          'match',
          intl('LoadBalancers.Create.PasswordNotSame'),
          function (confirmpassword2) {
            return !this.parent.password2 || !confirmpassword2 || confirmpassword2 === this.parent.password2;
          },
        ),
      });
    }

    this.schemas = object(initialSchema);
    this.state = getInitialState(props);
  }

  getAllHints() {
    const {isEdit} = this.props;

    return {
      slbDevice: {
        hint: <div className={stylesUtils.bold}>{intl('LoadBalancers.SecondDeviceProperties')}</div>,
      },
      slbHost: {
        hint: <Form.Input tid="slb-host2" placeholder={intl('LoadBalancers.Create.CreateManagement')} name="host2" />,
      },
      slbPort: {
        hint: <Form.Input tid="slb-port2" placeholder={intl('LoadBalancers.Create.CreatePort')} name="port2" />,
      },
      slbUsername: {
        hint: (
          <Form.Input tid="slb-username2" placeholder={intl('LoadBalancers.Create.CreateUsername')} name="username2" />
        ),
      },
      slbPassword: {
        hint: (
          <Form.Input
            type="password"
            tid="slb-password2"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="password2"
          />
        ),
      },
      slbComfirm: {
        hint: (
          <Form.Input
            type="password"
            tid="slb-confirmpassword2"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="confirmpassword2"
          />
        ),
      },
      slbCheck: {
        hint: <Form.Checkbox name="check2" tid="slb-check2" />,
      },
    };
  }

  getAllDevices(showDeviceHints = []) {
    const {isEdit} = this.props;

    const devices = {
      slbDevice: {
        tid: 'slb-devices',
        value: <div className={stylesUtils.bold}>{intl('LoadBalancers.FirstDeviceProperties')}</div>,
      },
      slbHost: {
        tid: 'slb-host',
        key: <Form.Label name="host1" title={intl('LoadBalancers.Detail.DeviceManagement')} />,
        value: <Form.Input tid="slb-host1" placeholder={intl('LoadBalancers.Create.CreateManagement')} name="host1" />,
      },
      slbPort: {
        tid: 'slb-port',
        key: <Form.Label name="port1" title={intl('Port.Port')} />,
        value: <Form.Input tid="slb-port1" placeholder={intl('LoadBalancers.Create.CreatePort')} name="port1" />,
      },
      slbUsername: {
        tid: 'slb-username',
        key: <Form.Label name="username1" title={intl('Common.Username')} />,
        value: (
          <Form.Input tid="slb-username1" placeholder={intl('LoadBalancers.Create.CreateUsername')} name="username1" />
        ),
      },
      slbPassword: {
        tid: 'slb-password',
        key: <Form.Label name="password1" title={intl('Common.Password')} />,
        value: (
          <Form.Input
            type="password"
            tid="slb-password1"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="password1"
          />
        ),
      },
      slbComfirm: {
        tid: 'slb-confirmpassword',
        key: <Form.Label name="confirmpassword1" title={intl('Common.ConfirmPassword')} />,
        value: (
          <Form.Input
            type="password"
            tid="slb-confirmpassword1"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="confirmpassword1"
          />
        ),
      },
      slbCheck: {
        tid: 'slb-check',
        key: <Form.Label name="check1" title={intl('Common.VerifyTLS')} />,
        value: <Form.Checkbox name="check1" tid="slb-check1" />,
      },
    };

    const finalDevice = [];

    if (showDeviceHints.length) {
      const hints = this.getAllHints();

      showDeviceHints.forEach(item => {
        if (devices[item] && hints[item]) {
          devices[item].hint = hints[item].hint;
          finalDevice.push(devices[item]);
        }
      });
    } else {
      labelArray.forEach(item => {
        finalDevice.push(devices[item]);
      });
    }

    return finalDevice;
  }

  getDeviceDetail(options) {
    const {values} = options;

    this.formik = options;

    const {
      state: {deviceTypeOptions},
      props: {isEdit, nenSelectorOptions},
    } = this;

    const {nfc} = values;
    const showSupportedDevices = nenSelectorOptions.length < 2 || Boolean(nfc);

    const deviceDetail = [
      {divider: true},
      {title: intl('Common.Settings')},
      {
        tid: 'slb-name',
        key: <Form.Label name="name" title={intl('Common.Name')} />,
        value: <Form.Input tid="name" placeholder={intl('LoadBalancers.Create.CreateName')} name="name" />,
      },
      {
        tid: 'slb-description',
        key: <Form.Label name="description" title={intl('Common.Description')} />,
        value: (
          <Form.Textarea
            tid="description"
            placeholder={intl('LoadBalancers.Create.CreateDescription')}
            name="description"
          />
        ),
      },
      {
        tid: 'nen-selector',
        key: <Form.Label name="networkEnforcementNode" title={intl('Switches.NENHostname')} />,
        value:
          nenSelectorOptions.length === 1 ? (
            nfc?.label
          ) : (
            <Form.Selector name="nfc" options={nenSelectorOptions} onChange={this.handleNfcChange} />
          ),
      },
    ];

    if (showSupportedDevices) {
      deviceDetail.push(
        {
          tid: 'slb-devicetype',
          key: <Form.Label name="deviceType" title={intl('Common.DeviceType')} />,
          value: (
            <Form.Selector
              name="deviceType"
              options={deviceTypeOptions}
              onChange={this.handleDeviceTypeChange}
              disabled={isEdit}
            />
          ),
        },
        {
          tid: 'slb-devicenum',
          key: <Form.Label name="numberOfDevice" title={intl('LoadBalancers.Detail.NumberOfDevices')} />,
          value: (
            <Form.Selector
              name="numberOfDevice"
              options={deviceNumberOptions}
              onChange={this.handleNumDevicesChange}
              disabled={isEdit}
            />
          ),
        },
      );
    }

    return deviceDetail;
  }

  getDevices() {
    const {values} = this.formik;

    const numberOfDevices = Number(values.numberOfDevice && values.numberOfDevice.value);

    const oneDevice = this.getAllDevices();
    const twoDevices = this.getAllDevices(labelArray);

    let addMultipleDevices;

    if (numberOfDevices > 0) {
      addMultipleDevices = numberOfDevices === 2 ? twoDevices : oneDevice;
    }

    return addMultipleDevices;
  }

  getSmallDevices() {
    const {values} = this.formik;
    const {isEdit} = this.props;
    const numberOfDevices = Number(values.numberOfDevice && values.numberOfDevice.value);
    let addMultipleDevices;
    const firstDevice = [
      {
        tid: 'slb-devices',
        value: <div className={stylesUtils.bold}>{intl('LoadBalancers.FirstDeviceProperties')}</div>,
      },
      {
        tid: 'slb-host',
        key: <Form.Label name="host1" title={intl('LoadBalancers.Detail.DeviceManagement')} />,
        value: <Form.Input tid="slb-host1" placeholder={intl('LoadBalancers.Create.CreateManagement')} name="host1" />,
      },
      {
        tid: 'slb-port',
        key: <Form.Label name="port1" title={intl('Port.Port')} />,
        value: <Form.Input tid="slb-port1" placeholder={intl('LoadBalancers.Create.CreatePort')} name="port1" />,
      },
      {
        tid: 'slb-username',
        key: <Form.Label name="username1" title={intl('Common.Username')} />,
        value: (
          <Form.Input tid="slb-username1" placeholder={intl('LoadBalancers.Create.CreateUsername')} name="username1" />
        ),
      },
      {
        tid: 'slb-password',
        key: <Form.Label name="password1" title={intl('Common.Password')} />,
        value: (
          <Form.Input
            type="password"
            tid="slb-password1"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="password1"
          />
        ),
      },
      {
        tid: 'slb-confirmpassword',
        key: <Form.Label name="confirmpassword1" title={intl('Common.ConfirmPassword')} />,
        value: (
          <Form.Input
            type="password"
            tid="slb-confirmpassword1"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="confirmpassword1"
          />
        ),
      },
      {
        tid: 'slb-check',
        key: <Form.Label name="check1" title={intl('Common.VerifyTLS')} />,
        value: <Form.Checkbox name="check1" tid="slb-check1" />,
      },
    ];

    const secondDevice = [
      {
        tid: 'slb-devices',
        value: <div className={stylesUtils.bold}>{intl('LoadBalancers.SecondDeviceProperties')}</div>,
      },
      {
        tid: 'slb-host',
        key: <Form.Label name="host2" title={intl('LoadBalancers.Detail.DeviceManagement')} />,
        value: <Form.Input tid="slb-host2" placeholder={intl('LoadBalancers.Create.CreateManagement')} name="host2" />,
      },
      {
        tid: 'slb-port',
        key: <Form.Label name="port2" title={intl('Port.Port')} />,
        value: <Form.Input tid="slb-port2" placeholder={intl('LoadBalancers.Create.CreatePort')} name="port2" />,
      },
      {
        tid: 'slb-username',
        key: <Form.Label name="username2" title={intl('Common.Username')} />,
        value: (
          <Form.Input tid="slb-username2" placeholder={intl('LoadBalancers.Create.CreateUsername')} name="username2" />
        ),
      },
      {
        tid: 'slb-password',
        key: <Form.Label name="password2" title={intl('Common.Password')} />,
        value: (
          <Form.Input
            type="password"
            tid="slb-password2"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="password2"
          />
        ),
      },
      {
        tid: 'slb-confirmpassword',
        key: <Form.Label name="confirmpassword2" title={intl('Common.ConfirmPassword')} />,
        value: (
          <Form.Input
            type="password"
            tid="slb-confirmpassword2"
            placeholder={!isEdit ? intl('LoadBalancers.Create.CreatePassword') : '••••••••'}
            name="confirmpassword2"
          />
        ),
      },
      {
        tid: 'slb-check',
        key: <Form.Label name="check2" title={intl('Common.VerifyTLS')} />,
        value: <Form.Checkbox name="check2" tid="slb-check2" />,
      },
    ];

    if (numberOfDevices > 0) {
      addMultipleDevices = numberOfDevices === 2 ? firstDevice.concat(secondDevice) : firstDevice;
    }

    return addMultipleDevices;
  }

  async handleSave() {
    const {loadBalancer, currentRouteParams} = this.props;
    const {values, setSubmitting} = this.formik;
    const {isEdit} = this.props;
    const {fetcher, navigate} = this.context;

    let payload = {};

    if (isEdit) {
      const {
        config: {host: host1, port: port1, username: username1, check_certificate: checkCertificate1},
      } = loadBalancer.devices[0];

      if (values.name !== loadBalancer.name) {
        payload.name = values.name.toString().trim();
      }

      if (values.description !== loadBalancer.description) {
        payload.description = values.description.toString().trim();
      }

      if (values.nfc?.value !== loadBalancer.nfc?.href) {
        payload.nfc = {href: values.nfc?.value.toString().trim()};
      }

      const device1 = {
        config: {},
        href: values.href1,
      };

      if (values.host1 !== host1) {
        device1.config.host = values.host1;
      }

      if (values.port1 !== port1) {
        device1.config.port = parseInt(values.port1, 10);
      }

      if (values.username1 !== username1) {
        device1.config.username = values.username1;
      }

      if (values.password1 !== '') {
        device1.config.credential = values.password1;
      }

      if (values.checkCertificate !== checkCertificate1) {
        device1.config.check_certificate = values.check1;
      }

      const devices = [];

      const device2 = {config: {}};

      if (values.numberOfDevice && values.numberOfDevice.value === '2') {
        const {
          config: {host: host2, port: port2, username: username2, check_certificate: checkCertificate2},
        } = loadBalancer.devices[1];

        device2.href = values.href2;

        if (values.host2 !== host2) {
          device2.config.host = values.host2;
        }

        if (values.port2 !== port2) {
          device2.config.port = parseInt(values.port2, 10);
        }

        if (values.username2 !== username2) {
          device2.config.username = values.username2;
        }

        if (values.password2 !== '') {
          device2.config.credential = values.password2;
        }

        if (values.checkCertificate !== checkCertificate2) {
          device2.config.check_certificate = values.check2;
        }
      }

      if (values.numberOfDevice && values.numberOfDevice.value === '1' && !_.isEmpty(device1.config)) {
        devices.push(device1);
        payload.devices = devices;
      }

      if (
        values.numberOfDevice &&
        values.numberOfDevice.value === '2' &&
        (!_.isEmpty(device1.config) || !_.isEmpty(device2.config))
      ) {
        devices.push(device1, device2);
        payload.devices = devices;
      }
    }

    if (!isEdit) {
      const device1 = {
        config: {
          host: values.host1,
          port: parseInt(values.port1, 10),
          username: values.username1,
          credential: values.password1,
          credential_type: 'password',
          check_certificate: values.check1,
        },
      };

      const device2 = {config: {}};

      payload = {
        name: values.name.toString().trim(),
        description: values.description.toString().trim(),
        ...(values.nfc && {nfc: {href: values.nfc?.value.toString().trim()}}),
        device_type: values.deviceType?.value,
        devices: [device1],
      };

      if (this.state.numberOfDevice && this.state.numberOfDevice.value === '2') {
        device2.config.host = values.host2;
        device2.config.port = parseInt(values.port2, 10);
        device2.config.username = values.username2;
        device2.config.credential = values.password2;
        device2.config.credential_type = 'password';
        device2.config.check_certificate = values.check2;
        payload.devices.push(device2);
      }
    }

    try {
      let id;

      await reactUtils.setStateAsync({saving: true}, this);
      // Call formik method to set isSubmitting to true
      setSubmitting(true);

      if (isEdit) {
        id = currentRouteParams.id;
        await fetcher.spawn(updateLoadBalancer, {id, payload});
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createLoadBalancerFiles, payload);

        id = hrefUtils.getId(href);
      }

      await fetcher.fork(fetchLoadBalancerItem, {params: {id}}, true);

      // Wait progress on save button to finish
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      // Navigate to a view page
      navigate({to: 'loadBalancers.item', params: {id}});
    } catch (error) {
      this.setState({error, saving: false});
      setSubmitting(false);
    }
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  handleNumDevicesChange(evt, numberOfDevice, name) {
    const {setFieldValue, setFieldTouched, touched, values} = this.formik;

    if (values[name] !== numberOfDevice) {
      setFieldValue(name, numberOfDevice);
      this.setState({
        numberOfDevice,
      });
    }

    if (!touched[name]) {
      setFieldTouched(name, true);
    }
  }

  async handleNfcChange(event, value, name) {
    const {setFieldValue} = this.formik;
    const {isEdit} = this.props;

    setFieldValue(name, value);

    // keep selected device type when editing
    if (isEdit) {
      return;
    }

    const nfcHref = value.value; // arg "value" is an option with {label, value}
    const deviceTypeOptions = getDeviceTypeOptions(this.props, nfcHref);

    await reactUtils.setStateAsync({deviceTypeOptions}, this);
  }

  handleDeviceTypeChange(evt, selection, name) {
    const {setFieldValue, setFieldTouched, touched, values} = this.formik;

    if (values[name] !== selection) {
      setFieldValue(name, selection);
      this.setState({
        deviceType: selection,
      });
    }

    if (!touched[name]) {
      setFieldTouched(name, true);
    }
  }

  toggleLockCheckbox(value, name) {
    const {setFieldValue} = this.formik;

    if (name === 'check1') {
      setFieldValue('check1', !value);
    } else {
      setFieldValue('check2', !value);
    }
  }

  // Render alert message when edit or create fails
  renderErrorAlert() {
    const {isEdit} = this.props;
    const {error} = this.state;
    const title = isEdit ? intl('LoadBalancers.Errors.Edit') : intl('LoadBalancers.Errors.Create');
    const message = _.get(error, 'data[0].message', error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    const {isValid, values} = options;

    this.formik = options;

    const {
      state: {saving, onSaveDone, error},
      props: {
        isEdit,
        currentRouteParams: {id},
      },
    } = this;

    return (
      <>
        <HeaderProps
          title={intl('VirtualServers.Detail.ServerLoadBalancers')}
          label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
          up={isEdit ? {to: 'loadBalancers.item.view', params: {id}} : 'loadBalancers.list'}
        />
        <ToolBar>
          <ToolGroup>
            <Button
              textIsHideable
              icon="save"
              type="submit"
              text={intl('Common.Save')}
              tid="save"
              disabled={isValid === false}
              onClick={this.handleSave}
              onProgressDone={onSaveDone}
              progressCompleteWithCheckmark
              progress={saving}
              progressError={error !== null}
            />
            <Button.Link
              textIsHideable
              color="standard"
              disabled={saving || Boolean(onSaveDone)}
              icon="cancel"
              tid="cancel"
              link={isEdit ? {to: 'loadBalancers.item.view', params: {id}} : 'loadBalancers.list'}
              text={intl('Common.Cancel')}
            />
          </ToolGroup>
        </ToolBar>
        <SizeWatcher breakpoints={breakpoints}>
          {({data}) => {
            const {isSmall} = data;
            const deviceDetail = this.getDeviceDetail(options);

            if (values.numberOfDevice) {
              deviceDetail.push(...(isSmall ? this.getSmallDevices() : this.getDevices()));
            }

            return (
              <AttributeList
                valuesGap="gapLarge"
                valueColumnWidth="minmax(auto, 600px)"
                hintColumnWidth="minmax(auto, 600px)"
              >
                {deviceDetail}
              </AttributeList>
            );
          }}
        </SizeWatcher>
        {error && this.renderErrorAlert()}
      </>
    );
  }

  render() {
    return (
      <Form
        enableReinitialize
        schemas={this.schemas}
        initialValues={this.state.initialValues}
        onSubmit={this.handleSubmit}
      >
        {this.renderForm}
      </Form>
    );
  }
}
