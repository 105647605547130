/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put, all, spawn} from 'redux-saga/effects';
import {getVens, getVensCount, getCalculatedRouteFilters, getCalculatedStaticValues} from './VenListState';
import {getSelectorSettings, gridSettings} from './VenListConfig';
import gridSaga from 'components/Grid/GridSaga';
import {getUrlScopeValue} from 'components/Grid/GridSelectors';
import {fetchValidScopeLabels} from 'containers/Selectors/SelectorSaga';
import {fetchHealth} from 'containers/Health/HealthSaga';
import {
  fetchActiveVens,
  fetchErrorVens,
  fetchSuspendedVens,
  fetchPendingVens,
  fetchWarningVens,
} from 'containers/Ven/Upgrade/VenUpgradeSaga';
import {isEdge} from 'containers/App/AppState';

export function* fetchVens({ignoreScope = false, containerCluster, filter, scope, force = false} = {}) {
  const staticValues = yield select(getCalculatedStaticValues);
  const query = {representation: 'ven_labels'};

  if (containerCluster) {
    query.container_clusters = JSON.stringify([{href: containerCluster}]);
  } else if (scope && !ignoreScope) {
    // no scope for detail page
    query.xxxlabels = [scope.scope.map(obj => obj.href)];
  }

  const edgeEnabled = yield select(isEdge);

  const {scopeMap} = yield select(getSelectorSettings);

  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (staticValues.hasOwnProperty(name)) {
        // If there is more than one parameter to make up the query
        if (Array.isArray(staticValues[name][value])) {
          staticValues[name][value].forEach(filter => {
            query[filter.name] = filter.value;
          });
        } else if (staticValues[name][value]) {
          if (name === 'condition' && staticValues[name][value] === 'authentication_recovery') {
            query.authentication_recovery = true;
          } else {
            query[name] = staticValues[name][value];
          }
        } else {
          query[name] = value;
        }
      } else if (scopeMap.hasOwnProperty(name)) {
        // labels are the only supported type at this moment
        if (!scope) {
          // otherwise xxxlabels are already generated
          if (query.xxxlabels && Array.isArray(query.xxxlabels[0])) {
            query.xxxlabels[0].push(value.href);
          } else {
            query.xxxlabels = [[value.href]];
          }
        }
      } else if (name === 'container_clusters' && edgeEnabled) {
        query.container_clusters = JSON.stringify([value.href]);
      } else if (name === 'disconnected_before') {
        query.disconnected_before = new Date(value).toJSON();
      } else {
        query[name] = value;
      }
    }
  }

  return yield call(apiSaga, 'vens.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getVens)) || count !== (yield select(getVensCount))) {
        yield put({type: 'VEN_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchVenListAdditionalData() {
  yield all([
    call(fetchErrorVens, {force: true}),
    call(fetchActiveVens, {force: true}),
    call(fetchSuspendedVens, {force: true}),
    call(fetchPendingVens, {force: true}),
    call(fetchWarningVens, {force: true}),
  ]);
}

export function* fetchVenList(route, refetch = false, options = {}) {
  const {params} = route;
  const {
    getGridSettings = gridSettings,
    getFilters = getCalculatedRouteFilters,
    containerCluster,
    ignoreScope,
    customScope,
    customFilter,
  } = options;

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const scopeParams = yield select(getUrlScopeValue, params);
      let scope;

      if (customScope) {
        scope = customScope;
      } else {
        scope = scopeParams.isEmpty ? undefined : scopeParams.valid;
        yield call(fetchValidScopeLabels, scope);
      }

      const [{list}] = yield all([
        call(fetchVens, {
          containerCluster,
          ignoreScope,
          params,
          filter: filterParams.isEmpty ? customFilter : {...filterParams.valid, ...customFilter},
          scope,
          force: refetch,
        }),
        call(fetchHealth, {force: refetch}),
        spawn(fetchVenListAdditionalData),
      ]);

      return list.length;
    },
  });
}
