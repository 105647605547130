/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {tidUtils} from 'utils';
import type {IconName} from './IconName';

const files = require.context('../../icons', false, /.*\.svg$/);
export const fileNames: string[] = files.keys();

// Inline icons using svg-sprite-loader
fileNames.forEach(files);

export const tidByName = (icons: IconName[]): Map<IconName, string> =>
  new Map(icons.map(name => [name, tidUtils.getTid('comp-icon', name)]));

export const getTitle = (iconName: IconName): string => {
  switch (iconName) {
    case 'add':
      return intl('Common.Add');
    case 'remove':
      return intl('Common.Remove');
    case 'close':
      return intl('Common.Close');
    case 'back':
      return intl('Common.Back');
    case 'next':
      return intl('Common.Next');
    case 'comment':
      return intl('Common.Comment');
    case 'capslock':
      return intl('Common.Capslock');
    case 'delete':
      return intl('Common.Delete');
    case 'duplicate':
      return intl('Common.Duplicate');
    case 'export':
      return intl('Common.Export');
    case 'download':
      return intl('Common.Download');
    case 'import':
      return intl('Common.Import');
    case 'edit':
      return intl('Common.Edit');
    case 'email':
      return intl('Common.Email');
    case 'group':
      return intl('Common.Group');
    case 'help':
      return intl('Common.Help');
    case 'env':
      return intl('Labels.Environment');
    case 'key':
      return intl('PairingProfiles.KeyGen');
    case 'version':
      return intl('Common.Version');
    case 'illuminate':
      return intl('Common.Illumination');
    case 'illumination':
      return intl('Common.Illumination');
    case 'internet':
      return intl('Common.Internet');
    case 'info':
      return intl('Common.Information');
    case 'loc':
      return intl('Common.Location');
    case 'map':
      return intl('Map.Illumination');
    case 'menu':
      return intl('Menu.Navigation');
    case 'org':
      return intl('Common.Organization');
    case 'user':
      return intl('Users.User');
    case 'pause':
      return intl('Common.Pause');
    case 'play':
      return intl('Common.Play');
    case 'provision':
      return intl('Common.Provision');
    case 'revert':
      return intl('Common.Undo');
    case 'role':
      return intl('Role.Label');
    case 'expand-role':
      return intl('Role.Expand');
    case 'collapse-role':
      return intl('Role.Collapse');
    case 'save':
      return intl('Common.Save');
    case 'search':
      return intl('Common.Search');
    case 'secure-connect':
      return intl('Common.SecureConnect');
    case 'settings':
      return intl('Common.Settings');
    case 'stop':
      return intl('Common.Stop');
    case 'check':
      return intl('Common.Success');
    case 'warning':
      return intl('Common.Warning');
    case 'critical':
      return intl('Common.Critical');
    case 'arrow-left':
      return intl('Common.ArrowLeft');
    case 'arrow-right':
      return intl('Common.ArrowRight');
    case 'arrow-up':
      return intl('Common.ArrowUp');
    case 'arrow-down':
      return intl('Common.ArrowDown');
    case 'arrow-left-long':
      return intl('Common.ArrowLeftLong');
    case 'arrow-right-long':
      return intl('Common.ArrowRightLong');
    case 'filter':
      return intl('Common.Filter');
    case 'add-user':
      return intl('Users.Add');
    case 'app':
      return intl('Labels.Application');
    case 'app-group-map':
      return intl('Common.AppGroupMap');
    case 'server':
      return intl('Common.Server');
    case 'video':
      return intl('Common.Video');
    case 'zoom-to-fit':
      return intl('Common.ZoomToFit');
    case 'cancel':
      return intl('Common.Cancel');
    case 'sort-up':
    case 'sort-down':
      return intl('Common.Sort');
    case 'enforced':
      return intl('Common.Enforced');
    case 'unmanaged':
      return intl('Common.Unmanaged');
    case 'error':
      return intl('Common.Error');
    case 'online':
      return intl('Common.Online');
    case 'syncing':
      return intl('Workloads.Status.Syncing');
    case 'list':
      return intl('Common.BackToList');
    case 'workload':
      return intl('Common.Workload');
    case 'third-party':
      return intl('Common.ThirdParty');
    case 'pending':
      return intl('Common.Pending');
    case 'expand':
      return intl('Common.Expand');
    case 'move-tool':
      return intl('Common.Move');
    case 'selection-tool':
      return intl('Common.Select');
    case 'pair':
      return intl('PairingProfiles.Pair');
    case 'unpair':
      return intl('Common.Unpair');
    case 'copy':
      return intl('Common.Copy');
    case 'navigateup':
      return intl('Common.NavigateUp');
    case 'manage':
      return intl('LoadBalancers.Detail.Manage');
    case 'service':
      return intl('Common.Service');
    case 'enabled':
      return intl('Common.Enabled');
    case 'disabled':
    case 'disabled-status':
      return intl('Common.Disabled');
    case 'deny':
      return intl('Common.Deny');
    case 'stopped':
      return intl('Common.Stopped');
    case 'dashboard':
      return intl('Common.Dashboard');
    case 'allowlist':
      return intl('Common.IPList');
    case 'link':
      return intl('Common.Link');
    case 'unlink':
      return intl('Common.Unlink');
    case 'ven':
      return intl('Common.VEN');
    case 'admin':
      return intl('Role.Admin');
    case 'machine-auth':
      return intl('Common.MachineAuthentication');
    case 'non-brn':
      return intl('Rulesets.Rules.NonCorporateNetworks');
    case 'container-workload':
      return intl('Common.ContainerWorkload');
    default:
      return _.capitalize(_.replace(iconName, /-/g, ' '));
  }
};
