/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select, all, retry} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {getIPInstance} from './IPListItemState';
import {cachedResponses} from 'api/apiCache';
import {RedirectError, RequestError} from 'errors';
import _ from 'lodash';
import {getRouteParams} from 'containers/App/AppState';
import {isAPIAvailable} from 'api/apiUtils';

export function* createIPList(payload, pversion = 'draft') {
  return yield call(apiSaga, 'ip_lists.create', {
    params: {pversion},
    data: payload,
    *onDone() {
      // Clear list collection cache
      cachedResponses.removeByMethodName('ip_lists.get_collection');
      // invalidate rules of group
      cachedResponses.removeByMethodName('rule_sets.get_instance');
    },
  });
}

export function* verifyUserPermissions({name, params: {id, pversion}}) {
  if (name?.endsWith('.edit') && !isAPIAvailable('ip_list.update')) {
    throw new RedirectError({
      to: 'iplists.item.view',
      params: {id, pversion: pversion || 'draft'},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  if (name?.endsWith('.create') && !isAPIAvailable('ip_lists.create')) {
    throw new RedirectError({to: 'iplists.list', proceedFetching: true, thisFetchIsDone: true});
  }
}

export function* fetchIPItem({params: {id, pversion}, name}) {
  const version = Number(pversion);
  // pversion is numeric when navigating from policy version thus check for numeric
  // e.g. #/versions
  // When looking at /draft, /active then pversion is non-numeric
  const isOldVersion = !_.isNaN(pversion) && pversion > 0;
  // pversion cannot be draft when in edit
  const isEditPage = name && name.endsWith('.edit') && pversion !== 'draft';
  const isDraftPage = pversion === 'draft';

  // Get active: current active
  //     draft: current draft
  //     oldPversionObj: the numeric pversion (only valid when pverion is numeric)
  //     oldPrevPversionObj: get the previous version by minus one (only valid when pverion is numeric)
  // Note: pversion can be 'draft || active' or numeric value 1123
  const [{data: active}, {data: draft}, oldPversionObj, oldPrevPversionObj] = yield all([
    call(apiSaga, 'ip_lists.get_instance', {
      ignoreCodes: [404],
      params: {ip_list_id: id, pversion: 'active'},
    }),
    call(apiSaga, 'ip_lists.get_instance', {
      ignoreCodes: [404],
      params: {ip_list_id: id, pversion: 'draft'},
    }),
    ...(isOldVersion
      ? [
          call(apiSaga, 'ip_lists.get_instance', {
            ignoreCodes: [404],
            params: {ip_list_id: id, pversion: version},
          }),
        ]
      : []),
    ...(isOldVersion && pversion > 1
      ? [
          call(apiSaga, 'ip_lists.get_instance', {
            ignoreCodes: [404],
            params: {ip_list_id: id, pversion: version - 1},
          }),
        ]
      : []),
  ]);

  // If both draft and active are not invalid, redirect to the list page
  // draft and active is undefined for deleted policy object
  // Redirect to list page if pversion is invalid
  const validItem = oldPversionObj || oldPrevPversionObj || draft || active;
  const validPversion = isOldVersion || ['draft', 'active'].includes(pversion);

  if (!validItem || !validPversion || (isOldVersion && !oldPversionObj && !oldPrevPversionObj)) {
    throw new RedirectError({to: 'iplists.list', proceedFetching: true, thisFetchIsDone: true});
  }

  const cached = yield select(getIPInstance);

  const data = {
    active,
    draft,
    ...(oldPversionObj ? {oldPversionObj: oldPversionObj?.data} : {}),
    ...(oldPrevPversionObj ? {oldPrevPversionObj: oldPrevPversionObj?.data} : {}),
  };

  // Set the reducer for draft, active, oldPversionObj, oldPrevPversionObj which is used for Provision,
  // Edit, View pages
  if (
    !cached ||
    active !== cached.active ||
    draft !== cached.draft ||
    oldPversionObj?.data !== cached.oldPversion ||
    oldPrevPversionObj?.data !== cached.oldPrevPversion
  ) {
    yield put({type: 'IP_LISTS_GET_DETAIL', data});
  }

  // If there is no real draft (no pending changes), redirect to active version
  if (isDraftPage && !isEditPage && !draft.update_type) {
    throw new RedirectError({params: {id, pversion: 'active'}, proceedFetching: true, thisFetchIsDone: true});
  }

  return data;
}

export function* updateIPList(id, payload, pversion = 'draft') {
  yield call(apiSaga, 'ip_list.update', {
    params: {ip_list_id: id, pversion},
    data: payload,
    *onDone() {
      // Clear instance and list collection cache
      cachedResponses.removeByMethodName('ip_lists.get_instance');
      cachedResponses.removeByMethodName('ip_lists.get_collection');
      // invalidate rules of group
      cachedResponses.removeByMethodName('rule_sets.get_instance');
    },
  });
}

export function* deleteIPList() {
  const {id} = yield select(getRouteParams);

  yield call(apiSaga, 'ip_list.delete', {
    params: {ip_list_id: id, pversion: 'draft'},
    *onDone() {
      // Clear list collection cache
      cachedResponses.removeByMethodName('ip_lists.get_collection');
    },
  });
}

export function* fetchIpMatches({query, params, retries = 2}) {
  let errorMessage;

  try {
    // Call to get the matching query name
    const matches = yield retry(retries, 0, apiSaga, 'ip_lists.autocomplete', {params, query});

    return matches;
  } catch (error) {
    errorMessage = error;
  }

  // Throw error message when request fails
  throw new RequestError({
    message: errorMessage,
  });
}

export function* fetchAnyIPList() {
  return yield call(apiSaga, 'ip_lists.get_instance', {
    params: {ip_list_id: 'any', pversion: 'draft'},
  });
}
