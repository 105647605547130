/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';

export default {
  settings: (() => {
    const defaultSettings = {
      colorBlind: 'normal',
      explorerMaxResults: 5000,
      explorerMaxDownloadResults: 100_000,
      defaultPolicyVersion: 'reported',
    };

    return (state = defaultSettings, action) => {
      if (action.type === 'SET_USER_SETTINGS') {
        return _.defaultsDeep({}, action.data, defaultSettings);
      }

      return state;
    };
  })(),
};

export const getUserSettings = state => state.user.settings;

export const getColorBlindMode = createSelector(getUserSettings, settings => settings.colorBlind);
