/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {useCallback, useContext, useState} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {Button, Icon, MenuItem, MenuDelimiter, ModalMachineAuto} from 'components';
import {fetchRulesetItem, updateRuleset, removeRuleset} from '../RulesetItemSaga';
import RulesetSummaryEditModal from './RulesetSummaryEditModal';
import {getDuplicateRuleset, getErrorMessage} from '../RulesetItemUtils';
import styles from '../RulesetItem.css';
import {formatDataReference} from 'utils/dataValidation';

export default function RulesetActions(props) {
  const {
    actionButtonsDisabled,
    addScopeButtonDisabled,
    invokeDiscardChanges,
    pversionObj,
    rulesetId,
    pversion,
    onAddScope,
    hideScope,
    setErrors,
  } = props;

  const {fetcher} = useContext(AppContext);
  const [remove, setRemove] = useState();

  const handleRulesetStatusChange = useCallback(async () => {
    const abortAction = await invokeDiscardChanges();

    if (abortAction) {
      return;
    }

    try {
      const payload = {
        params: {pversion: 'draft', rule_set_id: rulesetId},
        data: {
          enabled: !pversionObj.enabled,
          ...(pversionObj.external_data_reference
            ? {external_data_reference: formatDataReference(pversionObj.external_data_reference)}
            : {}),
        },
      };

      await fetcher.spawn(updateRuleset, payload);
      await fetcher.fork(fetchRulesetItem.refetch);
    } catch (error) {
      setErrors([getErrorMessage(error)]);
    }
  }, [fetcher, pversionObj, rulesetId, invokeDiscardChanges, setErrors]);

  const handleRemoveRulesetClick = useCallback(async () => {
    const abortAction = await invokeDiscardChanges();

    if (abortAction) {
      return;
    }

    setRemove(remove => !remove);
  }, [invokeDiscardChanges]);

  const handleRemoveRuleset = useCallback(() => {
    // ModalMachineAuto performs error handling
    return fetcher.spawn(removeRuleset, {id: rulesetId});
  }, [fetcher, rulesetId]);

  const handleEdit = useCallback(
    async (onOpen, isDuplicate) => {
      const rulesetData = isDuplicate
        ? getDuplicateRuleset(pversionObj)
        : {
            name: pversionObj.name,
            description: pversionObj.description,
            external_data_reference: pversionObj.external_data_reference,
            rulesetId,
          };

      const abortAction = await invokeDiscardChanges();

      if (abortAction) {
        return;
      }

      onOpen(rulesetData);
    },
    [invokeDiscardChanges, pversionObj, rulesetId],
  );

  const renderRemoveConfirmation = () => {
    return (
      <ModalMachineAuto saga={handleRemoveRuleset} onClose={handleRemoveRulesetClick}>
        {{
          title: intl('Rulesets.Delete', {count: 1}),
          confirmMessage: intl('Rulesets.DeleteRuleset'),
          error: {
            title: intl('Rulesets.Delete', {count: 1}),
          },
        }}
      </ModalMachineAuto>
    );
  };

  const renderRulesetActionMenu = useCallback(
    onOpen => {
      return [
        <MenuItem
          tid="ruleset-edit"
          theme={styles}
          themePrefix="noIconMenuItem-"
          text={intl('Rulesets.EditRuleset')}
          onClick={_.partial(handleEdit, onOpen, false)}
        />,
        <MenuItem
          tid="ruleset-duplicate"
          text={
            <>
              <Icon name="duplicate" position="before" />
              {intl('Rulesets.DuplicateRuleset')}
            </>
          }
          onClick={_.partial(handleEdit, onOpen, true)}
        />,
        <MenuItem
          tid="ruleset-status"
          text={
            <>
              <Icon name={pversionObj.enabled ? 'disabled' : 'enabled'} position="before" />
              {pversionObj.enabled ? intl('Rulesets.DisableRuleset') : intl('Rulesets.EnableRuleset')}
            </>
          }
          onClick={handleRulesetStatusChange}
        />,
        <MenuItem
          tid="ruleset-remove"
          theme={styles}
          themePrefix="noIconMenuItem-"
          text={intl('Rulesets.RemoveRuleset')}
          onClick={handleRemoveRulesetClick}
        />,
        ...(!hideScope
          ? [
              <MenuDelimiter />,
              <MenuItem
                tid="scope-add"
                theme={styles}
                themePrefix="noIconMenuItem-"
                text={intl('Rulesets.Scopes.AddScope')}
                onClick={onAddScope}
                disabled={addScopeButtonDisabled}
              />,
            ]
          : []),
      ];
    },
    [
      pversionObj,
      handleRemoveRulesetClick,
      handleEdit,
      handleRulesetStatusChange,
      addScopeButtonDisabled,
      onAddScope,
      hideScope,
    ],
  );

  return (
    <>
      {pversion === 'draft' && (
        <RulesetSummaryEditModal>
          {({handleOpen}) => (
            <Button.Menu
              textIsHideable
              color="secondary"
              text={intl('Rulesets.RulesetActions')}
              disabled={actionButtonsDisabled}
              menuAlign="right"
              as="menu"
              menu={renderRulesetActionMenu(handleOpen)}
              tid="ruleset-actions"
            />
          )}
        </RulesetSummaryEditModal>
      )}
      {remove && renderRemoveConfirmation()}
    </>
  );
}
