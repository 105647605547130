/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {hrefUtils} from 'utils';
import {Link, Pill} from 'components';
import {getMethodParameterizedPath} from 'api/apiUtils';
import {createSelector} from 'reselect';
import {getId, isContainerClusterHref, isServiceAccountHref} from 'utils/href';
import stylesUtils from 'utils.css';
import {sortAndStringifyArray} from 'utils/general';
import * as GridUtils from 'components/Grid/GridUtils';
import styles from './RBACUtils.css';

//  This is a list of all possible roles that a user can have.
export const allRoles = [
  'owner',
  'admin',
  'read_only',
  'ruleset_manager',
  'limited_ruleset_manager',
  'ruleset_provisioner',
  'ruleset_viewer',
  'global_object_provisioner',
  'workload_manager',
];
export const allRolesSet = new Set(allRoles);

//  This is a list of the possible global roles. global roles are roles which must have all all all scope.
export const globalRoles = __ANTMAN__
  ? ['owner', 'admin', 'ruleset_manager', 'ruleset_provisioner', 'global_object_provisioner', 'read_only']
  : ['owner', 'admin', 'read_only', 'global_object_provisioner'];
export const globalRolesSet = new Set(globalRoles);

//  This is a list of all scoped roles which can allow all all all for their scope.
export const scopedAllRoles = ['ruleset_manager', 'ruleset_provisioner', 'ruleset_viewer', 'workload_manager'];
export const scopedAllRolesSet = new Set(scopedAllRoles);

// This is used to handle change for a selector to determine if value is chosen. This is default order
// for scope. Please don't change the order.
export const labelsSelectorValues = ['app', 'env', 'loc'];

export const allScopeIdString = 'labels.app!labels.env!labels.loc';

// This is a mapping for the labels
export const allLabelDataMap = createSelector([], () => ({
  app: {
    categoryKey: 'all_applications',
    value: intl('Common.AllApplications'),
  },
  env: {
    categoryKey: 'all_environments',
    value: intl('Common.AllEnvironments'),
  },
  loc: {
    categoryKey: 'all_locations',
    value: intl('Common.AllLocations'),
  },
}));

export const categoryKeyCollection = [
  allLabelDataMap().app.categoryKey,
  allLabelDataMap().env.categoryKey,
  allLabelDataMap().loc.categoryKey,
];

//  This object maps role names to their intl strings
export const roleStrings = createSelector([], () => ({
  read_only: intl('Role.GlobalReadOnly'),
  admin: intl('Role.GlobalAdmin'),
  owner: intl('Role.GlobalOrgOwner'),
  ruleset_manager: intl('Role.RulesetManager'),
  limited_ruleset_manager: intl('Role.LimitedRulesetManager'),
  ruleset_provisioner: intl('Role.RulesetProvisioner'),
  ruleset_viewer: intl('Role.RulesetViewer'),
  global_object_provisioner: intl('Role.GlobalPolicyObjectProvisioner'),
  workload_manager: intl('Role.WorkloadManager'),
}));

export const globalRolesArray = [
  intl('Role.GlobalReadOnly'),
  intl('Role.GlobalAdmin'),
  intl('Role.GlobalOrgOwner'),
  intl('Role.GlobalPolicyObjectProvisioner'),
];

export const getLabelObject = label => label.label || label.label_group || label;
export const getLabelObjects = scope => (Array.isArray(scope) ? scope.map(label => getLabelObject(label)) : []);
export const getScopeAll = () => [];

export const roleSortValues = allRoles.reduce((roleSortValues, role) => {
  if (!(role in roleSortValues)) {
    roleSortValues[role] = Object.values(roleStrings()).sort().indexOf(roleStrings()[role]);
  }

  return roleSortValues;
}, {});

/*
 *  This is a compare function used to sort lists of roles by the order given in allRoles
 */
export const roleSort = (a, b) => {
  if (allRoles.indexOf(a) > allRoles.indexOf(b)) {
    return 1;
  }

  if (allRoles.indexOf(a) < allRoles.indexOf(b)) {
    return -1;
  }

  if (allRoles.indexOf(a) === allRoles.indexOf(b)) {
    return 0;
  }
};

export const getRolesString = roles =>
  roles
    .sort(roleSort)
    .map(role => roleStrings()[role])
    .join(', ');

export const getScopeHrefsFromId = (orgId, scopeId, labelsMap) =>
  Array.isArray(scopeId)
    ? scopeId.reduce((res, labelObject) => {
        if (res === 'invalid' || !_.isPlainObject(labelObject) || !labelObject.id) {
          return 'invalid';
        }

        const {type: labelType, id: labelId} = labelObject;

        if (labelType && labelId && ['labels', 'label_groups'].includes(labelType)) {
          const isLabel = labelType === 'labels';
          const method = isLabel ? 'labels.get_instance' : 'label_groups.get_instance';
          const params = {
            ...(isLabel && {label_id: labelId}),
            ...(!isLabel && {pversion: 'active', label_group_id: labelId}),
          };

          const labelHref = getMethodParameterizedPath({orgId, params, method});

          if (labelsMap) {
            const label = labelsMap[labelHref];

            if (!labelsSelectorValues.includes(label.key)) {
              return 'invalid';
            }
          }

          res.push(labelHref);
        } else {
          return 'invalid';
        }

        return res;
      }, [])
    : 'invalid';

export const getScopeIdString = scopeId =>
  Array.isArray(scopeId)
    ? scopeId.length
      ? _.orderBy(scopeId, 'key').reduce(
          (result, {type, key, id}) => `${result}${result ? '!' : ''}${type}.${key}.${id}`,
          '',
        )
      : allScopeIdString
    : '';

/*
 *  This function takes a scope list and converts it to the scopd id format used for RBAC pages
 *  The format is label id # for any specified label, or all for any unspecified labels, joined by '-'
 *  i.e. if scope had app label id = 2, env label id = 3 and no loc label, the returned scopeId would be:
 *  2-3-all. labelsMap is not required when scope has the value and key properties of the labels
 */
export const getScopeId = scope => {
  const labelIds = getLabelObjects(scope).reduce((res, label) => {
    if (label?.key && label?.href) {
      const type = label.href.includes('label_groups') ? 'label_groups' : 'labels';

      // Use label.key for mapping
      return {...res, [label.key]: {id: hrefUtils.getId(label.href), type}};
    }

    return res;
  }, {});

  return Object.entries(labelIds).map(([key, {id, type}]) => ({key, id, type}));
};

export const getScopeIdStringFromScope = scope => getScopeIdString(getScopeId(scope));

/*
 *  This function takes a scopeHrefs list and converts it to a string w/ all the label's values
 *  This is commonly used to display scopes inside <Pill.Label> components in the RBAC pages.
 *  Note: labelsMap is optiinal
 */
export const getScopeString = (scope, labelsMap) => {
  const allString = intl('Common.All');
  const labelValues = getLabelObjects(scope).reduce(
    (res, labelHref) => {
      const scopeLabel = (labelsMap && labelsMap[labelHref]) || labelHref;

      if (scopeLabel) {
        if (scopeLabel.key === undefined && scopeLabel.value) {
          // assign scopeLabel.key when key is undefined for new labels
          res.scopeLabel.key = scopeLabel.value;
        } else {
          res[scopeLabel.key] = scopeLabel.value || scopeLabel.name;
        }
      }

      if (scopeLabel && scopeLabel.key === undefined && scopeLabel.value) {
        res.scopeLabel.key = scopeLabel.value;
      } else if (scopeLabel && scopeLabel.key) {
        res[scopeLabel.key] = scopeLabel.value || scopeLabel.name;
      }

      return res;
    },
    {app: allString, env: allString, loc: allString},
  );

  return `${labelValues.app} | ${labelValues.env} | ${labelValues.loc}`;
};

/*
 * Get the default scope string name
 */
export const getDefaultScopeString = createSelector([], () => {
  const allString = intl('Common.All');

  return `${allString} | ${allString} | ${allString}`;
});

export const getScopeLabel = (scopeHrefs, labelsMap) => (
  <Pill.Label type="scope">{getScopeString(scopeHrefs, labelsMap)}</Pill.Label>
);

export const getAuthSecPrincipalCountStrings = (localUserCount, externalUserCount, externalGroupCount) => {
  const finalCountString = [];

  const localUserString = intl('RBAC.PrincipalsTypeCount.LocalUsers', {count: localUserCount});
  const externalUserString = intl('RBAC.PrincipalsTypeCount.ExternalUsers', {count: externalUserCount});
  const externalGroupString = intl('RBAC.PrincipalsTypeCount.ExternalGroups', {count: externalGroupCount});

  if (localUserCount > 0) {
    finalCountString.push(localUserString);
  }

  if (externalUserCount > 0) {
    finalCountString.push(externalUserString);
  }

  if (externalGroupCount > 0) {
    finalCountString.push(externalGroupString);
  }

  return {
    localUserString,
    externalUserString,
    externalGroupString,
    authSecPrincipalString: finalCountString.join(', '),
  };
};

export const getRoleHref = (orgId, roleName) =>
  getMethodParameterizedPath({
    orgId,
    params: {role_name: roleName},
    method: 'roles.get_instance',
  });

export const getRoleHrefs = (roles, orgId) => roles.map(role => getRoleHref(orgId, role));

/*
 *  This function takes a list of authSecPrincipalHrefs and returns different counts / formatted strings based on the
 *  type (user, group) and usertype (local, external) of the input authSecPrincipals
 */
export const getAuthSecPrincipalBreakdown = (authSecPrincipalHrefs, authSecPrincipalsMap) => {
  const breakdown = authSecPrincipalHrefs.reduce(
    (res, authSecPrincipalHref) => {
      const authSecPrincipal = authSecPrincipalsMap[authSecPrincipalHref];

      res.authSecPrincipalNames.add(authSecPrincipal.name);

      if (authSecPrincipal.type === 'user') {
        res.userCount += 1;

        if (authSecPrincipal.usertype === 'local') {
          res.localUserCount += 1;
          res.localUserAuthSecPrincipalHrefs.add(authSecPrincipalHref);
          res.localUserNames.add(authSecPrincipal.name);
        } else if (authSecPrincipal.usertype === 'external') {
          res.externalUserCount += 1;
          res.externalUserAuthSecPrincipalHrefs.add(authSecPrincipalHref);
          res.externalUserNames.add(authSecPrincipal.name);
        }
      } else if (authSecPrincipal.type === 'group') {
        res.externalGroupCount += 1;
        res.externalGroupAuthSecPrincipalHrefs.add(authSecPrincipalHref);
        res.externalGroupNames.add(authSecPrincipal.name);
      }

      return res;
    },
    {
      localUserCount: 0,
      externalUserCount: 0,
      externalGroupCount: 0,
      userCount: 0,
      localUserNames: new Set(),
      externalUserNames: new Set(),
      externalGroupNames: new Set(),
      authSecPrincipalNames: new Set(),
      localUserAuthSecPrincipalHrefs: new Set(),
      externalUserAuthSecPrincipalHrefs: new Set(),
      externalGroupAuthSecPrincipalHrefs: new Set(),
    },
  );

  return {
    ...breakdown,
    totalAuthSecPrincipalCount: authSecPrincipalHrefs.length,
    ...getAuthSecPrincipalCountStrings(
      breakdown.localUserCount,
      breakdown.externalUserCount,
      breakdown.externalGroupCount,
    ),
  };
};

export const getPermissionBreakdown = (permissionHrefs, permissionsMap, authSecPrincipalsMap) => {
  const breakdown = permissionHrefs.reduce(
    (res, permissionHref) => {
      const permission = permissionsMap[permissionHref];

      res.scopeIds.add(permission.scopeId);
      res.scopeStrings.add(permission.scopeString);
      // scopeHrefs are arrays of labelHrefs. We join the scopeHrefs into a string so that duplicates won't be added to the set.
      // in the return for getPermissionBreakdown, we will convert the unique set of scopeHrefs strings back into scopeHrefs arrays.
      res.stringifiedScopeHrefs.add(JSON.stringify(permission.scopeHrefs));
      res.authSecPrincipalHrefs.add(permission.authSecPrincipalHref);
      res.roles.add(permission.role);
      res.roleHrefs.add(permission.roleHref);
      res.roleCounts[permission.role] += 1;
      res.scope = getLabelObjects(permission.scope);

      if (globalRolesSet.has(permission.role)) {
        res.globalRoles.add(permission.role);
        res.globalRoleHrefs.add(permission.roleHref);
      } else {
        res.scopedRoles.add(permission.role);
        res.scopedRoleHrefs.add(permission.roleHref);
      }

      return res;
    },
    {
      scopeIds: new Set(),
      scopeStrings: new Set(),
      stringifiedScopeHrefs: new Set(),
      authSecPrincipalHrefs: new Set(),
      roles: new Set(),
      roleHrefs: new Set(),
      globalRoles: new Set(),
      globalRoleHrefs: new Set(),
      scopedRoles: new Set(),
      scopedRoleHrefs: new Set(),
      roleCounts: {
        ...allRoles.reduce((res, role) => ({...res, [role]: 0}), {}), // a count for each individual role name
      },
      scope: [],
    },
  );

  return {
    ...breakdown,
    scopeHrefs: [...breakdown.stringifiedScopeHrefs].map(stringifiedScopeHrefs => JSON.parse(stringifiedScopeHrefs)),
    rolesString: getRolesString([...breakdown.roles]),
    ...getAuthSecPrincipalBreakdown([...breakdown.authSecPrincipalHrefs], authSecPrincipalsMap),
  };
};

/*
 * Filter only these three values: ['key', 'value', 'href'].
 * @labelsMaps: Object - label list
 */
export const filterLabelMaps = (labelsMap = {}) =>
  Object.keys(labelsMap).reduce((item, curItem) => {
    item[curItem] = _.pick(labelsMap[curItem], ['key', 'value', 'name', 'href']);

    return item;
  }, {});

/*
 * This function is used to merge the scopes data with a scope label type: 'app', etc...
 * to be used as a object to create <Pill.Label>
 * @scopes: Set - A set of scope labels e.g. 'All | All | All'
 */
export const mergeScopeToLabels = scopes => {
  // scopes is assumed to have index: 0 is app, index: 1 is loc, index: 2: env
  const scopeValue = scopes.values().next().value;
  const scopeLabels = (scopeValue && scopeValue.split(' | ')) || [];
  const scopeLabelTypes = ['app', 'env', 'loc'];
  const mergedLabels = [];

  // length needs to match
  if (scopeLabels.length !== scopeLabelTypes.length) {
    return mergedLabels;
  }

  let i = 0;

  while (i < scopeLabels.length) {
    // merge to create a type and label
    // e.g. [{type: 'app', label: 'All Applications'}]
    const label = scopeLabels[i] === 'All' ? allLabelDataMap()[scopeLabelTypes[i]].value : scopeLabels[i];

    mergedLabels.push({label, type: scopeLabelTypes[i]});
    i++;
  }

  return mergedLabels;
};

/* Finalize the proper scope hrefs data structure for sending to API
 * @finalScopeHrefs: Set(string) - scope hrefs to update
 */
export const processScopeHrefs = finalScopeHrefs => {
  const allData = [
    allLabelDataMap().app.categoryKey,
    allLabelDataMap().loc.categoryKey,
    allLabelDataMap().env.categoryKey,
  ];

  // exclude 'all_[application, location, environment]' from the finalScopes to process.
  // Server side API doesn't accept any scope 'all_[application, location, environment]' information.
  // e.g.
  // finalScopeHrefs = new Set(["/orgs/1/labels/18", "/orgs/1/labels/10", "all_locations"])
  // finalScopes = ["/orgs/1/labels/18", "/orgs/1/labels/10"] - only pass in non-all scope declarations
  const finalScopes = [...finalScopeHrefs].filter(scope => !allData.includes(scope));

  return new Set(finalScopes);
};

/*
 * Sort roles in alphabetical order. 'Global Read Only' role will be placed first
 * in the list.
 */
export const sortRoles = roles => {
  if (!Array.isArray(roles)) {
    return roles;
  }

  return [...roles].sort((a, b) =>
    (a > b && a !== intl('Role.GlobalReadOnly')) || (a < b && b === intl('Role.GlobalReadOnly')) ? 1 : -1,
  );
};

export const sortRolesHref = row => {
  if (!Array.isArray(row.roles)) {
    return row.roles;
  }

  const roleWithHref = row.roles.map((role, index) => {
    if (globalRolesArray.includes(role)) {
      return {label: role, href: row.roleHrefs[index].href};
    }

    return {label: role};
  });

  return roleWithHref.sort((a, b) =>
    (a.label > b.label && a.label !== intl('Role.GlobalReadOnly')) ||
    (a.label < b.label && b.label === intl('Role.GlobalReadOnly'))
      ? 1
      : -1,
  );
};

export const getRolesLink = roles => {
  return roles.reduce((result, role, index) => {
    if (role.href) {
      result.push(
        <Link to="rbac.roles.global.detail" params={{id: getId(role.href)}}>
          {role.label}
        </Link>,
      );
    } else {
      result.push(role.label);
    }

    if (index < roles.length - 1) {
      result.push(<span>, </span>);
    }

    return result;
  }, []);
};

export const formatRoles = roles => {
  if (!Array.isArray(roles)) {
    return roles;
  }

  return roles.map(role => role.label).join(', ');
};

export const getRoleFromHref = href => hrefUtils.getId(href);
export const getFormattedRoleFromHref = href => roleStrings()[getRoleFromHref(href)];
export const isGlobalRoleHref = href => globalRoles.includes(getRoleFromHref(href));
export const isHrefLabelGroups = href => href?.includes('label_groups');
export const areScopesEqual = (src, dest) =>
  sortAndStringifyArray(src, ['key']) === sortAndStringifyArray(dest, ['key']);
export const getFormattedUserActivityRole = (href, isDefaultReadOnly) =>
  isDefaultReadOnly ? intl('RBAC.DefaultReadOnly') : roleStrings()[getRoleFromHref(href)];
export const getFormattedScope = scope =>
  _.orderBy(
    scope.map(({href, key, value, name}) => ({href, key, value: value ?? name})),
    'key',
  );
export const getRolesFromPermissions = permissions =>
  permissions.reduce((result, permission) => {
    const isGlobalRole = isGlobalRoleHref(permission.role.href);
    const scope = getLabelObjects(permission.scope);
    const foundScope = result.find(
      item => areScopesEqual(item.scope, scope) && isGlobalRole === (item.type === 'global'),
    );
    const role = getFormattedUserActivityRole(permission.role.href);

    if (foundScope) {
      // Don't append the same role
      if (!foundScope.roles.includes(role)) {
        foundScope.roles.push(role);
      }
    } else {
      const type = isGlobalRole ? 'global' : 'scoped';

      result.push({
        key: `${type}${getScopeIdStringFromScope(scope)}`,
        type,
        scope,
        labels: GridUtils.getLabelsMap(scope),
        roles: [role],
      });
    }

    return result;
  }, []);

export const getScopeValue = (scope = []) =>
  scope
    .reduce(
      (res, scopeLabel) => {
        if (scopeLabel.key === 'app') {
          res[0] = scopeLabel.value;
        } else if (scopeLabel.key === 'env') {
          res[1] = scopeLabel.value;
        } else if (scopeLabel.key === 'loc') {
          res[2] = scopeLabel.value;
        }

        return res;
      },
      [intl('Common.All'), intl('Common.All'), intl('Common.All')],
    )
    .join(' | ');

/** Fill user details (username, full_name)
 *  @param {Object} obj :user object with href prop
 *  @param {Map<any, any>} usersMap :user list
 */
export const fillUserInfo = (usersMap = new Map(), userObj, {key = 'href'} = {}) => {
  let result = {id: -1, full_name: 'Unknown', username: 'Unknown'};

  if (userObj) {
    const user = usersMap.get(userObj[key]);

    if (user) {
      result = {id: user.id, href: user.href, full_name: user.full_name, username: user.username, type: user.type};
    } else if (userObj.href === '/users/0') {
      result = {...userObj, id: 0, full_name: 'System', username: 'System'};
    } else if (isContainerClusterHref(userObj.href)) {
      // Usually, it is a user object with href.
      // With the container work, objects can be provisioned by Kubelink.
      // For this special case, 'Container Cluster' is displayed, instead of Unknown.
      result = {
        id: -1,
        href: userObj.href,
        full_name: intl('Menu.ContainerClusters', {multiple: false}),
        username: intl('Menu.ContainerClusters', {multiple: false}),
      };
    } else if (isServiceAccountHref(userObj.href)) {
      result = {
        id: getId(userObj.href),
        href: userObj.href,
        type: 'serviceAccount',
        full_name: userObj.name,
        username: userObj.name,
      };
    } else {
      result = {...userObj, id: -1, full_name: 'Unknown', username: 'Unknown'};
    }
  }

  return result;
};

export const getLabelsQueryParam = scopeHrefs =>
  Array.isArray(scopeHrefs)
    ? scopeHrefs.map(href => ({...(isHrefLabelGroups(href) ? {label_group: {href}} : {label: {href}})}))
    : '';
export const formatErrorData = errorData =>
  (errorData?.token && intl(`ErrorsAPI.err:${errorData.token}`)) || errorData?.message;
export const getLabelsHrefs = labels => labels.map(label => label.href);
export const getScopeLabelPills = (labels = []) =>
  Array.isArray(labels) ? (
    labels.length ? (
      labels.map(label => (
        <Pill.Label key={label.key} type={label.key} group={isHrefLabelGroups(label.href)} href={label.href}>
          {label.value || label.name}
        </Pill.Label>
      ))
    ) : (
      <Pill.Label type="scope">{intl('Common.All')}</Pill.Label>
    )
  ) : (
    ''
  );
export const getScopeLabelsArrayFromMap = labelsMap =>
  labelsSelectorValues.reduce((result, key) => {
    if (labelsMap.has(key)) {
      result.push(labelsMap.get(key));
    }

    return result;
  }, []);
export const getStyledScopeLabelPills = value => (
  <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapHorizontalWrap}`}>{getScopeLabelPills(value)}</div>
);
export const scopeColumn = {
  header: intl('Common.Scopes'),
  role: {
    header: intl('Common.Role'),
    ...GridUtils.clickableLabelColumn,
    value: ({row}) => row.labels.role,
  },
  app: {
    header: intl('Common.Application'),
    ...GridUtils.clickableLabelColumn,
    value: ({row}) => row.labels.app,
  },
  env: {
    header: intl('Common.Environment'),
    ...GridUtils.clickableLabelColumn,
    value: ({row}) => row.labels.env,
  },
  loc: {
    header: intl('Common.Location'),
    ...GridUtils.clickableLabelColumn,
    value: ({row}) => row.labels.loc,
  },
  templates: ['role', 'app', 'env', 'loc'],
  format: ({row, content}) => (
    <div key="scope" className={styles.scope} data-tid="comp-grid-column-scope">
      {row.scope.length === 0 ? <Pill.Label type="scope">{intl('Common.All')}</Pill.Label> : content}
    </div>
  ),
};
