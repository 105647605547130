/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Component, createElement, Fragment} from 'react';
import {connect} from 'react-redux';
import {reactUtils, hrefUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import {fetchWorkloadList, startWorkloadsPolling, stopWorkloadsPolling} from './WorkloadListSaga';
import {getMaxPageNotificationList} from 'components/Grid/GridUtils';
import {applyPolicyWorkloads, updatePolicyState, increaseTrafficUpdateRate} from '../WorkloadSaga';
import {getWorkloadsPage} from './WorkloadListState';
import {enforcementIntl, visibilityIntl, groupErrorsByTokens} from '../WorkloadUtils';
import {
  Button,
  ButtonRefresh,
  Grid,
  ToolBar,
  ToolGroup,
  MenuItem,
  Notifications,
  Pill,
  MenuDelimiter,
  Modal,
  TypedMessages,
  Drawer,
  AttributeList,
  CopyButton,
  StatusIcon,
  TextLabel,
  Cutout,
  TabPanel,
} from 'components';
import {enforcementVisibility} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import ModalContent from 'components/Modal/ModalContent';

import {HeaderProps, ReportButtons} from 'containers';
import {ComboSelect} from 'containers/Selectors';
import WorkloadRemove from '../Remove/WorkloadRemove';
import EditLabels from '../EditLabels/EditLabels';
import styles from './WorkloadList.css';
import stylesUtils from 'utils.css';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import {AppContext} from 'containers/App/AppUtils';
import VenUpgradeConditions from 'containers/Ven/Upgrade/VenUpgradeConditions';
import PairingProfileReducers from 'containers/PairingProfile/PairingProfileState';
import WorkloadReducers from '../WorkloadState';
import {cachedResponses} from 'api/apiCache';
import {transitionStep, hideOnboarding} from 'antman/containers/Onboarding/OnboardingSaga';
import TooltipTour from 'antman/components/TooltipTour';
import OnboardingGuide from 'antman/containers/Onboarding/Guide/OnboardingGuide';
import OnboardingModalCSVImport from 'antman/containers/Onboarding/Modal/OnboardingModalCSVImport';
import WorkloadCSVImport from '../CSVImport/WorkloadCSVImport';

const buttonsTheme = {textIsHideable: styles['button-textIsHideable']};
const removeRowHighLight = {className: stylesGridUtils.rowToRemove};
const applyPolicyRowHighLight = {className: stylesGridUtils.rowToApplyPolicy};
const scopeFilterKeys = ['role', 'app', 'env', 'loc'];

const getEmptyState = props => ({
  ...(__ANTMAN__ && {
    copied: false,
    osType: 'windows',
    isDrawerClosed: props.currentStep !== null,
    isAutoRefreshing: props.currentStep === 'ONBOARDING_WORKLOADS',
  }),
  extraPropsKeyMap: new Map(),
  selectedKeySet: new Set(),
  selectedEnforcement: {
    idle: new Set(),
    visibility_only: new Set(),
    ...(!props.edgeEnabled && {selective: new Set()}),
    full: new Set(),
  },
  selectedVisibility: {
    all: new Set(),
    flow_off: new Set(),
    flow_drops: new Set(),
    flow_summary: new Set(),
    enhanced_data_collection: new Set(),
  },
  selectedKeySetWithVen: new Set(),
  selectedKeySetUnmanaged: new Set(),
  selectedKeySetStaticPolicy: new Set(),
  policyState: null,
  applyPolicy: null,
  enforcement: null,
  visibility: null,
  increasingTrafficUpdateRate: false,
  increaseTrafficUpdateRateError: null,
});

@connect((state, props) => getWorkloadsPage(state, props))
export default class WorkloadList extends Component {
  static prefetch = fetchWorkloadList;
  static contextType = AppContext;
  static reducers = __ANTMAN__ ? [PairingProfileReducers, WorkloadReducers] : [WorkloadReducers];

  static propTypes = {
    gridOffset: PropTypes.string, // Custom Grid offset
    noScope: PropTypes.bool, // Ignore scope and treat labels filter as any other filter
    hideHeaderProps: PropTypes.bool, // Hide <HeaderProps>
    hideAdd: PropTypes.bool, // Hide add button
    hideRemove: PropTypes.bool, // Hide remove button
    hideEditLabels: PropTypes.bool, // Hide labels editing button
    hidePolicy: PropTypes.bool, // Hide policy button
    hidePolicyApply: PropTypes.bool, // Hide policy apply button
    hidePolicyApplyAll: PropTypes.bool, // Hide apply to all option on policy apply button
    hideReports: PropTypes.bool, // Hide reports button
    showGlobalLink: PropTypes.bool, // Show button that leads to all workloads
    hideGroupColumn: PropTypes.bool, // hide group column on edge group dashboard
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState(props);
    this.enforcementIntl = enforcementIntl();
    this.visibilityIntl = visibilityIntl();
    this.renderAddMenu = this.renderAddMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCSVImportApply = this.handleCSVImportApply.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleAutoRefresh = this.handleAutoRefresh.bind(this);
    this.handleAutoRefreshCancel = this.handleAutoRefreshCancel.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.renderEnforcementMenu = this.renderEnforcementMenu.bind(this);
    this.renderVisibilityMenu = this.renderVisibilityMenu.bind(this);
    this.handleEnforcementClose = this.handleEnforcementClose.bind(this);
    this.handleVisibilityClose = this.handleVisibilityClose.bind(this);
    this.handleEnforcementConfirm = this.handleEnforcementConfirm.bind(this);
    this.handleVisibilityConfirm = this.handleVisibilityConfirm.bind(this);
    this.handleIncreaseTrafficUpdateRate = this.handleIncreaseTrafficUpdateRate.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleOnsetPairingWorkloadsPairField = this.handleOnsetPairingWorkloadsPairField.bind(this);
    this.renderPairingScripts = this.renderPairingScripts.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);

    for (const state of Object.keys(this.state.selectedEnforcement)) {
      this[`handleEnforcementSelect${state}`] = this.handleEnforcementSelect.bind(this, state);
      this[`handleEnforcementFocus${state}`] = this.handleEnforcementFocus.bind(this, state);
    }

    for (const state of Object.keys(this.state.selectedVisibility)) {
      this[`handleVisibilitySelect${state}`] = this.handleVisibilitySelect.bind(this, state);
      this[`handleVisibilityFocus${state}`] = this.handleVisibilityFocus.bind(this, state);
    }

    // this.handlePolicyStateMenuClose = this.handlePolicyStateMenuClose.bind(this);
    this.handleEnforcementMenuClose = this.handleEnforcementMenuClose.bind(this);
    this.handleVisibilityMenuClose = this.handleVisibilityMenuClose.bind(this);

    this.renderApplyPolicyMenu = this.renderApplyPolicyMenu.bind(this);
    this.handleApplyPolicy = this.handleApplyPolicy.bind(this);
    this.handleApplyPolicyAll = this.handleApplyPolicyAll.bind(this);
    this.handleApplyPolicyClose = this.handleApplyPolicyClose.bind(this);
    this.handleApplyPolicyConfirm = this.handleApplyPolicyConfirm.bind(this);

    this.handleRemoveHover = this.handleRemoveHover.bind(this);
    this.handleRemoveDone = this.handleRemoveDone.bind(this);

    this.handleEditLabelsDone = this.handleEditLabelsDone.bind(this);

    this.handleActionDone = this.handleActionDone.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentDidMount() {
    if (__ANTMAN__ && this.state.isAutoRefreshing) {
      this.handleAutoRefresh();
    }
  }

  componentWillUnmount() {
    if (__ANTMAN__) {
      this.handleAutoRefreshCancel();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.grid.rows !== prevState.rows || nextProps.currentStep !== prevState.currentStep) {
      const nextState = {
        rows: nextProps.grid.rows,
        rowsMap: nextProps.grid.rowsMap,
        currentStep: nextProps.currentStep,
      };

      if (nextProps.currentStep !== prevState.currentStep) {
        if (nextProps.currentStep === 'ONBOARDING_WORKLOAD_PAIRED') {
          nextState.isDrawerClosed = true;
        } else if (nextProps.currentStep === 'ONBOARDING_COPY_PAIRING_SCRIPT') {
          nextState.isDrawerClosed = false;
        }
      }

      if (prevState.policyState || prevState.applyPolicy || prevState.enforcement || prevState.visibility) {
        // If we are changing items and refetch is done, we can close modal
        Object.assign(nextState, getEmptyState(nextProps));
      } else if (prevState.selectedKeySet.size > 0) {
        nextState.selectedKeySet = new Set();
        nextState.selectedKeySetWithVen = new Set();
        nextState.selectedEnforcement = {
          idle: new Set(),
          visibility_only: new Set(),
          ...(!nextProps.edgeEnabled && {selective: new Set()}),
          full: new Set(),
        };
        nextState.selectedVisibility = {
          flow_off: new Set(),
          flow_drops: new Set(),
          flow_summary: new Set(),
          all: new Set(),
          enhanced_data_collection: new Set(),
        };
        nextState.selectedKeySetUnmanaged = new Set();
        nextState.selectedKeySetStaticPolicy = new Set();

        for (const row of nextProps.grid.rows) {
          if (row.selectable && prevState.selectedKeySet.has(row.key)) {
            nextState.selectedKeySet.add(row.key);

            if (row.unmanaged) {
              nextState.selectedKeySetUnmanaged.add(row.key);
            } else {
              nextState.selectedKeySetWithVen.add(row.key);

              if (nextState.selectedEnforcement[row.enforcement] && !row.unmanaged) {
                nextState.selectedEnforcement[row.enforcement].add(row.key);
              }

              if (row.enforcement === 'visibility_only') {
                if (row.hasEnhancedDataCollection) {
                  nextState.selectedVisibility.enhanced_data_collection.add(row.key);
                  nextState.selectedVisibility.all.add(row.key);
                }

                if (row.visibility !== 'flow_summary') {
                  nextState.selectedVisibility.flow_summary.add(row.key);
                  nextState.selectedVisibility.all.add(row.key);
                }
              } else if (row.enforcement === 'full') {
                nextState.selectedVisibility.all.add(row.key);
                nextState.selectedVisibility.flow_summary.add(row.key);
                nextState.selectedVisibility.flow_off.add(row.key);
                nextState.selectedVisibility.flow_drops.add(row.key);
                nextState.selectedVisibility[row.visibility].delete(row.key);

                if (row.hasEnhancedDataCollection) {
                  nextState.selectedVisibility.enhanced_data_collection.add(row.key);
                }
              } else if (row.enforcement === 'selective') {
                if (row.hasEnhancedDataCollection) {
                  nextState.selectedVisibility.enhanced_data_collection.add(row.key);
                  nextState.selectedVisibility.all.add(row.key);
                }

                if (row.visibility !== 'flow_summary') {
                  nextState.selectedVisibility.flow_summary.add(row.key);
                  nextState.selectedVisibility.all.add(row.key);
                }
              }
            }

            if (row.staticPolicy) {
              nextState.selectedKeySetStaticPolicy.add(row.key);
            }
          }
        }
      }

      return nextState;
    }

    return null;
  }

  // Get Pairing Scripts
  getPairingScripts() {
    const {activationCode, workloadOsFamilies, currentStep} = this.props;

    const {osType, copied, isDrawerClosed} = this.state;

    let pairingScriptsAttributes = [];

    if (activationCode.activation_code) {
      pairingScriptsAttributes = [
        {
          divider: true,
        },
        {
          value: (
            <>
              <StatusIcon status="warning" noTextColor />
              {intl('PairingProfiles.TLSWarning')}
            </>
          ),
          valueGap: 'gapSmall gapHorizontal',
        },
      ];

      const venOSTitle = {
        linux: intl('PairingProfiles.Workloads.Linux'),
        windows: intl('PairingProfiles.Workloads.Windows'),
        darwin: intl('PairingProfiles.Workloads.Darwin'),
      };

      for (const venOS of workloadOsFamilies) {
        const pairingPolicyCommand = venOS.pairing_policy.replace('{{pairing_key}}', activationCode.activation_code);

        if (
          (osType === 'linux' && venOS.os_family_id.toLowerCase() === 'linux') ||
          (osType === 'windows' && venOS.os_family_id.toLowerCase() === 'windows')
        ) {
          pairingScriptsAttributes.push({
            tid: venOS.os_family_id,
            // os_family_id from the backend can either be ['Linux','linux','Windows','windows'] thus set to lower case
            key: <TextLabel title={venOSTitle[venOS.os_family_id.toLowerCase()]} paddingTop="var(--5px)" />,
            value: (
              <div className={styles.command}>
                <div data-private className={styles.text}>
                  {pairingPolicyCommand}
                </div>
                <CopyButton
                  copyText={pairingPolicyCommand}
                  onCopy={this.handleCopy}
                  tooltipProps={{
                    interactive: true,
                    content: intl('Antman.Onboarding.WorkloadCopyPairingScript', {
                      os: intl(
                        osType === 'linux'
                          ? 'Workloads.Unpair.Options.OperatingSystems.Linux'
                          : 'Workloads.Unpair.Options.OperatingSystems.Windows',
                      ),
                    }),
                    visible: currentStep === 'ONBOARDING_COPY_PAIRING_SCRIPT' && !copied && !isDrawerClosed,
                  }}
                />
              </div>
            ),
          });
        }
      }
    }

    return pairingScriptsAttributes;
  }

  getFilteredUserRoles() {
    const {userRoleHrefs} = this.props;

    return userRoleHrefs.filter(userRoleHref => userRoleHref?.includes('workload_manager'));
  }

  handleClick(evt, row) {
    this.context.navigate({evt, to: 'workloads.item', params: {id: hrefUtils.getId(row.key)}});
  }

  handleSelect({affectedRows, selecting}) {
    this.setState(state => {
      const selectedKeySet = new Set(state.selectedKeySet);
      const selectedKeySetWithVen = new Set(state.selectedKeySetWithVen);
      const selectedEnforcement = _.transform(
        state.selectedEnforcement,
        (result, set, key) => (result[key] = new Set(set)),
      );
      const selectedVisibility = _.transform(
        state.selectedVisibility,
        (result, set, key) => (result[key] = new Set(set)),
      );
      const selectedKeySetUnmanaged = new Set(state.selectedKeySetUnmanaged);
      const selectedKeySetStaticPolicy = new Set(state.selectedKeySetStaticPolicy);

      for (const row of affectedRows) {
        selectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.unmanaged) {
            selectedKeySetUnmanaged.add(row.key);
          } else {
            selectedKeySetWithVen.add(row.key);

            if (selectedEnforcement[row.enforcement] && !row.unmanaged) {
              selectedEnforcement[row.enforcement].add(row.key);
            }

            if (row.enforcement === 'visibility_only') {
              if (row.hasEnhancedDataCollection && row.visibility !== 'enhanced_data_collection') {
                selectedVisibility.enhanced_data_collection.add(row.key);
                selectedVisibility.all.add(row.key);
              }

              if (row.visibility !== 'flow_summary') {
                selectedVisibility.flow_summary.add(row.key);
                selectedVisibility.all.add(row.key);
              }
            } else if (row.enforcement === 'full') {
              selectedVisibility.all.add(row.key);
              selectedVisibility.flow_summary.add(row.key);
              selectedVisibility.flow_off.add(row.key);
              selectedVisibility.flow_drops.add(row.key);
              selectedVisibility[row.visibility].delete(row.key);

              if (row.hasEnhancedDataCollection) {
                selectedVisibility.enhanced_data_collection.add(row.key);
              }
            } else if (row.enforcement === 'selective') {
              if (row.hasEnhancedDataCollection && row.visibility !== 'enhanced_data_collection') {
                selectedVisibility.enhanced_data_collection.add(row.key);
                selectedVisibility.all.add(row.key);
              }

              if (row.visibility !== 'flow_summary') {
                selectedVisibility.flow_summary.add(row.key);
                selectedVisibility.all.add(row.key);
              }
            }
          }

          if (row.staticPolicy) {
            selectedKeySetStaticPolicy.add(row.key);
          }
        } else {
          selectedKeySetWithVen.delete(row.key);

          if (selectedEnforcement[row.enforcement]) {
            selectedEnforcement[row.enforcement].delete(row.key);
          }

          if (row.enforcement === 'visibility_only') {
            selectedVisibility.all.delete(row.key);
            selectedVisibility.flow_summary.delete(row.key);
            selectedVisibility.enhanced_data_collection.delete(row.key);
          } else if (row.enforcement === 'full') {
            selectedVisibility.all.delete(row.key);
            selectedVisibility.flow_summary.delete(row.key);
            selectedVisibility.enhanced_data_collection.delete(row.key);
            selectedVisibility.flow_off.delete(row.key);
            selectedVisibility.flow_drops.delete(row.key);
          } else if (row.enforcement === 'selective') {
            selectedVisibility.all.delete(row.key);
            selectedVisibility.flow_summary.delete(row.key);
            selectedVisibility.enhanced_data_collection.delete(row.key);
          }

          selectedKeySetUnmanaged.delete(row.key);
          selectedKeySetStaticPolicy.delete(row.key);
        }
      }

      return {
        selectedKeySet,
        selectedKeySetWithVen,
        selectedKeySetStaticPolicy,
        selectedKeySetUnmanaged,
        selectedEnforcement,
        selectedVisibility,
      };
    });
  }

  async handleCSVImportApply() {
    await this.handleRefresh();
  }

  handleRefresh = () => {
    // Refetch the list, cancelable on page leave
    // Return promise, so Pagination component can publish event for QA
    if (this.props.onRefresh) {
      const workloadUpdate = true;

      return this.props.onRefresh(workloadUpdate);
    }

    const {
      context: {fetcher},
      props: {isInGroupView},
    } = this;

    // Need to make a fetch call for Group Views on Dashboard since the
    // view is not hydrated with the prefetch saga for embedded views initially
    if (isInGroupView) {
      const {params, router, pversionObj} = this.props;

      return fetcher.fork(fetchWorkloadList, {params, router}, true, {
        customScope: {scope: [{key: 'role', href: pversionObj?.roleLabel?.href}]},
      });
    }

    return fetcher.fork(fetchWorkloadList.refetch);
  };

  handleAutoRefresh(event) {
    // don't set state or cancel polling on initial render when no event is generated
    if (event) {
      if (this.state.isAutoRefreshing) {
        return this.handleAutoRefreshCancel();
      }

      this.setState({isAutoRefreshing: true});
    }

    this.context.store.runSaga(startWorkloadsPolling);
  }

  handleAutoRefreshCancel() {
    this.context.store.runSaga(stopWorkloadsPolling);
    this.setState({isAutoRefreshing: false});
  }

  handleFilterChange(selection) {
    const scopeItems = selection.filter(item => scopeFilterKeys.includes(item.categoryKey));
    // The detail page ignores the scope
    const filterItems = this.props.noScope
      ? selection
      : selection.filter(item => !scopeFilterKeys.includes(item.categoryKey));

    const scope = scopeItems.map(({key, href}) => ({key, href}));

    const filter = filterItems.reduce((result, {categoryKey, href, value, from, to}) => {
      if (scopeFilterKeys.includes(categoryKey)) {
        // it is in detail page
        result[categoryKey] = [{value, href}];
      } else if (!from && !to) {
        result[categoryKey] = [value];
      } else {
        if (from) {
          result[`${categoryKey}[gte]`] = Array.isArray(from) ? from : [from];
        }

        if (to) {
          result[`${categoryKey}[lte]`] = Array.isArray(to) ? to : [to];
        }
      }

      return result;
    }, {});

    this.context.navigate({
      params: {
        scope: !this.props.noScope && scope.length ? {scope} : undefined,
        [this.props.grid.settings.id]: {...this.props.grid.params, filter, page: null},
      },
    });
  }

  // Set the Form Selector Field
  handleOnsetPairingWorkloadsPairField(evt, valueSelected) {
    const id = hrefUtils.getId(valueSelected.value);

    // Redirect route to selected id
    this.context.navigate({to: 'workloads.list', params: {id}, replace: true, evt});
  }

  // Handle Copy
  async handleCopy() {
    await reactUtils.setStateAsync({copied: true}, this);

    // We assume that by copying script user has intention to pair a new workload,
    // so invalidate workloads/labels/firewall_settings cache,
    // and when user returns to workload list page hecan potentially see new workload
    cachedResponses.removeByMethodName('workloads.get_collection');
    cachedResponses.removeByMethodName('firewall_settings.get');
    cachedResponses.removeByMethodName('labels.get_collection');
  }

  handleActionDone() {
    this.setState(this.getEmptyState(this.props));
    fetchWorkloadList.refetch();
  }

  handleEnforcementFocus(enforcementmode) {
    this.setState(state => {
      if (!state.selectedKeySetWithVen.size) {
        return null;
      }

      const extraPropsKeyMap = new Map();
      const set = state.selectedEnforcement[enforcementmode];

      for (const href of state.selectedKeySetWithVen) {
        if (!set.has(href)) {
          extraPropsKeyMap.set(href, applyPolicyRowHighLight);
        }
      }

      return {enforcementHighlighted: true, extraPropsKeyMap};
    });
  }

  handleVisibilityFocus(visibilitylevel) {
    this.setState(state => {
      if (!state.selectedVisibility[visibilitylevel].size) {
        return null;
      }

      const extraPropsKeyMap = new Map();

      for (const href of state.selectedVisibility[visibilitylevel]) {
        extraPropsKeyMap.set(href, applyPolicyRowHighLight);
      }

      return {visibilityHighlighted: true, extraPropsKeyMap};
    });
  }

  handleEnforcementMenuClose() {
    // Drop highlight if user closed PolicyState modal
    if (!this.state.enforcement && this.state.enforcementHighlighted && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleVisibilityMenuClose() {
    // Drop highlight if user closed PolicyState modal
    if (!this.state.visibility && this.state.visibilityHighlighted && this.state.extraPropsKeyMap.size) {
      this.setState({extraPropsKeyMap: new Map()});
    }
  }

  handleEnforcementSelect(state) {
    const enforcementState = {
      key: state,
      name: state,
    };

    this.setState({enforcement: enforcementState});
  }

  handleVisibilitySelect(state) {
    const visibilityState = {
      key: state,
      name: state,
    };

    this.setState({visibility: visibilityState});
  }

  handleEnforcementClose() {
    this.setState(state => ({
      enforcement: null,
      enforcementHighlighted: false,
      extraPropsKeyMap: state.extraPropsKeyMap.size ? new Map() : state.extraPropsKeyMap,
    }));
  }

  handleVisibilityClose() {
    this.setState(state => ({
      visibility: null,
      visibilityHighlighted: false,
      extraPropsKeyMap: state.extraPropsKeyMap.size ? new Map() : state.extraPropsKeyMap,
    }));
  }

  async handleEnforcementConfirm() {
    const {rowsMap} = this.state;
    const {selectedKeySetWithVen, enforcement, selectedEnforcement} = await reactUtils.setStateAsync(
      ({enforcement}) => ({enforcement: {...enforcement, running: true}}),
      this,
    );

    // Find the selected rows which are not already set to the selected policyState
    const filteredSelectedKeySetWithVen = Array.from(selectedKeySetWithVen).filter(
      href => !selectedEnforcement[enforcement.key].has(href),
    );

    const data = {
      workloads: filteredSelectedKeySetWithVen.map(href => ({href})),
      enforcement_mode: enforcement.key,
    };

    if (enforcement.key !== 'idle') {
      data.visibility_level = 'flow_summary';
    }

    const enforcementMap = filteredSelectedKeySetWithVen
      .map(href => ({href}))
      .reduce((info, {href}) => {
        const rows = rowsMap.get(href);

        if (rows) {
          const enforcementMoveTo = enforcementVisibility()[enforcement.key];

          if (enforcement.key === 'idle') {
            if (!info.has('idle')) {
              info = info.set('idle', {enforcement_mode: enforcement.key, workloads: [{href}]});
            } else {
              const idle = info.get('idle');

              idle.workloads.push({href});
              info.set('idle', {...idle, workloads: idle.workloads});
            }
          } else if (!enforcementMoveTo[rows.visibility]) {
            /** When toggling to an enforcement mode that doesn't have supported visibility level
             * e.g. Switch enforcement from enforcement: full to selective and current visibility_level = 'flow_off'
             * We need to set the visibility_level = 'flow_summary' (default) since enforcement: selective doesn't support 'flow_off'
             */
            if (!info.has('flow_summary')) {
              info = info.set('flow_summary', {
                visibility_level: 'flow_summary', // 'flow_summary' is default
                enforcement_mode: enforcement.key,
                workloads: [{href}],
              });
            } else {
              const flowSummary = info.get('flow_summary');

              flowSummary.workloads.push({href});
              info.set('flow_summary', {...flowSummary, workloads: flowSummary.workloads});
            }
          } else if (enforcementMoveTo[rows.visibility]) {
            /** When toggling to an enforcement mode that has supported visibility level
             * e.g. Switch enforcement to another enforcement level when both support same visibility_level we don't need
             *  to switch visibility_level. Just keep the original visibility level.
             */
            if (!info.has('useVisibilityLevelDefault')) {
              // Don't need to pass in visibility_level, thus keep as existing
              info = info.set('useVisibilityLevelDefault', {enforcement_mode: enforcement.key, workloads: [{href}]});
            } else {
              const defaultVisibility = info.get('useVisibilityLevelDefault');

              defaultVisibility.workloads.push({href});
              info.set('useVisibilityLevelDefault', {...defaultVisibility, workloads: defaultVisibility.workloads});
            }
          }
        }

        return info;
      }, new Map());

    const error = await this.context.fetcher.spawn(updatePolicyState, Array.from(enforcementMap.values()));

    const errorMessage = Array.isArray(error) && error.length > 0 ? groupErrorsByTokens(error) : error;

    if (error) {
      this.setState(({enforcement}) => ({enforcement: {...enforcement, running: false, error: errorMessage}}));
    } else {
      // If there is no errors, refresh the list, which will close modal and remove selections
      this.handleRefresh();
    }
  }

  async handleVisibilityConfirm() {
    const {visibility, selectedVisibility} = await reactUtils.setStateAsync(
      ({visibility}) => ({visibility: {...visibility, running: true}}),
      this,
    );

    // Find the selected rows which are not already set to the selected policyState
    const filteredSelectedKeySetWithVen = Array.from(selectedVisibility[visibility.key]);

    const error = await this.context.fetcher.spawn(updatePolicyState, [
      {
        workloads: filteredSelectedKeySetWithVen.map(href => ({href})),
        visibility_level: visibility.key,
      },
    ]);

    const errorMessage = Array.isArray(error) && error.length > 0 ? groupErrorsByTokens(error) : error;

    if (error) {
      this.setState(({visibility}) => ({visibility: {...visibility, running: false, error: errorMessage}}));
    } else {
      // If there is no errors, refresh the list, which will close modal and remove selections
      this.handleRefresh();
    }
  }

  async handleIncreaseTrafficUpdateRate() {
    try {
      const {selectedKeySet: data} = this.state;

      await reactUtils.setStateAsync({increasingTrafficUpdateRate: true, increaseTrafficUpdateRateError: null}, this);
      await this.context.fetcher.spawn(increaseTrafficUpdateRate, Array.from(data));
      await reactUtils.setStateAsync({increasingTrafficUpdateRate: false}, this);
    } catch (error) {
      this.setState({increaseTrafficUpdateRateError: error, increasingTrafficUpdateRate: false});
    }
  }

  handleApplyPolicy() {
    this.setState({applyPolicy: {type: 'selected'}});
  }

  handleApplyPolicyAll() {
    this.setState({applyPolicy: {type: 'all'}});
  }

  handleApplyPolicyClose() {
    this.setState(state => ({
      applyPolicy: null,
      applyPolicyHighlighted: false,
      extraPropsKeyMap: state.extraPropsKeyMap.size ? new Map() : state.extraPropsKeyMap,
    }));
  }

  async handleApplyPolicyConfirm() {
    try {
      const {selectedKeySetStaticPolicy, applyPolicy} = await reactUtils.setStateAsync(
        ({applyPolicy}) => ({applyPolicy: {...applyPolicy, running: true}}),
        this,
      );

      const labelFilters = []; // TODO: Get scope filters
      // If no workloads are selected or no label filters are active, send an empty body
      const data = {};

      if (applyPolicy.type === 'selected' && selectedKeySetStaticPolicy.size) {
        // API needs href in a format of
        // workloads: [{href: 123}, {href: 456}]
        data.workloads = Array.from(selectedKeySetStaticPolicy, href => ({href}));
      } else if (labelFilters.length) {
        // API needs href in a format of
        // workloads: [{labels: [{href: 123}, {href: 456}]}]
        data.workloads = [{labels: labelFilters.map(({href}) => ({href}))}];
      }

      await this.context.fetcher.spawn(applyPolicyWorkloads, data);

      // If there is no errors, refresh the list, which will close modal and remove selections
      this.handleRefresh();
    } catch (error) {
      // If we have errors, show alert with errors
      this.setState(({applyPolicy}) => ({applyPolicy: {...applyPolicy, running: false, error}}));
    }
  }

  handleRemoveHover(evt, type) {
    this.setState(state => {
      if (type === 'enter' && state.selectedKeySetUnmanaged.size) {
        return {
          policyStateHighlighted: false,
          applyPolicyHighlighted: false,
          extraPropsKeyMap: new Map(Array.from(state.selectedKeySetUnmanaged, key => [key, removeRowHighLight])),
        };
      }

      if (type === 'leave' && state.extraPropsKeyMap.size) {
        return {extraPropsKeyMap: new Map()};
      }

      // Return null to prevent updating state
      return null;
    });
  }

  handleRemoveDone(removed) {
    if (removed.length) {
      // When removing is done we need to refetch the list, which will cause modal close in getDerivedStateFromProps
      this.handleRefresh();
    } else {
      this.setState(getEmptyState(this.props));
    }
  }

  handleEditLabelsDone(edited, error) {
    if (edited?.length && !error) {
      // When removing is done we need to refetch the list, which will cause modal close in getDerivedStateFromProps
      return this.handleRefresh();
    }

    return reactUtils.setStateAsync(getEmptyState(this.props), this);
  }

  async handleNextClick() {
    await this.context.fetcher.spawn(transitionStep);
  }

  async handleSkipClick(type) {
    await this.context.fetcher.spawn(hideOnboarding);

    if (type === 'tooltip') {
      this.handleToggle();
    }
  }

  handleToggle() {
    this.setState(({isDrawerClosed}) => ({isDrawerClosed: !isDrawerClosed}));
  }

  handleTabClick(_evt, _routeName, {osType}) {
    this.setState({osType});
  }

  renderPairingScripts() {
    const pairingScripts = this.getPairingScripts();
    const {osType} = this.state;

    return (
      <>
        <TabPanel primary key="tabs">
          {[
            {
              link: {params: {osType: 'windows'}, active: osType === 'windows'},
              text: intl('Workloads.Unpair.Options.OperatingSystems.Windows'),
              tid: 'windows',
              onClick: this.handleTabClick,
            },
            {
              link: {params: {osType: 'linux'}, active: osType === 'linux'},
              text: intl('Workloads.Unpair.Options.OperatingSystems.Linux'),
              tid: 'linux',
              onClick: this.handleTabClick,
            },
          ]}
        </TabPanel>
        <AttributeList>{[...pairingScripts]}</AttributeList>
      </>
    );
  }

  renderAddMenu() {
    return (
      <>
        <MenuItem
          link="pairingProfiles.pair"
          tid="add-paired"
          text={
            <>
              {intl('Workloads.List.PairWithPairingProfile')}
              <br />
              <small>{intl('Workloads.List.PairInstallVEN')}</small>
            </>
          }
        />
        <MenuDelimiter />
        <MenuItem
          link="workloads.create"
          tid="add-unmanaged"
          text={
            <>
              {intl('Workloads.AddUnmanaged')}
              <br />
              <small>{intl('Workloads.List.AddWorkloadWithoutVEN')}</small>
            </>
          }
        />
      </>
    );
  }

  renderEnforcementMenu() {
    const {selectedEnforcement} = this.state;
    const {crowdstrikeEnabled, edgeEnabled} = this.props;
    const total = Object.values(selectedEnforcement).reduce((total, set) => total + set.size, 0);

    if (crowdstrikeEnabled) {
      // TODO: Issue with high not existing for selective enforcement
      this.enforcementIntl.full = intl('Common.Enforced');
    }

    if (edgeEnabled) {
      this.enforcementIntl.full = intl('Common.Enforced');
    }

    return createElement(
      Fragment,
      null,
      ...Object.entries(selectedEnforcement).reduce((result, [state, set]) => {
        const numberToConvertTo = total - set.size;

        if (numberToConvertTo) {
          if (result.length) {
            result.push(<MenuDelimiter />);
          }

          const handleEnforcementSelect = this[`handleEnforcementSelect${state}`];
          const handleEnforcementFocus = this[`handleEnforcementFocus${state}`];

          result.push(
            <MenuItem
              counter={numberToConvertTo}
              counterColor="gray"
              text={this.enforcementIntl[state]}
              tid={state}
              disabled={crowdstrikeEnabled && state === 'idle'}
              onSelect={handleEnforcementSelect}
              onFocus={handleEnforcementFocus}
            />,
          );
        }

        return result;
      }, []),
    );
  }

  renderVisibilityMenu() {
    const {selectedVisibility} = this.state;

    return createElement(
      Fragment,
      null,
      ...Object.entries(selectedVisibility).reduce((result, [state, set]) => {
        if (state !== 'all') {
          const numberToConvertTo = set.size;

          if (numberToConvertTo) {
            if (result.length) {
              result.push(<MenuDelimiter />);
            }

            const handleVisibilitySelect = this[`handleVisibilitySelect${state}`];
            const handleVisibilityFocus = this[`handleVisibilityFocus${state}`];

            result.push(
              <MenuItem
                counter={numberToConvertTo}
                counterColor="gray"
                text={this.visibilityIntl[state]}
                tid={state}
                onSelect={handleVisibilitySelect}
                onFocus={handleVisibilityFocus}
              />,
            );
          }
        }

        return result;
      }, []),
    );
  }

  renderModal(renderModalProps = {}) {
    const {
      props: {
        grid: {rowsMap},
      },
    } = this;
    const {
      error,
      filteredSelectedKeySet,
      title,
      confirmModalTitle,
      confirmIsInProgress,
      onClose,
      onCancel,
      onConfirm,
      onClick,
    } = renderModalProps;

    if (error) {
      return (
        <Modal large notResizable onClose={onClose}>
          <Modal.Header title={intl('Workloads.Errors.Edit')} />
          <Modal.Content notScrollable>
            <TypedMessages key="errors">
              {Array.isArray(error)
                ? Array.from(error, info => ({
                    icon: 'error',
                    content: (
                      <>
                        {info.message}:
                        <ol>
                          {info.href &&
                            info.href.map((href, index) => {
                              // Get the hostname of the workload to display
                              const {
                                data: {hostname},
                              } = rowsMap.get(href);

                              return <li key={index}>{hostname}</li>;
                            })}
                        </ol>
                      </>
                    ),
                  }))
                : [{icon: 'error', content: error}]}
            </TypedMessages>
          </Modal.Content>
          <Modal.Footer>
            <Button key="primary" text={intl('Common.OK')} tid="ok" onClick={onClick} />
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal.Confirmation
        title={confirmModalTitle}
        confirmIsInProgress={confirmIsInProgress}
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        {intl(
          'Policy.ManagedStateChangeMessage',
          {
            count: filteredSelectedKeySet.length,
            countTID: 'policystate-managed-affected',
            title,
            titleTID: 'policystate-managed-state',
          },
          {html: true},
        )}
        <ol>
          {filteredSelectedKeySet.map(key => {
            const {cells} = rowsMap.get(key);

            return <li key={key}>{cells.get('name').value}</li>;
          })}
        </ol>
      </Modal.Confirmation>
    );
  }

  renderOnboardingModal({onNextClick, onSkipClick}) {
    return (
      <Modal large notResizable idleOnBackdropClick>
        <ModalContent>
          <div className={styles.container}>
            <h1>{intl('Antman.Onboarding.WelcomePairingSetup')}</h1>
            <p>{intl('Antman.Onboarding.WelcomePairingSetupDesc')}</p>
            <p>{intl('Antman.Onboarding.WelcomePairingSetupDescVens')}</p>
            <p>{intl('Antman.Onboarding.PairWorkloads')}</p>
            <Button size="medium" onClick={onNextClick}>
              {intl('Common.Next')}
            </Button>
            <Button color="standard" noFill onClick={onSkipClick}>
              {intl('Antman.Onboarding.SkipTour')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    );
  }

  renderEnforcement() {
    const {
      state: {selectedKeySetWithVen, enforcement, selectedEnforcement},
    } = this;

    const error = enforcement.error;

    // Find the selected rows which are not already set to the selected policyState
    const filteredSelectedKeySet = Array.from(selectedKeySetWithVen).filter(
      href => !selectedEnforcement[enforcement.key].has(href),
    );

    return this.renderModal({
      error,
      filteredSelectedKeySet,
      title: this.enforcementIntl[enforcement.name],
      confirmIsInProgress: enforcement.running,
      onClose: this.handleEnforcementClose,
      onConfirm: this.handleEnforcementConfirm,
      onCancel: this.handleEnforcementClose,
      onClick: this.handleEnforcementClose,
      confirmModalTitle: intl('Policy.StateChangeConfirm'),
    });
  }

  renderVisibility() {
    const {
      state: {visibility, selectedVisibility},
    } = this;

    const error = visibility.error;

    // Find the selected rows which are not already set to the selected policyState
    const filteredSelectedKeySet = Array.from(selectedVisibility[visibility.key]);

    return this.renderModal({
      error,
      filteredSelectedKeySet,
      title: this.visibilityIntl[visibility.name],
      confirmIsInProgress: visibility.running,
      onClose: this.handleVisibilityClose,
      onConfirm: this.handleVisibilityConfirm,
      onCancel: this.handleVisibilityClose,
      onClick: this.handleVisibilityClose,
      confirmModalTitle: intl('Policy.VisibilityChangeConfirm'),
    });
  }

  renderApplyPolicyMenu() {
    return (
      <>
        {!this.props.hidePolicyApplyAll && (
          <MenuItem
            text={intl('Workloads.UpdateAllWorkloads')}
            tid="all-workloads"
            onSelect={this.handleApplyPolicyAll}
            onFocus={this.handleApplyPolicySelectedUnfocus}
          />
        )}
        {this.state.selectedKeySetStaticPolicy.size > 0 && (
          <>
            <MenuDelimiter />
            <MenuItem
              counter={this.state.selectedKeySetStaticPolicy.size}
              counterColor="gray"
              text={intl('Workloads.UpdateSelectedWorkloads')}
              tid="selected-workloads"
              onSelect={this.handleApplyPolicy}
              onFocus={this.handleApplyPolicySelectedFocus}
            />
          </>
        )}
      </>
    );
  }

  renderApplyPolicy() {
    const {
      props: {
        grid: {rowsMap},
      },
      state: {selectedKeySetStaticPolicy, applyPolicy},
    } = this;

    if (applyPolicy.error) {
      const errData = _.get(applyPolicy.error, 'data[0]');
      const message = (errData && errData.message) || applyPolicy.error.message;

      return (
        <Modal.Alert stretch title={intl('Workloads.ApplyPolicy')} onClose={this.handleApplyPolicyClose}>
          <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
        </Modal.Alert>
      );
    }

    //TBD for the Scope Filter
    const labelFilters = [];
    let content;

    if (applyPolicy.type !== 'all') {
      // Count all static policy workloads in the selected set
      content = (
        <>
          <strong>{intl('Workloads.StagedPolicyWillBeApplied', {count: selectedKeySetStaticPolicy.size})}</strong>
          <ol>
            {Array.from(selectedKeySetStaticPolicy, key => {
              const {cells} = rowsMap.get(key);

              return <li key={key}>{cells.get('name').value} </li>;
            })}
          </ol>
          <p>
            {intl('Workloads.ThisProcessCanTakeAFewMinutesToTakeEffect')}
            <br />
            {intl('Workloads.RefreshThePageToSeeTheUpdatedPolicySyncStatus')}
          </p>
        </>
      );
    } else if (labelFilters.length) {
      // Apply Policy to all workloads in the scope filter with static policy
      content = (
        <>
          <strong>{intl('Workloads.StagedPolicyWillBeAppliedWithLabel')}</strong>
          {labelFilters.map(value => (
            <Pill.Label id={value.id} type={value.key} ref={value.href}>
              {value.value}
            </Pill.Label>
          ))}
          <p>
            {intl('Workloads.ThisProcessCanTakeAFewMinutesToTakeEffect')}
            <br />
            {intl('Workloads.RefreshThePageToSeeTheUpdatedPolicySyncStatus')}
          </p>
        </>
      );
    } else {
      // Apply Policy to all workloads with static policy
      content = (
        <>
          <strong>{intl('Workloads.StagedPolicyAllStaticPolicyWorkloads')}</strong>
          <p>
            {intl('Workloads.ThisProcessCanTakeAFewMinutesToTakeEffect')}
            <br />
            {intl('Workloads.RefreshThePageToSeeTheUpdatedPolicySyncStatus')}
          </p>
        </>
      );
    }

    return (
      <Modal.Confirmation
        title={intl('Workloads.ApplyPolicy')}
        confirmIsInProgress={applyPolicy.running}
        defaultConfirmProps={{tid: 'ok', text: intl('Common.Apply'), onClick: this.handleApplyPolicyConfirm}}
        onCancel={this.handleApplyPolicyClose}
      >
        {content}
      </Modal.Confirmation>
    );
  }

  render() {
    const {
      props: {
        grid,
        count,
        selector,
        resourceType,
        hideAdd,
        hideRemove,
        hideEditLabels,
        hidePolicyApply,
        hidePolicyApplyAll,
        hideReports,
        hideRefresh,
        hideFilter,
        hideHeaderProps,
        hideIncreaseTrafficUpdateRate,
        showingDiscoveredWorkloads,
        showGlobalLink,
        gridOffset,
        hideEnforcement,
        hideVisibility,
        noScope,
        edgeEnabled,
        crowdstrikeEnabled,
        isInGroupView,
        includeMatches,
        selectedLabels,
        groupProfile,
        pairingProfile,
        showActionButtons,
        currentStep,
      },
      state: {
        extraPropsKeyMap,
        selectedKeySet,
        selectedKeySetUnmanaged,
        selectedKeySetWithVen,
        selectedKeySetStaticPolicy,
        applyPolicy,
        enforcement,
        visibility,
        selectedVisibility,
        increasingTrafficUpdateRate,
        increaseTrafficUpdateRateError,
        isAutoRefreshing,
        isDrawerClosed,
      },
    } = this;
    const notifications = getMaxPageNotificationList({page: grid.page, capacity: grid.capacity, count});

    if (showingDiscoveredWorkloads) {
      notifications.push({
        type: 'instruction',
        handleClose: null,
        message: intl('Edge.GroupSetupInstruction'),
      });
    }

    const isUpdateEnforcementDisabled = !selectedKeySetWithVen.size || !isAPIAvailable('workloads.update');

    const initialItems = noScope ? selector.filterItems : selector.scopeItems.concat(selector.filterItems);
    const isAddDisabled =
      !isAPIAvailable('workloads.create') || (!crowdstrikeEnabled && pairingProfile && !pairingProfile.enabled);
    const isRemoveDisabled = !isAPIAvailable('workload.delete');
    const isEditDisabled = !isAPIAvailable('workloads.set_labels');
    const isApplyPolicyDisabled = !isAPIAvailable('workloads.apply_policy');
    const isIncreaseTrafficUpdateRateDisabled =
      !isAPIAvailable('workloads.set_flow_reporting_frequency') || !selectedKeySet.size;
    // An invalid selection combination is when at least one selected workload is in idle state.
    const invalidGroupCombo = this.state.selectedEnforcement.idle.size > 0;

    let content = (
      <>
        {!hideFilter && (
          <ToolBar>
            <ToolGroup expand tid="page-filter">
              <ComboSelect
                scrollable
                objects={selector.objects}
                placeholder={intl('Common.FilterView')}
                initialItems={initialItems}
                categories={selector.categories}
                activeCategoryKey="name"
                partials={selector.partials}
                facets={selector.facets}
                statics={selector.statics}
                customPickers={selector.customPickers}
                onSelectionChange={this.handleFilterChange}
                resourceType={resourceType}
                includeMatches={isInGroupView ? includeMatches : []}
                selectedLabels={isInGroupView ? selectedLabels : null}
              />
            </ToolGroup>
          </ToolBar>
        )}
        <Grid
          grid={grid}
          theme={styles}
          inlineSize
          offset={gridOffset}
          count={count}
          selectedKeySet={selectedKeySet}
          dontHighlightSelected={extraPropsKeyMap.size > 0}
          extraPropsKeyMap={extraPropsKeyMap}
          emptyMessage={
            __ANTMAN__ && currentStep !== null
              ? intl('Antman.Workloads.WorkloadsAppearHere')
              : initialItems.length
              ? intl('Workloads.NoMatchData')
              : intl('Common.NoDataWorkload')
          }
          onClick={this.handleClick}
          onSelect={this.handleSelect}
        />
      </>
    );

    if (__ANTMAN__) {
      content = (
        <TooltipTour
          tooltipContent={intl('Antman.Common.QuickPairText')}
          onNextClick={this.handleNextClick}
          onSkipClick={_.partial(this.handleSkipClick, 'tooltip')}
          tooltipProps={{
            interactive: true,
            top: true,
            visible: __ANTMAN__ && currentStep === 'ONBOARDING_COPY_PAIRING_SCRIPT',
          }}
        >
          <Cutout
            visible={__ANTMAN__ && currentStep === 'ONBOARDING_COPY_PAIRING_SCRIPT'}
            className={stylesUtils.gapMedium}
          >
            {__ANTMAN__ && (
              <Drawer text={intl('Antman.Common.QuickPair')} onChange={this.handleToggle} closed={isDrawerClosed}>
                {this.renderPairingScripts()}
              </Drawer>
            )}

            {content}
          </Cutout>
        </TooltipTour>
      );
    }

    return (
      <div className={stylesUtils.gap}>
        {!hideHeaderProps && <HeaderProps subtitle={intl('Common.Workloads')} />}
        {notifications.length > 0 && <Notifications sidebar>{notifications}</Notifications>}
        {__ANTMAN__ && <OnboardingGuide />}
        <ToolBar>
          <ToolGroup noSingleton>
            {!edgeEnabled
              ? !hideAdd &&
                showActionButtons && (
                  <Button.Menu
                    textIsHideable
                    icon="add"
                    text={intl('Common.Add')}
                    disabled={isAddDisabled}
                    theme={buttonsTheme}
                    menuAlign="left"
                    menu={this.renderAddMenu}
                    tid="add"
                  />
                )
              : !crowdstrikeEnabled &&
                !hideAdd &&
                showActionButtons && (
                  <Button.Link
                    textIsHideable
                    icon="add"
                    text={intl('Common.Add')}
                    tooltip={isInGroupView && intl('Edge.GroupDashboard.PairVensTooltip')}
                    disabled={isAddDisabled}
                    theme={buttonsTheme}
                    link={{
                      to: 'pairingProfiles.pair',
                      params: {id: groupProfile && groupProfile.enabled && hrefUtils.getId(groupProfile?.href)},
                    }}
                    tid="add-paired"
                  />
                )}

            {(!edgeEnabled || crowdstrikeEnabled) && !hideRemove && showActionButtons && (
              <WorkloadRemove
                showCounter
                rowsMap={grid.rowsMap}
                theme={buttonsTheme}
                onButtonHover={this.handleRemoveHover}
                onDone={this.handleRefresh}
                hrefsToRemove={selectedKeySetUnmanaged}
                hrefsManaged={selectedKeySetWithVen}
                disabled={isRemoveDisabled}
              />
            )}
            {!hideEditLabels && showActionButtons && (
              <EditLabels
                showCounter
                rowsMap={grid.rowsMap}
                theme={buttonsTheme}
                disabled={isEditDisabled}
                onDone={this.handleEditLabelsDone}
                resourceType={resourceType}
                hrefsToEdit={selectedKeySet}
                unmanagedWorkloads={selectedKeySetUnmanaged}
                edgeEnabled={edgeEnabled}
                crowdstrikeEnabled={crowdstrikeEnabled}
              />
            )}

            {!hideEnforcement && !showingDiscoveredWorkloads && showActionButtons && (
              <Button.Menu
                color="standard"
                textIsHideable
                text={intl('Common.Enforcement')}
                theme={buttonsTheme}
                tid="enforcement"
                menu={this.renderEnforcementMenu}
                menuProps={{onClose: this.handleEnforcementMenuClose}}
                disabled={isUpdateEnforcementDisabled || (crowdstrikeEnabled && invalidGroupCombo)}
                counter={selectedKeySetWithVen.size}
                menuAlign={hideAdd || hideRemove || hideEditLabels ? 'right' : 'left'}
                tooltip={crowdstrikeEnabled && invalidGroupCombo && intl('Workloads.InvalidStateToApplyPolicy')}
                tooltipProps={{instant: true}}
              />
            )}
            {!hideVisibility && !showingDiscoveredWorkloads && !crowdstrikeEnabled && showActionButtons && (
              <Button.Menu
                color="standard"
                textIsHideable
                text={intl('Common.Visibility')}
                theme={buttonsTheme}
                tid="visibility"
                menu={this.renderVisibilityMenu}
                menuProps={{onClose: this.handleVisibilityMenuClose}}
                disabled={
                  isUpdateEnforcementDisabled ||
                  selectedVisibility.all.size === 0 ||
                  (crowdstrikeEnabled && invalidGroupCombo)
                }
                counter={selectedVisibility.all.size}
                menuAlign={hideAdd || hideRemove || hideEditLabels ? 'right' : 'left'}
                tooltip={crowdstrikeEnabled && invalidGroupCombo && intl('Workloads.InvalidStateToApplyPolicy')}
                tooltipProps={{instant: true}}
              />
            )}
            {!edgeEnabled &&
            !hidePolicyApply &&
            count.staticMode &&
            showActionButtons &&
            (!hidePolicyApplyAll || selectedKeySetStaticPolicy.size) ? (
              <Button.Menu
                color="standard"
                textIsHideable
                text={intl('Workloads.ApplyPolicy')}
                tid="applypolicy"
                menu={this.renderApplyPolicyMenu}
                menuProps={{onClose: this.handleApplyPolicySelectedUnfocus}}
                disabled={isApplyPolicyDisabled}
                counter={selectedKeySetStaticPolicy.size}
                theme={buttonsTheme}
              />
            ) : null}
            {edgeEnabled && !crowdstrikeEnabled && !hideIncreaseTrafficUpdateRate && showActionButtons && (
              <Button
                color="standard"
                textIsHideable
                text={intl('Common.IncreaseTrafficUpdateRate')}
                tid="increase-traffic-update-rate"
                tooltip={intl('Common.IncreaseTrafficUpdateRateTooltip', {minutes: 10})}
                tooltipProps={{instant: true}}
                counter={selectedKeySet.size}
                disabled={isIncreaseTrafficUpdateRateDisabled}
                onClick={this.handleIncreaseTrafficUpdateRate}
                progress={increasingTrafficUpdateRate}
                progressCompleteWithCheckmark
                progressError={increaseTrafficUpdateRateError !== null}
                icon="increase"
                theme={buttonsTheme}
              />
            )}
          </ToolGroup>
          <ToolGroup>
            <VenUpgradeConditions
              type="workload"
              healthError={count.error}
              suspended={count.suspended}
              warning={count.warning}
              onFilterClick={this.handleFilterChange}
            />
            {!hideRefresh &&
              (__ANTMAN__ ? (
                <Button
                  color="standard"
                  textIsHideable
                  onClick={this.handleAutoRefresh}
                  theme={buttonsTheme}
                  tooltip={intl('Workloads.AutoRefresh')}
                >
                  <StatusIcon
                    status={isAutoRefreshing ? 'enabled' : 'disabled'}
                    label={intl('Common.AutoRefresh')}
                    noTextColor
                  />
                </Button>
              ) : (
                <ButtonRefresh color="standard" textIsHideable onRefresh={this.handleRefresh} theme={buttonsTheme} />
              ))}
            {__ANTMAN__ && <WorkloadCSVImport onApply={this.handleCSVImportApply} />}
            {!hideReports && !isInGroupView && (
              <ReportButtons
                type="workloads"
                // Export label columns in their own columns for CoreX
                {...(__ANTMAN__ && {csvQuery: {expand_label_csv_columns: true}})}
                disabledGen={!grid.rows.length}
                theme={buttonsTheme}
              />
            )}
            {showGlobalLink && (
              <Button.Link
                color="standard"
                textIsHideable
                icon="external-link"
                theme={buttonsTheme}
                text={intl('Workloads.All')}
                link="workloads.list"
              />
            )}
          </ToolGroup>
        </ToolBar>
        {content}
        {enforcement && this.renderEnforcement()}
        {visibility && this.renderVisibility()}
        {applyPolicy && this.renderApplyPolicy()}
        {__ANTMAN__ && (
          <>
            {currentStep === 'ONBOARDING_WORKLOADS' &&
              this.renderOnboardingModal({onNextClick: this.handleNextClick, onSkipClick: this.handleSkipClick})}
            {currentStep === 'ONBOARDING_ADD_LABELS' && (
              <OnboardingModalCSVImport onNextClick={this.handleNextClick} onSkipClick={this.handleSkipClick} />
            )}
          </>
        )}
      </div>
    );
  }
}
