/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {apiCachedResponses} from 'api/apiSaga';
import {AppContext} from 'containers/App/AppUtils';
import {call} from 'redux-saga/effects';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {ButtonGroup, Button, MenuItem, MenuDelimiter} from 'components';
import {composeDownloadUrl, types} from '../ReportsUtils';
import {startAsyncJobCollection} from '../ReportsSaga';
import {fetchReportsLists} from '../List/ReportsListSaga';
import {getReportButtons} from './ReportButtonsState';
import {reactUtils, sagasUtils} from 'utils';
import styles from './ReportButtons.css';

@connect((state, props) => getReportButtons(state, props))
export default class ReportButtons extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    type: PropTypes.string.isRequired,

    disabled: PropTypes.bool,
    disabledGen: PropTypes.bool,
    disableCsv: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.formats = {
      json: {label: intl('Common.AsJSON')},
      ...(!this.props.disableCsv && {csv: {label: intl('Common.AsCSV')}}),
    };

    this.formatKeys = Object.keys(this.formats);

    this.state = {reportIsInProgress: false, reportProgressError: false};

    this.handleGenerate = this.handleGenerate.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    setTimeout(() => this.startReportsPolling(true));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {reportsInProgress} = nextProps;

    if (reportsInProgress !== prevState.reportsInProgress) {
      return {reportIsInProgress: reportsInProgress.length > 0, reportsInProgress};
    }

    return null;
  }

  componentWillUnmount() {
    apiCachedResponses.removeByMethodName('jobs.get_collection');

    if (this.pollingTask) {
      this.context.fetcher.cancel(this.pollingTask);
    }

    this.mounted = false;
  }

  async handleGenerate(format) {
    try {
      const {
        props: {type, csvQuery = {}, jsonQuery = {}},
        context: {fetcher},
      } = this;

      await reactUtils.setStateAsync({reportIsInProgress: true}, this);
      fetcher.cancel(this.pollingTask);

      if (this.mounted) {
        await fetcher.spawn(startAsyncJobCollection, {
          typeName: type,
          format,
          query: format === 'csv' ? csvQuery : jsonQuery,
        });
      }

      if (this.mounted) {
        this.startReportsPolling(true);
      }
    } catch {
      this.setState({reportIsInProgress: false, reportProgressError: true});
    }
  }

  startReportsPolling(instantStart) {
    this.pollingTask = this.context.fetcher.fork(sagasUtils.startPolling, {
      interval: 10_000,
      instantStart,
      saga: call(fetchReportsLists, {force: true}),
    });
  }

  render() {
    const {type, disabled, disabledGen, reportsComplete, reportsDone, reportsInProgress} = this.props;
    const {reportIsInProgress, reportProgressError} = this.state;

    const latestReportFailed =
      !reportsInProgress.length && reportsComplete.length > 0 && reportsComplete[0].status === 'failed';
    const reportsAreInProgress = reportIsInProgress || reportsInProgress.length > 0;

    const generateMenu = (types()[type].formats || this.formatKeys).map(format => (
      <MenuItem
        disabled={disabledGen}
        text={this.formats[format].label}
        tid={format}
        onSelect={_.partial(this.handleGenerate, format)}
      />
    ));

    const downloadMenu = reportsDone
      .map((report, index) => (
        <MenuItem
          text={report.description}
          title={report.description}
          tid={`downloaded-report-${index}`}
          link={{href: composeDownloadUrl(report.result.href, report.filename), download: report.filename}}
        />
      ))
      .concat(
        <MenuDelimiter />,
        <MenuItem link="reports.list" text={intl('Exports.ViewAllExports')} data-tid="comp-navbar-pages-reports" />,
      );

    return (
      <ButtonGroup
        size="medium"
        color="standard"
        data-tid="generate-report-group"
        theme={composeThemeFromProps(styles, this.props)}
      >
        <Button.Menu
          tid="generate-report"
          menu={generateMenu}
          textIsHideable
          disabled={disabled}
          theme={composeThemeFromProps(styles, this.props, {prefix: 'generate-'})}
          text={reportsAreInProgress ? intl('Common.Generating') : intl('Common.Export')}
          icon={reportsAreInProgress ? 'syncing' : 'export'}
          progress={reportsAreInProgress}
          progressError={reportProgressError || latestReportFailed}
          progressCompleteWithCheckmark
        />

        {reportsDone.length > 0 && (
          <Button.Menu
            tid="download-reports"
            menu={downloadMenu}
            icon="download"
            disabled={disabled}
            theme={composeThemeFromProps(styles, this.props, {prefix: 'download-'})}
          />
        )}
      </ButtonGroup>
    );
  }
}
