/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';

export default {
  ikeCertificate(state = {}, action) {
    switch (action.type) {
      case 'IKE_CERTIFICATE_GET':
        return action.data;
      default:
        return state;
    }
  },
};

export const getIKECertificate = state => state.ikeCertificate;
export const doesIKECertificateExist = createSelector([getIKECertificate], cert => Boolean(cert.ikeCertificate));

export const getVenOperationsPage = createSelector([getIKECertificate], cert => cert);

export const isVenOperationsEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
