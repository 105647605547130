/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {routesTree as commonRoutes, routesMerger} from '../router/routes';

export default _.mergeWith(
  commonRoutes,
  {
    name: 'app',
    children: [
      {name: 'welcome', path: '/welcome', container: 'Welcome', load: () => import(/* webpackChunkName: 'Welcome' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/Group/Welcome/Welcome')},
      {
        name: 'landing',
        path: '/',
        forwardTo: '>dashboard',
        children: [
          {
            name: 'dashboard',
            container: 'GroupDashboard', load: () => import(/* webpackChunkName: 'GroupDashboard' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/Group/Dashboard/GroupDashboard'),
            path: '/?:group?:grouplist?:tab?:pversion?:datatype?:time?:policyview?:direction?:explorerlist?:filter?:groupWorkloadlist?:discoveredWlList?:venslist?:groupRuleList?:adminGroupList?:allowRules?:denyRules',
            defaultParams: {},
          },
        ],
      },
      {
        name: 'groups',
        path: '/groups',
        forwardTo: 'landing',
        children: [
          {
            name: 'view',
            container: 'GroupView', load: () => import(/* webpackChunkName: 'GroupView' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/Group/GroupView'),
            path: '/:group/?:tab?:pversion?:datatype?:time?:policyview?:direction?:explorerlist?:filter?:groupWorkloadlist?:venslist?:groupRuleList?:adminGroupList?:allowRules?:denyRules',
            defaultParams: {tab: 'inboundpolicy', pversion: 'draft'},
          },
          {name: 'create', path: '/wizard/?:step', container: 'GroupWizard', load: () => import(/* webpackChunkName: 'GroupWizard' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/Group/Wizard/GroupWizard'), defaultParams: {step: 'serviceSelection'}},
          {
            name: 'edit',
            path: '/:id/wizard/?:step',
            container: 'GroupWizard', load: () => import(/* webpackChunkName: 'GroupWizard' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/Group/Wizard/GroupWizard'),
            defaultParams: {step: 'serviceSelection'},
          },
          {
            name: 'editadmin',
            path: '/:id/admin/wizard/?:step',
            container: 'AdminAccessWizard', load: () => import(/* webpackChunkName: 'AdminAccessWizard' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/Group/AdminAccessWizard/AdminAccessWizard'),
            defaultParams: {step: 'serviceSelection'},
          },
        ],
      },
      {
        name: 'venoperations',
        path: __ATR__ ? '/venoperations' : '/ikecertificates',
        container: 'VenOperationsEnabled', load: () => import(/* webpackChunkName: 'VenOperationsEnabled' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/VenOperations/VenOperationsEnabled'),
        forwardTo: '>detail',
        children: [
          {name: 'detail', path: '/', container: 'VenOperations', load: () => import(/* webpackChunkName: 'VenOperations' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/VenOperations/VenOperations')},
          {name: 'edit', path: '/edit', container: 'VenOperationsEdit', load: () => import(/* webpackChunkName: 'VenOperationsEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/VenOperations/VenOperationsEdit')},
        ],
      },
      {
        name: 'rulesets',
        path: '/rulesets',
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:scope?:rulesetlist', container: 'RulesetList', load: () => import(/* webpackChunkName: 'RulesetList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ruleset/List/RulesetList')},
          {name: 'create', path: '/create', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
          {name: 'clone', path: '/clone/:rule_set_id/:pversion', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
          {
            name: 'item',
            path: '/:id/:pversion',
            forwardTo: '>rules',
            defaultParams: {pversion: 'draft'},
            container: 'RulesetItem', load: () => import(/* webpackChunkName: 'RulesetItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ruleset/Item/RulesetItem'),
            children: [
              {name: 'view', path: '/', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
              {name: 'edit', path: '/edit', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
              {name: 'rules', path: '/rules', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
            ],
          },
        ],
      },
      {
        name: 'workloads',
        path: '/workloads',
        forwardTo: '>list',
        container: 'WorkloadManagement', load: () => import(/* webpackChunkName: 'WorkloadManagement' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/WorkloadManagement/WorkloadManagement'),
        children: [
          {name: 'list', path: '/?:scope?:workloadlist', container: 'WorkloadList', load: () => import(/* webpackChunkName: 'WorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/List/WorkloadList')},
          {name: 'create', path: '/create', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/Edit/WorkloadEdit')},
          {
            name: 'item',
            path: '/:id',
            forwardTo: '>view',
            container: 'WorkloadItem', load: () => import(/* webpackChunkName: 'WorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/WorkloadItem'),
            children: [
              {name: 'view', path: '/', container: 'WorkloadSummary', load: () => import(/* webpackChunkName: 'WorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/Summary/WorkloadSummary')},
              {name: 'edit', path: '/edit', container: 'WorkloadEdit', load: () => import(/* webpackChunkName: 'WorkloadEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/Edit/WorkloadEdit')},
              {name: 'virtualServices', path: '/virtualServices?:workloadvs', container: 'WorkloadVirtualServices', load: () => import(/* webpackChunkName: 'WorkloadVirtualServices' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/VirtualServices/WorkloadVirtualServices')},
              {name: 'services', path: '/services?:workloadprocesses', container: 'WorkloadProcesses', load: () => import(/* webpackChunkName: 'WorkloadProcesses' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/Processes/WorkloadProcesses')},
              {name: 'blockedTraffic', path: '/blockedtraffic', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
              {name: 'rules', path: '/rules?:inbound?:outbound?:forward?:customip?:admin', container: 'WorkloadRules', load: () => import(/* webpackChunkName: 'WorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/Rules/WorkloadRules')},
              {name: 'report', path: '/report?:compatibilityReport', container: 'WorkloadCompatibilityReport', load: () => import(/* webpackChunkName: 'WorkloadCompatibilityReport' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Workload/Item/Report/WorkloadCompatibilityReport')},
              {name: 'vulnerabilities', path: '/vulnerabilities?:workloadvulnerability', container: 'JumpToOld', load: () => import(/* webpackChunkName: 'JumpToOld' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/JumpToOld')},
            ],
          },
          {
            name: 'containers',
            path: '/containers',
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:scope?:cclusterworkloadlist', container: 'ContainerWorkloadList', load: () => import(/* webpackChunkName: 'ContainerWorkloadList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerWorkload/List/ContainerWorkloadList')},
              {
                name: 'item',
                path: '/:id',
                container: 'ContainerWorkloadItem', load: () => import(/* webpackChunkName: 'ContainerWorkloadItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerWorkload/Item/ContainerWorkloadItem'),
                forwardTo: '>view',
                children: [
                  {name: 'view', path: '/', container: 'ContainerWorkloadSummary', load: () => import(/* webpackChunkName: 'ContainerWorkloadSummary' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerWorkload/Item/Summary/ContainerWorkloadSummary')},
                  {name: 'containers', path: '/containers', container: 'ContainerWorkloadContainers', load: () => import(/* webpackChunkName: 'ContainerWorkloadContainers' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerWorkload/Item/Containers/ContainerWorkloadContainers')},
                  {name: 'rules', path: '/rules?:inbound?:outbound?:customip', container: 'ContainerWorkloadRules', load: () => import(/* webpackChunkName: 'ContainerWorkloadRules' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerWorkload/Item/Rules/ContainerWorkloadRules')},
                ],
              },
            ],
          },
          {
            name: 'vens',
            path: '/vens',
            container: 'Ven', load: () => import(/* webpackChunkName: 'Ven' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ven/Ven'),
            forwardTo: '>list',
            children: [
              {name: 'list', path: '/?:scope?:venslist', container: 'VenList', load: () => import(/* webpackChunkName: 'VenList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ven/List/VenList')},
              {
                name: 'item',
                path: '/:id',
                forwardTo: '>view',
                container: 'VenItem', load: () => import(/* webpackChunkName: 'VenItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ven/Item/VenItem'),
                children: [
                  {name: 'view', path: '/?:supportreportlist', container: 'VenSummary', load: () => import(/* webpackChunkName: 'VenSummary' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ven/Item/Summary/VenSummary')},
                  {name: 'edit', path: '/edit', container: 'VenEdit', load: () => import(/* webpackChunkName: 'VenEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ven/Item/Edit/VenEdit')},
                  {
                    name: 'containerWorkloads',
                    path: '/containerworkloads?:cclusterworkloadlist',
                    container: 'VenContainerWorkloads', load: () => import(/* webpackChunkName: 'VenContainerWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/Ven/Item/ContainerWorkloads/VenContainerWorkloads'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'pairingProfiles',
        path: '/pairingprofiles',
        container: 'PairingProfile', load: () => import(/* webpackChunkName: 'PairingProfile' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/PairingProfile'),
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:scope?:pairingprofilelist', container: 'PairingProfileList', load: () => import(/* webpackChunkName: 'PairingProfileList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/List/PairingProfileList')},
          {name: 'create', path: '/create', container: 'PairingProfileEdit', load: () => import(/* webpackChunkName: 'PairingProfileEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/Item/Edit/PairingProfileEdit')},
          {name: 'pair', path: '/pair/?:id', container: 'PairingProfilePair', load: () => import(/* webpackChunkName: 'PairingProfilePair' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/Item/Pair/PairingProfilePair')},
          {
            name: 'item',
            path: '/:id',
            forwardTo: '>view',
            container: 'PairingProfilesItem', load: () => import(/* webpackChunkName: 'PairingProfilesItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/Item/PairingProfilesItem'),
            children: [
              {name: 'view', path: '/', container: 'PairingProfileView', load: () => import(/* webpackChunkName: 'PairingProfileView' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/Item/View/PairingProfileView')},
              {name: 'edit', path: '/edit', container: 'PairingProfileEdit', load: () => import(/* webpackChunkName: 'PairingProfileEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/PairingProfile/Item/Edit/PairingProfileEdit')},
            ],
          },
        ],
      },
      {
        name: 'secureGateways',
        path: '/secureconnectgateways',
        container: 'SecureGateway', load: () => import(/* webpackChunkName: 'SecureGateway' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/SecureGateway/SecureGateway'),
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:securegatewaylist', container: 'SecureGatewayList', load: () => import(/* webpackChunkName: 'SecureGatewayList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/SecureGateway/List/SecureGatewayList')},
          {name: 'create', path: '/create', defaultParams: {mode: 'create'}, container: 'SecureGatewayEdit', load: () => import(/* webpackChunkName: 'SecureGatewayEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/SecureGateway/Item/Edit/SecureGatewayEdit')},
          {
            name: 'item',
            path: '/:id?:mode',
            forwardTo: '>view',
            container: 'SecureGatewayItem', load: () => import(/* webpackChunkName: 'SecureGatewayItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/SecureGateway/Item/SecureGatewayItem'),
            children: [
              {
                name: 'edit',
                path: '/edit?:sgrules?:pversion',
                defaultParams: {mode: 'edit', pversion: 'draft'},
                container: 'SecureGatewayEdit', load: () => import(/* webpackChunkName: 'SecureGatewayEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/SecureGateway/Item/Edit/SecureGatewayEdit'),
              },
              {
                name: 'view',
                path: '/:pversion?:sgrules',
                defaultParams: {pversion: 'draft'},
                container: 'SecureGatewayView', load: () => import(/* webpackChunkName: 'SecureGatewayView' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/SecureGateway/Item/View/SecureGatewayView'),
              },
            ],
          },
        ],
      },
      {
        name: 'loadBalancers',
        path: '/loadbalancers',
        container: 'LoadBalancer', load: () => import(/* webpackChunkName: 'LoadBalancer' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/LoadBalancer'),
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:loadbalancerslist', container: 'LoadBalancerList', load: () => import(/* webpackChunkName: 'LoadBalancerList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/List/LoadBalancerList')},
          {name: 'create', path: '/create', container: 'LoadBalancerEdit', load: () => import(/* webpackChunkName: 'LoadBalancerEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/Item/Edit/LoadBalancerEdit')},
          {
            name: 'item',
            path: '/:id',
            forwardTo: '>view',
            container: 'LoadBalancerItem', load: () => import(/* webpackChunkName: 'LoadBalancerItem' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/Item/LoadBalancerItem'),
            children: [
              {name: 'view', path: '/', container: 'LoadBalancerSummary', load: () => import(/* webpackChunkName: 'LoadBalancerSummary' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/Item/Summary/LoadBalancerSummary')},
              {name: 'edit', path: '/edit', container: 'LoadBalancerEdit', load: () => import(/* webpackChunkName: 'LoadBalancerEdit' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/Item/Edit/LoadBalancerEdit')},
              {name: 'servers', path: '/servers?:virtualserverlist', container: 'LoadBalancerServers', load: () => import(/* webpackChunkName: 'LoadBalancerServers' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/LoadBalancer/Item/Servers/LoadBalancerServers')},
            ],
          },
        ],
      },
      {
        name: 'containerClusters',
        path: '/containerclusters',
        container: 'ContainerCluster', load: () => import(/* webpackChunkName: 'ContainerCluster' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/ContainerCluster'),
        forwardTo: '>list',
        children: [
          {name: 'list', path: '/?:cclusterlist', container: 'ContainerClusterList', load: () => import(/* webpackChunkName: 'ContainerClusterList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/List/ContainerClusterList')},
          {name: 'create', path: '/create', container: 'ContainerClusterConfig', load: () => import(/* webpackChunkName: 'ContainerClusterConfig' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Config/ContainerClusterConfig')},
          {
            name: 'item',
            path: '/:id',
            forwardTo: '>summary',
            container: 'ContainerClusterDetail', load: () => import(/* webpackChunkName: 'ContainerClusterDetail' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/ContainerClusterDetail'),
            children: [
              {name: 'summary', path: '/summary', container: 'ContainerClusterSummary', load: () => import(/* webpackChunkName: 'ContainerClusterSummary' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Summary/ContainerClusterSummary')},
              {
                name: 'profiles',
                path: '/profiles',
                forwardTo: '>list',
                children: [
                  {
                    name: 'list',
                    path: '/?:cclusterprofilelist',
                    container: 'ContainerClusterContainerWorkloadProfileList', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileList' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Profile/List/ContainerClusterContainerWorkloadProfileList'),
                  },
                  {name: 'create', path: '/create', container: 'ContainerClusterContainerWorkloadProfileConfig', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileConfig' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Profile/Config/ContainerClusterContainerWorkloadProfileConfig')},
                  {name: 'item', path: '/:profileId', container: 'ContainerClusterContainerWorkloadProfileDetail', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileDetail' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Profile/Detail/ContainerClusterContainerWorkloadProfileDetail')},
                  {name: 'edit', path: '/:profileId/edit', container: 'ContainerClusterContainerWorkloadProfileConfig', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloadProfileConfig' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Profile/Config/ContainerClusterContainerWorkloadProfileConfig')},
                ],
              },
              {name: 'workloads', path: '/workloads?:workloadlist', container: 'ContainerClusterWorkloads', load: () => import(/* webpackChunkName: 'ContainerClusterWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Workload/ContainerClusterWorkloads')},
              {name: 'vens', path: '/vens?:venslist', container: 'ContainerClusterVens', load: () => import(/* webpackChunkName: 'ContainerClusterVens' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/Ven/ContainerClusterVens')},
              {
                name: 'containerWorkloads',
                path: '/containerworkloads?:cclusterworkloadlist',
                container: 'ContainerClusterContainerWorkloads', load: () => import(/* webpackChunkName: 'ContainerClusterContainerWorkloads' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/ContainerWorkload/ContainerClusterContainerWorkloads'),
              },
              {
                name: 'serviceBackends',
                path: '/serviceBackends?:cclusterservicebackendlist',
                container: 'ContainerClusterServiceBackends', load: () => import(/* webpackChunkName: 'ContainerClusterServiceBackends' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Detail/ServiceBackend/ContainerClusterServiceBackends'),
              },
              {name: 'edit', path: '/edit', container: 'ContainerClusterConfig', load: () => import(/* webpackChunkName: 'ContainerClusterConfig' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/containers/ContainerCluster/Config/ContainerClusterConfig')},
            ],
          },
        ],
      },
      {
        name: 'outboundpolicy',
        path: '/outboundpolicy',
        forwardTo: '>list',
        children: [
          {
            name: 'list',
            path: '/?:pversion?:denyRules?:allowRules',
            defaultParams: {pversion: 'draft'},
            container: 'OutboundPolicy', load: () => import(/* webpackChunkName: 'OutboundPolicy' */ '/home/jenkins/BUILD_AREA/workspace/hor-pipeline_trains_edge_22.31.1/app/edge/containers/OutboundPolicy/OutboundPolicy'),
          },
        ],
      },
    ],
  },
  routesMerger,
);
