/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getRouteCurrentParams, getRouteParams, isEdge, isIPForwardingEnabled} from 'containers/App/AppState';
import {isHealthEnabled, isUserReadOnly, isUserScoped} from 'containers/User/UserState';
import {createSelector} from 'reselect';
import {getModifiedSections, getInitialRows} from './SecuritySettingsUtils';
import {getSelectorSettings, gridSettings, policyState} from './SecuritySettingsConfig';
import {getUrlFilterParam, getGridColumnIds} from 'components/Grid/GridSelectors';

export default {
  settings(state = {}, action) {
    switch (action.type) {
      case 'SECURITY_GET_SETTINGS':
        return action.data;
      default:
        return state;
    }
  },
};

export const getSecuritySettings = state => state.settings;

export const getGridSettings = createSelector(
  [getRouteParams, gridSettings, isEdge],
  ({section, mode}, gridSettings, isEdge) => {
    const clonedGrid = _.cloneDeep(gridSettings);
    const columns = {...clonedGrid.columns};

    // Generate grid id for each security section page section
    clonedGrid.id = `${gridSettings.id}-${section}`;

    columns.app.disabled = isEdge;
    columns.loc.disabled = isEdge;
    columns.env.disabled = isEdge;

    if (['staticscopes', 'containers', 'blockaction', 'ipforwarding', 'loopbackinterfaces'].includes(section)) {
      columns.state.disabled = true;
      columns.primaryFirewall.disabled = true;
    }

    if (mode !== 'edit') {
      columns.checkboxes.disabled = true;
      columns.action.disabled = true;
    }

    if (mode !== 'draft' && mode !== 'edit') {
      // Hide status column, not disable, to keep sorting on it between edit/view switch
      columns.status.hidden = true;
    }

    return {...clonedGrid, columns};
  },
);

const getFilterMapAndStaticValues = createSelector(
  [getRouteCurrentParams, getSelectorSettings],
  ({mode, section}, selectorSettingsObject) => {
    //Take relevant selector categories based on mode and section
    //Remove provision status category from selector in active/edit mode
    //Remove state and primaryFirewall categories from selector in staticscopes section
    const filterByUrlParam = obj =>
      Object.keys(obj).reduce((result, key) => {
        if (
          (mode !== 'draft' && key === 'status') ||
          (['staticscopes', 'containers', 'blockaction', 'ipforwarding', 'loopbackinterfaces'].includes(section) &&
            (key === 'state' || key === 'primaryFirewall')) ||
          (section === 'firewallcoexistence' && key === 'label_groups')
        ) {
          return result;
        }

        result[key] = obj[key];

        return result;
      }, {});

    return {
      filterMap: filterByUrlParam(selectorSettingsObject.filterMap),
      staticValues: filterByUrlParam(selectorSettingsObject.staticValues),
    };
  },
);

export const getScopeAndLabelRows = createSelector(
  [getSecuritySettings, getRouteCurrentParams],
  (securitySettings, {mode, section}) => getInitialRows(mode, section, securitySettings),
);

const getFilters = state =>
  getUrlFilterParam(state, {
    settings: getGridSettings,
    filterMap: createSelector(getFilterMapAndStaticValues, filter => filter.filterMap),
  });

export const getFirewallSettingsPage = createSelector(
  [
    getSecuritySettings,
    getGridSettings,
    getRouteCurrentParams,
    isUserReadOnly,
    getFilterMapAndStaticValues,
    getScopeAndLabelRows,
    getFilters,
    getSelectorSettings,
    isIPForwardingEnabled,
    isEdge,
    isHealthEnabled,
    // Make page dependent on columns to rerender if seen colums are changed
    state => getGridColumnIds(state, {settings: getGridSettings}),
  ],
  (
    securitySettings,
    gridSettings,
    {section, mode},
    userIsReadOnly,
    {filterMap, staticValues},
    initialRows,
    filter,
    selectorSettingsObject,
    isIPForwardingEnabled,
    isEdge,
    isHealthEnabled,
  ) => {
    const draftSettings = securitySettings && securitySettings.draft;
    const activeSettings = securitySettings && securitySettings.active;
    let modifiedSections = {};
    const currSettings = securitySettings ? (mode === 'active' ? activeSettings : draftSettings) : {};

    // Populate modifiedSections object
    const modified = getModifiedSections(securitySettings);

    if (mode === 'draft') {
      modifiedSections = modified;
    }

    const hasDraftVersion = !_.isEmpty(modified);

    const initialItems = Object.keys(filter.valid).reduce((result, categoryKey) => {
      if (selectorSettingsObject.scopeMap[categoryKey]) {
        const filterItems = filter.valid[categoryKey];

        filterItems.forEach(scope => {
          result.push({
            ...scope,
            categoryKey,
            categoryName: filterMap[categoryKey].value || filterMap[categoryKey],
          });
        });
      } else {
        result.push({
          categoryKey,
          value: filter.valid[categoryKey][0].value,
          categoryName: filterMap[categoryKey].value || filterMap[categoryKey],
        });
      }

      return result;
    }, []);
    // Selector parameters based on filter and config
    const selector = {
      initialItems,
      objects: Object.values(selectorSettingsObject.objectMap),
      categories: Object.entries(filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        ...value,
      })),
      statics: Object.entries(staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    const ipv6 = currSettings.allow_ipv6 ? 'allowed' : 'blocked';
    const ipv6Mode = currSettings.ipv6_mode || 'policy_based';

    return {
      isEdit: mode === 'edit',
      section,
      mode,
      modifiedSections,
      hasDraftVersion,
      userIsReadOnly,
      currSettings,
      securitySettings,
      initialRows,
      selector,
      filter: filter && filter.valid,
      gridSettings,
      ipv6,
      ipv6Mode,
      policyState,
      isEdge,
      isHealthEnabled,
      isIPForwardingEnabled,
    };
  },
);

export const isSecuritySettingsEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
