/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {HeaderProps} from 'containers';
import WorkloadReducers from 'containers/Workload/WorkloadState';
import LabelReducers from '../Label/LabelState';
import ReportsReducers from '../Reports/ReportsState';
import {TabPanel} from 'components';
import {fetchStatuses} from './WorkloadManagementSaga';
import {getWorkloadManagementStatus} from './WorkloadManagementSatate';

@connect(getWorkloadManagementStatus)
export default class WorkloadManagement extends PureComponent {
  static prefetch = fetchStatuses;
  static reducers = [LabelReducers, ReportsReducers, WorkloadReducers];

  render() {
    const {routeName, isEdge, isCrowdstrike, status} = this.props;

    return (
      <>
        <HeaderProps title={isCrowdstrike ? intl('Common.Workloads') : intl('Common.WorkloadManagement')} />
        {routeName.endsWith('.list') && (
          <TabPanel key="tabs">
            {[
              {
                link: 'workloads.list',
                text: intl('Common.Workloads'),
                tid: 'workloads',
                icon: status.workloadError ? 'info' : status.workloadWarning ? 'warning' : null,
                iconStyle: status.workloadError ? 'error' : status.workloadWarning ? 'warning' : null,
              },
              !__ANTMAN__ &&
                !isEdge && {
                  link: 'workloads.containers.list',
                  text: intl('Common.ContainerWorkloads'),
                  tid: 'container-workloads',
                },
              !isCrowdstrike && {
                link: 'workloads.vens.list',
                text: intl('Common.VENS'),
                tid: 'vens',
                icon: status.venError ? 'info' : status.venWarning ? 'warning' : null,
                iconStyle: status.venError ? 'error' : status.venWarning ? 'warning' : null,
              },
            ]}
          </TabPanel>
        )}
        {this.props.children}
      </>
    );
  }
}
