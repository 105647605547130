/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
/* intl-disable */
import intl from 'intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base', numeric: true});

export const staticValues = createSelector([], () => ({
  protocol: {
    [intl('Protocol.TCP')]: [{name: intl('Protocol.TCP'), value: 6}],
    [intl('Protocol.UDP')]: [{name: intl('Protocol.UDP'), value: 17}],
    [intl('Protocol.ICMP')]: [{name: intl('Protocol.ICMP'), value: 1}],
    [intl('Protocol.ICMPv6')]: [{name: intl('Protocol.ICMPv6'), value: 58}],
  },
}));

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'flowcollectionfilterslist',
  sort: '-action',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    action: {
      header: intl('Common.Action'),
      value: 'action',
    },
    transmission: {
      header: intl('Components.Transmission'),
      value: 'transmission',
    },
    protocol: {
      header: intl('Common.Protocol'),
      value: 'protocol',
    },
    destinationip: {
      header: intl('Common.DestinationIP'),
      value: 'destinationip',
    },
    destinationport: {
      header: intl('Common.DestinationPort'),
      value: 'destinationport',
      sortFunction: ({a, b, sortFactor}) =>
        collator.compare(a.data.destinationport, b.data.destinationport) * sortFactor,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    id: ?string, // Optional breakpoint id, goes to cellFormat function
    data: ?object, // Object with any data, goes to cellFormat function
    maxWidth: number, // Maximum width for breakpoint, minimum will be calculated automatically, goes to cellFormat function
    template: string | Function, // Columns configuration
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['action'], size: 'minmax(120px, auto)'},
      {columns: ['transmission'], size: 'minmax(100px, auto)'},
      {columns: ['protocol'], size: 'minmax(120px, auto)'},
      {columns: ['destinationip'], size: 'minmax(120px, auto)'},
      {columns: ['destinationport'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 960,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['action'], size: 'max-content'},
          {columns: ['transmission', 'protocol'], size: 'minmax(120px, auto)'},
          {columns: ['destinationip'], size: 'minmax(120px, auto)'},
          {columns: ['destinationport'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['action'], size: 'minmax(120px, auto)'},
          {columns: ['transmission', 'protocol'], size: 'minmax(120px, auto)'},
          {columns: ['destinationip'], size: 'minmax(120px, auto)'},
          {columns: ['destinationport'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['action'], size: 'minmax(100px, auto)'},
            {columns: ['transmission', 'protocol'], size: 'minmax(120px, auto)'},
            {columns: ['destinationip', 'destinationport'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['action', 'transmission', 'protocol'], size: 'minmax(100px, auto)'},
          {columns: ['destinationip', 'destinationport'], size: 'minmax(120px, auto)'},
        ];
      },
    },
  ],
}));
