/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {reactUtils} from 'utils';
import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {AppContext} from 'containers/App/AppUtils';
import {Button, Form, AttributeList, Modal, TypedMessages} from 'components';
import {setRecoveryMode} from '../VenSaga';
import {object, string, number} from 'yup';
import styles from './VenRecoveryMode.css';

const getEmptyState = () => ({
  showModal: false,
  saving: false,
  error: null,
});

const timeSelector = [
  {label: intl('Common.Hours'), value: 'hour'},
  {label: intl('Common.Days'), value: 'day'},
];

export default class VenRecoveryMode extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    hrefs: PropTypes.instanceOf(Set).isRequired,

    onDone: PropTypes.func,
    onCancel: PropTypes.func,
    onButtonHover: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onButtonHover: _.noop,
    onDone: _.noop,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setRecoveryMode = this.setRecoveryMode.bind(this);

    this.initialValues = {
      duration: '1',
      time: timeSelector[0],
    };

    this.schemas = object({
      time: string(),
      duration: number()
        .typeError(intl('VEN.SetRecoveryModeInteWarning'))
        .positive(intl('VEN.SetRecoveryModeInteWarning'))
        .integer(intl('VEN.SetRecoveryModeInteWarning'))
        .required(intl('VEN.SetRecoveryModeReqWarning'))
        .test(
          'out-of-range',
          () =>
            this.formik.values.time.value === 'hour'
              ? intl('VEN.SetRecoveryModeHourWarning')
              : intl('VEN.SetRecoveryModeDayWarning'),
          // conditionally check what logic to use for hour or day
          val => {
            if (this.formik.values.time.value === 'hour') {
              if (val > 720) {
                return false;
              }
            } else if (val > 30) {
              return false;
            }

            return true;
          },
        ),
    });
  }

  async setRecoveryMode() {
    const {
      context: {fetcher},
      formik: {values},
      props: {hrefs},
    } = this;

    let seconds;
    let error;

    if (values.time.value === 'hour') {
      seconds = values.duration * 3600;
    } else if (values.time.value === 'day') {
      seconds = values.duration * 3600 * 24;
    }

    try {
      await reactUtils.setStateAsync({saving: true}, this);
      error = await fetcher.fork(setRecoveryMode, {
        hrefs: Array.from(hrefs, href => ({href})),
        seconds,
      });

      if (error && error.length > 0) {
        this.setState(({showModal}) => ({showModal: {...showModal, error}, saving: false}));
      }

      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false}));
      await this.props.onDone();
      this.handleClose();
    } catch (error) {
      this.setState(({showModal}) => ({showModal: {...showModal, error}, saving: false}));
    }
  }

  handleButtonClick() {
    this.setState({showModal: true});
  }

  handleClose() {
    this.setState(getEmptyState());
  }

  renderModal() {
    const {
      state: {showModal, onSaveDone, saving},
      props: {rowsMap, summary},
    } = this;

    const error = showModal.error;

    const renderListItems = hrefs => {
      return (
        <ol>
          {hrefs.map(href => {
            return <li key={href}>{rowsMap.get(href).data.hostname}</li>;
          })}
        </ol>
      );
    };

    if (error) {
      return (
        <Modal large notResizable onClose={this.handleClose}>
          <Modal.Header title={intl('VEN.SetRecoveryModeError')} />
          <Modal.Content notScrollable>
            <TypedMessages key="errors">
              {error.map(error => ({
                icon: 'error',
                content:
                  summary || !error.hrefs ? (
                    error.message
                  ) : (
                    <>
                      {error.message}: {renderListItems(error.hrefs)}
                    </>
                  ),
              }))}
            </TypedMessages>
          </Modal.Content>
          <Modal.Footer>
            <Button key="primary" text={intl('Common.OK')} tid="ok" onClick={this.handleClose} />
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Modal large notResizable onClose={this.handleClose}>
        <Modal.Header title={intl('VEN.SetRecoveryMode')} />
        <Form schemas={this.schemas} initialValues={this.initialValues} allowLeaveOnDirty>
          {options => {
            this.formik = options;

            const {values} = options;

            return (
              <>
                <Modal.Content notScrollable>
                  <AttributeList>
                    {[
                      {
                        value: (
                          <AttributeList theme={styles}>
                            {[
                              {
                                key: (
                                  <Form.Label
                                    name="rotationInterval"
                                    title={intl('VEN.RecoveryModeDuration')}
                                    lineHeight="var(--30px)"
                                  />
                                ),
                                value: (
                                  <div className={styles.customContainer}>
                                    <div className={styles.duration}>
                                      <Form.Input
                                        tid="duration"
                                        name="duration"
                                        placeholder={intl('VEN.RecoveryModeSecondaryPlaceholder')}
                                      />
                                    </div>
                                    <div className={styles.time}>
                                      <Form.Selector name="time" tid="time" options={timeSelector} />
                                    </div>
                                  </div>
                                ),
                                secondary: intl('VEN.RecoveryModeSecondaryText'),
                              },
                            ]}
                          </AttributeList>
                        ),
                      },
                      {
                        value: intl('VEN.RecoveryModeDescription'),
                      },
                      {
                        value: intl('VEN.RecoveryModeSecondDescription'),
                      },
                    ]}
                  </AttributeList>
                </Modal.Content>
                <Modal.Footer>
                  <Button text={intl('Common.Cancel')} tid="cancel" onClick={this.handleClose} noFill />
                  <Button
                    text={intl('Common.Confirm')}
                    tid="confirm"
                    disabled={!_.isEmpty(options.errors)}
                    onClick={_.partial(this.setRecoveryMode, values)}
                    onProgressDone={onSaveDone}
                    progressCompleteWithCheckmark
                    progress={saving}
                    progressError={error?.length > 0}
                  />
                </Modal.Footer>
              </>
            );
          }}
        </Form>
      </Modal>
    );
  }

  render() {
    const {
      props: {hrefs, disabled, showCounter},
      state: {showModal},
    } = this;

    return (
      <>
        <Button
          color="standard"
          textIsHideable
          text={intl('VEN.SetRecoveryMode')}
          tid="recoverymode"
          counter={showCounter ? hrefs.size : undefined}
          counterColor="yellow"
          disabled={!hrefs.size || disabled}
          onClick={this.handleButtonClick}
        />
        {showModal && this.renderModal()}
      </>
    );
  }
}
