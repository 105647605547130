/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {getIPItem} from '../IPListItemState';
import IPListReducers from 'containers/IPList/IPListState';
import {updateIPList, createIPList, fetchIPItem, fetchIpMatches, verifyUserPermissions} from '../IPListItemSaga';
import {array, object, string} from 'yup';
import {
  AttributeList,
  Button,
  Modal,
  ToolBar,
  ToolGroup,
  TypedMessages,
  Form,
  InfoCard,
  StatusIcon,
  Checkbox,
  Link,
} from 'components';
import {reactUtils, hrefUtils} from 'utils';
import {setExistingName, isExcludeName, getDuplicateObject} from 'containers/FormComponents/duplicateValidation';
import {fetchPending} from 'containers/Provisioning/ProvisioningSaga';
import {validateIpList, getIPInstructions, getFQDNInstructions} from '../../IPListUtils';
import {processIpItems} from './IPListEditUtils';
import {HeaderProps} from 'containers';
import {randomString} from 'utils/general';
import {formatDataReference} from 'utils/dataValidation';

const IP_NAMES = 'ipNames';
const FQDN_NAMES = 'fqdnNames';

// Initial Values
const getInitialValues = props => {
  const {ip = {}} = props;
  // Important to initialize values to avoid controlled vs uncontrolled warnings
  const {name = '', description = '', ipNames = [], fqdnNames = []} = ip;

  return {
    [IP_NAMES]: ipNames,
    [FQDN_NAMES]: fqdnNames,
    name,
    description,
  };
};

// Initial State
const getInitialState = props => ({
  initialValues: getInitialValues(props),
  // Initial values to convert to DraftJS data type
  initialEditorStateValues: props.ip?.ip_ranges || null,
  initialEditorStateFQDN: props.ip?.fqdn || null,
  externalDataReference: props.ip?.draft?.external_data_reference,
  // Flag to determine validation on IPs
  disableValidation: false,
});

// we need to ignore selector when container is controlled, otherwise selector will overwrite props
const mapStateToProps = (state, props) => (props.controlled ? {} : getIPItem(state));

@connect(mapStateToProps, null, null, {forwardRef: true})
export default class IPListEdit extends Component {
  static prefetch = verifyUserPermissions;
  static contextType = AppContext;
  static reducers = IPListReducers;

  static propTypes = {
    ip: PropTypes.object,
    controlled: PropTypes.bool,
    noFQDN: PropTypes.bool,
    initiallyOpened: PropTypes.bool,
    edgeEnabled: PropTypes.bool,
    fetchedIPList: PropTypes.array,
    noExperimental: PropTypes.bool,
    excludeNames: PropTypes.array, // checking for exclude names in isExcludeName fn
    excludeNameMessage: PropTypes.string, // error message in case user hits one of the excluded names
  };

  static defaultProps = {
    noFQDN: false,
    controlled: false,
    ip: {},
    fetchedIPList: [],
    noExperimental: true,
  };

  constructor(props) {
    super(props);

    this.state = getInitialState(props);

    this.infoCardIconRef = createRef();

    this.infoCardIconFQDNRef = createRef();

    // editorStateErrors is used as a check in validateEditorState()
    this.editorStateErrors = {
      [IP_NAMES]: {},
      [FQDN_NAMES]: {},
    };

    // Use to determine if a ip name already exist setting this doesn't require re-render
    this.existingNameError = false;

    // Use IP_NAMES, FQDN_NAMES as a class property because setting it doesn't require a re-render
    this[IP_NAMES] = null;
    this[FQDN_NAMES] = null;

    // Variable to handle editorErrors setting this doesn't require re-render
    this.editorErrors = {};

    // Note:  ['ipNames', 'fqdnNames'] is passed to shape() to avoid cyclic console error with Yup Schemas.
    // https://github.com/jquense/yup/issues/193
    this.schemas = object().shape(
      {
        [IP_NAMES]: array().when(FQDN_NAMES, {
          is: data =>
            // When FQDN_NAMES has data and IP_NAMES is empty then this field is not required
            data?.length > 0 && this.formik.values[IP_NAMES].length === 0,
          then: array(),
          otherwise: array().required(),
        }),
        [FQDN_NAMES]: array().when(IP_NAMES, {
          is: data =>
            // When IP_NAMES has data and FQDN_NAMES is empty then this field is not required
            data?.length > 0 && this.formik.values[FQDN_NAMES].length === 0,
          then: array(),
          otherwise: array().required(),
        }),
        name: string()
          .max(255, intl('Common.NameIsTooLong'))
          .test(
            'is-duplicate',
            () => {
              if (this.duplicateObject) {
                return intl(
                  'Common.NameExist',
                  {
                    value: (
                      <Link
                        to="iplists.item"
                        params={{id: hrefUtils.getId(this.duplicateObject.href), pversion: 'draft'}}
                      >
                        {this.duplicateObject.name}
                      </Link>
                    ),
                  },
                  {jsx: true},
                );
              }

              return this.props.excludeNameMessage ?? intl('Common.NameIsNotAllowed');
            },
            () =>
              // During an onBlur (navigating away), need to preserve the error message when there is a name clash
              !this.existingNameError,
          )
          .required(Form.emptyMessage),
        description: string().max(255, intl('Common.NamespaceIsTooLong', {namespace: intl('Common.Description')})),
      },
      [['ipNames', 'fqdnNames']],
    );

    this.renderForm = this.renderForm.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOnConfirmationCancel = this.handleOnConfirmationCancel.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getIPEditorElement = this.getIPEditorElement.bind(this);
    this.validateEditorState = this.validateEditorState.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.getPayload = this.getPayload.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.validateAndUpdateName = _.debounce(this.validateAndUpdateName.bind(this), 500);
    this.handleFacetErrorClose = this.handleFacetErrorClose.bind(this);
    this.handleDisableValidation = this.handleDisableValidation.bind(this);
    this.duplicateObject = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Use _.xor to determine intersection equality
    const intersection = _.xor(nextProps.ip?.ipNames, prevState.initialValues.ipNames);

    // Check is made in case a route e.g. 'When clicking on About Illumio ASP'  has changed but the data integrity is the same thus don't
    // want to re-initialize current editorState unless the api data changed.
    if (intersection.length) {
      // There is a difference
      return {
        initialValues: getInitialValues(nextProps),
      };
    }

    return null;
  }

  // Get IP DraftJS component with necessary props to initialize Editor
  getIPEditorElement() {
    const {noExperimental, isEdit} = this.props;
    const {disableValidation} = this.state;

    return (
      <Form.IPListEditor
        name={IP_NAMES}
        noFQDN
        showModified={isEdit}
        disableValidation={disableValidation}
        noExperimental={noExperimental}
        placeholder={intl('IPLists.TypeOrPasteIPs')}
        validate={_.partial(this.validateEditorState, IP_NAMES)}
        initialValue={this.state.initialEditorStateValues}
        onChange={this.handleOnChange(this.state.initialValues[IP_NAMES])}
      />
    );
  }

  // Get FQDN DraftJS component with necessary props to initialize Editor
  getFqdnElement() {
    const {noExperimental, isEdit} = this.props;
    const {disableValidation} = this.state;

    return (
      <Form.IPListEditor
        name={FQDN_NAMES}
        noIP
        showModified={isEdit}
        disableValidation={disableValidation}
        noExperimental={noExperimental}
        validate={_.partial(this.validateEditorState, FQDN_NAMES)}
        initialValue={this.state.initialEditorStateFQDN}
        onChange={this.handleOnChange(this.state.initialValues[FQDN_NAMES])}
      />
    );
  }

  // Get the payload for this container
  getPayload() {
    const {values} = this.formik;
    const {noFQDN} = this.props;

    // concat IP_NAMES and FQDN_NAMES: List<Map> to JavaScript Objects by using toJS()
    const ipRanges = this[IP_NAMES].toJS();

    const ipInfo = {
      name: values.name,
      description: values.description,
      // convert this[IP_NAMES]: List<Map> to JavaScript Objects
      ip_ranges: ipRanges,
    };

    // Don't need to add FQDN when noFQDN is not needed
    if (!noFQDN) {
      ipInfo.ip_ranges = ipRanges.concat(this[FQDN_NAMES].toJS());
      ipRanges.concat(this[FQDN_NAMES].toJS());
    }

    // Properties used for parent
    return {
      payload: validateIpList(ipInfo),
      key: randomString(),
      sagaName: 'ip_lists.create',
    };
  }

  // Handle  enabling validation of IPs
  handleDisableValidation() {
    this.setState(state => ({disableValidation: !state.disableValidation}));
  }

  // Handle Facet Error
  handleFacetErrorClose() {
    this.setState({facet: null});
  }

  // Handle Edit Error
  handleErrorClose() {
    this.setState({error: null});
  }

  // Handle Cancel Confirmation
  handleOnConfirmationCancel() {
    const {
      props: {id},
      context: {navigate},
    } = this;

    // Note: When clicking on cancel when a change was made don't need to rely on popup for confirmation
    if (id) {
      navigate({to: 'iplists.item.view', params: {id, pversion: 'draft'}});
    } else {
      navigate({to: 'iplists.list'});
    }
  }

  // Handle the Editor onchange
  handleOnChange(defaultNames) {
    return (editorState, name, {ipErrors} = {}) => {
      const {setFieldValue} = this.formik;
      // Pass in the initialValues.[ipNames, fqdnNames] for formik to update 'isValid' flag properly. Since
      // ipNames is an array changing the ordering even though data is the same is considered dirty
      const {currentValue} = Form.IPListEditor.Utils.getCurrentDataCheck(defaultNames, editorState);

      // Save ipRanges OR fqdnData to class property to avoid formik validation dirty check since ipRanges
      // is an array of objects where the reference will change because of DraftJS editorState data type
      this[name] = processIpItems(editorState);

      this.editorStateErrors[name] = ipErrors[name];

      setFieldValue(name, currentValue);
    };
  }

  // Handle the api calls and logic parsing when Save is clicked
  async handleSave() {
    const {
      context: {fetcher, navigate},
      props: {isEdit, noFQDN},
      formik: {values},
    } = this;

    let id = this.props.id;

    if (!this[IP_NAMES] && !this[FQDN_NAMES]) {
      // Don't make any API calls for empty data
      return;
    }

    // concat IP_NAMES and FQDN_NAMES: List<Map> to JavaScript Objects by using toJS()
    let ipRanges = this[IP_NAMES].toJS();

    // Don't need to add FQDN when noFQDN is not needed
    if (!noFQDN) {
      ipRanges = ipRanges.concat(this[FQDN_NAMES].toJS());
    }

    // Properties that is needed for validating ip list
    const ipInfo = {
      name: values.name,
      description: values.description,
      // ipRanges is the data from IP_NAMES and FQDN_NAMES
      ip_ranges: ipRanges,
    };

    // Validate ipInfo by returning payload to pass to API
    const payload = validateIpList(ipInfo);

    try {
      // setStateAsync is used to call the render() with updated state properties, will not wait for batching
      // will call render() immediately
      await reactUtils.setStateAsync({saving: true}, this);

      if (isEdit) {
        const {externalDataReference} = this.state;

        if (externalDataReference) {
          payload.external_data_reference = formatDataReference(externalDataReference);
        }

        await fetcher.spawn(updateIPList, id, payload);
      } else {
        const {
          data: {href},
        } = await fetcher.spawn(createIPList, payload);

        id = hrefUtils.getId(href);
      }

      // Need to update ip instance reducers
      await fetcher.fork(fetchIPItem, {name: 'iplists.item.view', params: {id, pversion: 'draft'}}, true);

      // Update pending counnter, don't wait
      fetcher.spawn(fetchPending);

      // Set onSaveDone as a resolve promise reference to handle Button callback onProgressDone
      await new Promise(onSaveDone => this.setState({onSaveDone, saving: false, error: null}));
      // Navigate to a view page in draft mode whenever update happens
      navigate({to: 'iplists.item.view', params: {pversion: 'draft', id}});
    } catch (error) {
      await reactUtils.setStateAsync({saving: false, error}, this);
    }
  }

  // Handle the input field
  async handleNameChange(evt) {
    const {setFieldValue, validateForm} = this.formik;
    const value = evt.target.value;

    // Update the Form.Input name value since self component is controlling
    setFieldValue('name', value);

    // Reset the existingNameError here to prevent seeing the deleted character delay
    if (this.existingNameError) {
      this.existingNameError = false;
      // Call to validateForm formik's schema
      validateForm();
    }

    // Don't need to call debounce when value is empty
    if (value.trim()) {
      // Note: Invoke debounce here to delay after calling setFieldValue for formik's values to update properly
      this.validateAndUpdateName();
    }
  }

  // Validating name if it's already existing
  async validateAndUpdateName() {
    const {
      context: {fetcher},
      props: {excludeNames},
      formik: {values},
    } = this;

    this.duplicateObject = null;

    if (isExcludeName(excludeNames, this.formik.values.name, this.state.initialValues.name)) {
      this.existingNameError = true;
      setExistingName(this.formik);

      return;
    }

    // Note: Important to trim value to pass to facet API
    const value = values.name.trim();

    // Don't need to make request when the original ip name match current in addition make only
    // request when there is a value.
    if (value && this.formik.initialValues.name !== value) {
      if (this.fetchMatches) {
        // Cancel task if it is still running
        fetcher.cancel(this.fetchMatches);
      }

      this.fetchMatches = fetcher.fork(fetchIpMatches, {
        query: {facet: 'name', query: value, max_results: 1},
        params: {pversion: 'draft'},
      });

      try {
        const {data} = await this.fetchMatches;

        // returns duplicated object of Input request with a link (href) to render in errorMessage of Input
        this.duplicateObject = getDuplicateObject(data?.matches, this.formik.values.name);

        if (this.duplicateObject) {
          this.existingNameError = true;
          setExistingName(this.formik);

          return;
        }
      } catch (error) {
        await reactUtils.setStateAsync({facet: {error}}, this);
      }
    }
  }

  // Handle Validation
  validateEditorState(name) {
    const {disableValidation} = this.state;

    // Return emptyMessage to indicate error
    // Note: Yup schemas can overwrite the return error message that is returned here
    // Yup schemas will be called after validateEditorState()
    if (this.editorStateErrors[name].error === true && !disableValidation) {
      return Form.emptyMessage;
    }
  }

  // Render alert message when error or create fails
  renderEditAlert() {
    const {error} = this.state;
    const {isEdit} = this.props;
    // Index of error to retrieve from error response
    let errorIndex = 0;

    // There is a edge case where the API returns two tokens for invalid IPs that are passed with validation disabled.
    // This condition looks for the index of the human readable token (ip_address_invalid).
    if (error?.data?.length === 2) {
      const {data: errorData} = error;

      // Map to track error token to indexes
      const indexedErrorTokens = errorData.reduce((tokens, current, index) => {
        tokens[current.token] = {errorIndex: index};

        return tokens;
      }, {});

      if (indexedErrorTokens.from_ip_required && indexedErrorTokens.ip_address_invalid) {
        errorIndex = indexedErrorTokens.ip_address_invalid.errorIndex;
      }
    }

    const token = _.get(error, `data[${errorIndex}].token`);
    const title = isEdit ? intl('IPLists.Errors.Edit') : intl('IPLists.Errors.Create');
    const message =
      (token && intl(`ErrorsAPI.err:${token}`)) || _.get(error, `data[${errorIndex}].message`, error.message);

    return (
      <Modal.Alert title={title} onClose={this.handleErrorClose} buttonProps={{tid: 'ok', text: intl('Common.OK')}}>
        <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  // Render alert message when facets request fails
  renderFacetAlert() {
    const {facet} = this.state;

    const message = facet?.error?.message;

    return (
      <Modal.Alert
        title={intl('IPLists.IpName')}
        onClose={this.handleFacetErrorClose}
        buttonProps={{tid: 'ok', text: intl('Common.OK')}}
      >
        <TypedMessages>
          {[
            {icon: 'error', content: message},
            {icon: 'error', content: intl('IPLists.Errors.RequestFacetFail')},
          ]}
        </TypedMessages>
      </Modal.Alert>
    );
  }

  renderForm(options) {
    this.formik = options;

    const {saving, onSaveDone, error, facet, disableValidation} = this.state;
    const {isValid} = this.formik;
    const {controlled, noFQDN} = this.props;

    // When either ip or fqdn has an error disable
    const hasTouchedError = Object.values(this.editorStateErrors).some(error => error.untouchedError || error.error);

    // Set disabled by also checking !saving to avoid mutual exclusive conflicts with progress={saving} when saving is true
    // Both saving and disabled props cannot be the same.
    const disabled = hasTouchedError || (isValid === false && !saving);

    const form = (
      <>
        {!controlled && (
          <ToolBar>
            <ToolGroup>
              <Button
                icon="save"
                type="submit"
                tid="save"
                disabled={disabled}
                text={intl('Common.Save')}
                progressCompleteWithCheckmark
                progress={saving}
                progressError={error !== null}
                onClick={this.handleSave}
                onProgressDone={onSaveDone}
              />
              <Button
                color="standard"
                disabled={saving || Boolean(onSaveDone)}
                icon="cancel"
                tid="cancel"
                text={intl('Common.Cancel')}
                onClick={this.handleOnConfirmationCancel}
              />
            </ToolGroup>
            <Checkbox
              label={
                <>
                  {intl('IPLists.EnableValidation')}
                  <StatusIcon
                    tooltip={intl('IPLists.EnableValidationTooltip')}
                    tooltipProps={{instant: true, bottom: true, maxWidth: 480}}
                    status="info"
                    position="after"
                  />
                </>
              }
              data-tid="show-validation"
              checked={disableValidation}
              onChange={this.handleDisableValidation}
            />
          </ToolBar>
        )}
        <AttributeList valuesGap="gapLarge">
          {[
            controlled ? null : {divider: true},
            {title: intl('Common.General')},
            {
              key: <Form.Label name="name" title={intl('Common.Name')} />,
              value: (
                <Form.Input
                  name="name"
                  tid="name"
                  autoFocus
                  onChange={this.handleNameChange}
                  placeholder={intl('IPLists.Mixin.IPListName')}
                />
              ),
            },
            {
              key: <Form.Label name="description" title={intl('Common.Description')} />,
              value: (
                <Form.Textarea
                  name="description"
                  tid="description"
                  placeholder={intl('IPLists.Mixin.IPListDescription')}
                />
              ),
            },
            {
              key: <Form.Label name={IP_NAMES} title={intl('IPLists.IPAddresses')} />,
              tid: 'iplists-edit-ip',
              value: (
                <>
                  {this.getIPEditorElement()}
                  <InfoCard trigger={this.infoCardIconRef}>{() => getIPInstructions()}</InfoCard>
                </>
              ),
              icon: <InfoCard.Icon ref={this.infoCardIconRef} />,
            },
            !noFQDN
              ? {
                  key: <Form.Label name={FQDN_NAMES} title={intl('PCE.FQDN')} />,
                  tid: 'iplists-edit-fqdn',
                  value: (
                    <>
                      {this.getFqdnElement()}
                      <InfoCard trigger={this.infoCardIconFQDNRef}>{() => getFQDNInstructions()}</InfoCard>
                    </>
                  ),
                  icon: <InfoCard.Icon ref={this.infoCardIconFQDNRef} />,
                }
              : null,
          ]}
        </AttributeList>
        {error && this.renderEditAlert()}
        {facet && this.renderFacetAlert()}
      </>
    );

    return controlled ? (
      <div
        onKeyUp={_.partial(this.props.onValid, disabled)}
        onMouseMove={_.partial(this.props.onValid, disabled)}
        onMouseUp={_.partial(this.props.onValid, disabled)}
      >
        {form}
      </div>
    ) : (
      form
    );
  }

  render() {
    const {isEdit, controlled, edgeEnabled} = this.props;
    const {
      initialValues: {name},
    } = this.state;

    return (
      <>
        {!controlled && (
          <HeaderProps
            title={edgeEnabled ? intl('Common.IPRange') : intl('Common.IPList')}
            subtitle={name}
            label={`(${intl(isEdit ? 'Common.Edit' : 'Common.Create')})`}
            up={{onClick: this.handleOnConfirmationCancel}}
          />
        )}
        <Form enableReinitialize schemas={this.schemas} initialValues={this.state.initialValues}>
          {this.renderForm}
        </Form>
      </>
    );
  }
}
