/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName, getRoutePreviousName, getRouteCurrentParams} from 'containers/App/AppState';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'LABELS_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getLabelInstance = state => state.label.instance;

export const getLabel = createSelector([getRouteName, getLabelInstance], (routeName, label) =>
  routeName === 'app.labels.create' ? {} : label,
);

export const getLabelEdit = createSelector(
  [getRouteName, getLabel, getRoutePreviousName, getRouteCurrentParams],
  (routeName, label, previousRouteName, currentRouteParams) => ({
    label,
    previousRouteName,
    routeName,
    currentRouteParams,
  }),
);
