/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {isUserOwner} from 'containers/User/UserState';
import {reverseProviderConsumer, getOrg, getAdvancedRulesetDisplay, isEdge} from 'containers/App/AppState';

export const isPolicySettingsEnabled = createSelector(isUserOwner, userIsOwner => userIsOwner);

const requireSecPolicyCommitMessage = createSelector(getOrg, org => org.require_sec_policy_commit_message);

const providerConsumerOrder = createSelector(reverseProviderConsumer, reverseProviderConsumer =>
  reverseProviderConsumer ? 'consumerFirst' : 'providerFirst',
);

export const getPolicySettings = createStructuredSelector({
  requireSecPolicyCommitMessage,
  providerConsumerOrder,
  advancedRulesetDisplay: getAdvancedRulesetDisplay,
  isEdge,
});
