/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {HeaderProps} from 'containers';
import {TabPanel} from 'components';
import {fetchContainerWorkloadItem} from '../ContainerWorkloadSaga';
import ContainerWorkloadReducers from 'containers/ContainerWorkload/ContainerWorkloadState';
import {getContainerWorkloadItemPage} from './ContainerWokloadItemState';

@connect(getContainerWorkloadItemPage)
export default class ContainerWorkloadItem extends PureComponent {
  static prefetch = fetchContainerWorkloadItem;
  static reducers = [ContainerWorkloadReducers];

  render() {
    const {currentRouteParams, containerWorkload, routeName, userIsWithReducedScope} = this.props;

    const isEdit = routeName.endsWith('edit');

    return (
      <>
        <HeaderProps
          title={intl('Common.ContainerWorkloads')}
          subtitle={containerWorkload.name}
          up="workloads.containers"
        />
        <TabPanel color="primary">
          {[
            {
              link: 'workloads.containers.item.view',
              text: intl('Common.Summary'),
              params: {id: currentRouteParams.id},
              tid: 'containerworkloads-summary',
            },
            {
              link: 'workloads.containers.item.containers',
              text: intl('Common.Containers'),
              params: {id: currentRouteParams.id},
              tid: 'containerworkloads-containers',
              disabled: isEdit,
            },
            !userIsWithReducedScope && {
              link: 'workloads.containers.item.rules',
              text: intl('Common.Rules'),
              params: {id: currentRouteParams.id},
              tid: 'containerworkloads-rules',
              disabled: isEdit,
            },
          ]}
        </TabPanel>
        {this.props.children}
      </>
    );
  }
}
