/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect/lib/index';
import {getRouteParams, isEdge, isIPForwardingEnabled} from 'containers/App/AppState';
import {getMembers, staticsFunction} from './LabelGroupItemUtils';
import {getAllUsersMap, isUserReadOnly, isUserReadOnlyAll} from '../../User/UserState';
import {isAPIAvailable} from 'api/apiUtils';
import {fillPolicyObjUserInfo, getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {memberOfGridSettings, memberOfFilterMap} from './MemberOf/LabelGroupMemberOfConfig';
import {memberGridSettings, memberFilterMap} from './Members/LabelGroupMembersConfig';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_DETAIL':
        return action.data;
      default:
        return state;
    }
  },
  membersOfList(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_OF_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  membersOfCount(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_OF_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  membersOfData(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_OF_LIST':
        return action.data.static;
      default:
        return state;
    }
  },
  membersList(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  membersCount(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  membersData(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_LIST':
        return action.data.static;
      default:
        return state;
    }
  },
};

export const getLabelGroupDetail = state => state.labelGroup.detail;

export const getMemberOfData = state => state.labelGroup.membersOfData;
export const getMemberOfList = state => state.labelGroup.membersOfList;
export const getMemberOfCount = state => state.labelGroup.membersOfCount;

export const getMemberData = state => state.labelGroup.membersData;
export const getMemberList = state => state.labelGroup.membersList;
export const getMemberCount = state => state.labelGroup.membersCount;

export const getLabelGroupSummary = createSelector(
  [getRouteParams, getLabelGroupDetail, getAllUsersMap, isUserReadOnly, isIPForwardingEnabled],
  (params, labelGroupDetail, usersMap, isUserReadOnly, isIPForwardingEnabled) => {
    const labelGroupId = params.id;
    let {versions, isOldVersion, pversionObjIsDeleted} = getPolicyVersions(labelGroupDetail.detail, params.pversion);
    const policyVersion = isOldVersion ? Number(params.pversion) : params.pversion;

    versions = fillPolicyObjUserInfo(versions, usersMap);

    return {
      versions,
      policyVersion,
      isOldVersion,
      labelGroupId,
      labelGroupDetail,
      params,
      isUserReadOnly,
      pversionObjIsDeleted,
      isIPForwardingEnabled,
    };
  },
);

const getLabelGroupsMemberRows = createSelector(
  [getLabelGroupSummary, getRouteParams],
  ({versions, policyVersion}, params) => {
    let members = getMembers(versions.pversionObj, versions.prevPversionObj, policyVersion);

    const filter = params.memberlist ? JSON.parse(params.memberlist).filter : {};
    const labelGroupFilter = filter && filter.label_groups ? filter.label_groups : [];
    const labelFilter = filter && filter.labels ? filter.labels : [];

    members = members.map(member => ({
      ...member,
      type: versions.pversionObj.key || member.key,
      key: member.href,
      removable: isAPIAvailable('label_group.update') && (!member.update_type || member.update_type !== 'delete'),
      selectable: isAPIAvailable('label_group.update'),
      isGroup: member.href.indexOf('label_groups') > 0,
      name: member.name || member.value,
      value: member.name || member.value,
      filtered: !_.isEmpty(filter)
        ? member.href.indexOf('label_groups')
          ? labelGroupFilter.includes(member.name)
          : labelFilter.includes(member.value)
        : true,
    }));

    const memberStatics = staticsFunction(members);

    members = members.filter(member => member.filtered);

    return {members, memberStatics};
  },
);

const getMemberRows = createSelector(
  [getMemberList, getMemberCount, getLabelGroupSummary],
  (memberList, memberCount, {versions: {pversionObj}, policyVersion}) =>
    memberList.map(item => ({
      type: pversionObj.key || item.key,
      key: item.href,
      removable: isAPIAvailable('label_group.update') && (!item.update_type || item.update_type !== 'delete'),
      selectable: isAPIAvailable('label_group.update') && item.update_type !== 'delete',
      isGroup: item.href.indexOf('label_groups') > 0,
      name: item.name || item.value,
      value: item.name || item.value,
      update_type: item.update_type,
      policyVersion,
    })),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, memberGridSettings],
  (userIsReadOnlyAll, memberGridSettings) => {
    const columns = {...memberGridSettings.columns};

    columns.checkboxes.disabled = !isAPIAvailable('label_group.update') && userIsReadOnlyAll;

    return {...memberGridSettings, columns};
  },
);

const getMemberGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getMemberRows,
    filterMap: memberFilterMap(),
  });

export const getLabelGroupMember = createSelector(
  [getMemberGrid, getMemberCount, getMemberData, getLabelGroupSummary],
  (
    memberGrid,
    memberCount,
    memberData,
    {versions: {pversionObj, draft}, policyVersion, labelGroupId, isOldVersion},
  ) => {
    const initialItems = [];

    Object.entries(memberGrid.filter).forEach(item => {
      const categoryKey = item[0];
      const categoryName = memberFilterMap()[categoryKey];

      item[1].forEach(value => {
        initialItems.push({categoryKey, value, categoryName});
      });
    });

    const selector = {
      initialItems,
      objects: [
        {type: 'labels', key: pversionObj.key},
        {type: 'label_groups', pversion: 'draft', key: pversionObj.key},
      ],
      categories: [
        {categoryKey: 'labels', value: 'Labels'},
        {categoryKey: 'label_groups', value: 'Label Groups'},
      ],
      removeCheckbox: true,
    };

    return {
      memberGrid,
      memberCount,
      memberData,
      pversionObj,
      draft,
      policyVersion,
      labelGroupId,
      selector,
      isOldVersion,
    };
  },
);

const getMemberOfRows = createSelector([getMemberOfList, getLabelGroupSummary], (membersOf, {versions}) =>
  membersOf.map(item => ({
    key: item.href,
    type: versions.pversionObj.key,
    data: item,
  })),
);

const getMemberOfGrid = state =>
  getGridSelector(state, {
    settings: memberOfGridSettings,
    rows: getMemberOfRows,
    filterMap: memberOfFilterMap(),
  });

export const getLabelGroupMemberOfPage = createSelector(
  [getMemberOfGrid, getMemberOfData, getMemberOfCount, getLabelGroupSummary],
  (grid, memberOfData, memberOfCount, {versions}) => {
    const initialItems = [];

    Object.entries(grid.filter).forEach(item => {
      const categoryKey = item[0];
      const categoryName = memberOfFilterMap()[categoryKey];

      item[1].forEach(value => {
        initialItems.push({categoryKey, value, categoryName});
      });
    });

    const selector = {
      initialItems,
      objects: [{type: 'label_groups', pversion: 'draft', key: versions.pversionObj.key}],
      categories: [{categoryKey: 'label_groups', value: 'Label Groups'}],
    };

    return {grid, memberOfData, memberOfCount, versions, selector};
  },
);

export const getLabelGroupAddPage = createSelector(
  [getLabelGroupDetail, getLabelGroupsMemberRows, getMemberOfData, isEdge],
  ({detail}, {members}, memberOf, edgeEnabled) => ({detail, members, memberOf, edgeEnabled}),
);
