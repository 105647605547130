/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useLayoutEffect, useCallback, useRef, useMemo} from 'react';
import {KEY_BACK_SPACE, KEY_RETURN, KEY_TAB} from 'keycode-js';
import {Pill} from 'components';
import SelectedValue from './SelectedValue';
import styleUtils from 'utils.css';
import {
  VALUEPANEL_ID,
  useFilialPiety,
  getOptionId,
  getOptionById,
  getOptionText,
} from 'containers/Selector/SelectorUtils';

export default function SelectedResource(props) {
  const {
    resource,
    resource: {
      id,
      categoryId,
      displayResourceAsCategory,
      name,
      optionProps: {idPath, textPath} = {},
      selectedProps = {},
      selectedProps: {formatResource} = {},
    },
    disabled,
    insensitive,
    registerHandlers,
    valuesInResource,
    values,
    errors,
    onMouseLeave,
    onRemove,
    onSelectedValueClick,
    onSetHighlighted,
    theme,
    saveRef,
  } = props;

  const {saveChildRef, highlightedChild, setHighlightedChild, resetHighlightedChild} = useFilialPiety();
  const highlightedChildRef = useRef();
  const valuesRef = useRef();
  const onRemoveRef = useRef();
  const onSelectedValueClickRef = useRef();

  const isLastSelected = useMemo(() => [...values.keys()].at(-1) === id, [values, id]);

  highlightedChildRef.current = highlightedChild;
  valuesRef.current = valuesInResource;
  onRemoveRef.current = onRemove;
  onSelectedValueClickRef.current = onSelectedValueClick;

  const saveRefCallback = useCallback(element => saveRef(id, element), [id, saveRef]);

  const handleRemove = useCallback(
    (evt, valueId) => onRemoveRef.current(evt, new Map([[id, [getOptionById(valuesRef.current, valueId, idPath)]]])),
    [id, idPath],
  );

  const handleClick = useCallback(
    evt => {
      onSelectedValueClickRef.current(evt, categoryId);
    },
    [categoryId],
  );

  const handleMouseOver = useCallback(
    (evt, valueId) => {
      if (valueId !== highlightedChild?.id) {
        // remove previous highlighted child and set new highlighted child
        onSetHighlighted(evt, {pathArr: [VALUEPANEL_ID, id], newHighlightedId: valueId});
      }
    },
    [id, onSetHighlighted, highlightedChild],
  );

  const handleKeyDown = useCallback(
    evt => {
      if (evt.keyCode === KEY_BACK_SPACE && highlightedChildRef.current) {
        handleRemove(evt, highlightedChildRef.current.id);
      }

      if (evt.keyCode === KEY_RETURN || evt.keyCode === KEY_TAB) {
        handleClick(evt);
      }
    },
    [handleRemove, handleClick],
  );

  useLayoutEffect(() => {
    const unregister = registerHandlers(id, {setHighlightedChild, resetHighlightedChild, keyDown: handleKeyDown}); // register handlers

    return () => unregister();
  }, [registerHandlers, id, setHighlightedChild, resetHighlightedChild, handleKeyDown]);

  const formatContent = useCallback(
    selectedValues => {
      const {
        formatValueText,
        formatValue,
        isPill = true,
        valueJoiner,
        hideResourceName,
        selectedResourceName,
        pillPropsValue,
        pillPropsResource = {},
        joinerIsPill = true,
      } = selectedProps;

      let content = selectedValues.map((value, index, {length}) => {
        const valueId = getOptionId(value, idPath);

        // Error specific to a value is set at its index position in errors array
        // If specific error do not exist but there is an error in the selected resource then errors is a string
        // Pass resource error if joiner is false so that each selected pill can render error message
        const error = typeof errors === 'object' ? errors[valueId] : valueJoiner && joinerIsPill ? null : errors;

        const val = (
          <SelectedValue
            theme={theme}
            resource={resource}
            saveRef={saveChildRef}
            shouldScrollToElement={isLastSelected && index === length - 1}
            disabled={disabled}
            insensitive={insensitive}
            key={valueId}
            id={valueId}
            text={getOptionText(value, textPath)}
            value={value}
            formatValueText={formatValueText}
            formatValue={formatValue}
            resourceName={selectedResourceName ? `${selectedResourceName} ` : `${name}: `}
            hideResourceName={hideResourceName ?? Boolean(valueJoiner)}
            error={error}
            isPill={valueJoiner && joinerIsPill ? false : isPill}
            pillProps={pillPropsValue}
            highlighted={valueId === highlightedChild?.id}
            onClick={displayResourceAsCategory ? undefined : handleClick}
            onRemove={handleRemove}
            onMouseOver={handleMouseOver}
            onMouseLeave={onMouseLeave}
          />
        );

        if (valueJoiner) {
          return (
            <div key={valueId} className={cx(styleUtils.gapInline, styleUtils.gapHorizontal)}>
              {index > 0 && <div className={theme.joiner}>{valueJoiner}</div>}
              {val}
            </div>
          );
        }

        return val;
      });

      if (valueJoiner && joinerIsPill) {
        // Wrap joined values in a pill if individual values are not wrapped in a pill
        const pillProps = {
          theme,
          error: errors && typeof errors !== 'object', //errors is a string or node
          disabled,
          insensitive,
          ...(disabled && {themePrefix: 'pillDisabled-'}),
          ...(typeof pillPropsResource === 'function' ? pillPropsResource(selectedValues) : pillPropsResource),
        };

        content = (
          <Pill {...pillProps}>
            {hideResourceName ? (
              content
            ) : (
              <>
                {selectedResourceName ? `${selectedResourceName} ` : `${name}: `}
                <span className={styleUtils.bold}>{content}</span>
              </>
            )}
          </Pill>
        );
      }

      return content;
    },
    [
      name,
      idPath,
      textPath,
      disabled,
      displayResourceAsCategory,
      insensitive,
      selectedProps,
      resource,
      theme,
      errors,
      handleMouseOver,
      handleClick,
      handleRemove,
      saveChildRef,
      highlightedChild,
      isLastSelected,
      onMouseLeave,
    ],
  );

  return (
    <div className={theme.selectedResource} ref={saveRefCallback}>
      {formatResource?.({
        resource,
        theme,
        formatContent,
        values,
        valuesInResource,
        saveRef,
        highlightedChild,
        onClick: displayResourceAsCategory ? undefined : handleClick,
        onRemove: handleRemove,
        onMouseOver: handleMouseOver,
        onMouseLeave,
      }) ?? formatContent(valuesInResource)}
    </div>
  );
}
