/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useCallback, useRef, useEffect} from 'react';
import {Pill, Icon, Tooltip} from 'components';
import styleUtils from 'utils.css';
import {scrollToElement} from 'utils/dom';

export default function SelectedValue(props) {
  const {
    resource,
    theme,
    saveRef,
    disabled,
    insensitive,
    id,
    text,
    value,
    formatValue,
    formatValueText,
    resourceName,
    hideResourceName,
    error,
    highlighted,
    isPill,
    pillProps,
    shouldScrollToElement,
    onClick,
    onRemove,
    onMouseOver,
    onMouseLeave,
  } = props;

  const elementRef = useRef();
  const saveRefCallback = useCallback(
    element => {
      elementRef.current = element;
      saveRef(id, element);
    },
    [id, saveRef],
  );
  const handleRemove = useCallback(evt => onRemove(evt, id), [id, onRemove]);
  const handleMouseOver = useCallback(evt => onMouseOver(evt, id), [id, onMouseOver]);

  useEffect(() => {
    if (!shouldScrollToElement) {
      return;
    }

    scrollToElement({element: elementRef.current});
  }, [shouldScrollToElement]);

  const isInsensitive = insensitive || disabled;

  let val = (
    <div
      className={cx(theme.selectedValueText, {[theme.clickable]: isInsensitive ? undefined : onClick})}
      onClick={isInsensitive ? undefined : onClick}
    >
      {formatValueText?.(value) ??
        (hideResourceName || !resourceName ? (
          text
        ) : (
          <>
            {resourceName}
            <span className={styleUtils.bold}>{text}</span>
          </>
        ))}
    </div>
  );

  const hasErrorContent = typeof error === 'string' ? Boolean(error.trim()) : error;

  if (hasErrorContent) {
    val = (
      <Tooltip content={error} visible>
        {val}
      </Tooltip>
    );
  }

  return (
    <div
      ref={saveRefCallback}
      onMouseMove={isInsensitive ? undefined : handleMouseOver}
      onMouseLeave={onMouseLeave}
      data-tid="comp-selected-value"
    >
      {formatValue?.({value, resource, insensitive, disabled, highlighted, theme, onRemove: handleRemove, onClick}) ??
        (isPill ? (
          <Pill
            onClose={isInsensitive ? undefined : handleRemove}
            theme={theme}
            error={typeof error === 'string'}
            themePrefix={highlighted ? 'pillHighlighted-' : undefined}
            insensitive={insensitive}
            disabled={disabled}
            {...(typeof pillProps === 'function' ? pillProps(value) : pillProps)}
          >
            {val}
          </Pill>
        ) : (
          <div
            className={cx(styleUtils.gapInline, styleUtils.gapHorizontal, {
              [theme.selectedValueError]: typeof error === 'string' && !highlighted,
              [theme.highlighted]: highlighted,
            })}
          >
            {val}
            <Icon name="close" theme={theme} onClick={isInsensitive ? undefined : handleRemove} themePrefix="close-" />
          </div>
        ))}
    </div>
  );
}
