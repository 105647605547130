/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {isUserReadOnly} from 'containers/User/UserState';
import {isEdge} from 'containers/App/AppState';
import {gridSettings, getSelectorSettings} from './LabelListConfig';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'LABEL_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  map(state = {}, action) {
    switch (action.type) {
      case 'LABELS_REMOVE': {
        return _.omit(state, action.data);
      }
      case 'LABEL_GET_LIST': {
        const result = {...state};

        for (const label of action.data.list) {
          result[label.href] = label;
        }

        return result;
      }
      case 'LABELS_GET_INSTANCE':
        if (action.data && !_.isEqual(action.data, state[action.data.href])) {
          return {...state, [action.data.href]: action.data};
        }

        return state;
      case 'VIRTUAL_SERVICES_GET_LIST':
      case 'PAIRING_PROFILE_GET_LIST': {
        const result = {...state};

        for (const {labels} of action.data.list) {
          for (const label of labels) {
            result[label.href] = label;
          }
        }

        return result;
      }
      case 'RULESET_GET_LIST': {
        const result = {...state};

        for (const {scopes} of action.data.list) {
          for (const scope of scopes) {
            for (const label of scope) {
              if (label?.label?.href) {
                result[label.label.href] = label.label;
              }
            }
          }
        }

        return result;
      }
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getLabels = state => state.label.list;
export const getLabelsCount = state => state.label.count;
export const getLabelsHrefMap = state => state.label.map;

const getLabelsRows = createSelector([getLabels, isUserReadOnly], (labels, userIsReadOnly) =>
  labels.map(item => ({
    key: item.href,
    selectable: !userIsReadOnly,
    removable: !userIsReadOnly,
    data: item,
  })),
);

export const getGridSettings = createSelector(
  [isUserReadOnly, isEdge, gridSettings],
  (userIsReadOnly, isEdge, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnly;
    columns.containerWorkloads.disabled = isEdge;
    columns.containerWorkloadProfiles.disabled = isEdge;
    columns.virtualServices.disabed = isEdge;
    columns.virtualServers.disabled = isEdge;
    columns.containerSettings.disabled = isEdge;

    return {...gridSettings, columns};
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLabelsRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getLabelsPage = createSelector(
  [getGrid, getLabelsRows, getLabelsCount, isUserReadOnly, getSelectorSettings],
  (grid, rows, count, userIsReadOnly, selectorSettingsObject) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.entries(grid.filter).map(([categoryKey, [value]]) => ({
        categoryKey,
        value,
        categoryName: selectorSettingsObject.filterMap[categoryKey],
      })),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
      statics: Object.entries(selectorSettingsObject.staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {grid, count, selector, userIsReadOnly};
  },
);
