/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getRoute} from 'containers/App/AppState';
import {fetchPairingProfileItem} from './PairingProfileItemSaga';
import {fetchPairingKeyList} from 'containers/PairingProfile/Item/Pair/PairingProfilePairSaga';

@connect(getRoute)
export default class PairingProfilesItem extends PureComponent {
  // in antman we need pairing profile item data & pairing key information since it's simplified to a single view
  static prefetch = __ANTMAN__ ? fetchPairingKeyList : fetchPairingProfileItem;

  render() {
    return this.props.children;
  }
}
