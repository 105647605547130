/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import intl from 'intl';
import {useCallback, useContext, useRef, useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import PubSub from 'pubsub';
import produce from 'immer';
import {AppContext} from 'containers/App/AppUtils';
import {moveItemInArray} from 'intl/utils';
import {
  GridLocal,
  ToolBar,
  ToolGroup,
  Button,
  ButtonRefresh,
  TabPanel,
  MenuItem,
  MenuDelimiter,
  Modal,
  TypedMessages,
  Drawer,
} from 'components';
import {
  fetchRulesetItem,
  createRule,
  createDenyRule,
  updateDenyRule,
  updateRule,
  updateRules,
  updateRuleset,
} from './RulesetItemSaga';
import ProvisioningNotification from 'containers/Provisioning/ProvisioningNotification';
import {HeaderProps} from 'containers';
import RulesetActions from './Summary/RulesetActions';
import RulesetScope from './Scope/RulesetScope';
import RulesetSummary from './Summary/RulesetSummary';
import RulesetScopeHeader from './Scope/RulesetScopeHeader';
import RulesGridFilter from './RulesFilter/RulesGridFilter';
import RulesetReducers from 'containers/Ruleset/RulesetState';
import IpListReducers from 'containers/IPList/IPListState';
import ServiceReducers from 'containers/Service/ServiceState';
import labelReducers from 'containers/Label/LabelState';
import labelGroupReducers from 'containers/LabelGroup/LabelGroupState';
import {getRulesetItem} from './RulesetItemState';
import {labelTypes} from 'components/Pill/Label/LabelUtils';
import {getAllNonEmptyContentBlockText} from 'components/Form/DraftJS/DraftJSUtils';
import {
  newRuleHref,
  getRowsCount,
  prepareRulePayload,
  prepareIpTableRulePayload,
  prepareDenyRulePayload,
  hasIntraExtraScopeRuleChanged,
  hasIpTablesRuleChanged,
  getIntraExtraScopeRuleErrors,
  getIpTablesRuleErrors,
  formatEndpoint,
  formatServices,
  omitUnwritableAttributes,
  getResolvedLabelsAs,
  parseStatementString,
  isNotReversible,
  hasErrorOrWarningChanged,
  getErrorMessage,
} from './RulesetItemUtils';
import {filterIntraExtraScopeRules, filterIpTableRules} from './RulesFilter/RulesGridFilterUtils';
import {
  newScopeId,
  getEmptyScopeText,
  getScopeKey,
  getDuplicateScopeMessage,
  filterScopes,
} from './Scope/RulesetScopeUtils';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';

import stylesGridUtils from 'components/Grid/GridUtils.css';
import stylesUtils from 'utils.css';
import styles from './RulesetItem.css';
import {formatDataReference} from 'utils/dataValidation';

RulesetItem.prefetch = fetchRulesetItem;
RulesetItem.reducers = [RulesetReducers, IpListReducers, labelReducers, labelGroupReducers, ServiceReducers];

const backgroundStyle = {
  added: {className: stylesGridUtils.addRows},
  modified: {className: stylesGridUtils.modifiedRows},
  remove: {className: stylesGridUtils.rowToRemove},
  disable: {className: stylesGridUtils.rowToRevert},
  enable: {className: styles.rowToEnable},
  disabledRules: {className: styles.disabledRules},
};

export default function RulesetItem() {
  const {
    intraScopeRulesRows,
    extraScopeRulesRows,
    overrideDenyRulesRows,
    ipTablesRulesRows,
    rulesGridSettings,
    ipTablesGridSettings,
    versions: {pversionObj = {}, prevPversionObj, draft, active},
    pversionObjIsDeleted,
    summaryObj,
    rulesetId,
    provisionIsDisabled,
    pversion,
    isOldVersion,
    isAdditionPending,
    isReverseProviderConsumer,
    advancedRulesetDisplay,
    tab,
    ruleEditorProp,
    userIsScoped,
    scopeIsRequired,
    policyGeneratorId,
    prevRouteParams,
    actionButtonsDisabled,
    recents,
  } = useSelector(getRulesetItem);

  const {
    fetcher,
    dispatch,
    navigate,
    store: {prefetcher},
    sendAnalyticsEvent,
  } = useContext(AppContext);

  const thisProxy = useRef({});
  const dropdownActionRef = useRef();
  const isDirtyRef = useRef(false);

  const [ruleEditorState, setRuleEditorState] = useState(null);

  const [scopesFilter, setScopesFilter] = useState(new Map());
  const [rulesFilter, setRulesFilter] = useState(new Map());
  const [scopeInEdit, setScopeInEdit] = useState();

  const [extraPropsKeyMap, setExtraPropsKeyMap] = useState(new Map());
  const [addScopeIsDisabled, setAddScopeIsDisabled] = useState();
  const [errors, setErrors] = useState();
  const [remove, setRemove] = useState();

  const [selectedKeySet, setSelectedKeySet] = useState(new Set());
  const [selectedKeySetToEnable, setSelectedKeySetToEnable] = useState(new Set());
  const [selectedKeySetToDisable, setSelectedKeySetToDisable] = useState(new Set());
  const [selectedKeySetToRemove, setSelectedKeySetToRemove] = useState(new Set());

  const [progressObj, setProgressObj] = useState({}); // {enableProgress, disableProgress, removeProgress, onProgressDone}

  const [collapseRules, setCollapseRules] = useState({allow: false, deny: false, overrideDeny: false});

  const filteredIntraScopeRulesRows = useMemo(
    () => filterIntraExtraScopeRules(intraScopeRulesRows, rulesFilter),
    [intraScopeRulesRows, rulesFilter],
  );
  const filteredExtraScopeRulesRows = useMemo(
    () => filterIntraExtraScopeRules(extraScopeRulesRows, rulesFilter),
    [extraScopeRulesRows, rulesFilter],
  );
  const filteredOverrideDenyRulesRows = useMemo(
    () => filterIntraExtraScopeRules(overrideDenyRulesRows, rulesFilter),
    [overrideDenyRulesRows, rulesFilter],
  );
  const filteredIpTablesRulesRows = useMemo(
    () => filterIpTableRules(ipTablesRulesRows, rulesFilter),
    [ipTablesRulesRows, rulesFilter],
  );

  const [rows, rowsProp] = useMemo(() => {
    switch (true) {
      case __ANTMAN__:
        return [
          {
            intraScope: filteredIntraScopeRulesRows,
            extraScope: filteredExtraScopeRulesRows,
            overrideDenies: filteredOverrideDenyRulesRows,
          },
          {
            intraScope: intraScopeRulesRows,
            extraScope: extraScopeRulesRows,
            overrideDenies: overrideDenyRulesRows,
          },
        ];
      case tab === 'intrascope':
        return [filteredIntraScopeRulesRows, intraScopeRulesRows];
      case tab === 'extrascope':
        return [filteredExtraScopeRulesRows, extraScopeRulesRows];
      case tab === 'iptables':
        return [filteredIpTablesRulesRows, ipTablesRulesRows];
      default:
        return [];
    }
  }, [
    filteredOverrideDenyRulesRows,
    filteredIntraScopeRulesRows,
    filteredExtraScopeRulesRows,
    overrideDenyRulesRows,
    intraScopeRulesRows,
    extraScopeRulesRows,
    tab,
    filteredIpTablesRulesRows,
    ipTablesRulesRows,
  ]);

  const scopes = useMemo(() => filterScopes(pversionObj.scopes, scopesFilter), [scopesFilter, pversionObj]);

  const hasScopes = pversionObj.scopes?.[0]?.length > 0 || scopeInEdit === newScopeId;
  const showScopeSection = advancedRulesetDisplay || hasScopes || prevPversionObj?.scopes[0]?.length > 0;

  const excludeKeys = useMemo(() => {
    let excludeKeys = ['role'];

    const scopesExists = pversionObj.scopes?.[0]?.length > 0;
    const isEditingLastScope = scopesExists && scopeInEdit === getScopeKey(pversionObj.scopes[0]);

    // Compute excludeKeys from first scope for subsequent scope addition
    // Skip computing from scope if we are editing the last scope, because
    // in this case either excludeKeys should be taken from rules if applicable Or user should be able to select any label type
    const shouldComputeExcludeKeysFromScope = scopesExists && (pversionObj.scopes.length > 1 || !isEditingLastScope);

    if (shouldComputeExcludeKeysFromScope) {
      const typesInfirstScope = pversionObj.scopes[0].reduce((result, {label, label_group}) => {
        result.push(label?.key ?? label_group.key);

        return result;
      }, []);

      excludeKeys = [...new Set([...excludeKeys, ..._.difference(labelTypes, typesInfirstScope)])];
    } else if (pversionObj.rules?.length || pversionObj.ip_tables_rules?.length) {
      excludeKeys = [
        ...new Set(
          [...(pversionObj.rules ?? []), ...(pversionObj.ip_tables_rules ?? [])].reduce(
            (result, rule) => {
              const {providers = [], consumers = [], actors = []} = rule;

              // Skip consumers of extra scope rules
              const entities = [...providers, ...actors, ...(scopesExists && rule.unscoped_consumers ? [] : consumers)];

              result.push(...entities.map(({label, label_group}) => label?.key ?? label_group?.key).filter(Boolean));

              return result;
            },
            ['role'],
          ),
        ),
      ];
    }

    return excludeKeys;
  }, [pversionObj, scopeInEdit]);

  const resetScopeEditor = useCallback(() => {
    setScopeInEdit(null);

    setAddScopeIsDisabled(false);
  }, []);

  const resetRuleEditor = useCallback(() => {
    setRuleEditorState(null);
    // First update the redux store and then reset state since grid configs uses component state to populate editor rule
    dispatch({type: 'RULESET_RULE_EDITOR', data: null});
  }, [dispatch]);

  const resetForm = useCallback(() => {
    resetRuleEditor();
    resetScopeEditor();
  }, [resetRuleEditor, resetScopeEditor]);

  const publishFormIsDirty = useCallback(
    ({dirty, force = false}) => {
      if (force || isDirtyRef.current !== dirty) {
        isDirtyRef.current = dirty;
        PubSub.publish('FORM.DIRTY', {dirty, resetForm}, {immediate: true});
      }
    },
    [resetForm],
  );

  const invokeDiscardChanges = useCallback(async () => {
    if (prefetcher.formIsDirty) {
      // Cancel confirmation is mounted if form has changes
      const answer = await new Promise(resolve =>
        PubSub.publish('UNSAVED.WARNING', {
          resolve,
          ...getDiscardChangesModalProps(),
        }),
      );

      if (answer === 'cancel') {
        return true;
      }
    }

    // Publishing FORM.DIRTY servers two purpose here
    // 1. Resets prefetcher formIsDirty
    // 2. Publish resetForm function so that other components such as ProvisionButtons can reset editor
    publishFormIsDirty({dirty: false, force: true});

    resetForm();
  }, [prefetcher, resetForm, publishFormIsDirty]);

  const handlePversionNavigate = useCallback(async () => {
    const abortAction = await invokeDiscardChanges();

    if (abortAction) {
      return false;
    }
  }, [invokeDiscardChanges]);

  const handleResetKeySetState = useCallback(() => {
    setSelectedKeySetToRemove(new Set());
    setSelectedKeySetToDisable(new Set());
    setSelectedKeySetToEnable(new Set());
    setSelectedKeySet(new Set());
  }, []);

  const handleRefresh = useCallback(async () => {
    await fetcher.fork(fetchRulesetItem.refetch);
  }, [fetcher]);

  const handleScopeEdit = useCallback(
    async id => {
      const abortAction = await invokeDiscardChanges();

      if (abortAction) {
        return;
      }

      setScopeInEdit(id);
    },
    [invokeDiscardChanges],
  );

  const handleScopeSave = useCallback(
    async (id, scope) => {
      try {
        const newScopes = pversionObj.scopes[0]?.length ? [...pversionObj.scopes] : [];

        if (id === newScopeId) {
          newScopes.push(scope);
        } else {
          const scopeIndex = pversionObj.scopes.findIndex(scope => getScopeKey(scope) === id);

          newScopes[scopeIndex] = scope;
        }

        const scopesPayload = newScopes.reduce((result, scope) => {
          const newScope = [];

          scope.forEach(({label, label_group, exclusion}) => {
            newScope.push({
              exclusion,
              [label_group ? 'label_group' : 'label']: {href: label?.href ?? label_group.href},
            });
          });

          result.push(newScope);

          return result;
        }, []);

        const payload = {
          params: {pversion: 'draft', rule_set_id: rulesetId},
          data: {
            scopes: scopesPayload,
            ...(pversionObj.external_data_reference
              ? {external_data_reference: formatDataReference(pversionObj.external_data_reference)}
              : {}),
          },
        };

        await fetcher.spawn(updateRuleset, payload);
      } catch (error) {
        setErrors([getErrorMessage(error)]);
      }
    },
    [fetcher, rulesetId, pversionObj],
  );

  const handleRemoveScope = useCallback(
    async scopeKey => {
      const removingLastScope = pversionObj.scopes.length === 1;
      const hasRules = pversionObj.rules.length > 0;
      const shouldConfirmRemove = removingLastScope && hasRules;

      if (shouldConfirmRemove) {
        const answer = await new Promise((onConfirm, onCancel) => {
          setRemove({removingLastScope: true, onConfirm, onCancel});
        }).catch(() => 'cancel');

        if (answer === 'cancel') {
          setRemove(null);

          return;
        }
      }

      setProgressObj({runningRemove: true});

      const newScopes = [...pversionObj.scopes];
      const scopeIndexToRemove = pversionObj.scopes.findIndex(scope => getScopeKey(scope) === scopeKey);

      newScopes.splice(scopeIndexToRemove, 1);

      const scopesPayload = newScopes.reduce((result, scope) => {
        const newScope = [];

        if (scope.length) {
          scope.forEach(({exclusion, label, label_group}) => {
            newScope.push({
              exclusion,
              [label_group ? 'label_group' : 'label']: {href: label?.href ?? label_group.href},
            });
          });

          result.push(newScope);
        }

        return result;
      }, []);

      const payload = {
        params: {pversion: 'draft', rule_set_id: rulesetId},
        data: {
          scopes: scopesPayload,
          ...(pversionObj.external_data_reference
            ? {external_data_reference: formatDataReference(pversionObj.external_data_reference)}
            : {}),
        },
      };

      fetcher.spawn(updateRuleset, payload);

      setRemove(null);
      setProgressObj({runningRemove: false});
    },
    [fetcher, rulesetId, pversionObj],
  );

  const handleSetBackgroundStyle = useCallback(() => {
    // antman doesn't have tabs
    setExtraPropsKeyMap(
      (tab && Array.isArray(rows) ? rows : [...rows.extraScope, ...rows.intraScope, ...rows.overrideDenies]).reduce(
        (map, {key, isInEditMode, data: {enabled}}) =>
          isInEditMode
            ? map.set(key, key === newRuleHref ? backgroundStyle.added : backgroundStyle.modified)
            : enabled
            ? map
            : map.set(key, backgroundStyle.disabledRules),
        new Map(),
      ),
    );
  }, [rows, tab]);

  const handleRulesFilterChange = useCallback(valuesMap => setRulesFilter(valuesMap), []);

  const handleSetTouched = useCallback(() => {
    setRuleEditorState(ruleEditor => ({...ruleEditor, touched: true}));
  }, []);

  const handleRuleAdd = useCallback(
    async ({tab, type, rule = {}}) => {
      const abortAction = await invokeDiscardChanges();

      if (abortAction) {
        return;
      }

      const {unscoped_consumers, ...ruleData} = rule;
      const isDuplicate = !_.isEmpty(ruleData);

      const ruleEditor = {...rule, href: newRuleHref, touched: isDuplicate, ...(__ANTMAN__ && {type})};

      dispatch({
        type: 'RULESET_RULE_EDITOR',
        data: {
          ..._.cloneDeep(ruleEditor),
          saveIsDisabled: !isDuplicate, // save should be enabled when rule exists (case of reverse/duplicate)
          isDuplicateOrReverse: isDuplicate,
          type,
        },
      });

      setRuleEditorState(ruleEditor);

      if (__ANTMAN__) {
        setCollapseRules(collapseRules => {
          return Object.keys(collapseRules).reduce(
            (ruleTypes, ruleType) => ({...ruleTypes, [ruleType]: ruleType !== type}),
            {},
          );
        });
      } else {
        navigate({params: {tab}});
      }
    },
    [dispatch, navigate, invokeDiscardChanges],
  );

  const handleEditClick = useCallback(
    async (evt, rowInEdit, reverse) => {
      const abortAction = await invokeDiscardChanges();

      if (abortAction) {
        return;
      }

      const rule = {...rowInEdit.data.rule, touched: true, ...(__ANTMAN__ && {type: rowInEdit.type})};

      if (reverse) {
        if (isNotReversible(rule)) {
          return setErrors([intl('Rulesets.Rules.ReverseError')]);
        }

        [rule.consumers, rule.providers] = [rule.providers, rule.consumers];
      }

      await dispatch({
        type: 'RULESET_RULE_EDITOR',
        data: {..._.cloneDeep(rule), saveIsDisabled: !reverse, isDuplicateOrReverse: reverse},
      });

      setRuleEditorState(rule);
    },
    [dispatch, invokeDiscardChanges],
  );

  const handleEditCancel = useCallback(async () => {
    const abortAction = await invokeDiscardChanges();

    if (abortAction) {
      return;
    }

    if (tab === 'iptables' && !pversionObj.ip_tables_rules.length) {
      navigate({params: {tab: prevRouteParams?.tab ?? 'intrascope'}});
    }
  }, [tab, pversionObj, navigate, invokeDiscardChanges, prevRouteParams]);

  const handleSetLoading = useCallback(key => {
    // Move the rule row in loading state, useEffect will capture the row in loading state and invoke save/remove saga
    setExtraPropsKeyMap(prevState => new Map([...prevState, [key, {loading: true}]]));
  }, []);

  const handleRuleDropdownActions = useCallback(
    async ({rule, action = 'save'}) => {
      dropdownActionRef.current = {action, rule};

      const {rowErrorMsgs} =
        tab === 'iptables'
          ? getIpTablesRuleErrors({rule, scopes: pversionObj.scopes})
          : getIntraExtraScopeRuleErrors({
              rule,
              userIsScoped,
              scopes: pversionObj.scopes,
            });

      if (rowErrorMsgs?.length) {
        return setErrors(rowErrorMsgs);
      }

      handleSetLoading(rule.href);
    },
    [handleSetLoading, userIsScoped, pversionObj, tab],
  );

  const updateRulesetExternalDataRef = useCallback(async () => {
    try {
      if (draft && draft.external_data_reference && draft.external_data_set === 'illumio_segmentation_templates') {
        const payload = {params: {pversion: 'draft', rule_set_id: rulesetId}, data: {}};

        payload.data.external_data_reference = formatDataReference(draft.external_data_reference);

        await fetcher.spawn(updateRuleset, payload);
      }
    } catch (error) {
      setErrors([getErrorMessage(error)]);
    }
  }, [draft, fetcher, rulesetId]);

  const saveRule = useCallback(
    async loadingRowKey => {
      try {
        const ruleData = dropdownActionRef.current?.rule ?? ruleEditorState;

        let data = omitUnwritableAttributes(ruleData);

        // description is removed in omit unwritable attributes, include it back
        data.description = ruleData.description;

        const isOverrideDenyRule = ruleData.type === 'overrideDeny';
        const isDenyRule = typeof ruleData.override === 'boolean' || ruleData.type === 'deny' || isOverrideDenyRule;

        if (tab === 'iptables') {
          data = prepareIpTableRulePayload(data);
        } else if (isDenyRule) {
          data = prepareDenyRulePayload(data);
        } else {
          data = prepareRulePayload(data);
        }

        if (loadingRowKey === newRuleHref) {
          const {href, ...restData} = data;

          restData.enabled = true;

          if (isOverrideDenyRule) {
            restData.override = true;
          }

          await fetcher.spawn(isDenyRule ? createDenyRule : createRule, {
            rule_set_id: rulesetId,
            data: restData,
            isIpTableRule: tab === 'iptables',
          });
          updateRulesetExternalDataRef();
        } else {
          await fetcher.spawn(isDenyRule ? updateDenyRule : updateRule, {
            href: loadingRowKey,
            rule_set_id: rulesetId,
            data,
            isIpTableRule: tab === 'iptables',
          });
          updateRulesetExternalDataRef();
        }

        const isSubmittingForm = !dropdownActionRef.current;

        if (isSubmittingForm) {
          publishFormIsDirty({dirty: false});
          resetForm();
        }

        handleSetBackgroundStyle();

        await handleRefresh();
      } catch (error) {
        let errorMessage = error;

        if (error.data && error.data[0]?.token === 'invalid_uri' && error.data[0]?.message && recents) {
          // "Invalid URI: {.../labels/1}" to "/orgs/2/labels/1"
          // "Invalid URI: {{"href"=>".../label_groups/1"}}" to ".../label_groups/1"

          let href = error.data[0].message
            .split(`${intl(`ErrorsAPI.err:${error.data[0].token}`)}: `)?.[1]
            ?.slice(1, -1);

          if (href.includes('href')) {
            href = href.slice(10, -2);
          }

          if (href) {
            const invalidObj = Object.values(recents)
              ?.flat()
              .find(item => item.href === href);

            if (!_.isEmpty(invalidObj)) {
              const {value, name} = invalidObj;
              const resourceType = href.split('/').at(-2);

              errorMessage = intl('Rulesets.Rules.ResourceDeleted', {
                resourceName: `"${value || name}"`,
                object: resourceType,
              });
            }
          }
        }

        setErrors([getErrorMessage(errorMessage)]);
        handleSetBackgroundStyle();
      }

      dropdownActionRef.current = null;
    },
    [
      ruleEditorState,
      fetcher,
      handleRefresh,
      rulesetId,
      tab,
      updateRulesetExternalDataRef,
      recents,
      resetForm,
      publishFormIsDirty,
      handleSetBackgroundStyle,
    ],
  );

  const removeRule = useCallback(
    async loadingRowKey => {
      const ruleData = dropdownActionRef.current?.rule;
      const isDenyRule = typeof ruleData.override === 'boolean' || ruleData.type === 'deny';

      const updatedRules = pversionObj[
        tab === 'iptables' ? 'ip_tables_rules' : isDenyRule ? 'deny_rules' : 'rules'
      ].reduce((updatedRules, rule) => {
        if (rule.href === loadingRowKey) {
          return updatedRules;
        }

        return [...updatedRules, {href: rule.href}];
      }, []);

      try {
        await fetcher.spawn(updateRules, {
          rule_set_id: rulesetId,
          data: {
            [tab === 'iptables' ? 'ip_tables_rules' : isDenyRule ? 'deny_rules' : 'rules']: updatedRules,
          },
        });

        await handleRefresh();
      } catch (error) {
        setErrors([getErrorMessage(error)]);
      }

      dropdownActionRef.current = null;
    },
    [fetcher, handleRefresh, pversionObj, rulesetId, tab],
  );

  const handleButtonEnter = useCallback((keySet, styleClass) => {
    if (keySet.size) {
      setExtraPropsKeyMap(new Map(Array.from(keySet, key => [key, backgroundStyle[styleClass]])));
    }
  }, []);
  const handleButtonLeave = useCallback(() => handleSetBackgroundStyle(), [handleSetBackgroundStyle]);

  const handleBatchStatusChange = useCallback(
    async isEnable => {
      setProgressObj({[isEnable ? 'runningEnable' : 'runningDisable']: true});

      const keySet = isEnable ? selectedKeySetToEnable : selectedKeySetToDisable;

      const prepareRules = ({href}) => (keySet.has(href) ? {href, enabled: isEnable} : {href});

      const payload = {
        ip_tables_rules: pversionObj.ip_tables_rules.map(prepareRules),
        rules: pversionObj.rules.map(prepareRules),
      };

      if (__ANTMAN__) {
        payload.deny_rules = pversionObj.deny_rules.map(prepareRules);
      }

      try {
        await fetcher.spawn(updateRules, {rule_set_id: rulesetId, data: payload});

        await handleRefresh();

        handleResetKeySetState();
      } catch (error) {
        setErrors([getErrorMessage(error)]);
      }

      setProgressObj({[isEnable ? 'runningEnable' : 'runningDisable']: false});
    },
    [
      fetcher,
      handleRefresh,
      rulesetId,
      pversionObj,
      selectedKeySetToEnable,
      selectedKeySetToDisable,
      handleResetKeySetState,
    ],
  );

  const handleBatchRemove = useCallback(async () => {
    const answer = await new Promise((onConfirm, onCancel) => {
      setRemove({onConfirm, onCancel});
    }).catch(() => 'cancel');

    if (answer === 'cancel') {
      setRemove(null);

      return;
    }

    setProgressObj({runningRemove: true});

    const prepareRules = (result, {href}) => {
      if (!selectedKeySetToRemove.has(href)) {
        result.push({href});
      }

      return result;
    };

    const payload = {
      ip_tables_rules: pversionObj.ip_tables_rules.reduce(prepareRules, []),
      rules: pversionObj.rules.reduce(prepareRules, []),
    };

    if (__ANTMAN__) {
      payload.deny_rules = pversionObj.deny_rules.reduce(prepareRules, []);
    }

    try {
      await fetcher.spawn(updateRules, {rule_set_id: rulesetId, data: payload});

      await handleRefresh();

      handleResetKeySetState();
    } catch (error) {
      setErrors([getErrorMessage(error)]);
    }

    setRemove(null);
    setProgressObj({runningRemove: false});
  }, [fetcher, handleRefresh, rulesetId, pversionObj, selectedKeySetToRemove, handleResetKeySetState]);

  const handleEndpointChange = useCallback((type, valuesMap) => {
    setRuleEditorState(prevRuleEditorState => {
      const ruleEditorState = {...prevRuleEditorState};

      ruleEditorState[type] = formatEndpoint(valuesMap);
      ruleEditorState.resolve_labels_as = ruleEditorState.resolve_labels_as ?? {};
      ruleEditorState.resolve_labels_as[type] = getResolvedLabelsAs(valuesMap);

      ruleEditorState.use_workload_subnets = ruleEditorState.use_workload_subnets ?? [];

      if (valuesMap.has('use_workload_subnets')) {
        if (!ruleEditorState.use_workload_subnets.includes(type)) {
          ruleEditorState.use_workload_subnets.push(type);
        }
      } else {
        ruleEditorState.use_workload_subnets = ruleEditorState.use_workload_subnets.filter(entity => entity !== type);
      }

      if (type === 'consumers') {
        ruleEditorState.consuming_security_principals =
          valuesMap.get('consuming_security_principals')?.map(({href}) => ({href})) ?? [];

        if (valuesMap.has('container_host')) {
          ruleEditorState.unscoped_consumers = true;
        }
      }

      return ruleEditorState;
    });
  }, []);

  const handleServiceChange = useCallback((type, valuesMap) => {
    setRuleEditorState(prevRuleEditorState => {
      const ruleEditorState = {...prevRuleEditorState};

      const formattedService = formatServices(valuesMap);

      if (__ANTMAN__ && type === 'consumers') {
        ruleEditorState.egress_services = formattedService;
      } else {
        ruleEditorState.ingress_services = formattedService;
      }

      return ruleEditorState;
    });
  }, []);

  const handleOverrideDenyChange = useCallback(isChecked => {
    setRuleEditorState(prevRuleEditorState => ({...prevRuleEditorState, override: isChecked}));
  }, []);

  const handleRuleOptionsChange = useCallback(valuesMap => {
    setRuleEditorState(prevRuleEditorState => {
      const ruleEditorState = {...prevRuleEditorState};
      const values = valuesMap.get('ruleOptions') ?? [];

      ruleEditorState.ruleOptionsMap = valuesMap;
      ruleEditorState.stateless = values.includes(intl('Common.Stateless'));
      ruleEditorState.sec_connect = values.includes(intl('Common.SecureConnect'));
      ruleEditorState.machine_auth = values.includes(intl('Common.MachineAuthentication'));

      if (!__ANTMAN__) {
        if (values.includes(intl('Rulesets.Rules.NonCorporateNetworks'))) {
          ruleEditorState.network_type = 'non_brn';
        } else if (values.includes(intl('Rulesets.Rules.AllNetworks'))) {
          ruleEditorState.network_type = 'all';
        } else {
          ruleEditorState.network_type = 'brn';
        }
      }

      return ruleEditorState;
    });
  }, []);

  const handleReceiversChange = useCallback(
    valuesMap =>
      setRuleEditorState(prevRuleEditorState => ({...prevRuleEditorState, actors: formatEndpoint(valuesMap)})),
    [],
  );

  const handleIpRulesStatementsChange = useCallback(editorState => {
    const originalTexts = getAllNonEmptyContentBlockText(editorState);

    setRuleEditorState(prevRuleEditorState => {
      const ruleEditorState = {...prevRuleEditorState};

      ruleEditorState.statements = originalTexts.map(parseStatementString);

      return ruleEditorState;
    });
  }, []);

  const handleIpVersionChange = useCallback(
    ({value}) => {
      handleSetTouched();
      setRuleEditorState(prevRuleEditorState => ({...prevRuleEditorState, ip_version: value}));
    },
    [handleSetTouched],
  );

  const handleAddScope = useCallback(async () => {
    const abortAction = await invokeDiscardChanges();

    if (abortAction) {
      return;
    }

    setScopeInEdit(newScopeId);
    setAddScopeIsDisabled(true);
  }, [invokeDiscardChanges]);

  const handleScopeFilterChange = useCallback(valuesMap => setScopesFilter(valuesMap), []);

  const handleValidateScope = useCallback(
    (id, scope) => {
      const emptyScopes = pversionObj.scopes[0]?.length === 0;
      const existingScopes = pversionObj.scopes;
      const editingLastScope = emptyScopes
        ? true
        : existingScopes.length > 1
        ? false
        : id === getScopeKey(pversionObj.scopes[0]);

      const hasAllLabelTypes = editingLastScope ? scope.length > 0 : scope.length === existingScopes[0].length;

      if (!hasAllLabelTypes) {
        return {isValid: false};
      }

      const isDuplicate = existingScopes.some(existingScope => getScopeKey(existingScope) === getScopeKey(scope));

      if (isDuplicate) {
        return {
          isValid: false,
          message: {
            type: 'error',
            title: intl('Rulesets.Scopes.DuplicateScope'),
            message: getDuplicateScopeMessage(scope),
          },
        };
      }

      return {isValid: true};
    },
    [pversionObj],
  );

  const handleTabChange = useCallback(
    async (evt, tab) => {
      const abortAction = await invokeDiscardChanges();

      if (abortAction) {
        return false;
      }

      navigate({params: {tab}});
    },
    [navigate, invokeDiscardChanges],
  );

  const handleAlertModalClose = useCallback(() => setErrors(null), []);

  const renderRemoveConfirmation = useCallback(() => {
    const {removingLastScope, onCancel, onConfirm} = remove;

    if (removingLastScope) {
      return (
        <Modal.Confirmation
          title={intl('Rulesets.Scopes.RemoveLastScope')}
          onCancel={onCancel}
          confirmProps={{text: intl('Common.Remove'), progress: progressObj.runningRemove}}
          onConfirm={onConfirm}
        >
          <TypedMessages>
            {[
              {
                icon: 'warning',
                content: intl('Rulesets.Scopes.RemoveLastScopeWarning'),
              },
            ]}
          </TypedMessages>
        </Modal.Confirmation>
      );
    }

    return (
      <Modal.Confirmation
        title={intl('Rulesets.Rules.DeleteRules', {count: selectedKeySetToRemove.size})}
        confirmProps={{
          text: intl('Common.Remove'),
          progress: progressObj.runningRemove,
        }}
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        {intl('Rulesets.Rules.ConfirmRuleDelete', {count: selectedKeySetToRemove.size})}
      </Modal.Confirmation>
    );
  }, [progressObj, remove, selectedKeySetToRemove]);

  const renderAlertModal = useCallback(
    () => (
      <Modal.Alert title={intl('Common.Error')} medium onClose={handleAlertModalClose}>
        <TypedMessages>{errors.map(error => ({icon: 'error', content: error}))}</TypedMessages>
      </Modal.Alert>
    ),
    [handleAlertModalClose, errors],
  );
  const handleReorder = useCallback(
    async ({type}, dragIndex, hoverIndex) => {
      // In CoreX this only works for Allows, Denies needs support for this, but this should populate the handler with the selected row once it is ready
      const gridRows = __ANTMAN__
        ? type === 'allow'
          ? rows.intraScope
          : type === 'deny'
          ? rows.extraScope
          : rows.overrideDenies
        : rows;

      // Get the dragKey and hoverKey from rows in respective tab
      const dragKey = gridRows[dragIndex].key;
      const hoverKey = gridRows[hoverIndex].key;

      // We need to combine extra/intra scope and make api call with new rules
      // Find drag and hover index in merged rules rows
      let newRules;

      if (type === 'allow') {
        newRules = pversionObj.rules.map(({href}) => ({href}));
      } else {
        newRules = pversionObj.deny_rules.map(({href}) => ({href}));
      }

      const dragIndexNewRules = newRules.findIndex(({href}) => href === dragKey);
      const hoverIndexNewRules = newRules.findIndex(({href}) => href === hoverKey);

      const updatedRules = moveItemInArray(newRules, dragIndexNewRules, hoverIndexNewRules);

      try {
        sendAnalyticsEvent('rulesets.reorder', {
          to: hoverIndexNewRules,
          from: dragIndexNewRules,
          movedBy: Math.abs(dragIndexNewRules - hoverIndexNewRules),
          total: updatedRules.length,
        });

        await fetcher.spawn(updateRules, {
          rule_set_id: rulesetId,
          data: {[type === 'allow' ? 'rules' : 'deny_rules']: updatedRules},
        });

        await handleRefresh();
      } catch (error) {
        setErrors([getErrorMessage(error)]);
      }
    },
    [fetcher, handleRefresh, rulesetId, rows, pversionObj, sendAnalyticsEvent],
  );

  const handleSelect = useCallback(
    ({affectedRows, selecting}) => {
      const newSelectedKeySet = new Set(selectedKeySet);
      const newSelectedKeySetToRemove = new Set(selectedKeySetToRemove);
      const newSelectedKeySetToEnable = new Set(selectedKeySetToEnable);
      const newSelectedKeySetToDisable = new Set(selectedKeySetToDisable);

      for (const row of affectedRows) {
        newSelectedKeySet[selecting ? 'add' : 'delete'](row.key);

        if (selecting) {
          if (row.selectable) {
            newSelectedKeySetToRemove.add(row.key);

            if (row.data.enabled) {
              newSelectedKeySetToDisable.add(row.key);
            } else {
              newSelectedKeySetToEnable.add(row.key);
            }
          }
        } else {
          newSelectedKeySetToRemove.delete(row.key);
          newSelectedKeySetToDisable.delete(row.key);
          newSelectedKeySetToEnable.delete(row.key);
        }
      }

      setSelectedKeySet(newSelectedKeySet);
      setSelectedKeySetToRemove(newSelectedKeySetToRemove);
      setSelectedKeySetToDisable(newSelectedKeySetToDisable);
      setSelectedKeySetToEnable(newSelectedKeySetToEnable);
    },
    [selectedKeySet, selectedKeySetToRemove, selectedKeySetToEnable, selectedKeySetToDisable],
  );

  const handleDrawerToggle = useCallback(type => {
    setCollapseRules(collapseRules => ({...collapseRules, [type]: !collapseRules[type]}));
  }, []);

  useEffect(() => {
    // Skip validation:
    // 1. if rule editor is not set
    // 2. In case of create new rule where editor is not touched
    if (!ruleEditorState || !ruleEditorState.touched) {
      return;
    }

    // set/reset form dirty (if edited content is same as initial content)
    // perform rule validation and set error state on row
    // disable/enable save button of the rule
    const ruleIsChanged =
      tab === 'iptables'
        ? hasIpTablesRuleChanged(ruleEditorProp, ruleEditorState)
        : hasIntraExtraScopeRuleChanged(ruleEditorProp, ruleEditorState);

    publishFormIsDirty({dirty: ruleEditorProp.isDuplicateOrReverse || ruleIsChanged});

    const validationData =
      tab === 'iptables'
        ? getIpTablesRuleErrors({rule: ruleEditorState, scopes: pversionObj.scopes})
        : getIntraExtraScopeRuleErrors({
            rule: ruleEditorState,
            userIsScoped,
            scopes: pversionObj.scopes,
          });

    const saveIsDisabled =
      (!ruleEditorProp.isDuplicateOrReverse && !ruleIsChanged) ||
      Object.values(validationData.errors).flat().length > 0;

    const errorsOrWarningsChanged = hasErrorOrWarningChanged(ruleEditorProp, validationData);

    if (ruleEditorProp.saveIsDisabled !== saveIsDisabled || errorsOrWarningsChanged) {
      // Dispatch action to re-render button groups when Rule values/Errors are set/reset
      dispatch({
        type: 'RULESET_RULE_EDITOR',
        data: {
          ...ruleEditorProp,
          ...validationData,
          saveIsDisabled,
        },
      });
    }
  }, [dispatch, pversionObj, userIsScoped, publishFormIsDirty, ruleEditorProp, ruleEditorState, tab]);

  useEffect(() => {
    const [loadingRowKey] = [...extraPropsKeyMap].find(([, className]) => className.loading) ?? [];

    if (!loadingRowKey || (!dropdownActionRef.current && !ruleEditorState)) {
      // we need to check that both - loading state on row, and editor data. because,
      // this useEffect re-executes while loading is still set to true and item refetch is not complete
      return;
    }

    const {action} = dropdownActionRef.current ?? {};

    return action === 'remove' ? removeRule(loadingRowKey) : saveRule(loadingRowKey);
  }, [extraPropsKeyMap, saveRule, removeRule, tab, ruleEditorState]);

  useEffect(() => {
    return () => {
      // reset editor redux state on unmount
      publishFormIsDirty({dirty: false, force: true});
      resetForm();
    };
  }, [resetForm, publishFormIsDirty]);

  useEffect(() => {
    handleSetBackgroundStyle();
    handleResetKeySetState();
  }, [handleSetBackgroundStyle, handleResetKeySetState]);

  thisProxy.current = {
    dispatch,
    isReverseProviderConsumer,
    onEditClick: handleEditClick,
    onCancelClick: handleEditCancel,
    onSave: handleSetLoading,
    onRuleDropdownAction: handleRuleDropdownActions,
    onRuleAdd: handleRuleAdd,
    onEndpointChange: handleEndpointChange,
    onServiceChange: handleServiceChange,
    onRuleOptionsChange: handleRuleOptionsChange,
    onReceiversChange: handleReceiversChange,
    onIpVersionChange: handleIpVersionChange,
    onIpRulesStatementsChange: handleIpRulesStatementsChange,
    onBlur: handleSetTouched,
    onOverrideChange: handleOverrideDenyChange,
  };

  const isCreatedByRuleBuilder =
    pversionObj.external_data_set === 'illumio_rule_builder' ||
    pversionObj.external_data_set === 'illumio_policy_generator';

  const addScopeButtonDisabled =
    addScopeIsDisabled || isCreatedByRuleBuilder || actionButtonsDisabled || excludeKeys.length === labelTypes.length;

  const rulesetActionsProps = {
    actionButtonsDisabled,
    addScopeButtonDisabled,
    pversionObj,
    rulesetId,
    pversion,
    onAddScope: handleAddScope,
    invokeDiscardChanges,
    hideScope: __ANTMAN__,
    setErrors,
  };

  const showStartPolicyGenerator = isCreatedByRuleBuilder && policyGeneratorId && pversionObj.caps?.includes('write');

  const rulesetActions = (
    <>
      {showStartPolicyGenerator && (
        <Button.Link
          tid="policygenerator"
          color="secondary"
          text={intl('PolicyGenerator.StartPolicyGenerator')}
          link={{
            to: 'policygenerator',
            params: {id: policyGeneratorId},
          }}
        />
      )}
      <RulesetActions {...rulesetActionsProps} />
      <ButtonRefresh color="standard" textIsHideable onRefresh={handleRefresh} />
    </>
  );

  const renderAddRuleMenu = useCallback(() => {
    return (
      <>
        {__ANTMAN__ && (
          <>
            <MenuItem
              tid="add-override-deny-rule"
              text={
                <>
                  {intl('Antman.Rulesets.Rules.OverrideDeny.AddRule')}
                  <br />
                  <small>{intl('Antman.Rulesets.Rules.OverrideDeny.AddDesc')}</small>
                </>
              }
              onClick={_.partial(handleRuleAdd, {tab: 'extrascope', ...(__ANTMAN__ && {type: 'overrideDeny'})})}
            />
            <MenuDelimiter />
          </>
        )}
        <MenuItem
          tid="add-intra-scope-rule"
          text={
            <>
              {intl(__ANTMAN__ || pversionObj.scopes?.[0].length > 0 ? 'Rulesets.Rules.IntraScope.Add' : 'Rule.Add')}
              <br />
              <small>
                {intl(__ANTMAN__ ? 'Antman.Rulesets.Rules.IntraScope.AddDesc' : 'Rulesets.Rules.IntraScope.AddDesc')}
              </small>
            </>
          }
          onClick={_.partial(handleRuleAdd, {tab: 'intrascope', ...(__ANTMAN__ && {type: 'allow'})})}
        />
        {(__ANTMAN__ || pversionObj.scopes?.[0].length > 0) && (
          <>
            <MenuDelimiter />
            <MenuItem
              tid="add-extra-scope-rule"
              text={
                <>
                  {intl('Rulesets.Rules.ExtraScope.Add')}
                  <br />
                  <small>
                    {intl(
                      __ANTMAN__ ? 'Antman.Rulesets.Rules.ExtraScope.AddDesc' : 'Rulesets.Rules.ExtraScope.AddDesc',
                    )}
                  </small>
                </>
              }
              onClick={_.partial(handleRuleAdd, {
                tab: 'extrascope',
                rule: {unscoped_consumers: true},
                ...(__ANTMAN__ && {type: 'deny'}),
              })}
            />
          </>
        )}
        {!__ANTMAN__ && (
          <>
            <MenuDelimiter />
            <MenuItem
              tid="add-iptables-rule"
              text={
                <>
                  {intl('Rulesets.Rules.IpTables.Add')}
                  <br />
                  <small>{intl('Rulesets.Rules.IpTables.AddDesc')}</small>
                </>
              }
              onClick={_.partial(handleRuleAdd, {tab: 'iptables'})}
            />
          </>
        )}
      </>
    );
  }, [handleRuleAdd, pversionObj]);

  const showExtraScopeTab = !__ANTMAN__ && pversionObj.scopes?.[0]?.length > 0;
  const showIpTablesTab =
    !__ANTMAN__ && (pversionObj.ip_tables_rules?.length > 0 || filteredIpTablesRulesRows.length > 0);
  const rulesetIsDisabled = pversionObj.enabled === false;

  const rulesetScopeProps = {
    // Scopes created by rule builder/policy generator is not editable
    actionButtonsDisabled: actionButtonsDisabled || isCreatedByRuleBuilder,
    invokeDiscardChanges,
    publishFormIsDirty,
    excludeKeys,
    scopes,
    scopeInEdit,
    pversion,
    rulesetId,
    hideRemove: scopeIsRequired && pversionObj.scopes?.length === 1,
    setErrors,
    onCancel: invokeDiscardChanges,
    onEditClick: handleScopeEdit,
    onSetScopeInEdit: setScopeInEdit,
    onSave: handleScopeSave,
    onRemove: handleRemoveScope,
    onValidate: handleValidateScope,
  };

  const dontHighlightSelected = [...extraPropsKeyMap.values()].some(
    ({className}) => !className?.includes('disabledRules'),
  );

  const {gridSettings, denyRulesGridSettings} = useMemo(() => {
    const settings = (() => {
      if (tab === 'iptables') {
        return ipTablesGridSettings;
      }

      if (rulesFilter.size > 0 || pversion !== 'draft' || actionButtonsDisabled) {
        return produce(rulesGridSettings, draft => {
          draft.columns.dragbar.hidden = true;
        });
      }

      return rulesGridSettings;
    })();

    return {
      gridSettings: settings,
      ...(__ANTMAN__ && {
        denyRulesGridSettings: produce(settings, draft => {
          draft.columns.ruleOptions.header = '';
        }),
      }),
    };
  }, [tab, rulesFilter, pversion, ipTablesGridSettings, rulesGridSettings, actionButtonsDisabled]);

  return (
    <>
      <HeaderProps
        icon={rulesetIsDisabled ? {name: 'cancel', theme: styles, themePrefix: 'headerIcon-'} : undefined}
        title={intl(rulesetIsDisabled ? 'Rulesets.Disabled' : 'Common.Ruleset')}
        subtitle={pversionObj.name}
        up="rulesets"
      />
      {(isOldVersion || draft?.update_type) && (
        <ProvisioningNotification
          disabled={provisionIsDisabled}
          params={{
            active: {pversion: 'active', id: rulesetId, tab},
            draft: {pversion: 'draft', id: rulesetId, tab},
          }}
          pversion={pversion}
          active={active}
          draft={draft}
          onDone={handleRefresh}
          objectsToProvision={{rule_sets: [{href: draft?.href}]}}
          pversionObjIsDeleted={pversionObjIsDeleted}
          unsavedWarningData={getDiscardChangesModalProps('rule')}
          onNavigate={handlePversionNavigate}
        />
      )}
      {showScopeSection && (
        <>
          <ToolBar>
            <ToolGroup noSingleton>
              <RulesetScopeHeader
                pversion={pversion}
                excludeKeys={excludeKeys}
                hideSubtitle={!hasScopes}
                value={pversionObj.scopes}
                oldValue={prevPversionObj?.scopes}
                matched={scopesFilter?.size ? scopes.filter(scope => scope.length) : undefined}
                onFilterChange={handleScopeFilterChange}
              />
            </ToolGroup>
            <ToolGroup>{rulesetActions}</ToolGroup>
          </ToolBar>
          <div className={styles.divider} />
          <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap, stylesUtils.centerFlexAlign)}>
            {hasScopes ? (
              <RulesetScope {...rulesetScopeProps} />
            ) : (
              <span className={styles.scopeSubtitle} data-tid="add-scope-info">
                {getEmptyScopeText(excludeKeys, isCreatedByRuleBuilder)}
              </span>
            )}
            {pversion === 'draft' && (
              <Button
                color="standard"
                icon="add"
                disabled={addScopeButtonDisabled}
                text={intl('Rulesets.Scopes.AddScope')}
                textIsHideable
                tid="add-scope"
                onClick={handleAddScope}
              />
            )}
          </div>
        </>
      )}
      <ToolBar>
        <ToolGroup>
          {pversion === 'draft' && (
            <>
              {__ANTMAN__ || pversionObj.scopes?.[0]?.length > 0 || ipTablesRulesRows.length > 0 ? (
                <Button.Menu
                  theme={__ANTMAN__ && {'menu-itemsList': styles.menuItemsList}}
                  color="primary"
                  icon="add"
                  text={intl('Common.Add')}
                  textIsHideable
                  tid="rule-add-menu"
                  menuAlign="left"
                  menu={renderAddRuleMenu}
                  disabled={actionButtonsDisabled}
                />
              ) : (
                <Button
                  color="primary"
                  icon="add"
                  text={intl('Common.Add')}
                  textIsHideable
                  tid="rule-add"
                  disabled={actionButtonsDisabled}
                  onClick={_.partial(handleRuleAdd, {tab: 'intrascope'})}
                />
              )}
              <Button
                color="standard"
                icon="remove"
                text={intl('Common.Remove')}
                textIsHideable
                tid="rule-remove"
                counter={selectedKeySetToRemove.size}
                counterColor="red"
                disabled={!selectedKeySetToRemove.size}
                onClick={handleBatchRemove}
                onMouseEnter={_.partial(handleButtonEnter, selectedKeySetToRemove, 'remove')}
                onMouseLeave={handleButtonLeave}
              />
              <Button
                color="standard"
                icon="disabled"
                text={intl('Common.Disable')}
                textIsHideable
                tid="rule-disable"
                counter={selectedKeySetToDisable.size}
                counterColor="yellow"
                progressCompleteWithCheckmark
                progress={progressObj.runningDisable}
                disabled={!selectedKeySetToDisable.size}
                onClick={_.partial(handleBatchStatusChange, false)}
                onMouseEnter={_.partial(handleButtonEnter, selectedKeySetToDisable, 'disable')}
                onMouseLeave={handleButtonLeave}
              />
              <Button
                color="standard"
                icon="check"
                text={intl('Common.Enable')}
                textIsHideable
                tid="rule-enable"
                counter={selectedKeySetToEnable.size}
                progressCompleteWithCheckmark
                progress={progressObj.runningEnable}
                disabled={!selectedKeySetToEnable.size}
                onClick={_.partial(handleBatchStatusChange, true)}
                onMouseEnter={_.partial(handleButtonEnter, selectedKeySetToEnable, 'enable')}
                onMouseLeave={handleButtonLeave}
              />
            </>
          )}
          <RulesGridFilter
            key="rules-filter"
            pversion={pversion}
            onFilterChange={handleRulesFilterChange}
            intraScopeRulesRows={intraScopeRulesRows}
            extraScopeRulesRows={extraScopeRulesRows}
            ipTablesRulesRows={ipTablesRulesRows}
          />
        </ToolGroup>
        <ToolGroup>
          <RulesetSummary summaryObj={summaryObj} isAdditionPending={isAdditionPending} />
          {!showScopeSection && rulesetActions}
        </ToolGroup>
      </ToolBar>
      {!__ANTMAN__ && (
        <TabPanel primary>
          {[
            {
              link: {params: {tab: 'intrascope'}, scrollTop: false},
              text: scopes?.[0]?.length > 0 ? intl('PolicyGenerator.IntraScopeRules') : intl('Common.Rules'),
              tid: 'intrascope-rules',
              counter: getRowsCount(filteredIntraScopeRulesRows),
              counterColor: 'orange',
              onClick: _.partial(handleTabChange, _, 'intrascope'),
            },
            ...(showExtraScopeTab
              ? [
                  {
                    link: {params: {tab: 'extrascope'}, scrollTop: false},
                    text: intl('PolicyGenerator.ExtraScopeRules'),
                    tid: 'extrascope-rules',
                    counter: getRowsCount(filteredExtraScopeRulesRows),
                    counterColor: 'orange',
                    onClick: _.partial(handleTabChange, _, 'extrascope'),
                  },
                ]
              : []),
            ...(showIpTablesTab
              ? [
                  {
                    link: {params: {tab: 'iptables'}, scrollTop: false},
                    text: intl('Help.Title.CustomiptablesRules'),
                    tid: 'iptables-rules',
                    counter: getRowsCount(filteredIpTablesRulesRows),
                    counterColor: 'orange',
                    onClick: _.partial(handleTabChange, _, 'iptables'),
                  },
                ]
              : []),
          ]}
        </TabPanel>
      )}
      {__ANTMAN__ && (
        <>
          <Drawer
            text={`${intl('Antman.Rulesets.Rules.OverrideDeny.Rules')} (${getRowsCount(
              filteredOverrideDenyRulesRows,
            )})`}
            onChange={_.partial(handleDrawerToggle, 'overrideDeny')}
            closed={collapseRules.overrideDeny}
          >
            <GridLocal
              tid={tab}
              theme={styles}
              rows={rows.overrideDenies}
              settings={denyRulesGridSettings}
              component={thisProxy.current}
              selectedKeySet={selectedKeySet}
              extraPropsKeyMap={extraPropsKeyMap}
              dontHighlightSelected={dontHighlightSelected}
              emptyMessage={
                rulesFilter.size && rowsProp.overrideDenies.length
                  ? intl('Rulesets.Rules.NoMatchingRules', {type: 'overrideDeny'})
                  : intl('Rulesets.Rules.NoRulesDefined', {type: 'overrideDeny'})
              }
              onSelect={handleSelect}
              {...(__ANTMAN__ && {offset: 'var(--0px)'})}
              onReorder={_.partial(handleReorder, {type: 'overrideDeny'})}
            />
          </Drawer>
          <Drawer
            text={`${intl('Rulesets.AllowRules')} (${getRowsCount(filteredIntraScopeRulesRows)})`}
            onChange={_.partial(handleDrawerToggle, 'allow')}
            closed={collapseRules.allow}
          >
            <GridLocal
              tid={tab}
              theme={styles}
              rows={rows.intraScope}
              settings={gridSettings}
              component={{...thisProxy.current, type: 'allow'}}
              selectedKeySet={selectedKeySet}
              extraPropsKeyMap={extraPropsKeyMap}
              dontHighlightSelected={dontHighlightSelected}
              emptyMessage={
                rulesFilter.size && rowsProp.intraScope.length
                  ? intl('Rulesets.Rules.NoMatchingRules', {type: 'intrascope'})
                  : intl('Rulesets.Rules.NoRulesDefined', {type: 'intrascope'})
              }
              onSelect={handleSelect}
              onReorder={_.partial(handleReorder, {type: 'allow'})}
              offset="var(--0px)"
            />
          </Drawer>
          <Drawer
            text={`${intl('Rulesets.DenyRules')} (${getRowsCount(filteredExtraScopeRulesRows)})`}
            onChange={_.partial(handleDrawerToggle, 'deny')}
            closed={collapseRules.deny}
          >
            <GridLocal
              tid={tab}
              theme={styles}
              rows={rows.extraScope}
              settings={denyRulesGridSettings}
              component={{...thisProxy.current, type: 'deny'}}
              selectedKeySet={selectedKeySet}
              extraPropsKeyMap={extraPropsKeyMap}
              dontHighlightSelected={dontHighlightSelected}
              emptyMessage={
                rulesFilter.size && rowsProp.extraScope.length
                  ? intl('Rulesets.Rules.NoMatchingRules', {type: 'extrascope'})
                  : intl('Rulesets.Rules.NoRulesDefined', {type: 'extrascope'})
              }
              onSelect={handleSelect}
              onReorder={_.partial(handleReorder, {type: 'deny'})}
              offset="var(--0px)"
            />
          </Drawer>
        </>
      )}
      {!__ANTMAN__ && (
        <GridLocal
          tid={tab}
          theme={styles}
          rows={rows}
          settings={gridSettings}
          component={thisProxy.current}
          selectedKeySet={selectedKeySet}
          extraPropsKeyMap={extraPropsKeyMap}
          dontHighlightSelected={dontHighlightSelected}
          emptyMessage={
            rulesFilter.size && rowsProp.length
              ? intl('Rulesets.Rules.NoMatchingRules', {type: tab})
              : intl('Rulesets.Rules.NoRulesDefined', {type: tab})
          }
          onSelect={handleSelect}
          onReorder={_.partial(handleReorder, {type: tab})}
        />
      )}
      {remove && renderRemoveConfirmation()}
      {errors && renderAlertModal()}
    </>
  );
}
