/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import produce from 'immer';
import {Selector} from 'containers';
import {AttributeList, Button, Card, Form, Grid, HorizontalTrain, Input, Modal, Notifications} from 'components';
import styles from 'antman/containers/TemplatesWizard/TemplatesWizard.css';
import stylesUtils from 'utils.css';

/**
 * Constants
 */
export const RULE_SELECTION = 0;
export const RULE_PREVIEW = 1;
// TODO: enable this step later - we dont read this value just yet
//const RULE_IMPACT = 2;
export const CONFIRM_AND_SAVE = 3;

export const ADDITIONAL_DETAILS = 10; // an extra step in the middle of the wizard for configurable rules, hence high value

// used for the train step counter
const getStepNumber = (step, isConfigurableRule) => {
  if (step === RULE_SELECTION) {
    return RULE_SELECTION;
  }

  // we need to dynamically change step numbers when the user selects a configurable template
  if (step === ADDITIONAL_DETAILS) {
    return 1;
  }

  if (isConfigurableRule) {
    return step + 1;
  }

  return step;
};

// used to compute the next step in the template wizard state
export const getNextStep = (currentStep, isConfigurableRule, previous = false) => {
  // can only go forward on this step
  if (currentStep === RULE_SELECTION && isConfigurableRule) {
    return ADDITIONAL_DETAILS;
  }

  // only configurable rules can reach this step
  if (currentStep === ADDITIONAL_DETAILS) {
    return previous ? RULE_SELECTION : RULE_PREVIEW;
  }

  if (currentStep === RULE_PREVIEW) {
    if (isConfigurableRule && previous) {
      return ADDITIONAL_DETAILS;
    }

    // TODO: skip RULE_IMPACT step for now
    return previous ? RULE_SELECTION : CONFIRM_AND_SAVE;
  }

  // can only go back on this step
  if (currentStep === CONFIRM_AND_SAVE) {
    // TODO: skip RULE_IMPACT step for now
    return RULE_PREVIEW;
  }

  return getStepNumber(currentStep, isConfigurableRule) + (previous ? -1 : 1);
};

// flag for fields that need to be populated with additional details from the user
const NEED_ADDITIONAL_DETAILS = 'NEED_ADDITIONAL_DETAILS';

// flag that uses all services instead of new service object - e.g. 'All Services' instead of 'MSFT Domain Controllers'
const ALL_SERVICES = 'ALL_SERVICES';

// maps Template name to service name
export const ruleToService = {
  [intl('Antman.TemplatesWizard.BlockService', {service: 'NetBIOS'})]: 'NetBIOS',
  [intl('Antman.TemplatesWizard.BlockService', {service: 'Telnet'})]: 'Telnet',
  [intl('Antman.TemplatesWizard.BlockService', {service: 'RDP'})]: 'RDP',
  [intl('Antman.TemplatesWizard.BlockService', {service: 'MSFT SMB'})]: 'MSFT SMB',
  [intl('Antman.TemplatesWizard.BlockService', {service: 'WMI'})]: 'WMI',
  [intl('Antman.TemplatesWizard.ProtectService', {service: 'MSFT Domain Controllers'})]: 'MSFT Domain Controllers',
};

const steps = [
  intl('Antman.TemplatesWizard.SelectBestPractice'),
  intl('Antman.TemplatesWizard.ShowPreview'),
  intl('Antman.TemplatesWizard.PreviewImpact'),
  intl('Antman.TemplatesWizard.Confirm'),
];

const additionalSteps = [
  ...steps.slice(0, 1),
  intl('Antman.TemplatesWizard.ProvideAdditionalDetails'),
  ...steps.slice(1),
];

// selector preset
const selectorCategories = [
  produce(Selector.categoryPresets.labelsAndLabelGroups, draft => {
    draft.resources.labelsAndLabelGroups.statics = [];
    draft.resources.labelsAndLabelGroups.optionProps.tooltipProps = {
      instant: true,
      appearWhen: 'allWorkloads',
      content: intl('Antman.Rulesets.OverrideAllWorkloads'),
    };
    draft.resources.labelsAndLabelGroups.optionProps.filterOption = option => !option.href.includes('exists');
  }),
  {
    id: 'stickyCategory',
    resources: {
      allWorkloads: {
        sticky: true,
        statics: [intl('Workloads.All')],
        optionProps: {
          isPill: true,
          noFilter: true,
          tooltipProps: {
            instant: true,
            appearWhen: 'labelsAndLabelGroups',
            content: intl('Antman.Rulesets.OverrideLabels'),
          },
        },
        selectedProps: {hideResourceName: true},
      },
    },
  },
];

// TODO: won't be needed after EYE-88066 is implemented
export const predefinedServices = [
  {
    name: 'NetBIOS',
    description: intl('Antman.TemplatesWizard.NetBIOSService'),
    service_ports: [
      {port: 137, to_port: 139, proto: 17},
      {port: 137, to_port: 139, proto: 6},
    ],
  },
  {
    name: 'Telnet',
    description: 'Telnet',
    service_ports: [
      {port: 23, proto: 17},
      {port: 23, proto: 6},
    ],
  },
  {
    name: 'RDP',
    description: intl('Antman.TemplatesWizard.RDPDesc'),
    service_ports: [
      {port: 3389, proto: 17},
      {port: 3389, proto: 6},
    ],
  },
  {
    name: 'MSFT SMB',
    description: intl('Antman.TemplatesWizard.SMBService'),
    service_ports: [
      {port: 445, proto: 6},
      {port: 445, proto: 17},
    ],
  },
  {
    name: 'WMI',
    description: intl('Antman.TemplatesWizard.WMIService'),
    windows_services: [{service_name: 'Winmgmt'}],
  },
  {
    name: 'MSFT Domain Controllers',
    description: intl('Antman.TemplatesWizard.DomainControllerDesc'),
    service_ports: [
      {port: 123, proto: 17},
      {port: 135, proto: 6},
      {port: 464, proto: 6},
      {port: 464, proto: 17},
      {port: 389, proto: 6},
      {port: 389, proto: 17},
      {port: 636, proto: 6},
      {port: 3268, proto: 6},
      {port: 3269, proto: 6},
      {port: 53, proto: 6},
      {port: 53, proto: 17},
      {port: 88, proto: 6},
      {port: 88, proto: 17},
      {port: 445, proto: 6},
      {port: 49_152, to_port: 65_535, proto: 6},
    ],
  },
];

// will change once research spike for EYE-89449 concludes
export const predefinedRules = [
  {
    name: intl('Antman.TemplatesWizard.BlockService', {service: 'NetBIOS'}),
    allowRules: [],
    denyRules: [
      {
        enabled: true,
        providers: [{actors: 'ams'}],
        consumers: [],
        ingress_services: [],
      },
    ],
  },
  {
    name: intl('Antman.TemplatesWizard.BlockService', {service: 'Telnet'}),
    allowRules: [],
    denyRules: [
      {
        enabled: true,
        providers: [{actors: 'ams'}],
        consumers: [],
        ingress_services: [],
      },
    ],
  },
  {
    name: intl('Antman.TemplatesWizard.BlockService', {service: 'RDP'}),
    allowRules: [],
    denyRules: [
      {
        enabled: true,
        providers: [{actors: 'ams'}],
        consumers: [],
        ingress_services: [],
      },
    ],
  },
  {
    name: intl('Antman.TemplatesWizard.BlockService', {service: 'MSFT SMB'}),
    allowRules: [],
    denyRules: [
      {
        enabled: true,
        providers: [{actors: 'ams'}],
        consumers: [],
        ingress_services: [],
      },
    ],
  },
  {
    name: intl('Antman.TemplatesWizard.BlockService', {service: 'WMI'}),
    allowRules: [],
    denyRules: [
      {
        enabled: true,
        providers: [{actors: 'ams'}],
        consumers: [],
        ingress_services: [],
      },
    ],
  },
  {
    name: intl('Antman.TemplatesWizard.ProtectService', {service: 'MSFT Domain Controllers'}),
    allowRules: [
      {
        enabled: true,
        providers: NEED_ADDITIONAL_DETAILS,
        consumers: [],
        ingress_services: [],
      },
      {
        enabled: true,
        providers: NEED_ADDITIONAL_DETAILS,
        consumers: NEED_ADDITIONAL_DETAILS,
        ingress_services: ALL_SERVICES,
        secureConnect: true,
      },
    ],
    denyRules: [
      {
        enabled: true,
        providers: NEED_ADDITIONAL_DETAILS,
        consumers: [],
        ingress_services: ALL_SERVICES,
      },
    ],
  },
];

/**
 * Computation functions
 */
// takes a bunch of data and formats it for the grid in the rule preview step
export const computeTemplatePreview = ({predefinedRule, additionalDetails, allServicesHref, anyIP, service}) => {
  // TODO: temporary - predefined rules api
  const computedRules = {name: predefinedRule.name};

  const anyIpArr = [{ip_list: {href: anyIP.href, name: intl('IPLists.Any')}}];
  const allServicesArr = [{name: intl('Common.AllServices'), href: allServicesHref}];

  const labelsAndLabelGroups = additionalDetails.get('labelsAndLabelGroups')?.length
    ? additionalDetails.get('labelsAndLabelGroups').map(obj => {
        if (obj.href.includes('label_groups')) {
          return {label_group: obj};
        }

        return {label: obj};
      })
    : [];
  const allWorkloads = additionalDetails.get('allWorkloads')?.length ? [{actors: 'ams'}] : [];
  const additionalDetailsArr = [...labelsAndLabelGroups, ...allWorkloads];

  for (const ruleType of ['allowRules', 'denyRules']) {
    if (!predefinedRule[ruleType].length) {
      continue;
    }

    computedRules[ruleType] = predefinedRule[ruleType].map(rule => {
      const preview = {
        enabled: rule.enabled,
        action: ruleType === 'allowRules' ? 'allow' : 'deny',
        secureConnect: rule.secureConnect,
      };

      if (rule.consumers === NEED_ADDITIONAL_DETAILS) {
        preview.consumers = additionalDetailsArr;
      } else if (!rule.consumers.length) {
        preview.consumers = anyIpArr;
      } else {
        preview.consumers = rule.consumers;
      }

      if (rule.providers === NEED_ADDITIONAL_DETAILS) {
        preview.providers = additionalDetailsArr;
      } else if (!rule.providers.length) {
        preview.providers = anyIpArr;
      } else {
        preview.providers = rule.providers;
      }

      // some templates have multiple rules, don't always use predefined service
      if (service && rule.ingress_services !== ALL_SERVICES) {
        preview.ingress_services = [service];
      } else {
        preview.ingress_services = allServicesArr;
      }

      return preview;
    });
  }

  return computedRules;
};

// key can be 'label', 'ip_list', 'workload' etc.
// input: {label: {href, name, key}}
// output: {label: {href}}
const acceptedKeys = new Set(['label', 'label_group', 'ip_list']);
const getHrefRepresentation = obj => {
  if (obj.actors === 'ams') {
    return obj;
  }

  const key = Object.keys(obj).find(key => acceptedKeys.has(key));

  return {[key]: {href: obj[key].href}};
};

const resolve_labels_as = {consumers: ['workloads'], providers: ['workloads']};

export const preparePayload = ({enabled, consumers, ingress_services, providers, secureConnect}, rule_set_id, type) => {
  const payload = {
    rule_set_id,
    data: {
      enabled,
      consumers: consumers.map(getHrefRepresentation),
      providers: providers.map(getHrefRepresentation),
      ingress_services,
    },
  };

  if (type === 'allow') {
    payload.data.resolve_labels_as = resolve_labels_as;
  }

  if (secureConnect) {
    payload.data.sec_connect = true;
  }

  return payload;
};

/**
 * Helper functions that return renderable content
 */
const BestPracticeCard = ({name, description, onSelect} = {}) => (
  <li>
    <Card theme={styles} color="secondary" onClick={onSelect}>
      <h2 className={styles.title}>{name}</h2>
      <p className={styles.description}>{description}</p>
    </Card>
  </li>
);

const renderRuleSelection = ({searchFilter, handleSearchFilter, handleStep}) => (
  <>
    {
      // eslint-disable-next-line no-constant-binary-expression
      false && (
        // Enable once API is ready to be integrated
        <AttributeList breakpoints={[{sizeXS: {maxWidth: 1000}}]}>
          {[
            {
              valueGap: 'paddingSmallTop',
              key: intl('Antman.TemplatesWizard.SelectFromRecommendations'),
              value: (
                <Input
                  noWrap
                  tid="templatesSearch"
                  name="templatesSearch"
                  value={searchFilter}
                  onChange={handleSearchFilter}
                  placeholder={intl('Common.FilterView')}
                />
              ),
            },
          ]}
        </AttributeList>
      )
    }
    <ul className={styles.cardsContainer}>
      <BestPracticeCard
        name={intl('Antman.TemplatesWizard.BlockService', {service: 'NetBIOS'})}
        description={intl('Antman.TemplatesWizard.BlockNetBIOSDesc')}
        onSelect={_.partial(handleStep, {
          option: intl('Antman.TemplatesWizard.BlockService', {service: 'NetBIOS'}),
        })}
        handleStep
      />
      <BestPracticeCard
        name={intl('Antman.TemplatesWizard.BlockService', {service: 'Telnet'})}
        description={intl('Antman.TemplatesWizard.BlockTelnetDesc')}
        onSelect={_.partial(handleStep, {
          option: intl('Antman.TemplatesWizard.BlockService', {service: 'Telnet'}),
        })}
        handleStep
      />
      <BestPracticeCard
        name={intl('Antman.TemplatesWizard.BlockService', {service: 'RDP'})}
        description={intl('Antman.TemplatesWizard.BlockRDPDesc')}
        onSelect={_.partial(handleStep, {
          option: intl('Antman.TemplatesWizard.BlockService', {service: 'RDP'}),
        })}
      />
      <BestPracticeCard
        name={intl('Antman.TemplatesWizard.BlockService', {service: 'MSFT SMB'})}
        description={intl('Antman.TemplatesWizard.BlockSMBDesc')}
        onSelect={_.partial(handleStep, {
          option: intl('Antman.TemplatesWizard.BlockService', {service: 'MSFT SMB'}),
        })}
      />
      <BestPracticeCard
        name={intl('Antman.TemplatesWizard.BlockService', {service: 'WMI'})}
        description={intl('Antman.TemplatesWizard.BlockWMIDesc')}
        onSelect={_.partial(handleStep, {
          option: intl('Antman.TemplatesWizard.BlockService', {service: 'WMI'}),
        })}
      />
      <BestPracticeCard
        name={intl('Antman.TemplatesWizard.ProtectService', {service: 'MSFT Domain Controllers'})}
        description={intl('Antman.TemplatesWizard.DomainControllerDesc')}
        onSelect={_.partial(handleStep, {
          option: intl('Antman.TemplatesWizard.ProtectService', {service: 'MSFT Domain Controllers'}),
          needAdditionalDetails: true,
        })}
      />
    </ul>
  </>
);

const renderAdditionalDetails = ({handleAdditionalDetails, additionalDetails}) => (
  <div className={stylesUtils.gapXSmall}>
    <h3 style={{marginBottom: 0}}>{intl('Antman.TemplatesWizard.DomainControllerSelection')}</h3>
    <Selector
      categories={selectorCategories}
      autoFocus
      placeholder={intl('Antman.TemplatesWizard.SelectorPlaceholder')}
      onSelectionChange={handleAdditionalDetails}
      values={additionalDetails}
    />
  </div>
);

const renderRulePreview = ({allowRulesGrid, denyRulesGrid, componentObj}) => (
  <>
    {allowRulesGrid.rows.length ? (
      <div>
        <h3>{intl('Antman.AllowRuleCreation', {count: allowRulesGrid.rows.length})}</h3>
        <Grid grid={allowRulesGrid} theme={styles} component={componentObj} />
      </div>
    ) : null}
    {denyRulesGrid.rows.length ? (
      <div>
        <h3>{intl('Antman.DenyRuleCreation', {count: denyRulesGrid.rows.length})}</h3>
        <Grid grid={denyRulesGrid} theme={styles} component={componentObj} />
      </div>
    ) : null}
  </>
);

const renderConfirmAndSave = ({
  selectedOption,
  handleOnSubmit,
  errorNotification,
  train,
  actionButtons,
  schemas,
  handleNameChange,
  handleRulesetNameValidation,
  existingNameErrorRef,
}) => {
  return (
    <Form schemas={schemas} initialValues={{name: selectedOption}} onSubmit={handleOnSubmit} enableReinitialize>
      {({error, isSubmitting, setFieldValue, validateForm, setFieldError, setFieldTouched, touched, values}) => {
        // on first render we want to validation
        if (!touched.name && !touched.description) {
          handleRulesetNameValidation({touched, setFieldTouched, validateForm, setFieldError}, values.name);
        }

        return (
          <>
            <Modal.Content>
              {errorNotification}
              <div className={stylesUtils.gapXLarge}>
                {train}
                <AttributeList>
                  {[
                    {
                      key: <Form.Label name="name" title={intl('Common.Name')} />,
                      value: (
                        <Form.Input
                          name="name"
                          tid="name"
                          placeholder={intl('Rulesets.CreatePage.Placeholder.RulesetName')}
                          onChange={_.partial(handleNameChange, {
                            setFieldValue,
                            setFieldError,
                            validateForm,
                            touched,
                            setFieldTouched,
                          })}
                        />
                      ),
                    },
                    {
                      key: <Form.Label name="description" title={intl('Common.Description')} />,
                      value: (
                        <Form.Textarea
                          name="description"
                          tid="description"
                          placeholder={intl('Rulesets.CreatePage.Placeholder.RulesetDescription')}
                        />
                      ),
                    },
                  ]}
                </AttributeList>
              </div>
            </Modal.Content>
            <Modal.Footer>
              {actionButtons}
              <Button
                progressCompleteWithCheckmark
                progress={isSubmitting}
                progressError={error}
                disabled={existingNameErrorRef.current || !values.name}
                text={intl('Antman.TemplatesWizard.Confirm')}
                type="submit"
                tid="template-next"
              />
            </Modal.Footer>
          </>
        );
      }}
    </Form>
  );
};

export const renderModalContent = ({
  currentStep,
  searchFilter,
  handleSearchFilter,
  allowRulesGrid,
  denyRulesGrid,
  additionalDetails,
  handleStep,
  handleAdditionalDetails,
  componentObj,
  selectedOption,
  error,
  handleOnSubmit,
  onClickCancel,
  schemas,
  handleNameChange,
  handleRulesetNameValidation,
  existingNameErrorRef,
}) => {
  const isConfigurableRule = currentStep === ADDITIONAL_DETAILS || additionalDetails.size;
  const train = (
    <HorizontalTrain
      text={isConfigurableRule ? additionalSteps : steps}
      color="primary"
      themePrefix="train-"
      currentStep={getStepNumber(currentStep, isConfigurableRule)}
      numSteps={isConfigurableRule ? additionalSteps.length : steps.length}
    />
  );

  const errorNotification = error?.data && (
    <Notifications>
      {error.data.map(err => ({
        message: err.message,
        type: 'error',
      }))}
    </Notifications>
  );

  const actionButtons = (
    <>
      <Button noFill tid="template-close" text={intl('Common.Cancel')} onClick={onClickCancel} />
      {currentStep !== RULE_SELECTION && (
        <Button
          color="standard"
          tid="template-previous"
          type="button"
          text={intl('Common.Previous')}
          onClick={_.partial(handleStep, {previous: true})}
        />
      )}
    </>
  );

  // special case - we need the form to wrap the content and the submit button in the footer
  if (currentStep === CONFIRM_AND_SAVE) {
    return renderConfirmAndSave({
      selectedOption,
      handleOnSubmit,
      errorNotification,
      train,
      actionButtons,
      schemas,
      handleNameChange,
      handleRulesetNameValidation,
      existingNameErrorRef,
    });
  }

  return (
    <>
      <Modal.Content>
        {errorNotification}
        <div className={stylesUtils.gapXLarge}>
          {train}
          {currentStep === RULE_SELECTION
            ? renderRuleSelection({searchFilter, handleSearchFilter, handleStep})
            : currentStep === ADDITIONAL_DETAILS
            ? renderAdditionalDetails({handleAdditionalDetails, additionalDetails})
            : currentStep === RULE_PREVIEW
            ? renderRulePreview({allowRulesGrid, denyRulesGrid, componentObj})
            : null}
        </div>
      </Modal.Content>
      <Modal.Footer>
        {actionButtons}
        {currentStep !== RULE_SELECTION && (
          <Button
            onClick={handleStep}
            text={intl('Common.Next')}
            tid="template-next"
            disabled={currentStep === ADDITIONAL_DETAILS && !additionalDetails.size}
          />
        )}
      </Modal.Footer>
    </>
  );
};
