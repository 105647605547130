/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import {useState, useCallback, useMemo, useRef, useEffect} from 'react';
import {Button, Icon, StatusIcon, Link, Pill} from 'components';
import {Selector} from 'containers';
import {getScopeSubtitle, getScopeFilterCategories, getScopeDiff} from './RulesetScopeUtils';
import stylesUtils from 'utils.css';
import diffStyles from 'components/Diff/Diff.css';
import styles from '../RulesetItem.css';

export default function RulesetScopeHeader(props) {
  const {pversion, value, oldValue, matched, excludeKeys, hideSubtitle, onFilterChange} = props;
  const [showFilter, setShowFilter] = useState(false);
  const prevPversionRef = useRef(pversion);

  const handleShowFilter = useCallback(() => setShowFilter(true), []);
  const handleCancelFilter = useCallback(() => {
    setShowFilter(false);
    onFilterChange(new Map());
  }, [onFilterChange]);

  const categories = useMemo(() => getScopeFilterCategories(value), [value]);
  const {added, unchanged, removed} = getScopeDiff(value, oldValue);
  const isModified = added.length > 0 || removed.length > 0;

  const scopeDiffData = () => {
    const formatScope = scope => (
      <div className={cx(stylesUtils.gapXSmall, stylesUtils.gapHorizontalWrap)}>
        {scope.map(({exclusion, label, label_group}) => (
          <Pill.Label
            key={label?.href ?? label_group.href}
            type={label?.key ?? label_group?.key}
            group={Boolean(label_group)}
            insensitive
            exclusion={exclusion}
          >
            {label?.value ?? label_group?.name}
          </Pill.Label>
        ))}
      </div>
    );

    return (
      <div className={stylesUtils.gapXSmall}>
        {added[0]?.length > 0 && (
          <div data-tid="scope-added">
            {intl('Rulesets.Rules.AddedScope')}
            <div className={stylesUtils.gapXSmall}>
              {added.map((scope, index) => (
                <div className={diffStyles.row} key={index}>
                  <Icon name="add" tid="scope-added" theme={styles} themePrefix="add-" />
                  {formatScope(scope)}
                </div>
              ))}
            </div>
          </div>
        )}
        {unchanged[0]?.length > 0 && (
          <div data-tid="scope-unchanged">
            {intl('Rulesets.Rules.UnchangedScope')}
            <div className={stylesUtils.gapXSmall}>
              {unchanged.map((scope, index) => (
                <div className={diffStyles.row} key={index}>
                  <div />
                  {formatScope(scope)}
                </div>
              ))}
            </div>
          </div>
        )}
        {removed[0]?.length > 0 && (
          <div data-tid="scope-removed">
            {intl('Rulesets.Rules.RemovedScope')}
            <div className={stylesUtils.gapXSmall}>
              {removed.map((scope, index) => (
                <div className={diffStyles.row} key={index}>
                  <Icon name="remove" tid="scope-removed" theme={styles} themePrefix="remove-" />
                  {formatScope(scope)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (pversion !== prevPversionRef.current) {
      prevPversionRef.current = pversion;
      handleCancelFilter();
    }
  }, [pversion, handleCancelFilter]);

  return (
    <div className={cx(stylesUtils.centerFlexAlign, stylesUtils.gapSmall, stylesUtils.gapHorizontalWrap)}>
      <span className={stylesUtils.bold} data-tid="scope-title">
        {intl('Common.Scopes')}
      </span>
      {isModified && <StatusIcon status="modified" />}
      {!hideSubtitle && (
        <span className={styles.scopeSubtitle} data-tid="scope-subtitle">
          {getScopeSubtitle(value, excludeKeys, matched)}
        </span>
      )}
      {isModified && (
        <Link
          data-tid="scope-modified-details"
          tooltip={scopeDiffData()}
          tooltipProps={{trigger: 'click', maxWidth: 'calc(min(96vw, 580px))'}}
        >
          {intl('Rulesets.Scopes.ScopeModifiedDetails')}
        </Link>
      )}
      {value[0]?.length > 0 && //has at least one scope
        (showFilter ? (
          <div className={cx(stylesUtils.gapSmall, stylesUtils.gapHorizontal)}>
            <Selector
              hideClearAll
              tid="scope-filter-selector"
              noActiveIndicator
              closeDropdownOnSelection
              placeholder={intl('Common.SelectLabelsLabelGroups')}
              inputProps={{size: 28}}
              maxColumns={3}
              categories={categories}
              onSelectionChange={onFilterChange}
              footerProps={{filteringTipsContent: intl('ObjectSelector.FilteringTipsContentLabels')}}
            />
            <Button
              noFill
              color="standard"
              tid="scope-filter-cancel"
              size="small"
              icon="cancel"
              onClick={handleCancelFilter}
            />
          </div>
        ) : (
          <Button noFill color="standard" tid="scope-filter" size="small" icon="filter" onClick={handleShowFilter} />
        ))}
    </div>
  );
}
