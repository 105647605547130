/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import {Badge, Pill, StatusIcon} from 'components';
import {UserName} from 'containers';
import {getBadgeTypeByUpdateType, getBadgeLabelByUpdateType} from 'utils/formats';
import styles from './RulesetList.css';
import * as GridUtils from 'components/Grid/GridUtils';
import {allScopeLabels} from 'containers/Selectors/SelectorUtils';
import {createSelector} from 'reselect';
import stylesUtils from 'utils.css';

const objectMap = {
  rulesets: {type: 'rule_sets', pversion: 'draft'},
  ...(!__ANTMAN__ && {
    appLabels: {type: 'labels', key: 'app'},
    envLabels: {type: 'labels', key: 'env'},
    locLabels: {type: 'labels', key: 'loc'},
  }),
};

export const resourceType = 'rule_sets';
export const getSelectorSettings = createSelector([], () => ({
  objectMap,
  facetMap: {
    name: {value: intl('Common.Name'), object: objectMap.rulesets},
    description: {value: intl('Common.Description'), object: objectMap.rulesets},
  },
  staticMap: {
    provision: intl('Provision.Status'),
  },
  ...(!__ANTMAN__ && {
    scopeMap: {
      app: {
        value: intl('Labels.ApplicationLabels'),
        object: objectMap.appLabels,
        scope: true,
        statics: allScopeLabels().app,
      },
      env: {
        value: intl('Labels.EnvironmentLabels'),
        object: objectMap.envLabels,
        scope: true,
        statics: allScopeLabels().env,
      },
      loc: {
        value: intl('Labels.LocationLabels'),
        object: objectMap.locLabels,
        scope: true,
        statics: allScopeLabels().loc,
      },
    },
  }),
  filterMap: {
    ...(!__ANTMAN__ && {
      app: {
        value: intl('Labels.ApplicationLabels'),
        object: objectMap.appLabels,
        scope: true,
        statics: allScopeLabels().app,
      },
      env: {
        value: intl('Labels.EnvironmentLabels'),
        object: objectMap.envLabels,
        scope: true,
        statics: allScopeLabels().env,
      },
      loc: {
        value: intl('Labels.LocationLabels'),
        object: objectMap.locLabels,
        scope: true,
        statics: allScopeLabels().loc,
      },
    }),
    name: {value: intl('Common.Name'), object: objectMap.rulesets},
    description: {value: intl('Common.Description'), object: objectMap.rulesets},
    provision: intl('Provision.Status'),
  },
  staticValues: {
    provision: {
      [intl('Provision.PendingAddition')]: 'create',
      [intl('Provision.PendingDeletion')]: 'delete',
      [intl('Provision.PendingModification')]: 'update',
    },
  },
}));

/**
 [{
 header: string | Function,

 key: string | Function,
 value: string | Function,
 format: node | Function,
 sort: Function, // Getter for sorting value
 sortFunction: Function, // Custom sort function
 sortable: [true]boolean,
 isDate: boolean | string,
 }];
 */

export const gridSettings = createSelector([], () => ({
  id: 'rulesetlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: {
      header: intl('Provision.Status'),
      value: ({row}) => getBadgeLabelByUpdateType(row.data.update_type),
      format: ({row, value}) =>
        value ? (
          <Badge type={getBadgeTypeByUpdateType(row.data.update_type)} theme={styles} themePrefix="status-">
            {value}
          </Badge>
        ) : null,
    },
    state: {
      header: intl('Common.Status'),
      value: 'enabled',
      format: ({value}) => (
        <StatusIcon
          position="before"
          status={value ? 'inuse' : 'disabled-status'}
          label={<span className={stylesUtils.bold}>{value ? intl('Common.Enabled') : intl('Common.Disabled')}</span>}
        />
      ),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    scope: {
      header: intl('Common.Scopes'),
      sortable: false,
      disabled: __ANTMAN__,
      value: 'scopes',
      refs: ({value}) =>
        value[0].reduce((result, {label, label_group}) => {
          result[label?.href ?? label_group.href] = label => label?.element;

          return result;
        }, {}),
      onMouseOver: ({evt, elements}) => {
        return Object.values(elements).every(element => !element?.contains(evt.target));
      },
      format: ({value, refs}) => {
        if (value[0].length === 0) {
          return <Pill.Label type="scope">{intl('Common.All')}</Pill.Label>;
        }

        let result = (
          <div className={cx(stylesUtils.gapXSmall, stylesUtils.gapHorizontalWrap)}>
            {value[0].map(({label, label_group, exclusion}) => (
              <Pill.Label
                key={label?.href ?? label_group?.href}
                type={label?.key ?? label_group?.key}
                href={label?.href ?? label_group?.href}
                group={Boolean(label_group)}
                exclusion={exclusion}
                ref={refs[label?.href ?? label_group?.href]}
              >
                {label?.value ?? label_group?.name}
              </Pill.Label>
            ))}
          </div>
        );

        if (value.length > 1) {
          result = (
            <div>
              {result} {`+${value.length - 1} ${intl('Common.More')}`}
            </div>
          );
        }

        return result;
      },
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
   // Possible dimensions of breakpoint, go to format function
   minWidth: ?number,
   maxWidth: ?number,
   minHeight: ?number,
   maxHeight: ?number,

   // Required columns configuration for breapoint
   template: array | Function,

   // Optional breakpoint id, goes to format function
   id: ?string,
   // Optional props that will be merged to Grid container element
   props: ?object
   // Optional object with any data, goes to format function
   data: ?object,
   }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['state'], size: 'minmax(120px, auto)'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['scope'], size: 'minmax(350px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(130px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['state'], size: 'minmax(120px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['scope'], size: 'minmax(400px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(130px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['state'], size: 'minmax(120px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['scope'], size: 'minmax(400px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(130px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1200,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['state'], size: 'minmax(120px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['scope'], size: 'minmax(400px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['state'], size: 'minmax(120px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['scope'], size: 'minmax(400px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'state'], size: 'minmax(120px, auto)'},
            {columns: ['scope'], size: 'minmax(400px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'state'], size: 'minmax(120px, auto)'},
          {columns: ['scope'], size: 'minmax(400px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'state', 'status'], size: 'minmax(200px, auto)'},
            {columns: ['scope'], size: 'minmax(200px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'state', 'status'], size: 'minmax(200px, auto)'},
          {columns: ['scope'], size: 'minmax(200px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'scope', 'state', 'status'], size: 'minmax(170px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'scope', 'state', 'status'], size: 'minmax(170px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
