/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {createElement, useCallback, useRef, useLayoutEffect} from 'react';
import CategoryPanel from './CategoryPanel/CategoryPanel';
import OptionPanel from './OptionPanel';
import SelectorFooter from './SelectorFooter';
import {DROPDOWN_ID, useFilialPiety} from './SelectorUtils';

export default function Dropdown(props) {
  const {
    theme,
    saveRef,
    onCategoryClick,
    footer,
    registerHandlers,
    activeCategory,
    categoryPanelOnRight,
    footerProps = {},
    ...restProps
  } = props;

  const dropdownRef = useRef(null);
  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const saveRefCallback = useCallback(
    element => {
      dropdownRef.current = element;
      saveRef(DROPDOWN_ID, element);
    },
    [saveRef],
  );

  useLayoutEffect(() => {
    // listens to template change
    // fallback to Selector default small/wide screen template if template string changes to empty string
    // e.g. on label create option click, the template is set to empty string so that form can occupy all available space
    dropdownRef.current.style.setProperty(
      '--dropdown-grid-template',
      `
      '${categoryPanelOnRight ? 'optionPanel categoryPanel' : 'categoryPanel optionPanel'}' 1fr
      'footer footer' minmax(25px, min-content) / ${
        categoryPanelOnRight ? 'minmax(180px, auto) 180px' : '180px minmax(180px, 1fr)'
      }
      `,
    );
  }, [categoryPanelOnRight]);

  useLayoutEffect(() => {
    const unregister = registerHandlers(DROPDOWN_ID, {setHighlightedChild, resetHighlightedChild, keyDown});

    return () => unregister();
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, keyDown]);

  const dropdownStyles = cx(theme.dropdown, {
    [theme.hidden]: false,
    [theme.noCategoryPanel]: props.noCategoryPanel,
    [theme.clickable]: !props.insensitive,
  });

  // active category is needed because this can be a result of sideeffect and not user action
  // Added activeCategory to avoid having multiple categories in active status
  return (
    <div
      ref={saveRefCallback}
      className={dropdownStyles}
      onClick={props.onReturnFocusToInput}
      data-tid="comp-selector-dropdown"
    >
      {props.categories.length > 1 && (
        <CategoryPanel
          {...restProps}
          activeCategory={activeCategory}
          saveRef={saveChildRef}
          pathArr={[DROPDOWN_ID]}
          theme={theme}
          onClick={onCategoryClick}
          registerHandlers={registerChildHandlers}
        />
      )}
      <OptionPanel
        {...restProps}
        category={activeCategory}
        saveRef={saveChildRef}
        pathArr={[DROPDOWN_ID]}
        theme={theme}
        registerHandlers={registerChildHandlers}
      />
      <div className={cx(theme.footer)} data-tid="comp-selector-footer">
        {footer ? (
          createElement(footer, {theme, dropdownTippyInstance: restProps.dropdownTippyInstance, ...footerProps})
        ) : (
          <SelectorFooter theme={theme} dropdownTippyInstance={restProps.dropdownTippyInstance} {...footerProps} />
        )}
      </div>
    </div>
  );
}
