/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {useCallback, useMemo} from 'react';
import {Selector} from 'containers';
import {
  getEndpointCategories,
  advancedResourcesIds,
  useAdvancedOptionInfoPanel,
} from './Configs/RulesetRulesSelectorConfig';
import {typePrefixRegexp} from 'components/Pill/Label/LabelUtils';

const combinedResourceIds = ['labels_include', 'ip_list', 'workload'];
export default function EndpointSelector({
  values,
  type,
  onSelectionChange,
  showUserGroups,
  showContainerHost,
  showVirtualServers,
  warnings,
  isDenyRule,
  ...selectorProps
}) {
  const categories = useCallback(
    advancedEnabled =>
      getEndpointCategories({
        advanced: advancedEnabled,
        showUserGroups,
        showContainerHost,
        showVirtualServers,
        warnings,
        isDenyRule,
      }),
    [showUserGroups, showContainerHost, warnings, showVirtualServers, isDenyRule],
  );
  const handleSelectionChange = useCallback(
    values => {
      onSelectionChange(values);
    },
    [onSelectionChange],
  );

  const combinedCategoryProps = useMemo(
    () => ({
      infoPanel: useAdvancedOptionInfoPanel,
      resources: {
        combined: {
          queryKeywordsRegex: typePrefixRegexp,
          enableHistory: true,
          historyKey: 'endpoint',
        },
      },
    }),
    [],
  );

  const advancedEnabled = useMemo(() => advancedResourcesIds.some(id => values.has(id)), [values]);

  return (
    <Selector.MultiMode
      tid={`${type}-selector`}
      advancedEnabled={advancedEnabled}
      values={values}
      activeCategoryId="includeLabels"
      hideClearAll
      alwaysShowPlaceholder
      maxColumns={3}
      searchBarMaxHeight={250}
      combinedCategoryProps={combinedCategoryProps}
      placeholder={type === 'consumers' ? intl('Common.SelectConsumers') : intl('Common.SelectProviders')}
      categories={categories}
      combinedResourceIds={combinedResourceIds}
      onSelectionChange={handleSelectionChange}
      {...selectorProps}
    />
  );
}
