/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {useCallback, useMemo} from 'react';
import {useDeepCompareMemo} from 'utils/react';
import {isAPIAvailable} from 'api/apiUtils';
import {Selector} from 'containers';
import {getQueryAndKeyword} from 'containers/Selector/SelectorUtils';
import {labelTypes, typePrefixRegexp} from 'components/Pill/Label/LabelUtils';
import {scopeCategories} from '../Configs/RulesetRulesSelectorConfig';

export default function ScopePicker(props) {
  const {scope = [], onScopeChange, excludeKeys: excludeKeysProp = ['role'], ...selectorProps} = props;
  const excludeKeys = useDeepCompareMemo(excludeKeysProp);

  const handleSelectionChange = useCallback(
    values => {
      onScopeChange(
        [...values].reduce((result, [resourceId, [value]]) => {
          const labelObj = {
            [value.href.includes('label_groups') ? 'label_group' : 'label']: value,
            ...(resourceId.includes('exclude') && {exclusion: true}),
          };

          result.push(labelObj);

          return result;
        }, []),
      );
    },
    [onScopeChange],
  );

  const values = useMemo(
    () =>
      scope.reduce((result, {exclusion, label, label_group} = {}) => {
        const labelObject = label || label_group;

        // Selected resource array will contain one entry because only one label/label_group can be selected in each type
        // Resource Ids: {labelType}_include or {labelType_exclude} e.g. 'app_include', 'loc_exclude'
        result.set(`${labelObject.key}_${exclusion ? 'exclude' : 'include'}`, [labelObject]);

        return result;
      }, new Map()),
    [scope],
  );

  const categories = useMemo(() => {
    const apiArgs = {query: {exclude_keys: JSON.stringify(excludeKeys)}};

    return scopeCategories.map(category =>
      _.merge(_.cloneDeep(category), {
        resources: {
          [`labels_${category.id.includes('include') ? 'include' : 'exclude'}`]: {
            apiArgs,
            ...(isAPIAvailable('labels.create') ? {} : {allowCreateOptions: false}), // Scoped users should not be able to create objects
            optionProps: {
              filterOption: (option, values, {id}) => {
                const isInclude = id.includes('include');
                const selectedValues = values.get(`${option.key}_${isInclude ? 'include' : 'exclude'}`);

                return !excludeKeys.includes(option.key) && !selectedValues?.some(({href}) => href === option.href);
              },
            },
          },
          [`labelForm_${category.id.includes('include') ? 'include' : 'exclude'}`]: {
            containerProps: {
              excludeKeys, // Pass excludeKeys to Form to hide these types in option selector
              getContainerProps: ({query, onDone, onCancel} = {}) => ({
                label: {value: getQueryAndKeyword(query, typePrefixRegexp).query},
                onDone,
                onCancel,
              }),
            },
          },
          [`labelGroupForm_${category.id.includes('include') ? 'include' : 'exclude'}`]: {
            containerProps: {
              excludeKeys, // Pass excludeKeys to Form to hide these types in option selector
              getContainerProps: ({query, onDone, onCancel} = {}) => ({
                labelGroup: {
                  detail: {draft: {name: getQueryAndKeyword(query, typePrefixRegexp).query}},
                },
                onDone,
                onCancel,
              }),
            },
          },
        },
      }),
    );
  }, [excludeKeys]);

  return (
    <Selector
      tid="scope-selector"
      noActiveIndicator
      alwaysShowPlaceholder
      disabled={excludeKeys.length === labelTypes.length} // Disable Add because No label types can be selected
      maxColumns={3}
      dropdownMaxHeight={350}
      placeholder={intl('Common.SelectScope')}
      categories={categories}
      values={values}
      onSelectionChange={handleSelectionChange}
      {...selectorProps}
    />
  );
}
