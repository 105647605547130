/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {actions} from 'redux-router5';
import {PureComponent} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {connect, ReactReduxContext} from 'react-redux';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {Button, Gateway, Modal, UnsavedPendingWarning, TypedMessages, StickyContainer, Cutout} from 'components';
import {processParams} from 'components/Link/LinkUtils';
import {
  BrowserWarning,
  Header,
  HelpPopup,
  ProvisionProgress,
  ReminderPopups,
  NavigationAlert,
  InstantSearch,
} from 'containers';
import {fetchCommonData, fetchDeferredData} from './AppSaga';
import {getAppState, areStatePropsEqual, getRouteName, getRouteParams} from './AppState';
import {isClickInBrowsingContext, openHref, clickElement, preventEvent} from 'utils/dom';
import {randomString} from 'utils/general';
import PrefetchRouteChildren from '../../router/PrefetchRouteChildren';
import Fetcher from '../../router/Fetcher';
import styles from './App.css';
import SessionExpiration from '../SessionExpiration/SessionExpiration';
import OnboardingNotification from 'antman/containers/Onboarding/Notification/OnboardingNotification';
import styleUtils from 'utils.css';

@connect(getAppState, null, null, {areStatePropsEqual})
export default class App extends PureComponent {
  static contextType = ReactReduxContext;
  static prefetch = fetchCommonData;

  constructor(props, context) {
    super(props, context);

    this.state = {childProps: []};

    window.rendered = true;
    window.renderedAt = Date.now();
    window.contentRendered = false;
    window.contentRenderedAt = null;
    window.contentRenderedWithError = false;

    this.routeNameHandle = props.routeName.split('.')[0];

    this.navigate = this.navigate.bind(this);
    this.navigateBackOrTo = this.navigateBackOrTo.bind(this);
    this.addProps = this.addProps.bind(this);
    this.updateProps = this.updateProps.bind(this);
    this.upsertProps = this.upsertProps.bind(this);
    this.removeProps = this.removeProps.bind(this);
    this.sendAnalyticsEvent = this.sendAnalyticsEvent.bind(this);
    this.handleReload = () => window.location.reload(true);
    this.handleLogout = () => this.props.dispatch({type: 'LOGOUT'});

    this.appContext = {
      add: this.addProps,
      update: this.updateProps,
      upsert: this.upsertProps,
      remove: this.removeProps,
      store: context.store,
      router: context.store.router,
      dispatch: context.store.dispatch,
      navigate: this.navigate,
      navigateBackOrTo: this.navigateBackOrTo,
      sendAnalyticsEvent: this.sendAnalyticsEvent,
    };
  }

  componentDidMount() {
    if (window.opener) {
      // If it's a popup window, expose navigate method to window to allow opener change url of this popup.
      // For instance, main window change poppedout HelpPopup content by changing its url parameter
      window.navigate = this.navigate;
      window.navigateBackOrTo = this.navigateBackOrTo;
    }

    // middle clicks trigger a mousedown & mouseup events, with Event.button property set to 1, but not click event
    // if we find <a href> via Element.closest() method, don't do anything since the browser will take care of it
    // else programmatically send a click event with button prop. set to 1, so all custom onclick handlers that invoke navigation
    // can automatically support middle click with no extra overhead  - e.g. grid rows
    //
    // there is a bug in FF: https://bugzilla.mozilla.org/show_bug.cgi?id=1712021
    // as a workaround, FF middle click will open a tab in the foreground via target="_blank"
    document.addEventListener('mouseup', event => {
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
      if (event.button === 1 && event.target.closest('a') === null) {
        clickElement(event.target, null, {
          // key modifiers for middle click only work in Chromium based browsers, other browsers will open in the foreground
          ctrlKey: event.ctrlKey,
          metaKey: event.metaKey,
          shiftKey: event.shiftKey,
          altKey: event.altKey,
          button: 1,
        });
      }
    });

    // Remove fake header after appear animation is complete
    setTimeout(() => {
      const loadingHeader = document.querySelector('#loading-header');

      if (loadingHeader && !this.isInitialFetchFailed()) {
        if (typeof loadingHeader.remove === 'function') {
          loadingHeader.remove();
        } else if (typeof loadingHeader.removeNode === 'function') {
          loadingHeader.removeNode(true);
        }
      }
    }, 300);
  }

  componentDidCatch(error, info) {
    console.log('App DiDCatch', info);
  }

  contentRenderingDone() {
    window.contentRendered = true;
    window.contentRenderedAt = Date.now();

    if (document.querySelector('.loading-skeletons')) {
      // Remove all skeletons after content is rendered
      setTimeout(() => document.querySelector('.loading-skeletons').remove(), 500);
    }

    // After initial content is done loading, start fetching additional data, like polling provisioning, health, etc.
    setTimeout(this.context.store.runSaga, 100, fetchDeferredData);
  }

  /**
   * Navigation function, that can be accessed from App context.
   * Support relative routes and pressed keys (to open link in different browser context)
   *
   * @param {String|Object} arg    - Route name or object that contains route name, parameters, etc.
   * @param {String} [arg.to]      - Route name.
   *                                 If omitted, navigation considered relative,
   *                                 i.e. route name is current and specified params will be merged with current params.
   * @param {String} [arg.params]  - Route parameters.
   *                                 If navigation is relative, will be merged with existing params.
   *                                 Pass 'null' as parameter value, if you want to remove some param from url.
   * @param {String} [arg.mergeParams=true]  - By default, if route name is not specified, params will be merged with existing ones
   * @param {String} [arg.reload=false] - When trying to navigate to the current route nothing will happen unless reload is set to true
   * @param {String} [arg.replace=false] - Whether browser history should be replaced or pushed
   * @param {Number} [arg.evt]     - Click event, if user clicked on something that led to this navigation.
   *                                 Useful, when you want to treat pressed key to open link in different tab/window.
   * @param {String} [arg.target]  - Target, will open link in a new window if other than '_self'
   * @param {String} [arg.pageName=arg.to] - Name that will be used if user want to download page (Alt+Click)
   * @param {String} [arg.scrollTop=true] - Flag to determine scrolling to the top of the page after navigation
   *
   * @example
   *   // Navigates to map page
   *   this.context.navigate('map')
   *
   *   // Navigates to workload with id 1, replacing current route in history and treating pressed keys
   *   this.context.navigate({to: 'workloads.detail', params: {id: 1}, replace: true, evt})
   *
   *   // Adds 'showVersion' to current url treating pressed keys
   *   this.context.navigate({params: {showVersion: true}, evt})
   *
   *   // Remove 'showVersion' from current url treating pressed keys
   *   this.context.navigate({params: {showVersion: null}, evt})
   */
  navigate(arg) {
    if (typeof arg === 'string') {
      arg = {to: arg};
    }

    let {
      to,
      params,
      reload,
      replace,
      evt,
      pageName,
      scrollTop = true,
      noUnsavedPendingWarning,
      mergeParams = true,
      target,
    } = arg;
    const {
      props: {dispatch},
      context: {
        store,
        store: {router},
      },
    } = this;

    if (to) {
      to = `${this.routeNameHandle}.${to}`;
    } else {
      to = getRouteName(store.getState());

      if (mergeParams) {
        // By default, if route name is not specified, params will be merged with existing ones.
        // Take current route params from store here, not in selector, to avoid rerendering App component every time url param changes
        const routeParams = getRouteParams(store.getState());

        params = {...routeParams, ...params};
      }
    }

    const route = router.routesMap.get(to);

    // If defaultParams for this route is specified, merge passed params with it
    if (typeof route.autoParams === 'object') {
      params = {...route.autoParams, ...params};
    }

    params = processParams(params);

    const routerArgs = [to, params, {reload, replace, scrollTop, noUnsavedPendingWarning}];

    if (
      isClickInBrowsingContext(evt, target) ||
      !openHref({evt, href: router.buildUrl(...routerArgs), pageName: pageName || to, target})
    ) {
      dispatch(actions.navigateTo(...routerArgs));
    }
  }

  /**
   * Wrapper method over navigate that implements default back navigation when not initial app load.
   * @param arg
   */
  navigateBackOrTo(arg) {
    if (this.props.previousRoute || window['--JUMPED_FROM_LEGACY--'] /*#USERJUMP*/) {
      window.history.go(-1);
    } else {
      this.navigate(arg);
    }
  }

  addProps(props) {
    const id = randomString(5);

    this.setState(({childProps}) => ({childProps: [...childProps, {id, props}]}));

    return id;
  }

  updateProps(props, id) {
    this.setState(({childProps}) => {
      const item = childProps.find(item => item.id === id);

      if (!item || _.isEqual(item.props, props)) {
        return null; // If theme props are the same, exit without changing the state (null prevents rerendering)
      }

      return {childProps: childProps.map(i => (i === item ? {id, props} : item))};
    });

    return id;
  }

  upsertProps(props, id) {
    return id ? this.updateProps(props, id) : this.addProps(props);
  }

  removeProps(id) {
    this.setState(({childProps}) => {
      const item = childProps.find(item => item.id === id);

      if (!item) {
        return null; // If item doesn't exist in the stack, exit without changing the state (null prevents rerendering)
      }

      return {childProps: childProps.filter(i => i !== item)};
    });
  }

  sendAnalyticsEvent(name, data) {
    const {user} = this.props;
    const loginUrl = new URL(user.login_url);

    if (__LOG_ANALYTIC_EVENTS__ === true) {
      console.log(`%cAnalytic Event: %c${name}`, 'color:steelblue;', 'color:#008bff; font-weight:bold;', data);
    }

    if (this.props.uiAnalyticsIsEnabled) {
      import(/* webpackChunkName: 'logrocket' */ 'logrocket').then(({default: LogRocket}) => {
        // Mix in orgId and hostname for now, until Larry knows how to analyze those from the init call
        LogRocket.track(name, {orgId: user.org_id, hostname: loginUrl.hostname, ...data});
      });
    }
  }

  isInitialFetchFailed() {
    const {
      props: {user},
      appContext: {store},
    } = this;

    return store.prefetcher.initialFetchError || !user.href || _.isEmpty(user.orgs);
  }

  renderInitialFetchFailure() {
    const {
      props: {user},
      appContext: {
        store: {
          prefetcher: {initialFetchError},
        },
      },
    } = this;

    const statusCode = initialFetchError?.statusCode;
    const token = initialFetchError?.data?.[0]?.token;
    let content;

    if (
      !initialFetchError ||
      token === 'forbidden_error_access_restriction' ||
      token === 'max_sessions_exceeded' ||
      statusCode === 404 ||
      statusCode === 503
    ) {
      // If user is mangled show a Modal with user related information
      let message;
      let title;

      // https://jira.illum.io/browse/EYE-64856 - why we handle 404 and 503 here
      if (statusCode === 404 || statusCode === 503) {
        message = intl('Common.PCEUnavailable');
        title = intl('Common.SystemInitializing');
      } else if (token) {
        message = intl(`ErrorsAPI.err:${token}`) || initialFetchError.data[0].message;

        // add custom title to modal for some errors
        if (token === 'forbidden_error_access_restriction') {
          title = intl('User.AccessForbidden');
        } else if (token === 'max_sessions_exceeded') {
          title = intl('Users.SessionsExceeded');
        }
      } else if (!user.href) {
        message = intl('User.Empty');
      } else if (_.isEmpty(user.orgs)) {
        message = intl(
          'User.EmptyOrgs',
          {
            name: (
              <>
                "<strong>{user.full_name}</strong>" (<strong>{user.auth_username}</strong>)
              </>
            ),
          },
          {jsx: true},
        );
      }

      content = (
        <Modal medium idleOnEsc idleOnBackdropClick>
          <Modal.Header noCloseIcon title={title || intl('User.AccessError')} />
          <Modal.Content>
            <TypedMessages>{[{icon: 'error', content: message}]}</TypedMessages>
          </Modal.Content>
          <Modal.Footer>
            <Button color="standard" text={intl('Common.Reload')} tid="reload" onClick={this.handleReload} />
            <Button color="primary" text={intl('PasswordPolicy.Logout')} tid="logout" onClick={this.handleLogout} />
          </Modal.Footer>
        </Modal>
      );
    } else {
      // If any unhandled error occurred in AppSaga, then just show NavigationAlert with that error message
      content = <NavigationAlert />;
    }

    return (
      <AppContext.Provider value={this.appContext}>
        <Fetcher>
          <Gateway.Provider>
            {content}
            <SessionExpiration />
            <Gateway.Target type={Modal.Gateway} name="modal" />
          </Gateway.Provider>
        </Fetcher>
      </AppContext.Provider>
    );
  }

  render() {
    const {
      state: {childProps},
      props: {routeName, userIsWithReducedScope},
      context: {
        store: {
          router: {routesMap},
        },
      },
    } = this;

    if (this.isInitialFetchFailed()) {
      return this.renderInitialFetchFailure();
    }

    // If children have added their themes for App, compose them with styles cascadingly
    const theme = composeThemeFromProps(
      styles,
      childProps.map(({props}) => props),
    );
    // Get other children props form the AppProps component
    const {noContentFrame = false, insensitive = false} = childProps.reduce(
      (result, {props}) => Object.assign(result, props),
      {},
    );

    if (routeName === `${this.routeNameHandle}.helppopup`) {
      // If it's help popup separate window, just render it with root wrapper
      return (
        <AppContext.Provider value={this.appContext}>
          <div className={theme.root}>
            <PrefetchRouteChildren routeNameHandle={this.routeNameHandle}>{() => <HelpPopup />}</PrefetchRouteChildren>
          </div>
        </AppContext.Provider>
      );
    }

    return (
      <AppContext.Provider value={this.appContext}>
        <Fetcher>
          <Gateway.Provider>
            {
              // when the app is global insensitive, we also want to stop mouse events from bubbling up to the document root,
              // so we render a div to capture the events and stop the event from propagating
              insensitive && (
                <div
                  className={cx(styleUtils.fixedCurtain, styleUtils.globalSensitive)}
                  onMouseDown={_.partial(preventEvent, true)}
                  onMouseUp={_.partial(preventEvent, true)}
                  onClick={_.partial(preventEvent, true)}
                />
              )
            }

            <StickyContainer className={cx(theme.root, {[styleUtils.globalInsensitive]: insensitive})}>
              <Header />
              <PrefetchRouteChildren updateOnContainerRender routeNameHandle={this.routeNameHandle}>
                {({fetched, fetchError, routesNames, childrenContent}) => {
                  if (!fetched) {
                    return null; // <Spinner color="dark"/>;
                  }

                  let content = null;

                  if (fetchError) {
                    content = `Error!: ${String(fetchError)}`;
                    window.contentRenderedWithError = true;
                  } else {
                    content = childrenContent;
                    window.contentRenderedWithError = false;
                  }

                  if (!window.contentRendered) {
                    this.contentRenderingDone();
                  }

                  if (content === null) {
                    this.navigateBackOrTo('map');
                  }

                  // Render children directly without the content wrapper if children container have noAppContentWrapper static property
                  if (routesNames.some(routeName => routesMap.get(routeName).component?.noAppContentWrapper)) {
                    return content;
                  }

                  return <div className={cx(theme.content, {[theme.contentFrame]: !noContentFrame})}>{content}</div>;
                }}
              </PrefetchRouteChildren>
              <BrowserWarning />
              <NavigationAlert />
              <HelpPopup />
              <ReminderPopups />
              <InstantSearch />
              <UnsavedPendingWarning />
              <SessionExpiration />
              {!userIsWithReducedScope && routeName !== 'app.provisioning' && <ProvisionProgress />}
              {__ANTMAN__ && <OnboardingNotification />}
            </StickyContainer>

            {__ANTMAN__ && <Gateway.Target waitBeforeUnmount type={Cutout.Gateway} name="cutout" />}
            <Gateway.Target waitBeforeUnmount type={Modal.Gateway} name="modal" />
          </Gateway.Provider>
        </Fetcher>
      </AppContext.Provider>
    );
  }
}
