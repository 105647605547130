/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {areVulnerabilitiesEnabled} from 'containers/App/AppState';
import {getWorkloadInstance} from '../WorkloadItemState';
import {gridSettings} from './WorkloadProcessesConfig';
import {getVulnerabilities} from '../Vulnerabilities/WorkloadVulnerabilitiesState';
import {aggregateVulnerabilityValues} from '../Vulnerabilities/WorkloadVulnerabilitiesUtils';

export const getWorkloadProcesses = createSelector(
  [getWorkloadInstance, getVulnerabilities],
  (workload, vulnerabilities) => {
    let openServicePorts;
    let gridData = [];

    if (workload.services && workload.services.open_service_ports) {
      openServicePorts = workload.services.open_service_ports;
    } else if (
      workload.agent_info &&
      workload.agent_info.service_report &&
      workload.agent_info.service_report.open_service_ports
    ) {
      openServicePorts = workload.agent_info.service_report.open_service_ports;
    }

    gridData = _.uniqBy(openServicePorts, service => {
      const newProcess = service.process_name.replaceAll('\\', '/');

      service = {...service, processName: newProcess.split('/').at(-1).replace('.exe', '')};

      return [service.processName, service.address, service.port, service.protocol].join('');
    }).map((service, idx) => {
      const newProcess = service.process_name.replaceAll('\\', '/');

      service = {...service, key: idx};
      service.processName = newProcess.split('/').at(-1).replace('.exe', '');
      service.full_process_name = service.processName;

      if (service.win_service_name && service.win_service_name.trim().localeCompare(service.processName.trim()) !== 0) {
        service.full_process_name += ' (' + service.win_service_name + ')';
      }

      const vulnerabilitiesdata =
        vulnerabilities[workload.href] &&
        vulnerabilities[workload.href].instances[[service.port, service.protocol].join(',')];

      if (vulnerabilitiesdata) {
        service.vulnerability = vulnerabilitiesdata.reduce(
          (result, instance) => aggregateVulnerabilityValues(result, instance),
          null,
        );
      }

      service.process_path = service.process_name === service.processName ? '' : service.process_name;

      return service;
    });

    return {
      gridData,
    };
  },
);

export const getProcessesRows = createSelector([getWorkloadProcesses], ({gridData}) => gridData);

export const processesGridSettings = createSelector(
  [getWorkloadInstance, areVulnerabilitiesEnabled, gridSettings],
  (workload, vulnerabilitiesEnable, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.vulnerability.disabled =
      !vulnerabilitiesEnable || !workload.agent?.status || workload.agent?.config?.mode === 'idle';

    return {...gridSettings, columns};
  },
);

const getProcessesGrid = state =>
  getGridSelector(state, {
    settings: processesGridSettings,
    rows: getProcessesRows,
  });

export const getWorkloadProcessesPage = createSelector(
  [getProcessesGrid, getWorkloadInstance],
  (processesGrid, workload) => ({processesGrid, workload}),
);
