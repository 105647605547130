/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import cx from 'classnames';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {forwardRefFactory, type ForwardRefProps, forwardRefSymbol} from 'react-forwardref-utils';
import {Icon, Tooltip} from 'components';
import styles from './StatusIcon.css';
import type {IterableElement} from 'type-fest';
import type {ReactStrictNode} from 'utils/types';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {IconName} from 'components/Icon/IconName';

StatusIcon.icons = [
  'inuse',
  'error',
  'warning',
  'suspended',
  'deny',
  'syncing',
  'staged',
  'info',
  'online',
  'offline',
  'pending',
  'paired',
  'unpaired',
  'enabled',
  'disabled',
  'disabled-status',
  'unmanaged',
  'lock',
  'unlocked',
  'none',
  'help',
  'added',
  'removed',
  'modified',
  'check',
  'stopped',
] as const;

type StatusIconName = IterableElement<typeof StatusIcon.icons>;

export type StatusIconProps = {
  status: StatusIconName;
  label?: ReactStrictNode;
  title?: string;
  noTextColor?: boolean;

  // Shorthand to show pointer on the element hover
  pointer?: boolean;

  // tooltip props
  tooltip?: ReactStrictNode;
  tooltipProps?: TooltipProps;
} & ThemeProps &
  ForwardRefProps<HTMLDivElement>;

// StatusIcon is a simple wrapper of Icon component, that takes one property 'status' and pass all extra props down to Icon.
// Property 'status' sets Icon's name property and mix status css class with Icon css class.
// noTextColor sets text color to initial instead of applying status icon color.
function StatusIcon(props: StatusIconProps): JSX.Element {
  const {
    label,
    status,
    theme,
    title,
    tooltip: tooltipContent,
    tooltipProps,
    noTextColor = false,
    pointer = false,
    [forwardRefSymbol]: ref,
    ...iconProps
  } = mixThemeWithProps(styles, props);

  let tooltip = tooltipContent;
  let name: IconName;

  switch (status) {
    case 'paired':
      name = 'pair';
      break;
    case 'unpaired':
      name = 'pair';
      break;
    case 'offline':
      name = 'online';
      break;
    case 'added':
      name = 'add';
      tooltip = tooltip ?? intl('Provision.Added');
      break;
    case 'removed':
      name = 'remove';
      tooltip = tooltip ?? intl('Common.Removed');
      break;
    case 'modified':
      name = 'online';
      tooltip = tooltip ?? intl('Provision.Modified');
      break;
    case 'none':
      name = 'deny';
      break;
    default:
      name = status;
      break;
  }

  const statusIcon = (
    <span
      ref={ref}
      className={cx(theme[status], {[theme.pointer]: pointer})}
      data-tid="comp-status-icon"
      aria-label={typeof tooltip === 'string' ? tooltip : title}
    >
      <Icon name={name} theme={theme} position={label ? 'before' : undefined} {...iconProps} />
      {label ? (
        <span className={noTextColor ? theme.noTextColor : undefined} data-tid="elem-text">
          {label}
        </span>
      ) : null}
    </span>
  );

  if (tooltip) {
    const updatedProps: TooltipProps = {...tooltipProps};

    if (status === 'info') {
      updatedProps.instant ??= true;
    }

    return (
      <Tooltip content={tooltip} {...updatedProps}>
        {() => statusIcon}
      </Tooltip>
    );
  }

  return statusIcon;
}

export default forwardRefFactory(StatusIcon);
