/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {PureComponent} from 'react';
import {Pill, type PillProps} from 'components';
import {hrefUtils} from 'utils';
import {edge} from 'api/apiUtils';
import {getUpdateTypeProps} from '../PillUtils';
import type {LabelKey} from 'illumio';

export type LabelProps = {
  type?: LabelKey;
  // Alternatively, instead of 'link' you can just specify id or href of label/label_group, like 19 or "/orgs/1/labels/19" for label,
  // and "orgs/1/labelgroups/b88711a1-a3fb-4139-a09b-1fa6600a279f" or "b88711a1-a3fb-4139-a09b-1fa6600a279f" for labelgroup
  // In that case Label will automatically create link to {{to: 'labels.item', params: {id}}}, or {{to: 'labelGroups.item.view.tab',
  // params: {id, pversion: 'draft', tab: 'members'}}} where 'id' will be taken from 'id' prop or parsed from 'href' prop
  id?: number | string;
  href?: string;
  // policy version
  pversion?: string | number;
} & PillProps;

export default class Label extends PureComponent<LabelProps> {
  element: HTMLElement | null = null;

  constructor(props: LabelProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: Pill) {
    this.element = element?.element;
  }

  render() {
    const {
      type,
      id: objectId,
      href,
      children,
      pversion = 'draft',
      updateType,
      updateTypeTooltip,
      ...pillProps
    } = this.props;

    const content =
      pillProps.exclusion && typeof children === 'string' ? `${intl('Labels.Excluded', {type})} ${children}` : children;

    /** label_groups href is not numeric thus cannot use Number() */
    const id = objectId ?? (pillProps.group ? hrefUtils.getId(href) : Number(hrefUtils.getId(href)));

    // Don't show role icon in Illumio Edge, since it's group now
    if (!edge || (edge && type !== 'role')) {
      Object.assign(pillProps, {icon: type});
    }

    if (!id) {
      return (
        <Pill {...pillProps} ref={this.saveRef}>
          {content}
        </Pill>
      );
    }

    if (pillProps.group) {
      Object.assign(pillProps, {
        ref: this.saveRef,
        link: pillProps.onClick ? null : {to: 'labelGroups.item', params: {id, pversion}},
        ...getUpdateTypeProps({
          object: 'labelGroup',
          updateType,
          updateTypeTooltip,
          deleted: pillProps.deleted,
          pversion,
        }),
      });
    } else {
      Object.assign(pillProps, {
        ref: this.saveRef,
        link: pillProps.onClick ? null : {to: 'labels.item', params: {id}},
      });
    }

    return <Pill {...pillProps}>{content}</Pill>;
  }
}
