/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {AppContext} from 'containers/App/AppUtils';
import {getWorkloadStatusIntl} from 'intl/dynamic';
import {hrefUtils, webStorageUtils, reactUtils} from 'utils';
import {isAPIAvailable} from 'api/apiUtils';
import {
  Link,
  AttributeList,
  Pill,
  ToolGroup,
  Button,
  ToolBar,
  StatusIcon,
  Vulnerability,
  ModalMachineAuto,
  Icon,
} from 'components';
import {isSuperclusterMember} from 'containers/User/UserState';
import {getPceHealth} from 'containers/Health/HealthUtils';
import {locationText} from 'containers/Ven/VenUtils';
import {getLabelTypeName} from 'components/Pill/Label/LabelUtils';
import {getNodeAppGroupParent} from 'containers/Explorer/ExplorerUtils';
import {fetchSummary} from './WorkloadSummarySaga';
import {getWorkloadSummaryPage} from './WorkloadSummaryState';
import {increaseTrafficUpdateRate, removeWorkload} from '../../WorkloadSaga';
import {fetchWorkloadList} from '../../List/WorkloadListSaga';
import {
  getWorkloadName,
  getPolicyState,
  getWorkloadStatus,
  workloadUptime,
  externalDataSetPartners,
  lastHeartbeatOn,
  getWorkloadHealthStatus,
  getWorkloadSyncElement,
  getHumanFriendlySubjectName,
  getInterfaceDisplay,
  workloadHealthError,
} from '../../WorkloadUtils';
import {
  enforcementModeView,
  enforcementModeViewEdge,
  visibilityLevelView,
  visibilityLevelViewEdge,
} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import styles from 'containers/PairingProfile/Item/View/PairingProfileView.css';
import cx from 'classnames';

@connect(getWorkloadSummaryPage)
export default class WorkloadSummary extends Component {
  static prefetch = fetchSummary;
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      remove: null,
      increasingTrafficUpdateRate: false,
      increaseTrafficUpdateRateError: null,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleGoToAppMap = this.handleGoToAppMap.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleOnDone = this.handleOnDone.bind(this);
    this.handleIncreaseTrafficUpdateRate = this.handleIncreaseTrafficUpdateRate.bind(this);
  }

  handleEdit() {
    const {
      context: {navigate},
      props: {currentRouteParams},
    } = this;

    navigate({to: 'workloads.item.edit', params: {id: currentRouteParams.id}});
  }

  handleGoToAppMap(type) {
    const {
      context: {navigate},
      props: {workload, appGroupType},
    } = this;

    const id = getNodeAppGroupParent(workload, appGroupType);

    webStorageUtils.setSessionItem('MapPageStore', {appMapVersion: type});
    navigate({to: 'appMap.level', params: {type: 'focused', id}});
  }

  handleRemove() {
    this.setState(({remove}) => ({remove: !remove}));
  }

  handleOnDone() {
    const {fetcher, navigate} = this.context;

    navigate('workloads.list');

    return fetcher.fork(fetchWorkloadList.refetch);
  }

  async handleIncreaseTrafficUpdateRate() {
    try {
      const {workload} = this.props;

      await reactUtils.setStateAsync({increasingTrafficUpdateRate: true, increaseTrafficUpdateRateError: null}, this);
      await this.context.fetcher.spawn(increaseTrafficUpdateRate, [workload.href]);
      await reactUtils.setStateAsync({increasingTrafficUpdateRate: false}, this);
    } catch (error) {
      this.setState({increaseTrafficUpdateRateError: error, increasingTrafficUpdateRate: false});
    }
  }

  renderRemoveConfirmation() {
    return (
      <ModalMachineAuto onClose={this.handleRemove} onDone={this.handleOnDone} saga={removeWorkload}>
        {{
          title: intl('Workloads.Remove.TitleConfirmation', {count: 1}),
          confirmMessage: intl('Workloads.Remove.Confirm', {count: 1}),
          error: {
            title: intl('Workloads.Remove.TitleResult', {count: 1}),
            customErrorMessage: {
              virtual_service_used_in_rule: intl('VirtualServices.SingleBoundToRules'),
              bound_service_used_in_rule: intl('VirtualServices.SingleBoundToRules'),
              virtual_service_still_active: intl('VirtualServices.SingleBoundToWorkloads'),
              bound_service_still_active: intl('VirtualServices.SingleBoundToWorkloads'),
            },
          },
        }}
      </ModalMachineAuto>
    );
  }

  renderLabel(type) {
    const {
      workload: {labels},
    } = this.props;
    const currentLabel = labels.find(item => item.key === type);

    return currentLabel ? (
      <Pill.Label type={type} href={currentLabel.href}>
        {currentLabel.value}
      </Pill.Label>
    ) : null;
  }

  renderVulnerability(vulnerability, exposureApplicable) {
    let vulnerabilityExposureScore = vulnerability.vulnerabilityExposureScore;
    let maxSeverity = vulnerability.maxSeverity;
    const internetExposure = vulnerability.vulnerable_port_wide_exposure || vulnerability.wideExposure;

    if (vulnerability.hasOwnProperty('vulnerability_exposure_score')) {
      vulnerabilityExposureScore =
        vulnerability.vulnerability_exposure_score === null ? null : vulnerability.vulnerability_exposure_score / 10;
    }

    if (vulnerability.hasOwnProperty('max_vulnerability_score')) {
      maxSeverity = vulnerability.max_vulnerability_score / 10;
    } else if (vulnerability.hasOwnProperty('severity')) {
      maxSeverity = vulnerability.severity;
    }

    return (
      <Vulnerability
        internetExposure={internetExposure.any || internetExposure.ip_list}
        severityScore={maxSeverity}
        vulnerabilityExposureScore={vulnerabilityExposureScore}
        exposureApplicable={exposureApplicable}
      />
    );
  }

  render() {
    const {
      workload,
      staticCount,
      vulnerabilities,
      vulnerabilityReports,
      optionalFeatures,
      localData,
      ishealthEnabled,
      localCluster,
      userIsSuperclusterMember,
      appGroupType,
      areVulnerabilitiesEnabled,
      hasWritePermission,
      crowdstrikeEnabled,
      edgeEnabled,
    } = this.props;
    const {remove, increasingTrafficUpdateRate, increaseTrafficUpdateRateError} = this.state;
    const hasStatic = staticCount > 0;
    const classNameContent = styles.content;
    const unmanaged = !workload.agent || !workload.agent.status;

    const workloadStatus = getWorkloadStatus(workload);
    let policyState;

    if (workload.agent) {
      policyState = getPolicyState(workload.agent.config.mode, workload.agent.config.log_traffic);
    }

    let vulnerability = [];

    if (!edgeEnabled && areVulnerabilitiesEnabled) {
      const latestVulnerabilityReports = vulnerabilityReports.sort((a, b) =>
        b.updated_at > a.updated_at ? 1 : b.updated_at < a.updated_at ? -1 : 0,
      );
      const reportDate =
        latestVulnerabilityReports && latestVulnerabilityReports.length && latestVulnerabilityReports[0];
      const vulnerabilitiesdata = vulnerabilities[workload.href]
        ? Object.values(vulnerabilities[workload.href].instances).flat()
        : null;

      const exposureApplicable = policyState && policyState !== 'idle';

      vulnerability = [
        {
          tid: 'workload-total-vulnerability',
          key: intl('Vulnerability.TotalVEScore'),
          value: !vulnerabilitiesdata
            ? intl('Common.None')
            : this.renderVulnerability(vulnerabilities[workload.href].aggregatedValues, exposureApplicable),
        },
        {
          tid: 'workload-max-vulnerability-score',
          key: intl('Vulnerability.HighestVEScore'),
          value: !vulnerabilitiesdata
            ? intl('Common.None')
            : this.renderVulnerability(
                vulnerabilitiesdata.sort((a, b) => b.vulnerabilityExposureScore - a.vulnerabilityExposureScore)[0],
                exposureApplicable,
              ),
        },
        {
          tid: 'workload-max-vulnerability',
          key: intl('Vulnerability.HighestVulnerability'),
          value: !vulnerabilitiesdata
            ? intl('Common.None')
            : vulnerabilitiesdata.sort((a, b) => b.severity - a.severity)[0].severity,
        },
        {
          tid: 'workload-vulnerability-import',
          key: intl('Vulnerability.ImportTime'),
          value: reportDate && intl.utils.format.dateAtTimeBy(reportDate.updated_at),
        },
      ];

      if (!workload.agent || !workload.agent.status) {
        vulnerability = [
          {
            tid: 'workload-max-vulnerability',
            key: intl('Vulnerability.HighestVulnerability'),
            value: !vulnerabilitiesdata
              ? intl('Common.None')
              : vulnerabilitiesdata.sort((a, b) => b.severity - a.severity)[0].severity,
          },
          {
            tid: 'workload-vulnerability-import',
            key: intl('Vulnerability.ImportTime'),
            value: reportDate && intl.utils.format.dateAtTimeBy(reportDate.updated_at),
          },
        ];
      }
    }

    let pce = [];

    if (workload.agent.active_pce_fqdn && isSuperclusterMember) {
      pce = [
        {
          tid: 'pce',
          key: intl('Common.PCE'),
          value: workload.agent.active_pce_fqdn,
        },
      ];

      if (this.props.allClusters.some(cluster => cluster.fqdn === workload.agent.active_pce_fqdn)) {
        pce.push({
          tid: 'pce-health',
          key: intl('Health.PCEHealth'),
          value: getPceHealth(
            this.props.allClusters.find(cluster => cluster.fqdn === workload.agent.active_pce_fqdn).status,
          ),
        });
      }
    }

    const advanced = [];

    if (!edgeEnabled && workload.agent.status && workload.firewall_coexistence) {
      advanced.push(
        {
          tid: 'workload-firewallmode',
          key: intl('Firewall.Coexistence.Title'),
          value: intl('Common.Yes'),
        },
        {
          tid: 'workload-illumioprimarymode',
          key: intl('Firewall.Coexistence.IllumioAsPrimaryFirewall'),
          value: workload.firewall_coexistence.illumio_primary ? intl('Common.Yes') : intl('Common.No'),
        },
      );
    }

    if (
      !edgeEnabled &&
      hasStatic &&
      workload.agent?.config &&
      workload.agent.config.security_policy_update_mode === 'static'
    ) {
      advanced.push({
        tid: 'workload-policyupdatemode',
        key: intl('Common.StaticPolicy'),
        value: intl('Common.Yes'),
      });
    }

    if (
      !edgeEnabled &&
      workload.agent.status &&
      _.isBoolean(workload.containers_inherit_host_policy) &&
      workload.containers_inherit_host_policy
    ) {
      advanced.push({
        tid: 'workload-containerinherit',
        key: intl('Settings.Containers.ContainerInheritHostPolicy'),
        value: intl('Common.Yes'),
      });
    }

    if (!edgeEnabled && workload.agent.status && workload.blocked_connection_action === 'reject') {
      advanced.push({
        tid: 'workload-blockaction',
        key: intl('Common.BlockAction'),
        value: intl('Common.Yes'),
      });
    }

    let interfaces;

    if (workload.interfaces) {
      let ifaces = _.cloneDeep(workload.interfaces);

      ifaces = _.orderBy(ifaces, ['name', 'address', 'default_gateway_address']);
      ifaces = ifaces.map(iface => {
        iface.from_ip = iface.cidr_block ? iface.address + '/' + iface.cidr_block : iface.address;
        iface.gateway = iface.default_gateway_address;

        return iface;
      });

      interfaces = (
        <div>
          {ifaces.map(iface =>
            getInterfaceDisplay({
              workload,
              workloadInterface: iface,
              unamanged: !workload.agent || !workload.agent.status,
            }),
          )}
        </div>
      );
    }

    const authenticationSection = [];

    // data that is reported by VEN versions 21.2.0 or later
    const {authority_key_identifier, issuer_name, not_valid_after, not_valid_before, subject_name} =
      workload.ike_authentication_certificate ?? {};

    // distinguished_name & subject_name report the same data
    if (subject_name) {
      // VEN versions >= 21.2.0
      authenticationSection.push({
        tid: 'subjectName',
        key: intl('Workloads.SubjectName'),
        value: subject_name,
        secondary: getHumanFriendlySubjectName(subject_name),
      });
    } else if (workload.distinguished_name) {
      // VEN versions < 21.2.0
      authenticationSection.push({
        tid: 'subjectName',
        key: intl('Workloads.SubjectName'),
        value: workload.distinguished_name,
        secondary: getHumanFriendlySubjectName(workload.distinguished_name),
      });
    }

    if (authority_key_identifier) {
      authenticationSection.push({
        tid: 'aki',
        key: intl('Workloads.AKI'),
        value: authority_key_identifier,
      });
    }

    if (issuer_name) {
      authenticationSection.push({
        tid: 'issuerName',
        key: intl('Workloads.IssuerName'),
        value: issuer_name,
      });
    }

    if (not_valid_before) {
      authenticationSection.push({
        tid: 'notValidBefore',
        key: intl('Workloads.NotValidBefore'),
        value: not_valid_before,
      });
    }

    if (not_valid_after) {
      authenticationSection.push({
        tid: 'notValidAfter',
        key: intl('Workloads.NotValidAfter'),
        value: not_valid_after,
      });
    }

    // Show Visibility View
    let visibilityValue;

    const visibilityLevelViewText = visibilityLevelView();

    if (edgeEnabled && workload.enforcement_mode === 'idle') {
      visibilityValue = (
        <>
          {visibilityLevelViewText[workload.enforcement_mode]?.name}
          <div className={classNameContent}>{visibilityLevelViewEdge.idle?.desc}</div>
        </>
      );
    } else if (edgeEnabled) {
      visibilityValue = (
        <>
          {visibilityLevelViewText[workload.visibility_level]?.name}
          <div className={classNameContent}>
            {workload.enforcement_mode === 'full' && workload.visibility_level === 'flow_summary'
              ? visibilityLevelViewEdge.flow_summary_enforce?.desc
              : visibilityLevelViewEdge[workload.visibility_level]?.desc}
          </div>
        </>
      );
    } else if (!crowdstrikeEnabled && workload.enforcement_mode === 'idle') {
      visibilityValue = <div>{visibilityLevelViewText[workload.enforcement_mode].name}</div>;
    } else {
      visibilityValue = (
        <>
          {visibilityLevelViewText[workload.visibility_level]?.name}
          <div className={classNameContent}>{visibilityLevelViewText[workload.visibility_level]?.desc}</div>
        </>
      );
    }

    const healthStatus = getWorkloadHealthStatus({
      ven: workload.agent,
      localCluster,
      userIsSuperclusterMember,
      online: workload.online,
    });

    const enforcementModeViewText = enforcementModeView();

    return (
      <>
        <ToolBar>
          <ToolGroup noSingleton>
            <Button
              textIsHideable
              text={intl('Common.Edit')}
              icon="edit"
              onClick={this.handleEdit}
              tid="edit"
              disabled={!hasWritePermission || (crowdstrikeEnabled && workload.enforcement_mode === 'idle')}
            />
            {workload.caps.includes('write') && (!workload.agent || !workload.agent.status) && (
              <Button
                text={intl('Common.Remove')}
                icon="remove"
                tid="remove"
                color="standard"
                onClick={this.handleRemove}
              />
            )}
            {workload.rulesetCaps &&
            appGroupType &&
            appGroupType.length &&
            appGroupType.every(type => workload.labels.find(label => label.key === type)) ? (
              <Button
                text={intl('Common.AppGroupMap')}
                icon="external-link"
                tid="appgroup-map"
                color="standard"
                onClick={_.partial(this.handleGoToAppMap, 'policy')}
              />
            ) : null}
            {workload.rulesetCaps &&
            areVulnerabilitiesEnabled &&
            appGroupType &&
            appGroupType.length &&
            appGroupType.every(type => workload.labels.find(label => label.key === type)) ? (
              <Button
                text={intl('Map.Vulnerability')}
                icon="external-link"
                tid="vulnerability-map"
                color="standard"
                onClick={_.partial(this.handleGoToAppMap, 'vulnerability')}
              />
            ) : null}
            {edgeEnabled && !crowdstrikeEnabled && (
              <Button
                color="standard"
                textIsHideable
                text={intl('Common.IncreaseTrafficUpdateRate')}
                tid="increase-traffic-update-rate"
                tooltip={intl('Common.IncreaseTrafficUpdateRateTooltip', {minutes: 10})}
                tooltipProps={{instant: true}}
                onClick={this.handleIncreaseTrafficUpdateRate}
                disabled={!isAPIAvailable('workloads.set_flow_reporting_frequency')}
                progress={increasingTrafficUpdateRate}
                progressCompleteWithCheckmark
                progressError={increaseTrafficUpdateRateError !== null}
                icon="increase"
              />
            )}
          </ToolGroup>
        </ToolBar>
        <AttributeList>
          {[
            {divider: true},
            {title: intl('Common.General')},
            {
              tid: 'name',
              key: intl('Common.Name'),
              value: getWorkloadName(workload),
            },
            {
              tid: 'description',
              key: intl('Common.Description'),
              value: workload.description,
            },
            !unmanaged
              ? {
                  tid: 'enforcement-mode',
                  key: intl('Common.Enforcement'),
                  value: (
                    <>
                      {workload.enforcement_mode !== 'selective'
                        ? enforcementModeViewText[
                            edgeEnabled && workload.enforcement_mode === 'full' ? 'enforced' : workload.enforcement_mode
                          ]?.name
                        : intl('EnforcementBoundaries.SelectiveEnforcement')}
                      {crowdstrikeEnabled && workload.enforcement_mode === 'idle' && (
                        <Icon
                          name="info"
                          tooltip={intl('Workloads.IdlePolicyStateInfo')}
                          tooltipProps={{maxWidth: 'calc(var(--80px) * 5)'}}
                          position="after"
                        />
                      )}
                      <div className={cx(classNameContent)}>
                        {edgeEnabled && enforcementModeViewEdge[workload.enforcement_mode]?.desc}
                        {!edgeEnabled &&
                          (workload.enforcement_mode === 'selective' ? (
                            workload.num_enforcement_boundaries > 0 ? (
                              <>
                                {intl('EnforcementBoundaries.WorkloadEnforcementDesc', {
                                  count: workload.num_enforcement_boundaries,
                                })}{' '}
                                <Link to={__ANTMAN__ ? 'rulesets' : 'boundaries.list'}>
                                  {intl('Workloads.EnforcementBoundaries')}
                                </Link>
                                . {intl('EnforcementBoundaries.WorkloadEnforcementFollowingServices')}
                              </>
                            ) : (
                              intl('Workloads.EnforcementBoundariesSelectedInbound')
                            )
                          ) : (
                            enforcementModeViewText[workload.enforcement_mode]?.desc
                          ))}
                      </div>
                      <Pill.ServiceDiff value={workload.selectively_enforced_services} noDiff />
                    </>
                  ),
                }
              : null,
            !unmanaged && !crowdstrikeEnabled
              ? {
                  tid: 'visibility-level',
                  key: intl('Common.Visibility'),
                  value: visibilityValue,
                }
              : null,
            !crowdstrikeEnabled && workload.ven
              ? {
                  tid: 'venname',
                  key: intl('Common.VEN'),
                  value: (
                    <Link to="workloads.vens.item" params={{id: hrefUtils.getId(workload.ven.href)}}>
                      {workload.ven.name || workload.ven.hostname}
                    </Link>
                  ),
                }
              : null,
            workload.enforcement_mode !== 'idle' || workload.external_data_set !== externalDataSetPartners.crowdstrike
              ? {
                  tid: 'venconnectivity',
                  key: intl('Common.Connectivity'),
                  value: (
                    <div>
                      <StatusIcon
                        position="before"
                        status={workloadStatus}
                        label={getWorkloadStatusIntl(workloadStatus)}
                      />
                    </div>
                  ),
                }
              : null,
            !crowdstrikeEnabled && healthStatus
              ? {
                  tid: 'venhealth',
                  key: intl('Workloads.PolicySync'),
                  value: getWorkloadSyncElement(healthStatus, healthStatus ? healthStatus.label : null),
                }
              : null,
            !crowdstrikeEnabled && workloadHealthError(workload.agent).length > 0
              ? {
                  tid: 'venerror',
                  key: intl('Workloads.Summary.ErrorMessage'),
                  value: <div>{workloadHealthError(workload.agent)}</div>,
                }
              : null,
            !edgeEnabled && hasStatic && workload.agent.status
              ? {
                  tid: 'last-policy-date',
                  key: intl('Workloads.PolicyLastReceived'),
                  value: intl.utils.format.dateAtTimeBy(workload.agent.status.security_policy_received_at),
                }
              : null,
            (workload.enforcement_mode !== 'idle' ||
              workload.external_data_set !== externalDataSetPartners.crowdstrike) &&
            workload.agent.status
              ? {
                  tid: 'last-policy-date',
                  key: intl('Workloads.PolicyLastApplied'),
                  value: intl.utils.format.dateAtTimeBy(workload.agent.status.security_policy_applied_at),
                }
              : null,
            workload.agent.status && workload.container_cluster
              ? {
                  tid: 'workload-containercluster',
                  key: intl('Menu.ContainerClusters', {multiple: false}),
                  value: (
                    <Link to="containerClusters.item" params={{id: hrefUtils.getId(workload.container_cluster.href)}}>
                      {workload.container_cluster.name}
                    </Link>
                  ),
                }
              : null,
            !edgeEnabled && vulnerability.length > 0 ? {divider: true} : null,
            !edgeEnabled && vulnerability.length > 0 ? {title: intl('Common.Vulnerability')} : null,
            ...vulnerability,
            {divider: true},
            {title: edgeEnabled ? intl('Common.Group') : intl('Common.Labels')},
            {
              tid: 'labelrole',
              key: getLabelTypeName('role'),
              value: this.renderLabel('role'),
            },
            !edgeEnabled
              ? {
                  tid: 'labelapp',
                  key: getLabelTypeName('app'),
                  value: this.renderLabel('app'),
                }
              : null,
            !edgeEnabled
              ? {
                  tid: 'labelenv',
                  key: getLabelTypeName('env'),
                  value: this.renderLabel('env'),
                }
              : null,
            !edgeEnabled
              ? {
                  tid: 'labelloc',
                  key: getLabelTypeName('loc'),
                  value: this.renderLabel('loc'),
                }
              : null,
            !edgeEnabled && advanced.length > 0 ? {divider: true} : null,
            !edgeEnabled && advanced.length > 0 ? {title: intl('Common.Security')} : null,
            ...advanced,
            pce.length > 0 ? {divider: true} : null,
            pce.length > 0 ? {title: intl('Common.PCE')} : null,
            ...pce,
            {divider: true},
            {title: intl('Common.Attributes')},
            !crowdstrikeEnabled && workload.agent.status
              ? {
                  tid: 'venversion',
                  key: intl('Workloads.Summary.VENVersion'),
                  value: workload.agent.status.agent_version,
                }
              : null,
            {
              tid: 'hostname',
              key: intl('Common.Hostname'),
              value: workload.hostname,
            },
            !edgeEnabled
              ? {
                  tid: 'location',
                  key: intl('Common.Location'),
                  value: locationText(workload.data_center, workload.data_center_zone, Boolean(workload.agent.status)),
                }
              : null,
            {
              tid: 'os',
              key: intl('Common.OS'),
              value: workload.os_id,
            },
            {
              tid: 'release',
              key: intl('Workloads.Release'),
              value: workload.os_detail,
            },
            !crowdstrikeEnabled && workload.agent.status && workload.agent.status.instance_id
              ? {
                  tid: 'machine',
                  key: intl('Workloads.Summary.Machine'),
                  value: workload.agent.status.instance_id,
                }
              : null,
            !crowdstrikeEnabled && workload.agent.status
              ? {
                  tid: 'uptime',
                  key: intl('Workloads.Summary.Uptime'),
                  value: workloadUptime(workload.agent, localData, ishealthEnabled),
                }
              : null,
            !crowdstrikeEnabled && workload.agent.status
              ? {
                  tid: 'uptime',
                  key: intl('Workloads.HeartbeatLastReceived'),
                  value: lastHeartbeatOn(workload.agent, localData, ishealthEnabled),
                }
              : null,
            !edgeEnabled
              ? {
                  tid: 'publicip',
                  key: intl('Common.IPAddressPublic'),
                  value: workload.public_ip,
                }
              : null,
            {
              tid: 'interfaces',
              key: intl('Workloads.Interfaces'),
              value: workload.interfaces.length ? interfaces : edgeEnabled ? intl('Workloads.NoInterfaces') : null,
            },
            !crowdstrikeEnabled && workload.external_data_set !== undefined && workload.external_data_set !== null
              ? {
                  tid: 'extdataset',
                  key: intl('Common.ExternalSet'),
                  value: workload.external_data_set,
                }
              : null,
            workload.external_data_reference !== undefined && workload.external_data_reference !== null
              ? {
                  tid: 'extdataref',
                  key: crowdstrikeEnabled ? intl('Edge.Workloads.CrowdstrikeAID') : intl('Common.ExternalReference'),
                  value: workload.external_data_reference,
                }
              : null,
            workload.service_principal_name
              ? {
                  tid: 'serviceprincipalname',
                  key: intl('Workloads.Summary.KerberosServicePrincipalName'),
                  value: workload.service_principal_name,
                }
              : null,
            optionalFeatures.pki_authentication_enabled && optionalFeatures.pki_authentication_enabled.enabled
              ? {
                  tid: 'pki',
                  key: intl('Workloads.VenToPCEAuthentication.PKICertificateId'),
                  value: workload.agent_to_pce_certificate_authentication_id || '',
                }
              : null,
            authenticationSection.length > 0 ? {divider: true} : null,
            authenticationSection.length > 0 ? {title: intl('Common.MachineAuthentication')} : null,
            ...authenticationSection,
          ]}
        </AttributeList>
        {remove && this.renderRemoveConfirmation()}
      </>
    );
  }
}
