/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector, getUrlFilterParam} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './VenLibraryConfig';

export default {
  libraries(state = [], action) {
    switch (action.type) {
      case 'VEN_LIBRARIES_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  libraryCount(state = {}, action) {
    switch (action.type) {
      case 'VEN_LIBRARIES_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getVenLibraries = state => state.ven.libraries;
export const getVenLibraryCount = state => state.ven.libraryCount;

export const getAllVens = createSelector([getVenLibraries, isUserReadOnly], (vens, userIsReadOnly) =>
  vens.reduce((result, ven) => {
    // IF Windows VENS need to display OS Version in future, remove this line and the 2 if statements around this
    // variable and push all images to the results array EYE-54638
    const windowsReleases = {};

    ven.images.forEach(image => {
      if (!windowsReleases[image.filename]) {
        const key = `${image.href} - ${image.architecture} - ${image.major_version}`;

        if (!result.some(row => row.key === key)) {
          result.push({
            downloadPath: `api/v2${image.href}`,
            key: `${image.href} - ${image.architecture} - ${image.major_version}`,
            data: image,
            release: image.release,
            filename: image.filename,
            distribution: image.distribution,
            architecture: image.architecture,
            osVersion: image.distribution !== 'Windows' ? image.major_version.toString() : null,
            default: ven.default,
            readOnly: userIsReadOnly,
          });
        }
      }

      if (image.distribution === 'Windows') {
        windowsReleases[image.filename] = true;
      }
    });

    return result;
  }, []),
);

export const getGridSettings = createSelector(isUserReadOnly, userIsReadOnly => {
  const columns = {...gridSettings.columns};

  columns.download.disabled = userIsReadOnly;

  return {...gridSettings, columns};
});

export const getFilters = state =>
  getUrlFilterParam(state, {settings: getGridSettings, filterMap: getSelectorSettings().filterMap});

export const getVensForGrid = createSelector([getAllVens, getFilters], (vens, filters) => {
  if (filters.isEmpty) {
    return vens;
  }

  const filterStrings = {};

  for (const param in filters.valid) {
    if (filters.valid[param][0]) {
      filterStrings[param] = filters.valid[param][0];
    }
  }

  return _.filter(vens, _.matches(filterStrings));
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getVensForGrid,
    filterMap: getSelectorSettings().filterMap,
  });

export const getVensPage = createSelector(
  [getVenLibraryCount, getGrid, getAllVens, getSelectorSettings],
  (count, grid, allRows, selectorSettingsObject) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.entries(grid.filter).map(([categoryKey, [value]]) => ({
        categoryKey,
        value,
        categoryName: selectorSettingsObject.filterMap[categoryKey],
      })),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
      statics: {
        filename: Object.keys(_.groupBy(allRows, v => v.filename)),
        osVersion: Object.keys(_.groupBy(allRows, v => v.osVersion)),
        distribution: Object.keys(_.groupBy(allRows, v => v.distribution)),
        architecture: Object.keys(_.groupBy(allRows, v => v.architecture)),
        release: Object.keys(_.groupBy(allRows, v => v.release)),
      },
    };

    return {grid, count, selector};
  },
);
