/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {mixThemeWithProps, type ThemeProps, type Theme} from '@css-modules-theme/react';
import {Icon} from 'components';
import {pillIconsByType, type PillIconName} from '../PillUtils';
import styles from './PillIcon.css';

export type {PillIconName};

type TypeItem = {
  type: string;
  iconTheme: Theme;
};

const linkIconMap = new WeakMap<Theme, TypeItem[]>();

// Cache theme object for Icon by own PillIcon theme, so Icon will not need to recompose theme on each rerender,
// if PillIcon theme and icon are the same across rerenders
function getIconTheme(pillTheme: Theme, type: string) {
  let linkThemesByType = linkIconMap.get(pillTheme);

  if (linkThemesByType !== undefined) {
    const typeItem = linkThemesByType.find(item => item.type === type);

    if (typeItem !== undefined) {
      return typeItem.iconTheme;
    }
  } else {
    linkThemesByType = [];
    linkIconMap.set(pillTheme, linkThemesByType);
  }

  const typeItem = {
    type,
    iconTheme: {
      ...pillTheme,
      icon: cx(pillTheme.icon, pillTheme[type]),
    },
  };

  linkThemesByType.push(typeItem);

  return typeItem.iconTheme;
}

export type PillIconProps = {
  name: PillIconName;

  // If icon is for a group (icon will have 'cloned' background)
  group?: boolean;
} & ThemeProps;

export default function PillIcon(props: PillIconProps): JSX.Element {
  const {theme, group = false, ...iconProps} = mixThemeWithProps(styles, props);
  const type = Object.keys(pillIconsByType).find(
    type => pillIconsByType[type as keyof typeof pillIconsByType] === props.name,
  )!;

  return (
    <div className={cx(theme.pillIcon, {[theme.group]: group})}>
      <Icon {...iconProps} labelGroup={group} theme={getIconTheme(theme, type)} />
    </div>
  );
}
