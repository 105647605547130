/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {send} from 'xstate';

export const getModalConfig = defaultConfig => {
  const mergeConfig = {
    states: {
      errorProcessor: {
        states: {
          processError: {
            always: [{target: 'doneWithErrors', actions: [send('DONE.WITH.ERRORS'), send('RESET')]}],
          },
        },
      },
    },
  };

  return _.merge(defaultConfig, mergeConfig);
};
