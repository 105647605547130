/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Component} from 'react';
import {connect} from 'react-redux';
import {saveAs} from 'file-saver';
import {AttributeList, Button, Grid, ToolBar, ToolGroup, StatusIcon} from 'components';
import {getWorkloadCompatibilityReportsPage} from './WorkloadCompatibilityReportState';
import {fetchWorkloadCompatibilityReport} from './WorkloadCompatibilityReportSaga';
import {statusIcons} from '../../WorkloadUtils';
import styles from './WorkloadCompatibilityReport.css';

const statusText = {
  red: intl('Workloads.MajorIncompatibilities'),
  yellow: intl('Workloads.PossibleIncompatibilities'),
  green: intl('Workloads.NoIncompatibilities'),
};

@connect(getWorkloadCompatibilityReportsPage)
export default class WorkloadCompatibilityReport extends Component {
  static prefetch = fetchWorkloadCompatibilityReport;

  constructor(props) {
    super(props);

    this.handleDownload = this.handleDownload.bind(this);
  }

  handleDownload() {
    const fileName = `${this.props.workload.name || this.props.workload.hostname}Report.txt`;
    const blob = new Blob([JSON.stringify(this.props.compatibilityReport)], {type: 'text/plain;charset=utf-8'});

    saveAs(blob, fileName, true);
  }

  render() {
    const {compatibilityReportGrid, compatibilityReport, installedPackages, missingPackages} = this.props;

    return (
      <>
        <ToolBar>
          <ToolGroup>
            <Button
              textIsHideable
              icon="export"
              text={intl('Common.ExportJSON')}
              autoFocus
              onClick={this.handleDownload}
              tid="export"
            />
          </ToolGroup>
        </ToolBar>
        {!_.isEmpty(compatibilityReport) && (
          <AttributeList>
            {[
              {divider: true},
              {title: intl('Common.General')},
              {
                tid: 'last-updated',
                key: intl('Common.LastReportUpdate'),
                value: intl.utils.format.dateAtTimeBy(compatibilityReport.last_updated_at),
              },
              {
                tid: 'status',
                key: intl('Common.Status'),
                value: (
                  <>
                    <StatusIcon {...statusIcons[compatibilityReport.qualify_status]} />
                    <span> {statusText[compatibilityReport.qualify_status]}</span>
                  </>
                ),
              },
              ...(!_.isEmpty(installedPackages) || !_.isEmpty(missingPackages)
                ? [
                    {divider: true},
                    {title: intl('Common.Packages')},
                    !_.isEmpty(installedPackages) && {
                      tid: 'installed',
                      key: intl('Common.Installed'),
                      value: <span className={styles.installed}>{installedPackages.join(', ')}</span>,
                    },
                    !_.isEmpty(missingPackages) && {
                      tid: 'missing',
                      key: intl('Common.Missing'),
                      value: <span className={styles.missing}>{missingPackages.join(', ')}</span>,
                    },
                  ]
                : []),

              {divider: true},
              {title: intl('Common.Report')},
            ]}
          </AttributeList>
        )}
        <Grid grid={compatibilityReportGrid} tid="compatibility_report" emptyMessage={intl('Common.NoData')} />
      </>
    );
  }
}
