/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent, createElement, type ElementType, type CSSProperties} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import StickyContainer, {StickyContext} from './StickyContainer';
import styles from './StickyShadow.css';
import {type ReactStrictNode} from 'utils/types';

export {StickyContainer};

type WrappedComponentProps = {
  className?: string;
  theme?: Record<string, string>;
  themeNoCache?: boolean;
  style?: CSSProperties;
};

type StickyShadowOwnProps = {
  // Sticky element can mimic any html element ('div', 'h2' etc) or custom component (constructor like Link, Label etc)
  // Custom components are usually functions. but can be objects if they wrapped in forwardRef
  type?: ElementType<WrappedComponentProps>;
  typeTheme?: Record<string, string>;
  // Depth number from material design
  depth: 2 | 3 | 4 | 6 | 8 | 16 | 24;
  // Drop shadow only below element, to avoid overlapping shadows of adjacent elements if there are several stickies in a row
  shadowDown?: boolean;
  // By default shadow appear/disappear animated only after 100ms to allow immediate appearance in case history scroll is being restored
  // If you want it to be animated even on initial load, like in global Header, set alwaysAnimate to true
  alwaysAnimate?: boolean;
  // Whether stickiness is possible or not, for instance sometimes it should be turned off if container is disabled
  disabled?: boolean;
  // By default offset will be taken from StickyContainer through context. This property will override it
  offset?: string;
  // Any react content
  children: ReactStrictNode;
  style?: CSSProperties;
  typeThemeClass?: string;
  className?: string;
} & ThemeProps &
  typeof StickyShadow.defaultProps;

type contextType = {
  checkIn: (child: ReactStrictNode) => void;
  offset: string;
  checkOut: (child: ReactStrictNode) => void;
};

interface StickyShadowState {
  shadow: boolean;
  animate: boolean;
}

export default class StickyShadow extends PureComponent<StickyShadowOwnProps, StickyShadowState> {
  static defaultProps = {
    type: 'div',
    disabled: false,
    shadowDown: false,
    alwaysAnimate: false,
  };

  static contextType = StickyContext;

  animateTimeout: ReturnType<typeof setTimeout> | undefined;

  constructor(props: StickyShadowOwnProps, context: contextType) {
    super(props, context);

    this.state = {shadow: false, animate: props.alwaysAnimate};
  }

  componentDidMount() {
    this.context.checkIn(this);

    if (!this.props.alwaysAnimate) {
      this.animateTimeout = setTimeout(() => {
        this.setState({animate: true});
      }, 100);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.animateTimeout);
    this.context.checkOut(this);
  }

  setRatio(isIntersecting: boolean, ratio: boolean): void {
    const shadow = !isIntersecting && !ratio;

    if (shadow !== this.state.shadow) {
      this.setState({shadow});
    }
  }

  render() {
    const {
      children,
      depth,
      shadowDown,
      style,
      theme,
      disabled,
      alwaysAnimate,
      type,
      typeTheme,
      typeThemeClass,
      offset = this.context.offset,
      ...rest
    } = mixThemeWithProps(styles, this.props);

    const elementProps: WrappedComponentProps = rest;

    const {shadow, animate} = this.state;

    let stickyClassName;

    if (disabled) {
      elementProps.style = style;
      stickyClassName = theme[`shadowDepth${depth}z`];
    } else {
      let shadowClass = `shadowDepth${depth}`;

      if (!shadow) {
        shadowClass += 'z';
      } else if (shadowDown) {
        shadowClass += 'down';
      }

      elementProps.style = {...style, top: offset};
      stickyClassName = `${theme.sticky} ${theme[shadowClass]}`;

      if (animate) {
        stickyClassName += ` ${theme.animated}`;
      }
    }

    if (typeof type === 'string') {
      // If type is simple html element (string like 'div', 'h2' etc.), just create className from theme
      elementProps.className = stickyClassName;
    } else if (typeTheme && typeThemeClass) {
      // If type is a custom component, and theme for it is specified, then add sticky theme to it
      elementProps.theme = {
        ...typeTheme,
        [typeThemeClass]: `${typeTheme[typeThemeClass]} ${stickyClassName}`,
      } as ThemeProps['theme'];
      // And say to not cache composed theme in that component, since we generate injectTheme here on every render
      elementProps.themeNoCache = true;
    }

    return createElement(type, elementProps, children);
  }
}
