/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {useRef, useCallback, useState, useEffect} from 'react';
import {all, call} from 'redux-saga/effects';
import {Document as FSDocument, Index as FSIndex} from 'flexsearch';
import {KEY_UP, KEY_DOWN, KEY_RIGHT, KEY_LEFT, KEY_RETURN, KEY_TAB, KEY_BACK_SPACE} from 'keycode-js';
import {generalUtils, domUtils} from 'utils';
import {prefixMap, typePrefixRegexp} from 'components/Pill/Label/LabelUtils';
import {fetchResource} from './SelectorSaga';
import styles from './Selector.css';
import styleUtils from 'utils.css';
import modalStyles from 'components/Modal/Modal.css';

export const CATEGORYPANEL_ID = 'categoryPanel';
export const OPTIONPANEL_ID = 'optionPanel';
export const VALUEPANEL_ID = 'valuePanel';
export const DROPDOWN_ID = 'dropdown';
export const INPUT_ID = 'input';
export const SEARCHBAR_ID = 'searchBar';
export const SEARCHBAR_CONTAINER_ID = 'searchBarContainer';
export const COLUMN_WIDTH = 180;

export const categorySuggestionRegex = /\b(in)\b/i;
export const multipleWhiteSpaceRegex = /  +/g;
// Escape Regex http://stackoverflow.com/questions/3115150/#answer-9310752
export const ESCAPE = str => str.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&');

/**
 * Whether keyDown event is to navigte - UP, DOWN, or Ctrl/Cmd RIGHT/LEFT keys
 */
export const isMovingHighlighted = evt => {
  if (
    evt.keyCode === KEY_UP ||
    evt.keyCode === KEY_DOWN ||
    (generalUtils.cmdOrCtrlPressed(evt) && (evt.keyCode === KEY_LEFT || evt.keyCode === KEY_RIGHT))
  ) {
    return true;
  }
};

/**
 * When dropdown is open the focus stays on input element which handles keydown event,
 * An option/category in dropdown is highligted based on navigate events (UP, DOWN, Ctrl/Cmd RIGHT/LEFT)
 * This method returns true if there is a highlighted child and event should be handled by child and not input
 */
export const isHighlightedBlockEvent = (evt, valuePanelIsHighlighted) => {
  if (
    evt.keyCode === KEY_RETURN ||
    evt.keyCode === KEY_TAB ||
    // If a selected value is highlighted in value panel then
    // backspace should remove that selection and is handled by highligted value element (handle remove)
    // otherwise backspace event should delete query string in input
    (evt.keyCode === KEY_BACK_SPACE && valuePanelIsHighlighted)
  ) {
    return true;
  }
};

/**
 * Returns true if element rect is in the direction of navigation from base rect
 */
const isElementInDirection = ({direction, rect, elementRect}) => {
  let isInDirection = false;

  switch (direction) {
    case KEY_UP:
      isInDirection = Math.floor(elementRect.bottom) <= Math.floor(rect.top);
      break;
    case KEY_DOWN:
      isInDirection = Math.floor(elementRect.top) >= Math.floor(rect.bottom);
      break;
    case KEY_RIGHT:
      isInDirection = Math.floor(elementRect.left) >= Math.floor(rect.right);
      break;
    case KEY_LEFT:
      isInDirection = Math.floor(elementRect.right) <= Math.floor(rect.left);
      break;
  }

  return isInDirection;
};

/**
 * Returns the closest element in a direction from a reference point
 * @param {*} [childrenPropsMap] - id and ref Map of input elements
 * @param {*} [rect] - reference point rect
 * @returns {Object}
 */
const getClosestElement = ({direction, elementsMap, rect = {}} = {}) =>
  [...Array.from(elementsMap)].reduce((result, [id, elementProps = {}]) => {
    const elementRect = elementProps.element?.getBoundingClientRect() ?? {};

    if (elementRect.top === elementRect.bottom) {
      // Hidden element
      return result;
    }

    if (isElementInDirection({direction, elementRect, rect})) {
      const distance = (elementRect.left - rect.left) ** 2 + (elementRect.top - rect.top) ** 2;

      if (!result.minDistance || distance < result.minDistance) {
        return {id, ...elementProps, minDistance: distance};
      }
    }

    return result;
  }, {});

/**
 * A custom hook to:
 * 1. store a component's child elements information in a Map, E.g. their refs, keyDown and highlighted handlers
 * 2. capture which child is in highlighted and set highlighted among its children
 */
export const useFilialPiety = () => {
  const childrenPropsMap = useRef(null); //[id, {element, setHighlightedChild, keyDown}]
  const [highlightedChild, highlightedSetter] = useState(null); // {id, element}
  const highlightedChildRef = useRef(); // Ref to store current highligtedChild, added to remove any dependency in setHighlightedChild

  if (!childrenPropsMap.current) {
    childrenPropsMap.current = new Map();
  }

  const saveChildRef = useCallback((id, element) => childrenPropsMap.current.set(id, {element}), []);

  const registerChildHandlers = useCallback((id, handlers = {}) => {
    const {element} = childrenPropsMap.current.get(id) ?? {};

    childrenPropsMap.current.set(id, {element, ...handlers});

    return () => childrenPropsMap.current.delete(id); //Return a function to remove handlers (called during unmount)
  }, []);

  /*
  If we draw Selector component in a tree then each branch of this tree can be considered a pathArr,
  each node of this tree stores information on which of its child is highlighted (highlightedChild state),
  leaf nodes of this tree renders a set of options or selected values (<li> elements)
                                  input
                                /       \
      categoryPanel <--- dropdown            valuePanel
                        /        \            /   |...  \
              categoryPanel    optionPanel   R1    R2 ... Rn
             /   |...  \        /   |...  \                 \
           R1    R2 ... Rn    R1    R2 ... Rn             selected values
          /      |            /
     options  options....   option

  Examples of pathArr: If an option in resource R1 (lets assume R1 is in optionPanel) is highlighted then
  1. pathArr from input: ['dropdown', 'optionPanel', 'R1']
  2. pathArr from dropdown: ['optionPanel', 'R1']
  3. pathArr from optionPanel: ['R1']
  4. pathArr from resource R1: []
 */

  const resetHighlightedChild = useCallback((pathArr = []) => {
    highlightedChildRef.current = null;
    highlightedSetter(null);

    // we need to recursively call resetHighlightedChild of nodes along the pathArr
    return childrenPropsMap.current.get(pathArr.shift())?.resetHighlightedChild?.(pathArr);
  }, []); // NOTE: Adding a dependency to resetHighlightedChild will invoke component unmount which will delete its ref

  const setHighlightedChild = useCallback((options = {}) => {
    /*
      options: {pathArr, newHighlightedId, rect}
      There are three scenarios when setting highlighted on an option:
      1) No highlight exists - in this case the arguments are input element rect (root node) and direction of navigation
      2) highlighted option exists -
         in this case the arguments are direction of navigation, highlighted option rect and pathArr
      3) hover on an option - arguments are pathArr and optionId

      To set highlight on an option we need to know:
        a) its relative path from input element (tree branch)
        b) set of parameters to identify the option i.e. optionId Or rect & direction
      In scenario three both information are already provided to the function
    */
    if (_.isEmpty(options)) {
      return;
    }

    const {newHighlightedId, direction} = options;
    const pathArr = [...(options.pathArr ?? [])];
    const childrenMap = new Map(childrenPropsMap.current);
    const rect = highlightedChildRef.current?.element?.getBoundingClientRect() ?? options.rect;

    let closestElement = {};

    while (true) {
      if (pathArr.length > 0) {
        closestElement = {id: pathArr[0], ...childrenMap.get(pathArr[0])};
      } else {
        closestElement = newHighlightedId
          ? {id: newHighlightedId, element: childrenMap.get(newHighlightedId).element}
          : getClosestElement({direction, elementsMap: childrenMap, rect});
      }

      if (!closestElement?.element) {
        // (code tag #leave) If there is no child element in the direction Or if element is null
        // returning undefined will move highlighted search to its sibling node
        highlightedChildRef.current = null;
        highlightedSetter(null);

        return;
      }

      highlightedChildRef.current = closestElement;

      if (!closestElement.setHighlightedChild) {
        // (code tag #found) If setHighlightedChild is undefined then we have reached the leaf node
        // Scroll to the element if it is hidden and return its pathArr and rect.
        // Set this element as highlighted.
        const infoPanelElement = closestElement.element.parentElement?.parentElement?.querySelector(
          `.${styles.infoPanel}`,
        );

        // Scroll to the element if needed
        domUtils.scrollToElement({
          element: closestElement.element,
          ...(infoPanelElement && {offsetElements: [infoPanelElement]}),
        });

        highlightedSetter(closestElement);

        return [pathArr, closestElement.element.getBoundingClientRect()];
      }

      // Reset highlighted on parent, highlighted state moves to nested child
      highlightedSetter(null);

      // (code tag #enter)
      // Restore scroll position if it is a list resource
      const listResourceElement = closestElement.element.querySelector(`.${styles.listResource}`);
      const skipScrollRestore =
        pathArr.includes(closestElement.id) || // Highlight exists in this path
        direction === KEY_UP; // Highlight state enters the listResource at scroll height

      if (!skipScrollRestore && listResourceElement && listResourceElement?.scrollTop) {
        listResourceElement.scrollTop = 0;
      }

      const [highlightedElementPathArr, highlightedRectRef] =
        closestElement.setHighlightedChild({...options, pathArr: pathArr.slice(1), rect}) ?? [];

      if (!highlightedRectRef) {
        // A recursive call has returned undefined (code tag #leave) i.e. no highlighted child found in the direction
        // If pathArr exists then remove this node from path Arr and continue the closest node search.
        const index = pathArr.indexOf(closestElement.id);

        if (index !== -1) {
          pathArr.splice(index);
        }

        childrenMap.delete(closestElement.id);

        continue;
      }

      // highlighted option is found (code tag #found) then concatenate the node to pathArr and return
      return [[closestElement.id, ...(highlightedElementPathArr ?? [])], highlightedRectRef];
    }
  }, []); // NOTE: Adding a dependency to highlightedSetter will invoke component unmount which will delete its ref

  const keyDown = useCallback((evt, {pathArr = []}) => {
    if (childrenPropsMap.current.get(pathArr[0])?.keyDown) {
      // Recursively traverse the pathArr to delegate keyDown event to the child component which is managing highlighted
      return childrenPropsMap.current.get(pathArr[0]).keyDown(evt, {pathArr: pathArr.slice(1)});
    }
  }, []);

  return {
    childrenPropsMap: childrenPropsMap.current,
    saveChildRef,
    registerChildHandlers,
    highlightedChild,
    highlightedChildRef,
    setHighlightedChild,
    resetHighlightedChild,
    keyDown,
  };
};

export const getOptionIdPath = (option = {}, idPath) =>
  idPath ?? (option.href ? 'href' : option.id ? 'id' : option.value ? 'value' : option.name ? 'name' : '');
export const getOptionTextPath = (option = {}, textPath) =>
  textPath ?? (option.name ? 'name' : option.value ? 'value' : option.hostname ? 'hostname' : '');

export const getOptionId = (option, idPath) =>
  typeof option === 'object' ? _.get(option, getOptionIdPath(option, idPath)) : option;
export const getOptionText = (option, textPath) =>
  typeof option === 'object' ? _.get(option, getOptionTextPath(option, textPath)) : String(option);

export const getOptionById = (options = [], id, idPath) => options.find(option => getOptionId(option, idPath) === id);
export const getOptionByText = (options = [], text, textPath) =>
  text.trim() &&
  options.find(option => getOptionText(option, textPath).trim().toLowerCase() === text.trim().toLowerCase());

export const getSearchResult = (searchIndex, query, options = {enrich: true}) => {
  // Search Index can be an instance of Index Or Document
  // get options from result in case of document otherwise return the searchResult
  const searchResult = searchIndex.search(query, options);
  const document = _.get(searchResult, '0.result');

  return document ? document.map(({doc}) => doc) : searchResult;
};

export const prepareSearchIndex = ({options = [], idPath, textPath, store, indexOptions} = {}) => {
  const flexSearchOptions = {encoder: 'icase', tokenize: 'full', ...indexOptions};

  // flexSearch path is separated by ':' unlike lodash '.' separator
  const id = getOptionIdPath(options[0], idPath)?.replace('.', ':');
  const field = getOptionTextPath(options[0], textPath).replace('.', ':');

  if (flexSearchOptions.document || (id && field)) {
    const index = new FSDocument({
      document: {id, index: [field], store: store ?? [id, field]},
      ...flexSearchOptions,
    });

    options.forEach(option => index.add(option));

    return index;
  }

  const index = new FSIndex(flexSearchOptions);

  options.forEach(option => index.add(option, option));

  return index;
};

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

export const sortOptions = (options, query, indexOptions) => {
  if (query) {
    const searchIndex = prepareSearchIndex({
      options,
      indexOptions: {document: {id: 'value', index: ['value'], store: true}, ...indexOptions},
    });

    return getSearchResult(searchIndex, query, {enrich: true});
  }

  return options.sort((a, b) => collator.compare(a.value, b.value));
};

export const isCategorySelectable = ({hidden, displayResourceAsCategory, divider, sticky}) =>
  !sticky && !divider && !hidden && !displayResourceAsCategory;

/**
 * Find category ID of the last selected option
 */
export const getLastSelectedActiveCategory = ({values = new Map(), categories, allResources}) => {
  let lastSelectedCategoryId;

  _.forEachRight([...Array.from(values.keys())], resourceId => {
    if (lastSelectedCategoryId) {
      return;
    }

    const resource = allResources[resourceId];

    if (!resource.sticky) {
      const category = categories.find(({id}) => id === resource.categoryId);

      if (isCategorySelectable(category)) {
        lastSelectedCategoryId = category.id;
      }
    }
  });

  return lastSelectedCategoryId;
};

/**
 * Find active category object from given ID
 */
export const getNextVisibleCategoryId = categories => categories.find(isCategorySelectable)?.id;

/**
 * Find the longest matching text among options that starts with query string
 */
export const getSuggestionText = (inputQuery, options = [], textPath) => {
  if (!inputQuery.trim() || options.length === 0) {
    return;
  }

  const query = inputQuery.replace(multipleWhiteSpaceRegex, ' ');

  const ref = getOptionText(options[0], textPath);
  let suggestion = '';
  const startIndex = ref.toLowerCase().indexOf(query.toLowerCase());

  if (startIndex === -1) {
    return suggestion;
  }

  // Take first option as reference and find all the substring combinations from query string index
  for (let endIndex = startIndex + 1; endIndex <= ref.length; endIndex++) {
    const nextSuggestion = ref.substring(startIndex, endIndex);

    if (options.some(value => !getOptionText(value, textPath).toLowerCase().includes(nextSuggestion.toLowerCase()))) {
      // If the substring is not in any one of the value then return current result
      break;
    }

    // Otherwise, return new suggestion
    suggestion = nextSuggestion;
  }

  return suggestion.slice(query.length);
};

/**
 * create promise object
 */
export const createPromise = () => {
  const promiseObj = {};

  promiseObj.promise = new Promise((resolve, reject) => {
    promiseObj.onSearchDone = resolve;
    promiseObj.onSearchReject = reject;
  });

  return promiseObj;
};

export const populateSearchPromises = (categories, activeCategoryId) =>
  categories.reduce(
    (result, category) => {
      if (category.divider) {
        return result;
      }

      if (
        category.displayResourceAsCategory ||
        category.id === activeCategoryId ||
        category.sticky ||
        Object.values(category.resources).some(({sticky}) => sticky)
      ) {
        Object.entries(category.resources).forEach(([resourceId, {hidden, type}]) => {
          if (hidden || type === 'container') {
            return;
          }

          result[resourceId] = createPromise(); // promiseObj: {promise, onSearchDone, onSearchReject}
        });
      }

      return result;
    },
    categories.length === 1 ? {} : {[CATEGORYPANEL_ID]: createPromise()},
  );

export const populateInitialLoadPromises = resources =>
  Object.entries(resources).reduce((result, [resourceId, {hidden}]) => {
    if (hidden) {
      return result;
    }

    const promiseObj = {};

    promiseObj.promise = new Promise((resolve, reject) => {
      promiseObj.onInitialLoadDone = resolve;
      promiseObj.onInitialLoadReject = reject;
    }); // promiseObj: {promise, onInitialLoadDone, onInitialLoadReject}

    result[resourceId] = promiseObj;

    return result;
  }, {});

/**
 * Bold or underline the text match in each string
 */
export const getHighlightedText = ({query, text, bold = false} = {}) => {
  if (!query.trim() || query.length === 0 || !text) {
    return text;
  }

  const regex = new RegExp(`(${ESCAPE(query)})`, 'i');
  const matches = text.split(regex).map((text, index) => {
    const highlight = text.toLowerCase() === query.toLowerCase();
    const highlightStyle = bold ? styleUtils.bold : styles.highlightTextUnderline;

    return (
      <span key={index} className={highlight ? highlightStyle : ''}>
        {text}
      </span>
    );
  });

  return <span>{matches}</span>;
};

const findBestMatch = (suggestions, query) => {
  if (suggestions.length === 1) {
    return suggestions[0];
  }

  // Otherwise, we need to take primary match from each list and determine suggestion/highlighted among these
  const suggestion = getSuggestionText(query, suggestions, 'primaryMatch.text');

  const searchIndex = prepareSearchIndex({
    options: suggestions,
    idPath: 'primaryMatch.id',
    textPath: 'primaryMatch.text',
    store: true,
  });

  return {suggestion, ...getSearchResult(searchIndex, query, {enrich: true, limit: 1})[0]};
};

const getFinalSuggestion = (suggestions, query) => {
  if (suggestions.length === 1) {
    return suggestions[0];
  }

  // Category list suggestion
  const categorySuggestion = suggestions.find(({id}) => id === CATEGORYPANEL_ID);

  if (categorySuggestion) {
    return categorySuggestion;
  }

  const exactMatch = suggestions.find(
    ({primaryMatch}) => primaryMatch.text.toLowerCase() === query.trim().toLowerCase(),
  );

  if (exactMatch) {
    return exactMatch;
  }

  const stickySuggestions = suggestions.filter(({isSticky}) => isSticky);

  if (stickySuggestions.length) {
    return findBestMatch(stickySuggestions, query);
  }

  const [createOrPartialSuggestions, filteredSuggestions] = _.partition(
    suggestions,
    ({isCreateOrPartial}) => isCreateOrPartial,
  );

  const match = findBestMatch(filteredSuggestions, query);

  if (match.pathArr?.length) {
    return match;
  }

  return createOrPartialSuggestions[0] ?? {};
};

/**
 * Takes the result of options load promise and determine the next suggestion/highlighted among primary matches
 */
export const pickSuggestion = (allSuggestions = [], query) => {
  // allSuggestions is an array of objects - {suggestion, primaryMatch: {id, text}, id, pathArr}
  // Remove entries with undefined suggestions
  let suggestions = allSuggestions.filter(({suggestion}) => typeof suggestion === 'string');

  suggestions = suggestions.filter(({primaryMatch}) => primaryMatch !== undefined);

  if (suggestions.length === 0) {
    return;
  }

  const {primaryMatch, pathArr, suggestion, isCreateOrPartial} = getFinalSuggestion(suggestions, query);

  return {
    suggestion: isCreateOrPartial
      ? ''
      : primaryMatch && suggestion === ''
      ? getSuggestionText(query, [primaryMatch], 'text')
      : suggestion,
    highlighted: {pathArr, newHighlightedId: primaryMatch?.id},
  };
};

export const shouldCloseDropdown = (targetElement, searchBarElement, modalContext) => {
  // Case 1: Clicked in selector
  if (searchBarElement.contains(targetElement)) {
    // Should not close on click inside selector
    return false;
  }

  // modalContext is empty if selector is not mounted on a Modal
  // Case 2: Selector is mounted on a Modal and clicked anywhere in Modal
  if (modalContext.getModalRef?.()?.contains(targetElement)) {
    // Should close on click, return true to skip checking other scenarios
    return true;
  }

  const isModalClicked = targetElement.closest(`.${modalStyles.modal}`);

  // Case 3: Container resource in Selector has a modal, clicking on this should not close selector
  if (isModalClicked) {
    // Should not close on container resource modal click
    return false;
  }

  const isBackdropClicked = targetElement.classList.contains(styleUtils.fixedCurtain);
  const isNotParentModalBackdrop = !modalContext.zIndex || getComputedStyle(targetElement).zIndex > modalContext.zIndex;

  if (isBackdropClicked && isNotParentModalBackdrop) {
    // If backdrop z-order is higher than selector parent Modal then clicking on it should not close the selector, return false
    return false;
  }

  // Otherwise, close the dropdown
  return true;
};

export const getAllResourcesObject = categories =>
  // A category is an object with resources nested object, we need to flatten and combine resources in a single object
  // and, also assign the resource id and category id to its value
  categories.reduce(
    (result, category) => ({
      ...result,
      ..._.mapValues(category.resources, (value, key) => ({
        ...value,
        id: key,
        categoryId: category.id,
        name: value.name ?? category.name, // Assign category name to resource name if resource name is undefined
        displayResourceAsCategory: category.displayResourceAsCategory,
        sticky: value.sticky ?? category.sticky,
        hidden: value.hidden || category.hidden,
        searchIndex: Array.isArray(value.statics)
          ? prepareSearchIndex({
              options: value.statics,
              idPath: value.idPath,
              textPath: value.textPath,
              store: true,
            })
          : null,
      })),
    }),
    {},
  );

const areResourceValuesSame = (newValues, oldValues, sorter) => {
  if (newValues.length !== oldValues.length) {
    return false;
  }

  return (
    generalUtils.sortAndStringifyArray(newValues, sorter) === generalUtils.sortAndStringifyArray(oldValues, sorter)
  );
};

export const isValuesMapEqual = (map1 = new Map(), map2 = new Map(), allResources) => {
  if (map1.size !== map2.size) {
    return false;
  }

  if (map1.size === 0) {
    return true; // empty Map
  }

  for (const [resourceId, resourceValues] of map1) {
    if (!map2.has(resourceId)) {
      return false;
    }

    const textPath = allResources[resourceId].optionProps?.textPath;
    const sorter = [getOptionTextPath(resourceValues[0], textPath)];

    // compare values array
    if (!areResourceValuesSame(resourceValues, map2.get(resourceId), sorter)) {
      return false;
    }
  }

  return true;
};

export const getQueryAndKeyword = (query, queryKeywordsRegex) => {
  if (query && queryKeywordsRegex) {
    const keyword = query.match(queryKeywordsRegex)?.[0];

    return {keyword, query: query.substring(keyword?.length ?? 0)};
  }

  return {query};
};

export const getQuery = (queryString, values = new Map(), resource = {}) => {
  const query = {};
  const allowMultipleSelection = resource.optionProps?.allowMultipleSelection;
  const labels = values.get('labelsAndLabelGroups') ?? [];

  if (!allowMultipleSelection) {
    const excludeKeys = [...new Set(labels.map(({key}) => key))];

    if (excludeKeys.length) {
      query.exclude_keys = JSON.stringify(excludeKeys);
    }
  }

  const selectedScope = [];

  // IncludeSelectedResources: ['labelsAndLabelGroups']
  resource.includeSelectedResources?.forEach(resourceId => {
    const selections = values.get(resourceId)?.filter(({href}) => !href.includes('exists'));

    if (selections?.length) {
      selectedScope.push(
        ...selections.map(({href}) => ({[href.includes('label_groups') ? 'label_group' : 'label']: {href}})),
      );
    }
  });

  if (selectedScope.length > 0) {
    query.selected_scope = JSON.stringify(selectedScope);
  }

  const {keyword, query: value} = getQueryAndKeyword(queryString, typePrefixRegexp);

  if (keyword) {
    query.key = prefixMap[keyword.trim().toLowerCase()];
  }

  query.query = value;

  return query;
};

export const sanitizeOption = option => {
  let value = option;

  if (typeof value === 'object' && value.resourceId) {
    // omit redundant resourceId information from option
    value = _.omit(value, ['resourceId', ...(value.id?.includes(`_${value.resourceId}`) ? ['id'] : [])]);

    if (Object.keys(value).length === 1) {
      value = value.value;
    }
  }

  return value;
};

export const prepareApiArgs = (query, values, resource) => {
  const {apiArgs} = resource;

  if (!apiArgs) {
    return {query: {query}};
  }

  if (typeof apiArgs === 'function') {
    return apiArgs(query, values, resource);
  }

  const {query: {getQuery, ...otherQuery} = {}, ...args} = apiArgs;

  Object.assign(args, {query: {...otherQuery, ...(getQuery?.(query, values, resource) ?? {query})}});

  return args;
};

export const prepareContainerProps = params => {
  const {
    resource: {containerProps},
  } = params;

  if (typeof containerProps === 'function') {
    return containerProps(params);
  }

  const {getContainerProps, ...restContainerProps} = containerProps;

  return {...restContainerProps, ...getContainerProps(params)};
};

export const useAutoHideTooltip = (timeout = 5000) => {
  const [showTippy, setShowTippy] = useState();
  const [skipAutoHide, setSkipAutoHide] = useState();

  useEffect(() => {
    if (!showTippy || skipAutoHide) {
      return;
    }

    const filteringTipsTimeout = setTimeout(() => {
      setShowTippy(false); // hide tooltip after a few seconds
    }, timeout);

    return () => clearTimeout(filteringTipsTimeout);
  }, [showTippy, skipAutoHide, timeout]);

  return [showTippy, setShowTippy, setSkipAutoHide];
};

export const combinedCategoryId = 'combined';

export const populateCombinedCategory = ({categories, combinedResourceIds, theme, activeCategoryId}) => {
  const allResources = getAllResourcesObject(categories);

  const resourceIdsToCombine =
    combinedResourceIds ??
    Object.values(allResources).reduce((result, {id, sticky, type, hidden, displayResourceAsCategory}) => {
      if (hidden || sticky || type === 'container' || displayResourceAsCategory) {
        return result;
      }

      result.push(id);

      return result;
    }, []);

  return {
    id: combinedCategoryId,
    active: activeCategoryId === combinedCategoryId,
    name: intl('ObjectSelector.SearchAllCategories'),
    noActiveIndicator: true,
    maxColumns: 2,
    resources: {
      combined: {
        selectIntoResource: ({value, resource}) => value.resourceId ?? resource.id,
        *dataProvider({query, values}) {
          let options = [];
          let createOrPartialOptions = [];
          let strippedQuery = query;

          const responseArr = yield all(
            resourceIdsToCombine.map(id =>
              call(fetchResource, {
                resource: allResources[id],
                apiOptions: prepareApiArgs(query, values, allResources[id]),
              }),
            ),
          );

          resourceIdsToCombine.forEach((resourceId, index) => {
            const {staticsOptions, dataProviderOptions, createOptions = [], partialOption} = responseArr[index];
            const resource = allResources[resourceId];

            if (createOptions.length || partialOption) {
              createOrPartialOptions = [
                ...createOrPartialOptions,
                ...createOptions.map(option => ({...option, resourceId})),
                partialOption ? {...partialOption, resourceId} : [],
              ];
            }

            const resourceOptions = [
              ...((Array.isArray(staticsOptions) ? staticsOptions : staticsOptions?.matches) ?? []),
              ...((Array.isArray(dataProviderOptions) ? dataProviderOptions : dataProviderOptions?.matches) ?? []),
            ];

            options = [
              ...options,
              ...resourceOptions.map(option => ({
                ...(typeof option === 'string' ? {id: `${option}_${resourceId}`} : option),
                value: getOptionText(option, resource.optionProps?.textPath),
                resourceId,
              })),
            ];

            if (resource.queryKeywordsRegex) {
              strippedQuery = getQueryAndKeyword(strippedQuery, resource.queryKeywordsRegex).query;
            }
          });

          return [...createOrPartialOptions, ...sortOptions(options, strippedQuery)];
        },
        apiArgs: (query, values) => ({query, values}),
        optionProps: {
          filterOption: (option, values, resource) => {
            const optionResource = allResources[option.resourceId ?? resource.id];

            return (
              Boolean(optionResource) &&
              (allResources[optionResource.id]?.optionProps?.filterOption?.(option, values, optionResource) ?? true)
            );
          },
          format: ({option, formattedText, resource: {id} = {}}) => {
            const resource = allResources[option.resourceId ?? id];
            const {format, hint} = resource?.optionProps ?? {};

            const hintText = hint ? (typeof hint === 'function' ? hint(option) : hint) : resource?.name;

            const sanitizedOption = sanitizeOption(option);

            return (
              <div className={theme.formatOption}>
                <span>{format?.({option: sanitizedOption, formattedText, resource}) ?? formattedText}</span>
                {hintText && <span className={theme.hintTextStyle}>{hintText}</span>}
              </div>
            );
          },
          isPill: option => allResources[option.resourceId]?.optionProps?.isPill,
          pillProps: (option, resource, values) => {
            const pillProps = allResources[option.resourceId ?? resource.id]?.optionProps?.pillProps;

            return typeof pillProps === 'function' ? pillProps(option, resource, values) : pillProps;
          },
          allowMultipleSelection: true,
          tooltipProps: (option, resource) =>
            allResources[option.resourceId ?? resource.id]?.optionProps?.tooltipProps ?? {},
        },
      },
    },
  };
};

export const populateCategories = ({categories, theme, hideCombinedCategory}) => {
  const newCategories = [...categories];

  if (hideCombinedCategory) {
    return newCategories;
  }

  const combinedCategory = populateCombinedCategory({categories, theme});

  const shouldReplace = newCategories[0].id === combinedCategoryId;

  if (shouldReplace) {
    newCategories[0] = combinedCategory;
  } else {
    newCategories.unshift(combinedCategory);
  }

  return newCategories;
};

const emptySelectorHistoryObj = {flags: {advancedEnabled: false}, recents: {}};
export const getMigratedData = data => {
  if (!data) {
    return emptySelectorHistoryObj;
  }

  if (data.hasOwnProperty('flags') && data.hasOwnProperty('recents')) {
    return data;
  }

  return {...emptySelectorHistoryObj, recents: data};
};
