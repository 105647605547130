/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import * as qs from 'qs';
import _ from 'lodash';
import intl from 'intl';
import {TimeoutError, RequestAbort, RequestError, RequestStatusError} from 'errors';
import type {AsyncReturnType} from 'type-fest';
import JSONBig from 'json-bigint-keep-object-prototype-methods';

export type FetcherType = 'json' | 'form';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

interface FetcherCommonOptions extends Pick<RequestInit, 'mode' | 'method' | 'redirect' | 'credentials'> {
  url: string;
  headers?: Record<string, string>;
  // query parameters
  query?: Record<string, unknown>;
  strictNullHandling?: boolean;
  timeout?: number;
  parse?: boolean;
  ignoreCodes?: number[];
}

// These two unions ensures when type is 'json', data can be any object
// and when type is 'form', data can only be object with string values
interface FetcherJsonOptions extends FetcherCommonOptions {
  type?: 'json';
  data?: unknown;
}

interface FetcherFormOptions extends FetcherCommonOptions {
  type?: 'form';
  data?: Record<string, string>;
}

interface FetcherTextOptions extends FetcherCommonOptions {
  type?: 'text';
  data?: string;
}

export type FetcherOptions = FetcherJsonOptions | FetcherFormOptions | FetcherTextOptions;

export interface FetchResult {
  response: AsyncReturnType<typeof fetch>;
  data: unknown;
}

export interface FetcherResult {
  promise: Promise<FetchResult>;
  abort(): void;
}

export default function fetcher({
  url, // Target url
  headers = {}, // HTTP headers
  method = 'GET', // HTTP method
  query = {}, // List of key/value for constructing query parameters
  strictNullHandling = false, // Allows for passing of null query parameters
  // To use Discriminated Unions, we can't destruct data and type directly
  //
  // data /* Data to send, will be transformed according to method and type
  //          If method is PUT or POST will be passed as 'body' property to request
  //          If not - will be added to query parameters */,
  // type = 'json', // Data type for PUT and POST methods. If 'json' - data will be stingified, if 'form' - encoded
  redirect = 'follow', // Redirect mode: follow, error, or manual. Can't be polyfilled on top of XHR
  mode = 'same-origin', // Mode of the request: cors, no-cors, cors-with-forced-preflight, same-origin, or navigate
  credentials = 'same-origin' /* Whether the user agent should send cookies from the other domain in the case of cors.
                                  Values: omit, same-origin or include */,
  timeout, // How long to wait for response before promise will be rejected, in ms
  parse = true, // Parse response body using JSON.parse, or just return string as is if false
  ignoreCodes = [] /* List of codes that will not produce RequestStatusError and will not parse response body, keeping 'data' undefined
                       If you want reponse body to be put into `data` property, pass parse: false */,
  ...opts
}: FetcherOptions): FetcherResult {
  const controller = new AbortController();
  const requestHeaders = {...headers};
  const options: RequestInit = {
    mode,
    method,
    redirect,
    credentials,
    signal: controller.signal,
  };

  opts.type ??= 'json';

  if (opts.data) {
    if (method === 'PUT' || (method === 'POST' && opts.data)) {
      // For PUT/POST methods we must pass even empty object as data-binary param
      if (opts.type === 'json') {
        // Send data as JSON
        options.body = JSONBigIntNative.stringify(opts.data);
        requestHeaders['Content-Type'] ??= 'application/json';
      } else if (opts.type === 'form') {
        // this line assume when opts.type === 'form', data is an object of string values.
        // so we enforce this in the type using discriminated union.

        // Send data as encoded form (key=value&key=value)
        options.body = _.map(opts.data, (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join(
          '&',
        );

        requestHeaders['Content-Type'] ??= 'application/x-www-form-urlencoded; charset=utf-8';
      } else if (opts.type === 'text') {
        options.body = opts.data;

        requestHeaders['Content-Type'] ??= 'text/plain';
      }
    } else {
      query = {...query, data: opts.data};
    }
  }

  options.headers = new Headers(requestHeaders);

  const queryString = qs.stringify(query, {encode: true, arrayFormat: 'brackets', strictNullHandling});

  if (queryString) {
    url += '?' + queryString;
  }

  let timeoutID: number | undefined;
  let timedout = false;
  const promise = fetch(url, options)
    // After getting response start waiting for response body calling `response.text` (since it's also a promise)
    .then(response => Promise.all([response, response.text()]))
    // After getting response and its body, try parsing body (to catch in next block if fails)
    .then(([response, responseText]) => {
      let data: unknown;

      if (typeof responseText === 'string' && responseText.length) {
        if (parse && !ignoreCodes.includes(response.status)) {
          try {
            data = JSONBigIntNative.parse(responseText);
          } catch {
            data = responseText;
          }
        } else {
          data = responseText;
        }
      }

      return {response, data};
    })
    .catch((error: Error) => {
      if (error.name === 'AbortError') {
        if (timedout) {
          throw new TimeoutError({timeout, request: {url, method, headers: requestHeaders}});
        }

        throw new RequestAbort({request: {url, method, headers: requestHeaders}});
      }

      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        // 'TypeError. Failed to fetch' means that server can't be reach
        // or (if mode is cors) requested asset is not on the same origin and the server isn't returning the CORS headers
        // (you can overcome cors case by setting the mode of the request to no-cors)
        throw new RequestError({
          noConnection: true,
          request: {url, method, headers: requestHeaders},
          message: intl('Common.ConnectionFailed'),
        });
      }

      throw new RequestError({
        message: error.message,
        request: {url, method, headers: requestHeaders},
      });
    })
    .then(({response, data}) => {
      // Opaque response type means that no-cors mode was set
      // and it's ok to get the asset despite the server not supporting CORS,
      // however, the response type will be opaque for these responses,
      // meaning you won't be able to examine the response, resulting in no idea of the status or the content of the response
      if (
        response.type !== 'opaque' &&
        !ignoreCodes.includes(response.status) &&
        response.status >= 400 &&
        response.status < 600
      ) {
        throw new RequestStatusError({
          data,
          response,
          request: {url, method, headers: requestHeaders},
          timeout: [408, 460, 504, 524, 598].includes(response.status),
          message: `Cannot ${method} ${url} (${response.status} ${response.statusText})`,
        });
      }

      return {response, data};
    })
    .finally(() => {
      if (timeoutID && !timedout) {
        window.clearTimeout(timeoutID);
      }
    });

  const result = {
    promise,
    abort: () => controller.abort(),
  };

  if (timeout) {
    timeoutID = window.setTimeout(() => {
      timedout = true;
      result.abort();
    }, timeout);
  }

  return result;
}
