/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {actions} from 'redux-router5';
import {connect, type ConnectedProps} from 'react-redux';
import {shallowEqual} from 'utils/general';
import {AppContext, type AppContextValue} from 'containers/App/AppUtils';
import {Children, Component, createRef, type Context} from 'react';
import type {ComponentPropsWithoutRef, ComponentPropsWithRef, KeyboardEvent, MouseEvent, MutableRefObject} from 'react';
import {Compose, composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {isClickInBrowsingContext, type MouseEventLike, openHref} from 'utils/dom';
import {getRouteCurrent} from 'containers/App/AppState';
import {Tooltip} from 'components';
import styles from './Link.css';
import {processParams} from './LinkUtils';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {ReactStrictNode, UnionOmit} from 'utils/types';
import type {State} from 'router5';

// FIXME: once getRouteCurrent is typed, remove the assertion
const connector = connect(state => ({route: getRouteCurrent(state) as State}), null, null, {forwardRef: true});

type OnClick = (
  event: MouseEventLike,
  name?: string,
  params?: Record<string, unknown>,
  reload?: boolean,
  replace?: boolean,
) => Promise<boolean | void> | boolean | void;

const getLinkTheme = () => {
  const linkThemesMap = new Map();

  return (classes = '', activeClasses = ''): {link?: string; active?: string} => {
    const key = `${classes}${activeClasses}`;
    let linkTheme = linkThemesMap.get(key);

    if (linkTheme === undefined) {
      linkTheme = {};

      if (classes.length > 0) {
        linkTheme.link = classes;
      }

      if (activeClasses.length > 0) {
        linkTheme.active = activeClasses;
      }

      linkThemesMap.set(key, linkTheme);
    }

    return linkTheme;
  };
};

// need to define defaultProps ahead to prevent self reference of type inference
const defaultProps = {
  'params': {},
  'mergeParams': true,
  'reload': false,
  'replace': false,
  'scrollTop': true,
  'noUnsavedPendingWarning': false,
  'onClick': _.noop, // Custom onclick handler which can prevent navigation by returning false

  'activityTrack': false,
  'activityStrict': false,
  'activityIgnoreQuery': false,
  'disabled': false,

  'data-tid': 'elem-link',
};

export interface LinkProps extends ComponentPropsWithoutRef<'a'>, ThemeProps {
  'children': ReactStrictNode;

  /**
   * Route name
   *
   * If omitted, navigation considered relative,
   *
   * i.e. route name is current and specified params will be merged with current params (mergeParams options)
   */
  'to'?: string;

  /**
   * Path parameters
   *
   * If navigation is relative, will be merged with existing params
   *
   * Pass 'null' as parameter value, if you want to remove some param from url
   */
  'params'?: Record<string, unknown>;

  /**
   * By default, if route name is not specified, params will be merged with existing ones
   */
  'mergeParams'?: boolean;

  /**
   * When trying to navigate to the current route nothing will happen unless reload is set to true. Default if false
   */
  'reload'?: boolean;

  /**
   *  Whether browser history should be replaced or pushed. Default is false (push)
   */
  'replace'?: boolean;

  /**
   * Flag to determine scrolling to top of the page after navigation
   *
   * - `true`: Scroll to top (Default)
   * - `'smooth'`: Scroll to top with smooth behavior
   * - `false`: Skip scrolling to the top
   */
  'scrollTop'?: true | false | 'smooth';

  /**
   * Flag to determine if Form unsaved data cancel confirmation should be displayed on route change
   */
  'noUnsavedPendingWarning'?: boolean;

  /**
   *  Custom onClick handler, can prevent navigation by returning false
   */
  'onClick'?: OnClick;

  /**
   * Link accepts and renders  'data-tid' directly (not 'tid'), since parent component (Button, Tabs) usually provide it
   */
  'data-tid'?: string;

  /**
   * Additionally you can pass tid, that will be added to 'data-tid' in case Link is in active state
   */
  'data-tid-active'?: string;

  /** Control the active state */
  'active'?: boolean;

  /**
   * Whether link should track its active state
   */
  'activityTrack'?: boolean;

  /**
   * Whether to check if the given route is the active route, or part of the active route
   */
  'activityStrict'?: boolean;

  /**
   * Whether to ignore query parameters
   */
  'activityIgnoreQuery'?: boolean;

  /**
   * Callback for handling change of link's activity state
   */
  'onActivityChange'?: (isActive: boolean, instance: LinkClass) => void;

  /**
   * Toggle disabled
   */
  'disabled'?: boolean;
  'tooltip'?: TooltipProps['content'];
  'tooltipProps'?: UnionOmit<TooltipProps, 'content'>;
}

type DefaultProps = Required<Pick<LinkProps, keyof typeof defaultProps>>;

type LinkPropsIn = LinkProps & DefaultProps & ConnectedProps<typeof connector>;

type InstanceData = {
  href?: string;
  name?: string;
  params?: Record<string, unknown>;
  isActive?: boolean;
  activityChanged?: boolean;
  children?: LinkPropsIn['children'];
  childrenArray?: ReactStrictNode[];
  elementProps?: ComponentPropsWithRef<'a'>;
};

export class Link extends Component<LinkPropsIn> {
  static contextType = AppContext;
  declare context: AppContextValue;

  static defaultProps: DefaultProps = defaultProps;

  // Helper to cache theme object for link globally,
  // so it will be the same and Link will not need to recompose theme on each rerender,
  // if component props that affect link classname are the same across rerenders
  static getLinkTheme = getLinkTheme();

  isActive: boolean;
  elementProps: ComponentPropsWithRef<'a'>;

  href?: string;
  name?: string;
  params?: Record<string, unknown>;
  element: HTMLAnchorElement | null = null;
  linkRef: MutableRefObject<HTMLAnchorElement | null>;
  instanceData: InstanceData;
  activityChanged?: boolean;
  children: LinkPropsIn['children'];
  childrenArray: ReactStrictNode[];

  constructor(props: LinkPropsIn, context?: Context<AppContextValue>) {
    super(props, context);

    this.linkRef = createRef();
    this.instanceData = {};

    Object.assign(this, getTargetProps(props, this.context.router));

    const theme = composeThemeFromProps(styles, props, {compose: 'replace' as Compose});

    this.isActive = props.active ?? false;
    this.elementProps = getElementProps(props);
    this.elementProps.className = theme.link;

    if (!this.href) {
      // we know when href is undefined, name and params exist and thus the null assertions are ok
      this.elementProps.href = this.context.router.buildUrl(this.name!, this.params);

      if (typeof props.active === 'undefined' && props.activityTrack) {
        this.isActive = this.context.router.isActive(
          this.name!,
          this.params,
          props.activityStrict,
          props.activityIgnoreQuery,
        );

        this.activityChanged = false;
      }

      if (this.isActive) {
        // Parent component can pass '.active' classname in theme object that is used when Link is active
        this.elementProps.className = cx(this.elementProps.className, theme.active);

        if (props['data-tid-active']) {
          if (this.elementProps['data-tid']) {
            this.elementProps['data-tid'] += ' ' + props['data-tid-active'];
          } else {
            this.elementProps['data-tid'] = props['data-tid-active'];
          }
        }
      }
    }

    this.children = props.children;

    // Client side only
    // First try lighter alternatives to Children.toArray
    if (Array.isArray(props.children)) {
      this.childrenArray = props.children;
    } else if (Children.count(props.children) === 1) {
      this.childrenArray = [props.children];
    } else {
      this.childrenArray = Children.toArray(props.children) as ReactStrictNode[];
    }

    this.elementProps.onKeyDown = this.handleKeyDown.bind(this);
    this.elementProps.onKeyUp = this.handleKeyUp.bind(this);
    this.elementProps.onClick = this.handleClick.bind(this);
    this.saveRef = this.saveRef.bind(this);
    this.elementProps.ref = this.saveRef;
  }

  shouldComponentUpdate(nextProps: LinkPropsIn) {
    // we used to assign computed data to 'this', but React StrictMode calls each lifecycle method multiple times in dev
    // this caused subsequent calls to this method to return false and skip the render phase
    // this caused subtle issues such as the "up" button on detail pages to not applying the proper theme
    const {isActive, instanceData} = this;

    // this property is mutated in this method, instanceData computation needs to be pure, so use cached this.isActive value
    instanceData.isActive = isActive;

    // TODO: Compose should be string literal type instead of string enum
    const theme = composeThemeFromProps(styles, nextProps, {compose: 'replace' as Compose});
    let result = false;

    const {router} = this.context;
    const {name, params, href} = getTargetProps(nextProps, router);
    const elementProps = getElementProps(nextProps);
    const {children} = nextProps;

    elementProps.onKeyDown = this.elementProps.onKeyDown;
    elementProps.onKeyUp = this.elementProps.onKeyUp;
    elementProps.onClick = this.elementProps.onClick;
    elementProps.ref = this.saveRef;
    elementProps.className = theme.link;

    if (href) {
      // If href specified in props, simply check that it's been changed
      result = href !== this.href;
      instanceData.href = href;
    } else {
      // If route name or url params has been changed, consider as should update and recalc href
      if (name !== this.name || !shallowEqual(this.params, params)) {
        elementProps.href = router.buildUrl(name!, params);
        instanceData.name = name;
        instanceData.params = params;
        result = true;
      } else {
        elementProps.href = this.elementProps.href;
      }

      if (typeof nextProps.active === 'undefined') {
        if (nextProps.activityTrack) {
          if (isActive !== router.isActive(name!, params, nextProps.activityStrict, nextProps.activityIgnoreQuery)) {
            instanceData.isActive = !isActive;
            instanceData.activityChanged = true;

            result = true;
          }
        } else if (instanceData.isActive) {
          instanceData.isActive = false;
          instanceData.activityChanged = true;

          result = true;
        }
      } else {
        instanceData.isActive = nextProps.active;
        instanceData.activityChanged = nextProps.active !== this.props.active;
      }

      if (instanceData.isActive) {
        elementProps.className = cx(elementProps.className, theme.active);

        if (nextProps['data-tid-active']) {
          if (elementProps['data-tid']) {
            elementProps['data-tid'] += ' ' + nextProps['data-tid-active'];
          } else {
            elementProps['data-tid'] = nextProps['data-tid-active'];
          }
        }
      }
    }

    // Highly optimised children check
    if (children !== this.children) {
      const childrenCount = Children.count(children);

      if (childrenCount === 1) {
        // If it's the only child and not equal to the previous (even if it was array of children) - need to be rendered
        result = true;
        instanceData.childrenArray = [children];
      } else {
        const childrenArray = Array.isArray(children) ? children : (Children.toArray(children) as ReactStrictNode[]);

        // If children length has been changed or one of children - need to be rendered
        result =
          childrenCount !== this.childrenArray.length ||
          this.childrenArray.some((child, index) => child !== childrenArray[index]);

        instanceData.childrenArray = childrenArray;
      }

      instanceData.children = children;
    }

    // If still nothing has been changed before, check for element props and some options changed
    if (!result) {
      result =
        elementProps.className !== this.elementProps.className ||
        !shallowEqual(this.elementProps, elementProps) ||
        this.props.reload !== nextProps.reload ||
        this.props.replace !== nextProps.replace ||
        this.props.tooltip !== nextProps.tooltip;
    }

    instanceData.elementProps = elementProps;

    return result;
  }

  componentDidUpdate(): void {
    if (this.activityChanged) {
      this.activityChanged = false;

      if (this.props.onActivityChange) {
        this.props.onActivityChange(this.isActive, this);
      }
    }
  }

  private saveRef(element: HTMLAnchorElement | null) {
    this.element = element;
    this.linkRef.current = element;
  }

  private handleKeyDown(evt: KeyboardEvent<HTMLAnchorElement>) {
    if (evt.key === ' ') {
      evt.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  private handleKeyUp(evt: KeyboardEvent<HTMLAnchorElement>) {
    if (evt.key === ' ') {
      // By default link is clicked on Enter, emulate click on Space also
      this.click(evt);
    }

    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }
  }

  private async handleClick(evt: MouseEventLike) {
    const {dispatch, reload, replace, scrollTop, noUnsavedPendingWarning, onClick} = this.props;

    let onClickResult = onClick(evt, this.name, this.params, reload, replace);

    if (typeof onClickResult === 'object' && typeof onClickResult?.then === 'function') {
      evt.preventDefault();
      onClickResult = await onClickResult;
    }

    if (onClickResult === false) {
      // If there is custom onClick handler and it returns false, do nothing
      evt.preventDefault();
    } else if (evt.type === 'keyup') {
      // If this is keyup event (Space button)
      evt.preventDefault();

      if (this.href) {
        // If absolute href is specified, try to open it manually
        if (!openHref({evt, href: this.href, target: this.elementProps.target, pageName: this.name})) {
          window.location.assign(this.href);
        }
      } else {
        // Otherwise call navigate function
        this.context.navigate({
          evt,
          to: this.name!.match(/^app\.(.+)$/)?.[1],
          params: this.params,
          reload,
          replace,
          scrollTop,
          noUnsavedPendingWarning,
        });
      }
    } else if (!this.href && isClickInBrowsingContext(evt, this.elementProps.target)) {
      // If user clicks in current browser context, navigate to specified route by dispatching an action,
      // Otherwise browser will figure out how to open link on click with pressed meta buttons by its own
      evt.preventDefault();
      dispatch(actions.navigateTo(this.name!, this.params, {reload, replace, scrollTop, noUnsavedPendingWarning}));
    }
  }

  click(evt?: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>): void {
    // Manually invoke handleClick, because dom.click can be strange
    // if this click method is invoked from parent within another real click event handler of parent component
    // We could do dom.dispatchEvent(new MouseEvent('click')), but in this case we should attach MouseEvent.CLICK event
    this.handleClick(evt || new MouseEvent('click'));
  }

  focus(): void {
    this.element?.focus();
  }

  blur(): void {
    this.element?.blur();
  }

  render() {
    Object.assign(this, this.instanceData);

    const {tooltip, tooltipProps} = this.props;

    return (
      <>
        <a {...this.elementProps}>{this.children}</a>
        {tooltip ? <Tooltip content={tooltip} {...tooltipProps} reference={this.linkRef} /> : null}
      </>
    );
  }
}

const ConnectedLink = connector(Link);

export default ConnectedLink;

// This is for the typing of refs to Link instances
// FIXME: remove this if we can entirely remove the usage of the react instance.
// current use cases:
// - `node.element` to get the underlying HTML node ---- this could be achieved by forwardRef
export type LinkClass = Link;

/**
 * Represent the link configuration prop of components that render Link
 */
export type LinkLikeProp = string | Partial<LinkProps>;

// Properties that we don't pass to <a> element, but handle them ourselves
const elementExcludeProps = new Set([
  'active',
  'to',
  'params',
  'mergeParams',
  'reload',
  'replace',
  'onKeyDown',
  'onKeyUp',
  'onClick',
  'activityTrack',
  'activityStrict',
  'activityIgnoreQuery',
  'onActivityChange',
  'data-tid-active',
  'scrollTop',
  'noUnsavedPendingWarning',
  'dispatch',
  'navigate',
  'router',
  'route',
  'children',
  'theme',
  'themePrefix',
  'themeCompose',
  'themeNoCache',
  'tooltip',
  'tooltipProps',
  // Disable is not valid attribute for <a> and should not be inserted into dom:
  // https://dev.w3.org/html5/html-author/#the-a-element
  // Will be replaced with aria-disabled automatically
  'disabled',
]);

function getElementProps(props: LinkPropsIn) {
  const result: ComponentPropsWithRef<'a'> = {};

  if (props.href) {
    // Default rel to prevent pages from abusing window.opener
    result.rel = 'noopener noreferrer';
  }

  if (props.disabled) {
    // Apply aria-disabled attribute to element and don't set href (below) if link is disabled
    // https://css-tricks.com/how-to-disable-links/
    result['aria-disabled'] = true;
    result.tabIndex = -1; // If link is disabled, make it nontabable (but focusable with .focus())
  }

  let key: keyof typeof props;

  // for..in plus other checks is still the fastest way to filter object
  for (key in props) {
    if (props.hasOwnProperty(key) && !elementExcludeProps.has(key) && (key !== 'href' || !props.disabled)) {
      // this assertion assumes we correctly exclude props that are not in <a>.props
      // which might be wrong if `elementExcludeProps` isn't maintained properly
      result[key as keyof typeof result] = props[key];
    }
  }

  return result;
}

// Get from props name, params or href
function getTargetProps(props: LinkPropsIn, router: AppContextValue['router']) {
  if (props.href) {
    return {href: props.href};
  }

  const name = props.to ? (props.to.startsWith('app.') ? props.to : `app.${props.to}`) : props.route.name;
  let params = props.to ? props.params : props.mergeParams ? {...props.route.params, ...props.params} : props.params;

  const route = router.routesMap.get(name);

  if (!route) {
    console.log(`Route '${name}' is not found in the routes definitions`);
  }

  // If defaultParams for this route is specified, merge passed params with it
  if (typeof route?.autoParams === 'object') {
    params = {...route.autoParams, ...params};
  }

  return {name, params: processParams(params)};
}
