/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import PubSub from 'pubsub';
import {useState, useCallback, useContext, useRef, useMemo} from 'react';
import intl from 'intl';
import {object, string, array} from 'yup';
import {AttributeList, Button, Form, ModalMachine, Modal, Notifications} from 'components';
import ScopePicker from '../Scope/ScopePicker';
import {AppContext} from 'containers/App/AppUtils';
import {createRuleset, updateRuleset, fetchRulesetItem, fetchRulesetFacet} from '../RulesetItemSaga';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {getModalConfig} from './RulesetSummaryUtils';
import {getErrorMessage} from '../RulesetItemUtils';
import {getId} from 'utils/href';
import {formatDataReference} from 'utils/dataValidation';

export default function RulesetSummaryEditModal(props) {
  const {children, hideScopePicker, pversion = 'draft', scopeIsRequired} = props;
  const [rulesetData, setRulesetData] = useState();
  const [showModal, setShowModal] = useState(false);
  const {
    fetcher,
    navigate,
    store: {prefetcher},
  } = useContext(AppContext);

  const formikRef = useRef({});
  const scopeRef = useRef([]);

  const existingNameErrorRef = useRef();
  const facetFetchTaskRef = useRef();

  const errorNotificationRef = useRef();

  const [isDuplicate, isEdit] = useMemo(
    () => [Boolean(rulesetData && !rulesetData.rulesetId), Boolean(rulesetData?.rulesetId)],
    [rulesetData],
  );

  const schemas = useMemo(
    () =>
      object({
        scope: array().when((_, schema) => (scopeIsRequired ? schema.required(Form.emptyMessage) : schema)),
        name: string()
          .max(255, intl('Common.NameIsTooLong'))
          .test(
            'is-duplicate',
            val => intl('Common.NameExist', {value: val?.value}),
            () => !existingNameErrorRef.current,
          )
          .required(Form.emptyMessage),
        description: string().max(255, intl('Common.NamespaceIsTooLong', {namespace: intl('Common.Description')})),
      }),
    [scopeIsRequired],
  );

  const handleOpen = useCallback(rulesetData => {
    setRulesetData(rulesetData);
    setShowModal(true);
  }, []);

  const handleSave = useCallback(() => {
    PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});

    const {name, description} = formikRef.current.values ?? {};

    const {rulesetId, ...data} = rulesetData ?? {};
    const scopePayload = scopeRef.current.map(({exclusion = false, label, label_group}) => ({
      exclusion,
      [label_group ? 'label_group' : 'label']: {href: label?.href ?? label_group.href},
    }));

    const payload = {
      params: {pversion, ...(rulesetId && {rule_set_id: rulesetId})},
      data: isEdit || isDuplicate ? {...data, name, description} : {name, description, scopes: [scopePayload]},
    };

    if (isEdit && payload.data.external_data_reference) {
      payload.data.external_data_reference = formatDataReference(payload.data.external_data_reference);
    }

    return fetcher.spawn(isEdit ? updateRuleset : createRuleset, payload);
  }, [fetcher, pversion, rulesetData, isEdit, isDuplicate]);

  const handleScopeChange = useCallback(newScope => {
    const {setFieldValue} = formikRef.current;

    scopeRef.current = newScope;

    setFieldValue('scope', newScope);
  }, []);

  // Determine if name already exists
  const isNameEqual = useCallback(
    (existingNames = [], value) => {
      // Allow User to change name regardless of casesensitive
      return (
        value.localeCompare(rulesetData?.name ?? '', intl.locale, {sensitivity: 'base'}) !== 0 &&
        existingNames.some(name => !name.localeCompare(value, intl.locale, {sensitivity: 'base'}))
      );
    },
    [rulesetData],
  );

  const handleSaveDone = useCallback(
    (_, event) => {
      if (event.data) {
        // create ruleset saga was invoked that returned a payload, navigate to created ruleset detail page
        navigate({
          to: 'rulesets.item',
          params: {id: getId(event.data.data.href), pversion: 'draft', tab: 'intrascope'},
        });

        return Promise.resolve();
      }

      // update saga was invoked (API PUT), refetch in this case
      return fetcher.fork(fetchRulesetItem.refetch);
    },
    [navigate, fetcher],
  );

  // Set Existing Ruleset Name
  const handleSetExistingName = useCallback(() => {
    const {setFieldTouched, validateForm, touched} = formikRef.current;

    existingNameErrorRef.current = true;

    // Set touched immediately to show name clash error without waiting for onBlur
    if (!touched.name) {
      setFieldTouched('name', true);
    } else {
      // Call to validate formik's yup schemas
      validateForm();
    }
  }, []);

  // Use debounce to wait
  const handleNameChangeDebounce = useCallback(
    async value => {
      const {setFieldError} = formikRef.current;

      value = value.trim();

      if (value && rulesetData?.name !== value) {
        if (facetFetchTaskRef.current) {
          // Cancel task if it is still running
          fetcher.cancel(facetFetchTaskRef.current);
        }

        facetFetchTaskRef.current = fetcher.fork(fetchRulesetFacet, {
          query: {facet: 'name', query: value, max_results: 1},
          params: {pversion: 'draft'},
        });

        try {
          const {data} = await facetFetchTaskRef.current;

          if (isNameEqual(data?.matches, value)) {
            handleSetExistingName();

            return;
          }
        } catch (error) {
          setFieldError('name', error.message ?? error);
        }
      }
    },
    [fetcher, isNameEqual, rulesetData, handleSetExistingName],
  );

  // Handle the input field
  const handleNameChange = useCallback(
    evt => {
      const {setFieldValue, validateForm} = formikRef.current;
      const value = evt.target.value;

      // Update the Form.Input name value since this component is controlling
      setFieldValue('name', value);

      // Reset the existingNameError here to prevent seeing the deleted character delay
      if (existingNameErrorRef.current) {
        existingNameErrorRef.current = false;
        // Call to validateForm formik's schema
        validateForm();
      }

      // Don't need to call debounce when value is empty
      if (value.trim()) {
        // Note: Invoke debounce here to delay after calling setFieldValue for formik's values to update properly
        handleNameChangeDebounce(value);
      }
    },
    [handleNameChangeDebounce],
  );

  const handleClose = useCallback(async () => {
    if (prefetcher.formIsDirty) {
      // Cancel confirmation is mounted if form has changes
      const answer = await new Promise(resolve =>
        PubSub.publish('UNSAVED.WARNING', {
          resolve,
          ...getDiscardChangesModalProps(),
        }),
      );

      if (answer === 'cancel') {
        return;
      }

      PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
    }

    setShowModal(false);
    errorNotificationRef.current = null;
  }, [prefetcher]);

  const handleScopeTouched = useCallback(() => {
    const {setFieldTouched, touched} = formikRef.current;

    if (!touched.scope) {
      setFieldTouched('scope', true);
    }
  }, []);

  const renderForm = (context, send, options) => {
    formikRef.current = options;

    return (
      <AttributeList>
        {[
          (hideScopePicker && !scopeIsRequired) || rulesetData
            ? null
            : {
                key: <Form.Label name="scope" title={intl('Common.Scope')} />,
                value: (
                  <ScopePicker
                    name="scope"
                    errorMessage={options.touched.scope && options.errors.scope ? '' : undefined}
                    onScopeChange={handleScopeChange}
                    inputProps={{onBlur: handleScopeTouched}}
                  />
                ),
              },
          {
            key: <Form.Label name="name" title={intl('Common.Name')} />,
            value: (
              <Form.Input
                name="name"
                tid="name"
                placeholder={intl('Rulesets.CreatePage.Placeholder.RulesetName')}
                onChange={handleNameChange}
              />
            ),
          },
          {
            key: <Form.Label name="description" title={intl('Common.Description')} />,
            value: (
              <Form.Textarea
                name="description"
                tid="description"
                placeholder={intl('Rulesets.CreatePage.Placeholder.RulesetDescription')}
              />
            ),
          },
        ]}
      </AttributeList>
    );
  };

  const footer = (send, options, {matches, context: {onProgressDone}}) => (
    <Modal.Footer key="footer">
      <Button tid="cancel" color="standard" text={intl('Common.Cancel')} onClick={_.partial(send, {type: 'CANCEL'})} />
      <Button
        progressError={Boolean(errorNotificationRef.current)}
        progressCompleteWithCheckmark
        progress={matches('modal.submitting.progress')}
        tid="submit"
        text={intl('Common.Save')}
        disabled={formikRef.current.isValid === false}
        onClick={_.partial(send, {type: 'SUBMIT'})}
        onProgressDone={onProgressDone}
      />
    </Modal.Footer>
  );

  const renderModal = () =>
    showModal ? (
      <ModalMachine
        customConfig={getModalConfig}
        onClose={handleClose}
        services={{
          process: handleSave,
          onProcessDone: handleSaveDone,
        }}
        modalProps={{
          large: true,
          notResizable: true,
          dontRestrainChildren: true,
          idleOnBackdropClick: true,
          idleOnEsc: true,
        }}
      >
        {machine => {
          const {
            matches,
            context,
            context: {errors, notifications},
          } = machine;

          if (matches('modal.submitting')) {
            errorNotificationRef.current = null;
          }

          if (matches('modal.error') && !_.isEmpty(errors)) {
            errorNotificationRef.current = {
              type: 'error',
              message: getErrorMessage(errors),
            };
          }

          return {
            formProps: {
              schemas,
              initialValues: {
                scope: [],
                name: rulesetData?.name ?? '',
                description: rulesetData?.description ?? '',
              },
              isInitialValid: isDuplicate, // Duplicate ruleset (data exists but not id)
            },
            header: {
              props: {
                title: intl(
                  isEdit ? 'Rulesets.EditRuleset' : isDuplicate ? 'Rulesets.DuplicateRuleset' : 'Rulesets.Add',
                ),
              },
            },
            content: {
              props: {notScrollable: true},
              children: (send, options) => (
                <>
                  {(notifications.length > 0 || errorNotificationRef.current) && (
                    <Notifications>{[...notifications, errorNotificationRef.current]}</Notifications>
                  )}
                  {renderForm(context, send, options)}
                </>
              ),
            },
            footer,
          };
        }}
      </ModalMachine>
    ) : null;

  return (
    <>
      {children({handleOpen})}
      {renderModal()}
    </>
  );
}
