/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createStructuredSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {reverseProviderConsumer} from 'containers/App/AppState';
import {templateGridSettings} from './TemplatesWizardConfig';

export default {
  templatesWizardState(state = {}, action) {
    switch (action.type) {
      case 'SET_TEMPLATE_RULES':
        return action.data;
      default:
        return state;
    }
  },
};

export const getTemplatesWizardRules = state => state.templatesWizardState;
export const getAllowRulesRows = state => state.templatesWizardState.allowRules ?? [];
export const getDenyRulesRows = state => state.templatesWizardState.denyRules ?? [];

const getGrid = (settings, rows) => {
  return state => getGridSelector(state, {settings, rows});
};

export const getTemplateGrids = createStructuredSelector({
  allowRulesGrid: getGrid(templateGridSettings, getAllowRulesRows),
  denyRulesGrid: getGrid(templateGridSettings, getDenyRulesRows),
});

// best to try and keep referential equality for Grid's `component` prop
export const getComponentObj = createStructuredSelector({
  reverseProviderConsumer,
});
