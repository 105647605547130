/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from './FormUtils';
import {Selector} from 'containers';
import {getAllResourcesObject, isValuesMapEqual} from 'containers/Selector/SelectorUtils';

FormSelector.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  // By default, FormSelector calls Selector onSelectionChange method and set selection changes to form values
  // If you want to intercept selection change and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,

  // Might depend on other fields
  ...whenPropTypes,
};

export function FormSelector(props) {
  // Control field reset when dependecies are not true
  const {[forwardRefSymbol]: ref, onChange, form, field, ...selectorProps} = useWhen(props.form, props.field, props);

  const {name} = field;
  const initialValue = _.get(form.initialValues, name);
  // An error border will be displayed by default if the name for this input appears as a key in the errors object.
  const error = _.get(form.touched, name) === true && _.has(form.errors, name);
  // An error message will be displayed if the value for this inputs key is not undefined.
  const errorMessage = error && _.get(form.errors, name);

  if (errorMessage) {
    selectorProps.errorMessage = errorMessage;
  }

  selectorProps.ref = ref;

  if (initialValue) {
    // create a Map from entries
    selectorProps.values = new Map(initialValue);
  }

  selectorProps.inputProps = selectorProps.inputProps ?? {};
  selectorProps.inputProps.onBlur = () => {
    // Only call touched when hasn't been touched
    // User can click on selector but not make selection, thus set touched
    if (!form.touched[name]) {
      form.setFieldTouched(name, true);
    }
  };

  selectorProps.onSelectionChange = valuesMap => {
    if (onChange) {
      // If FormSelector is controlled, just call onChange prop and let parent decide what to do
      return onChange(valuesMap);
    }

    if (isValuesMapEqual(valuesMap, new Map(initialValue), getAllResourcesObject(selectorProps.categories))) {
      // If the selected options are equal to the default ones,
      // then revert to the default to keep object references, so formik can unmark it as unchanged
      form.setFieldValue(name, initialValue);
    } else {
      // Set the new value and mark it as touched,
      // Note - since the schema of FormSelector field is an array, spread Map
      form.setFieldValue(name, [...valuesMap]);
      form.setFieldTouched(name, true);
    }
  };

  return <Selector {...selectorProps} />;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormSelector} />, {
  hoistSource: FormSelector,
});
