/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {
  getAllUsersMap,
  isUserReadOnly,
  isUserReadOnlyAll,
  doesUserHaveGlobalObjectPermissions,
} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './LabelGroupListConfig';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isEdge} from 'containers/App/AppState';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getLabelGroups = state => state.labelGroup.list;
export const getLabelGroupsCount = state => state.labelGroup.count;

const getLabelGroupsRows = createSelector(
  [getLabelGroups, getAllUsersMap, isUserReadOnly, doesUserHaveGlobalObjectPermissions],
  (labelGroups, usersMap, userIsReadOnly, userHasGlobalObjectPermissions) =>
    labelGroups.map(item => ({
      key: item.href,
      // Service is in draft mode if it has pending status
      draft: Boolean(item.update_type),
      // Make Service created by system not selectable, since it cannot be removed or provisioned
      selectable: userHasGlobalObjectPermissions && (!item.created_by || item.created_by.href !== '/users/0'),
      // Service is removable only if it's active or with pending changes expect pending deletion
      removable: !userIsReadOnly && (!item.update_type || item.update_type !== 'delete'),
      // Fill each Service with user object
      data: {
        ...item,
        created_by: fillUserInfo(usersMap, item.created_by),
        updated_by: fillUserInfo(usersMap, item.updated_by),
      },
    })),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, isEdge, gridSettings],
  (userIsReadOnlyAll, isEdge, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnlyAll;
    columns.containerSettings.disabled = isEdge;

    return {...gridSettings, columns};
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLabelGroupsRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const getLabelGroupsPage = createSelector(
  [getGrid, getLabelGroupsCount, isUserReadOnly, getSelectorSettings],
  (grid, count, isUserReadOnly, selectorSettingsObject) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: Object.entries(grid.filter).map(([categoryKey, [value]]) => ({
        categoryKey,
        value,
        categoryName: selectorSettingsObject.filterMap[categoryKey],
      })),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
      statics: Object.entries(selectorSettingsObject.staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {grid, count, selector, isUserReadOnly};
  },
);
