/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {RedirectError} from 'errors';
import resetMiddleware from 'redux-reset';
import createSagaMiddleware from 'redux-saga';
import {router5Middleware} from 'redux-router5';
import {batchedSubscribe} from 'redux-batched-subscribe';
import {legacy_createStore as createStore, combineReducers, applyMiddleware, compose} from 'redux';

const RESET_ACTION_TYPE = 'RESET_REDUX_STATE';

export default function configureStore(router) {
  const sagaMiddleware = createSagaMiddleware({
    logger(level, ...args) {
      // Log info/warnings/errors, but not uncaught errors, they are handled by onError handler
      if (!args.some(arg => arg === 'uncaught' || (arg.includes && arg.includes('RedirectError')))) {
        console[level]('Saga', level, args.length > 1 ? args.join(' ') : args[0]);
      }
    },
    onError(error) {
      if (!(error instanceof RedirectError)) {
        console.warn('Uncaught exceptions from Saga', error);
      }
    },
  });

  const middlewares = [router5Middleware(router), sagaMiddleware];

  if (process.env.NODE_ENV !== 'production' && __LOG_ACTIONS__ === true) {
    middlewares.unshift(
      require('redux-logger').createLogger({
        predicate: (getState, payload) => {
          // Log when action is actually fired
          console.log(` %caction %c${payload.type} %cstart`, 'color:gray;', 'color:#6fa6bf;', 'color:gray;');

          // If true - will log when action ends (all reducers are set and new state is ready), return false to turn off
          return true;
        },
        colors: {
          title: () => '#1b81af',
          prevState: () => '#9E9E9E',
          action: () => '#03A9F4',
          nextState: () => '#4CAF50',
          error: () => '#F20404',
        },
        logErrors: true,
        collapsed: true,
        duration: true,
        timestamp: true,
      }),
    );
  }

  const middleware = applyMiddleware(...middlewares);
  const resetEnhancer = resetMiddleware(RESET_ACTION_TYPE);
  const composeEnhancer =
    __DEV__ && typeof window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  return class Store {
    batching = false;
    batchingLastNotify = null;

    constructor(reducer, initialState) {
      const enhancer = composeEnhancer(
        middleware,
        resetEnhancer,
        batchedSubscribe(notify => {
          if (this.batching) {
            this.batchingLastNotify = notify;
          } else {
            notify();
          }
        }),
      );

      this.reducers = reducer;
      this.injectedReducers = {};

      Object.assign(this, createStore(combineReducers(this.reducers), initialState, enhancer));

      this.runSaga = sagaMiddleware.run;
      this.router = router;
      router.setDependency('store', this);
    }

    // Adds functionality to add reducers on the fly. The main use case would be for routes
    // to inject reducers when their containers are requested.
    injectReducer = (key, reducer) => {
      if (!this.injectedReducers[key]) {
        this.injectedReducers[key] = reducer;
      }

      // Update reducer tree
      this.replaceReducer(this.createReducer(this.injectedReducers));
    };

    createReducer(injectedReducers) {
      return combineReducers({
        ...this.reducers,
        ...injectedReducers,
      });
    }

    reset(state) {
      this.dispatch({type: RESET_ACTION_TYPE, state});
    }

    batchStart(id) {
      if (!this.batching) {
        this.batching = id || true;
      }
    }

    batchEnd(notify, id) {
      if (!this.batching || (this.batching !== true && this.batching !== id)) {
        return;
      }

      this.batching = false;

      if (notify && this.batchingLastNotify) {
        this.batchingLastNotify();
      }

      this.batchingLastNotify = null;
    }
  };
}
