/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {AppContext} from 'containers/App/AppUtils';
import styles from './Selectors.css';
import * as PropTypes from 'prop-types';
import Input from './Input';
import OptionContainer from './OptionContainer';
import {Icon, TypedMessages} from 'components';
import {Component, cloneElement, createRef} from 'react';
import {reactUtils} from 'utils';
import {createNewObject} from './SelectorSaga';

export const ESCAPE = str => str.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&');
const MAX_SCROLL = 7;
const scrollTheme = {icon: styles.scrollCaret};
const labelKeys = new Set(['role', 'app', 'env', 'loc']);

export default class Selectors extends Component {
  static contextType = AppContext;
  static propTypes = {
    initialItems: PropTypes.array, // items pre-selected
    alwaysActive: PropTypes.bool, // whether selector stays open after you select something
    allowMultipleItems: PropTypes.bool, // allows more than one item to be selected
    clearAll: PropTypes.bool, // show 'x' button on the right to delete all items at once
    activeCategoryKey: PropTypes.string, // category key of currently open (active) category
    categories: PropTypes.array, // list of objects of categories
    facets: PropTypes.array, // list of facet keys (name, hostname)
    objects: PropTypes.array, // list of resource objects needed (i.e. workloads, labels)
    matches: PropTypes.object.isRequired, // list of values for the dropdown
    allowCreateTypes: PropTypes.array, // list of objects that allow you to create within the selector (labels, label_groups)
    dontAllowCreate: PropTypes.func, // function to not allow creation of certain objects.
    addValue: PropTypes.func, // not used anywhere, can be deleted
    placeholder: PropTypes.string, // global placeholder text to show in selector input bar

    onSelectionChange: PropTypes.func, // occurs after user selects/unselects items
    onCreate: PropTypes.func, // function from preset that calls create APIs
    onInputChange: PropTypes.func.isRequired, // function from preset that fetches data as user types
    onOpenCategory: PropTypes.func, // function to fetch data when user opens a new category

    // visual props
    footer: PropTypes.any, // custom footer to put at the bottom of the selector
    renderOption: PropTypes.func, // function to custom render options. needs further implementaion in Option.js probably
    maxResults: PropTypes.number, // max number of results to show in the dropdown at once. default is 5?
    showContainerTitle: PropTypes.bool, // whether to show "Name - 5 of 8 results" at the top of an open category
    scrollable: PropTypes.bool, // whether dropdown is scrollable. MAX_SCROLL constant is the number of categories
    disabled: PropTypes.bool, // selector is grayed out and not clickable

    // props for MultiGroupSingleItemSelector
    hideCategory: PropTypes.bool, // whether to remove a category after an option has been selected from it
    statics: PropTypes.object, // categories that have static data (prefetched)
    partials: PropTypes.array, // list of categories where you can do partial search (see Workloads Name in list page)
    customPickers: PropTypes.object, // custom render a picker (like date time picker)
    showFacetName: PropTypes.bool, // whether to show facet name in selected item ("Name: workload 1")

    // props for MultiGroupMultiItemSelector
    hideSelectedOption: PropTypes.bool, // if you want to show option even after it's been selected
    hiddenCategories: PropTypes.array, // predetermined categories that are hidden initially and only show when "show All" is clicked

    // global settings
    displayAllCategories: PropTypes.bool, // display all categories in any condition

    // An error message to show but by default it is isn't shown unless showError is set to true
    // Passing in boolean[true | false] will not show errorMessage, useful when used in Form
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disableErrorMessage: PropTypes.bool,

    loading: PropTypes.bool,
  };

  static defaultProps = {
    alwaysActive: false,
    allowMultipleItems: false,
    customPickers: {},
    clearAll: false,
    facets: [],
    loading: false,
    hideCategory: false,
    hideSelectedOption: true,
    hiddenCategories: [],
    displayAllCategories: false,
    objects: [],
    partials: [],
    showContainerTitle: true,
    disableErrorMessage: false,
    maxResults: 5,
    onCreate: _.noop,
    onSelectionChange: _.noop,
  };

  constructor(props) {
    super(props);

    const {activeCategoryKey, categories, hideCategory, displayAllCategories, initialItems, matches, scrollable} =
      props;
    const filteredCategories = getFilteredCategoriesByItems(categories, initialItems, displayAllCategories);

    this.state = {
      active: false,
      activeCategoryKey: hideCategory
        ? findActiveCategoryKey(filteredCategories, activeCategoryKey)
        : activeCategoryKey,
      activeCustomPicker: {},
      categories: filteredCategories,
      debouncing: false,
      input: '',
      items: initialItems || [],
      matches,
      showSelectedOption: false,
      showAllCategories: false,
      showUpScrollbar: false,
      showDownScrollbar: scrollable,
    };

    this.focusedIndex = -1;
    this.optionList = [];
    this.refMap = new Map();
    this.selector = createRef();
    this.scrollContainer = createRef();
    this.pageInvoker = createRef();
    this.debouncedOnInputChange = _.debounce((input, object, facet) => {
      this.setState({
        debouncing: false,
      });
      this.props.onInputChange(input, object, facet, this.state.items);
    }, 175);
    this.fetchInputMatches = this.fetchInputMatches.bind(this);

    this.saveInputRef = this.saveInputRef.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.optionsRef = this.optionsRef.bind(this);
    this.handleShowScrollbars = this.handleShowScrollbars.bind(this);
    this.handleCustomPickerAdd = this.handleCustomPickerAdd.bind(this);
    this.handleCustomPickerOpen = this.handleCustomPickerOpen.bind(this);
    this.handleCustomPickerClose = this.handleCustomPickerClose.bind(this);
    this.handleScrollDown = this.handleScrollDown.bind(this);
    this.handleScrollUp = this.handleScrollUp.bind(this);
    this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleCaretToggle = this.handleCaretToggle.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleOpenCategory = this.handleOpenCategory.bind(this);
    this.handleClearItems = this.handleClearItems.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleShowAll = this.handleShowAll.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {matches, initialItems, hideCategory} = nextProps;
    const newState = {initialItems};

    if (!_.isEqual(matches, prevState.matches)) {
      newState.matches = _.cloneDeep(matches);
      newState.showUpScrollbar = false;
      newState.showDownScrollbar = true;
    }

    if (!_.isEqual(initialItems, prevState.initialItems)) {
      if (nextProps.hideCategory) {
        const categories = getFilteredCategoriesByItems(
          nextProps.categories,
          nextProps.initialItems,
          nextProps.displayAllCategories,
        );
        const activeCategoryKey = findActiveCategoryKey(categories, nextProps.activeCategoryKey);

        newState.categories = categories;
        newState.activeCategoryKey = activeCategoryKey;
      }

      // Do not reset state.items when initialItems and state.items are the same.
      // When nextProps.initialItems is not explicitly passed in
      // this check will make sure this.state.items is not overwritten with state.initialItem default value
      if (!_.isEqual(initialItems, prevState.items)) {
        newState.items = initialItems;
      }

      newState.matches = matches;
    }

    if (!hideCategory && !_.isEqual(nextProps.categories, prevState.categories)) {
      newState.categories = nextProps.categories;

      if (nextProps.activeCategoryKey !== prevState.prevPropActiveCategoryKey) {
        newState.prevPropActiveCategoryKey = nextProps.activeCategoryKey;
        newState.activeCategoryKey = nextProps.activeCategoryKey;
      }
    }

    return newState;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.matches !== prevState.matches && this.state.active) {
      this.input.focus();
    }

    if (typeof this.props.onFocus === 'function' && prevState.active !== this.state.active) {
      this.props.onFocus();
    }

    if (this.state.items !== prevState.items) {
      this.props.onSelectionChange(this.state.items);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
  }

  getOptionTotal(matches, statics, key, input) {
    if (statics && statics[key] && !this.state.input.length) {
      return statics[key].length;
    }

    if (matches[key]) {
      let less = 0; // It is used to reduce the count of unlabeledMap and allLabelsMap in ScopeSelector if those labels don't pass the input filter
      const {categories} = this.state;
      const category = categories.find(category => category.categoryKey === key) || {};

      if (category.statics && matches[key]) {
        const regex = new RegExp(ESCAPE(input), 'i');

        matches[key].matches.forEach(option => {
          const matched = option.name ? option.name.match(regex) : option.value.match(regex);

          if (!matched) {
            less++;
          }
        });
      }

      return matches[key].count - less;
    }

    return matches.count;
  }

  getOptions(key, input) {
    const {categories, matches, items} = this.state;
    const {statics, customPickers} = this.props;
    const regex = new RegExp(ESCAPE(input), 'i');

    if (statics && statics[key]) {
      return statics[key].reduce((result, option) => {
        const primaryMatch = option.name ? option.name.match(regex) : option.value.match(regex);
        const secondaryMatch = option.desc ? option.desc.match(regex) : false;

        if (primaryMatch || secondaryMatch) {
          // If we have an exact match add it to the front of the list so that it appears at the top of dropdown options.
          if ((primaryMatch && primaryMatch.input === input) || (secondaryMatch && secondaryMatch.input === input)) {
            result.unshift(option);
          } else {
            result.push(option);
          }
        }

        return result;
      }, []);
    }

    if (customPickers[key]) {
      return [];
    }

    const category = categories.find(category => category.categoryKey === key) || {};

    if (category.statics) {
      return (
        matches[key] &&
        matches[key].matches.filter(option => (option.name ? option.name.match(regex) : option.value.match(regex)))
      );
    }

    if (category.freeSearch) {
      return category.validate ? category.validate(category.categoryKey, input, items) : [{value: input}];
    }

    return matches[key] ? matches[key].matches : matches.matches;
  }

  getElement(focused) {
    return focused.option;
  }

  getFocusedIndex(option) {
    return this.optionList.indexOf(option);
  }

  // if value is a scope label, we want to prepend
  // if value is a normal value, we just append to the items array
  getNewItems(items, categoryName, categoryKey, value) {
    if (value.scope) {
      return [{categoryName, categoryKey, ...value}, ...items];
    }

    return [...items, {categoryName, categoryKey, ...value}];
  }

  // Find category based tooltip to display
  getTooltip(items, value, tooltipProps, key) {
    if (tooltipProps) {
      if (typeof tooltipProps.content === 'function') {
        const categoryItems = items.filter(item => item.categoryKey === key);
        const content = tooltipProps.content(categoryItems, value);

        if (content) {
          return {...tooltipProps, content};
        }
      }

      if (typeof tooltipProps.content === 'string') {
        const {appearWhen, ...props} = tooltipProps;

        if (appearWhen && items.some(item => item.categoryKey === appearWhen)) {
          return props;
        }
      }
    }

    return null;
  }

  saveInputRef(input) {
    this.input = input;
  }

  async handleSave(categoryKey, resource, data) {
    const created = await this.context.store
      .runSaga(createNewObject, resource, data.payload ?? data, {query: ''}, {pversion: 'draft'})
      .toPromise();

    await reactUtils.setStateAsync(
      prevState => ({
        items: [
          ...prevState.items,
          {
            categoryKey,
            categoryName: prevState.categories.find(({categoryKey: key}) => key === categoryKey).categoryName,
            ...created,
          },
        ],
      }),
      this,
    );
  }

  handleCustomPickerAdd(value) {
    this.handleSelect(value, this.state.activeCategoryKey);
    this.setState({activeCustomPicker: {}});
  }

  handleCustomPickerOpen(option) {
    this.setState({activeCustomPicker: {key: option.value}});
  }

  handleCustomPickerClose() {
    const {objects, onInputChange} = this.props;
    const {
      activeCustomPicker: {isCategory},
      categories,
      items,
    } = this.state;

    // if customPicker is at category level, close selector on customPicker Cancel
    // if customPicker is at option level, close customPicker but leave selector open
    if (isCategory) {
      const activeCategoryKey = findActiveCategoryKey(categories, this.props.activeCategoryKey);

      this.setState({active: false, activeCategoryKey, activeCustomPicker: {}, input: ''});

      // find API resource pertaining to first active category
      const object = objects.find(obj => obj.type === activeCategoryKey) || objects[0];

      if (object) {
        onInputChange('', object, activeCategoryKey, items);
      }
    } else {
      this.setState({activeCustomPicker: {}});
    }
  }

  handleScrollDown() {
    if (!this.state.showDownScrollbar) {
      return;
    }

    if (this.focusedIndex === -1) {
      this.scrollContainer.current.scrollTop += 10;
    } else {
      this.focusNextOption(this.focusedIndex + 1, this.optionList, false);
    }
  }

  handleScrollUp() {
    if (!this.state.showUpScrollbar) {
      return;
    }

    if (this.focusedIndex === -1) {
      this.scrollContainer.current.scrollTop -= 10;
    } else {
      this.focusNextOption(this.focusedIndex - 1, this.optionList, true);
    }
  }

  handleDocumentMouseDown(evt) {
    if (this.selector && !this.selector.current.contains(evt.target) && !this.pageInvoker.current) {
      this.setState({active: false});
      this.focusedOption = null;
      this.focusedIndex = -1;
    }
  }

  handleKeyDown(evt, object) {
    const {focusedIndex, optionList} = this;
    const {allowCreateTypes, customPickers, objects, onInputChange, partials} = this.props;
    const {active, items, input, activeCategoryKey} = this.state;

    if (evt.key === 'Tab' || evt.key === 'Escape') {
      this.setState({active: false});

      return;
    }

    // if input exists & backspace is pressed, or user is typing input, return
    if (
      (evt.key === 'Backspace' && (input || items.length === 0)) ||
      ![' ', 'Backspace', 'ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(evt.key) ||
      (evt.key === ' ' && focusedIndex === -1)
    ) {
      this.focusedIndex = -1;

      // prevent user from typing space as the beginning of their search string
      if (evt.key === ' ' && !input.length) {
        evt.preventDefault();
      }

      return;
    }

    evt.preventDefault();

    switch (evt.key) {
      case 'Backspace':
        const value = items.pop();

        if (value) {
          this.handleItemDelete(evt, value, object);
        }

        break;
      case 'ArrowDown':
        if (!active) {
          objects.forEach(object => onInputChange('', object, activeCategoryKey, items));
          this.focusedIndex = -1;
          this.setState({active: true});

          return;
        }

        this.focusNextOption(focusedIndex + 1, optionList, false);

        break;
      case 'ArrowUp':
        if (focusedIndex === -1 && active) {
          this.setState({active: false});

          return;
        }

        if (focusedIndex === 0) {
          return;
        }

        this.focusNextOption(focusedIndex - 1, optionList, true);

        break;
      case ' ':
      case 'Enter':
        if (!active) {
          objects.forEach(object => onInputChange('', object, activeCategoryKey, items));
          this.setState({active: true});

          return;
        }

        let focused = null;

        if (optionList[focusedIndex] && optionList[focusedIndex].props) {
          focused = optionList[focusedIndex].props.value || optionList[focusedIndex].props.category;
        }

        if (!focused) {
          let firstSelectable;
          const partialOption = partials.includes(activeCategoryKey) && input.length ? this.optionList[0] : null;

          if (partialOption) {
            firstSelectable = partialOption.props.value;
          } else {
            const options = this.getOptions(activeCategoryKey, input) || [];

            firstSelectable = options.find(option => !this.isValueIncluded(items, option));
          }

          if (firstSelectable) {
            this.handleSelect(firstSelectable, activeCategoryKey);
          } else if (allowCreateTypes && allowCreateTypes.includes(activeCategoryKey)) {
            this.handleCreate(input, activeCategoryKey);
          } else {
            onInputChange('', object, activeCategoryKey, items);
          }
        } else if (optionList[focusedIndex] && focusedIndex < optionList.length) {
          const option = optionList[focusedIndex];

          if (option.props.isCategory && (this.getOptions(focused.categoryKey, '') || option.props.value.freeSearch)) {
            evt.preventDefault();
            this.handleOpenCategory(focused.categoryKey);
          } else if (focused.categoryKey === 'show_all_categories') {
            evt.preventDefault();
            this.handleShowAll();
          } else if (!option.props.isCategory) {
            const included = this.isValueIncluded(items, focused);

            if (included) {
              this.handleItemDelete(evt, focused, activeCategoryKey);
            } else if (customPickers[option.props.value.value]) {
              this.handleCustomPickerOpen({value: option.props.value.value});
            } else {
              this.handleSelect(focused, activeCategoryKey);
            }
          } else {
            this.handleSelect(focused, focused.categoryKey);
          }
        }

        this.setState({input: ''});
        this.focusedIndex = -1;
        this.focusedOption = null;

        break;
    }
  }

  handleCaretToggle(evt) {
    evt.stopPropagation();
    this.input.focus();

    if (!this.state.active) {
      this.props.objects.forEach(object =>
        this.props.onInputChange('', object, this.state.activeCategoryKey, this.state.items),
      );
    }

    this.setState(state => ({active: !state.active}));
  }

  handleFocus() {
    const {active, activeCategoryKey, items} = this.state;
    const {hiddenCategories, removeShowAllCategories} = this.props;

    // if current activeCategoryKey is in hiddenCategories, do not show any dropdown
    if (removeShowAllCategories && hiddenCategories.includes(activeCategoryKey)) {
      return;
    }

    this.focusedIndex = -1;
    this.focusedOption = null;

    this.input.focus();

    if (!active) {
      this.props.objects.forEach(object =>
        this.props.onInputChange(this.input.value, object, activeCategoryKey, items),
      );
    }

    this.setState({active: true});
  }

  handleOpenCategory(categoryKey) {
    const {objects, onOpenCategory, customPickers} = this.props;
    const object = objects.find(
      obj => obj.type === this.state.activeCategoryKey || obj.key === this.state.activeCategoryKey,
    );

    // clear input for previously open category
    this.handleInputChange('', object, this.state.activeCategoryKey);

    if (onOpenCategory) {
      onOpenCategory(categoryKey, this.state.items);
    }

    this.input.focus();

    const newState = {activeCategoryKey: categoryKey};

    if (customPickers[categoryKey]) {
      newState.activeCustomPicker = {key: categoryKey, isCategory: true};
      newState.showUpScrollbar = false;
      newState.showDownScrollbar = true;
    }

    this.setState(newState);
  }

  handleHover(element) {
    this.focusedIndex = this.getFocusedIndex(element);

    element.option.focus();
    this.focusedOption = element.option;
  }

  async handleSelect(value, categoryKey) {
    const {
      clearItemsOnSelect,
      alwaysActive,
      allowMultipleItems,
      hideCategory,
      hideSelectedOption,
      matches,
      objects,
      facets,
      onOpenCategory,
      onInputChange,
    } = this.props;
    const newMatches = _.cloneDeep(this.state.matches);
    const categories = this.state.categories.map(category => ({...category}));
    const category = categories.find(category => category.categoryKey === categoryKey);
    const categoryName = category ? category.value : null;
    let {activeCategoryKey, input} = this.state;
    let newItems;

    if (_.isPlainObject(value)) {
      value.scope = category && category.scope;
    }

    if (allowMultipleItems) {
      newItems = this.getNewItems(this.state.items, categoryName, categoryKey, value);
    } else {
      // if overwriting an item selected, reset its category's matches & unhide its category
      if (this.state.items.length) {
        const oldCategoryKey = this.state.items[0].categoryKey;

        if (oldCategoryKey && newMatches[oldCategoryKey]) {
          newMatches[oldCategoryKey].matches = matches[oldCategoryKey].matches;
        }

        const oldCategory = categories.find(c => c.categoryKey === oldCategoryKey);

        if (typeof oldCategory === 'object') {
          oldCategory.hidden = false;
        }

        activeCategoryKey = findActiveCategoryKey(categories);
      }

      newItems = this.getNewItems([], categoryName, categoryKey, value);
    }

    // remove selected item from match list
    if (matches[categoryKey] && hideSelectedOption) {
      const filteredMatches = matches[categoryKey].matches.filter(match => !this.isValueIncluded(newItems, match));

      newMatches[categoryKey].matches = filteredMatches;
    }

    const isHidden =
      hideCategory ||
      facets.includes(categoryKey) ||
      (!_.get(newMatches[categoryKey], 'matches') &&
        this.state.categories.length > 1 &&
        !category.freeSearch &&
        !category.isStatic);

    // hide category after selected if category is empty or user specifies.
    if (isHidden) {
      const category = categories.find(c => c.categoryKey === categoryKey);

      if (typeof category === 'object') {
        category.hidden = true;
      }

      activeCategoryKey = findActiveCategoryKey(categories);
    }

    const active = !hideSelectedOption || alwaysActive;

    let stateUpdateItems = {
      active,
      input: '',
      categories,
      activeCategoryKey,
      matches: newMatches,
    };

    // When a new item is created the default 'value ===`${object}Create` defined in handleInputChange()
    if (typeof value === 'string' && value.includes('Create') && input) {
      // call handleCreate to create the new item by calling backend API
      this.handleCreate(input, activeCategoryKey);
    } else {
      // Only update 'state.items : newItems' for existing item NOT for newly created item.
      // e.g. Existing newItems
      //  newItems === {categoryKey: "labels"
      //                categoryName: "Labels"
      //                href: "/orgs/1/labels/25"
      //                key: "app"
      //                value: "coolios"}

      // Apply any category specific condition filters e.g. workloads cannot be selected if All Workloads is selected
      if (category.filterItems) {
        newItems = category.filterItems(this.state.items, newItems);

        // Insure hidden categories are reflected correctly in the event
        // of item deletion via filterItems callback.
        stateUpdateItems.categories.forEach(category => {
          if (category.hidden && !newItems.some(item => item.categoryKey === category.categoryKey)) {
            category.hidden = false;
          }
        });
      }

      stateUpdateItems = {...stateUpdateItems, items: newItems};
    }

    // Update the state to show the current Selector Component immediately by calling the render()
    // especially 'state.items : newItems'.
    // Example:
    //  When User uses the keyboard to click on 'Enter' the label will show immediately for existing item.
    await reactUtils.setStateAsync(() => stateUpdateItems, this);
    this.input.focus();
    this.focusedOption = null;
    this.focusedIndex = -1;

    if (clearItemsOnSelect) {
      await reactUtils.setStateAsync({items: []}, this);
    }

    if (onOpenCategory) {
      onOpenCategory(activeCategoryKey, this.state.items);
    }

    const object = objects.find(obj => obj.type === categoryKey);

    if (object) {
      onInputChange('', object, categoryKey, this.state.items);
    }
  }

  handleClearItems(evt) {
    const {objects, onInputChange, onOpenCategory} = this.props;

    if (evt) {
      evt.stopPropagation();
    }

    const items = [];
    const categories = this.props.categories.map(category => ({...category}));
    const activeCategoryKey = findActiveCategoryKey(categories, this.props.activeCategoryKey);
    const object = objects.find(obj => obj.type === activeCategoryKey) || objects[0];

    if (onOpenCategory) {
      onOpenCategory(activeCategoryKey, items);
    }

    if (
      !this.state.active ||
      (Array.isArray(this.state.items) && this.state.items.some(item => labelKeys.has(item?.key)))
    ) {
      objects.forEach(object => onInputChange('', object, activeCategoryKey, items));
    } else if (this.state.input.length === 0) {
      onInputChange('', object, activeCategoryKey, items);
    }

    this.setState(() => ({items, active: true, activeCategoryKey, categories}));
  }

  handleItemDelete(evt, value, object) {
    const {hideCategory, onInputChange, onOpenCategory, objects} = this.props;
    let {activeCategoryKey, items, categories, matches, input} = this.state;
    let newMatches = matches;
    const {categoryKey} = value;

    if (evt) {
      evt.stopPropagation();
    }

    if (matches[categoryKey] && !this.isValueIncluded(matches[categoryKey].matches, value) && input.length === 0) {
      // re-insert item into the match list at correct index only if user input is empty
      const index = this.props.matches[categoryKey].matches.findIndex(match => this.areItemsEqual(match, value));

      newMatches = _.cloneDeep(matches);
      newMatches[categoryKey].matches.splice(index, 0, value);
    }

    let category = categories.find(c => c.categoryKey === categoryKey) || {};

    // when item is deleted, add back its category if it was hidden
    if (hideCategory || category.hidden) {
      categories = categories.map(category => ({...category}));
      category = categories.find(c => c.categoryKey === categoryKey);
      category.hidden = false;
      activeCategoryKey = findActiveCategoryKey(categories, this.props.activeCategoryKey);
    }

    items = items.filter(item => !this.areItemsEqual(item, value));

    if (onOpenCategory) {
      onOpenCategory(activeCategoryKey, items);
    }

    if (!this.state.active || (value?.key && labelKeys.has(value.key))) {
      objects.forEach(object => onInputChange('', object, activeCategoryKey, items));
    } else if (input.length === 0) {
      onInputChange('', object, activeCategoryKey, items);
    }

    this.setState(() => ({items, active: true, activeCategoryKey, categories, matches: newMatches}));
  }

  handleCreate(input, categoryKey) {
    const {objects, onCreate} = this.props;
    const object = objects.find(obj => obj.type === categoryKey);

    onCreate(input, object, this.state.items);
    this.input.focus();
    this.setState({active: false, input: ''});
  }

  handleInputChange(input, object, facet) {
    const {allowMultipleItems} = this.props;

    this.handleShowScrollbars();

    if (allowMultipleItems || this.state.items.length === 0) {
      if (object) {
        this.fetchInputMatches(input, object, facet);
      }

      this.setState({input, active: true, inputValue: `${object}Create`});
      this.focusedOption = null;
      this.fousedIndex = -1;
    }
  }

  handleShowAll() {
    const categories = [...this.state.categories];
    const showAll = categories.find(category => category.categoryKey === 'show_all_categories');

    showAll.hidden = true;

    this.input.focus();
    this.setState(state => ({showAllCategories: !state.showAllCategories, categories}));
  }

  handleShowScrollbars() {
    if (!this.scrollContainer || !this.scrollContainer.current) {
      this.setState({showUpScrollbar: false, showDownScrollbar: true});

      return;
    }

    const boundingClientRect = this.scrollContainer.current.getBoundingClientRect();
    const childNodes = this.scrollContainer.current.childNodes;
    let hiddenAbove = false;
    let hiddenBelow = false;

    Array.from(childNodes).forEach(node => {
      const nodeRect = node.getBoundingClientRect();

      if (nodeRect.top < boundingClientRect.top) {
        hiddenAbove = true;
      }

      if (nodeRect.top > boundingClientRect.bottom) {
        hiddenBelow = true;
      }
    });

    const stateObj = {};
    let stateChange;

    if (this.state.showUpScrollbar !== hiddenAbove) {
      stateObj.showUpScrollbar = hiddenAbove;
      stateChange = true;
    }

    if (this.state.showDownScrollbar !== hiddenBelow) {
      stateObj.showDownScrollbar = hiddenBelow;
      stateChange = true;
    }

    if (stateChange) {
      this.setState(stateObj);
    }
  }

  fetchInputMatches(...args) {
    this.setState(({debouncing}) => (debouncing ? null : {debouncing: true}));
    this.debouncedOnInputChange(...args);
  }

  focusNextOption(newIndex, optionList, arrowUp) {
    if (newIndex >= optionList.length) {
      return;
    }

    let foundIndex;

    // if user clicked up arrow, we search for options right to left, using findLastIndex
    // if user clicked down, search for options left to right, using findIndex
    if (arrowUp) {
      foundIndex = _.findLastIndex(
        optionList,
        option => {
          this.focusedOption = option;

          return option ? this.getElement(option) : false;
        },
        newIndex,
      );
    } else {
      foundIndex = _.findIndex(
        optionList,
        option => {
          this.focusedOption = option;

          return option ? this.getElement(option) : false;
        },
        newIndex,
      );
    }

    if (foundIndex > -1) {
      const node = this.getElement(optionList[foundIndex]);

      node.focus({preventScroll: true});
      node.scrollIntoView({behavior: 'auto', block: 'nearest', inline: 'nearest'});

      this.focusedIndex = foundIndex;
    }
  }

  areItemsEqual(first, second) {
    if (first.href && second.href) {
      return first.href === second.href;
    }

    if (first.value && second.value) {
      return first.value === second.value && first.categoryKey === second.categoryKey;
    }

    if (first.name && second.name) {
      return first.name === second.name && first.categoryKey === second.categoryKey;
    }

    return first === second;
  }

  isValueIncluded(items, match) {
    return items.some(item => this.areItemsEqual(item, match));
  }

  optionsRef(item) {
    const {hiddenCategories, maxResults, partials} = this.props;
    const {activeCategoryKey, categories, input, inputValue, showAllCategories} = this.state;
    const category = categories.find(category => category.categoryKey === item.props.categoryKey);
    const value = item.props.value;

    this.refMap.set(value.href || value.value || value.name || value, item);

    let options = this.getOptions(activeCategoryKey, input) || [];

    options = options.slice(0, maxResults);

    // generate option element list from backend data (unless it's freeSearch and data is user-generated)
    this.optionList =
      category?.freeSearch && !options.length
        ? this.optionList
        : options.map(option => this.refMap.get(option.href || option.value || option.name || option));

    // if user input corresponds to a create option, add to end of list
    // or if user input corresponds to a partial, add to top of list
    // or if it's freeSearch and results don't come from API, add to end of list
    if (this.refMap.get(inputValue)) {
      this.optionList.push(this.refMap.get(inputValue));
    } else if (input && partials.includes(item.props.categoryKey)) {
      this.optionList.unshift(this.refMap.get(input));
    } else if (category?.freeSearch && !options.length) {
      this.optionList.push(this.refMap.get(value.value));
    }

    if (categories.length > 1) {
      categories.forEach(category => {
        if (
          category.disabled ||
          category.hidden ||
          category.categoryKey === activeCategoryKey ||
          (!showAllCategories && hiddenCategories.includes(category.categoryKey))
        ) {
          return;
        }

        const node = this.refMap.get(category.value);

        if (node) {
          this.optionList.push(node);
        }
      });
    }
  }

  render() {
    const {
      props: {
        objects,
        footer,
        statics,
        clearAll,
        customPickers,
        partials,
        placeholder,
        hiddenCategories,
        hideSelectedOption,
        dontAllowCreate,
        allowCreateTypes,
        disabled,
        loading,
        checkbox,
        scrollable,
        showContainerTitle,
        showResultsFooter,
        showFacetName,
        maxResults,
        renderOption,
        disableErrorMessage,
        errorMessage,
        tid,
        noIcon,
      },
      state: {
        matches,
        items,
        input,
        inputValue,
        active,
        categories,
        debouncing,
        initialItems,
        activeCategoryKey,
        activeCustomPicker,
        showAllCategories,
        showDownScrollbar,
        showUpScrollbar,
      },
    } = this;

    if (!matches) {
      return null;
    }

    if (disabled) {
      return (
        <div ref={this.selector} className={styles.selector} data-tid="comp-combobox">
          <Input active={false} disabled items={[]} placeholder={placeholder} />
        </div>
      );
    }

    const activeCategory = categories.find(c => c.categoryKey === activeCategoryKey) || {};
    const activeObject = activeCategory.object || objects.find(obj => activeCategoryKey === obj.type) || objects[0];
    const inputProps = {
      active,
      error: Boolean(errorMessage) && !disableErrorMessage,
      items,
      clearAll,
      value: input,
      placeholder,
      activeCategory,
      categories,
      showFacetName,
      object: activeObject,
      saveInputRef: this.saveInputRef,
      onFocus: this.handleFocus,
      onToggle: this.handleCaretToggle,
      onChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      onClearItems: this.handleClearItems,
      onItemDelete: this.handleItemDelete,
      noIcon,
      tid,
    };
    const containerRenderProps = {
      items,
      input,
      checkbox,
      loading,
      showContainerTitle,
      maxResults,
      allowCreateTypes,
      dontAllowCreate,
      renderOption,
      customPickers,
      showAllCategories,
      showResultsFooter,
      saveRef: this.optionsRef,
      onCustomPickerOpen: this.handleCustomPickerOpen,
      onOpen: this.handleOpenCategory,
      onHover: this.handleHover,
      onSelect: this.handleSelect,
      onUnselect: this.handleItemDelete,
    };
    let filteredCategories = 0;
    let activeCategoryContainer;
    let categoriesList;

    if (activeCategoryKey) {
      let optionsProps;

      if (activeCategory.isOption) {
        optionsProps = {
          isOption: true,
          category: activeCategory,
        };
      } else {
        const values = this.getOptions(activeCategoryKey, input) || [];

        optionsProps = {
          isOpen: true,
          total: this.getOptionTotal(matches, statics, activeCategoryKey, input),
          category: activeCategory,
          inputValue,
          object: activeObject,
          values: hideSelectedOption ? values.filter(match => !this.isValueIncluded(items, match)) : values,
          isPartial: partials.includes(activeCategoryKey),
        };

        const allowCreate = dontAllowCreate ? dontAllowCreate(input, activeCategory.categoryKey, matches) : true;

        if (
          allowCreateTypes &&
          allowCreateTypes.includes(activeCategoryKey) &&
          !values.some(({value}) => value === input) &&
          allowCreate
        ) {
          optionsProps.onCreate = this.handleCreate;
        }

        optionsProps.values.forEach(value => {
          if (optionsProps.category.tooltipProps) {
            // Add tooltip to option
            value.tooltip = this.getTooltip(
              items,
              value,
              optionsProps.category.tooltipProps,
              activeCategory.categoryKey,
            );
          }
        });
      }

      if (optionsProps.category.tooltipProps) {
        optionsProps.inputValue = {
          key: optionsProps.inputValue,
          tooltip: this.getTooltip(items, null, optionsProps.category.tooltipProps, activeCategory.categoryKey),
        };
      }

      activeCategoryContainer = (
        <OptionContainer
          {...containerRenderProps}
          {...optionsProps}
          onSave={this.handleSave}
          pageInvokerRef={this.pageInvoker}
        />
      );
    }

    if (categories.length) {
      categoriesList = categories.map((category, index) => {
        const {categoryKey: key, hidden, disabled, value, isOption, pageInvokerProps} = category;
        const isOptionCategoryAndSelected =
          isOption && initialItems.findIndex(({categoryKey}) => categoryKey === key) !== -1;

        if (
          hidden ||
          key === activeCategoryKey ||
          isOptionCategoryAndSelected ||
          (!showAllCategories && hiddenCategories.includes(key)) ||
          !new RegExp(ESCAPE(input), 'i').test(value)
        ) {
          return;
        }

        filteredCategories++;

        if (!isOption) {
          const values = this.getOptions(key, input);

          if (values || category.freeSearch) {
            return (
              <OptionContainer
                key={index}
                isOpen={false}
                disabled={disabled}
                values={values && values.filter(match => !this.isValueIncluded(items, match))}
                category={category}
                object={category.object || objects.find(obj => key === obj.type)}
                pageInvokerRef={this.pageInvoker}
                pageInvokerProps={pageInvokerProps}
                onSave={this.handleSave}
                {...containerRenderProps}
              />
            );
          }
        }

        if (category.tooltipProps) {
          // Add tooltip to category
          category.tooltip = this.getTooltip(items, category, category.tooltipProps, key);
        }

        return (
          <OptionContainer
            key={index}
            isOption
            input={input}
            saveRef={this.optionsRef}
            category={category}
            onHover={this.handleHover}
            pageInvokerRef={this.pageInvoker}
            pageInvokerProps={pageInvokerProps}
            onSave={this.handleSave}
            onSelect={
              !showAllCategories && hiddenCategories.length && key === 'show_all_categories'
                ? this.handleShowAll
                : this.handleSelect
            }
          />
        );
      });
    }

    let dropdownElem;

    if (activeCustomPicker.key) {
      dropdownElem = cloneElement(customPickers[activeCustomPicker.key], {
        onSave: this.handleCustomPickerAdd,
        onClose: this.handleCustomPickerClose,
      });
    } else if (activeCategoryContainer) {
      const showScrollbar = scrollable && filteredCategories > MAX_SCROLL;

      dropdownElem = (
        <div className={styles.content} onScroll={this.handleShowScrollbars}>
          {activeCategoryContainer}
          {showScrollbar && (
            <div
              className={cx(styles.scrollbarUp, {[styles.showScrollbarUp]: showUpScrollbar})}
              onClick={this.handleScrollUp}
            >
              <Icon name="up" theme={scrollTheme} />
            </div>
          )}
          {(filteredCategories > 0 || footer) && (
            <div className={scrollable ? styles.scroll : null} ref={this.scrollContainer}>
              {categoriesList}
              {footer}
            </div>
          )}
          {showScrollbar && (
            <div
              className={cx(styles.scrollbarDown, {[styles.showScrollbarDown]: showDownScrollbar})}
              onClick={this.handleScrollDown}
            >
              <Icon name="down" theme={scrollTheme} />
            </div>
          )}
        </div>
      );
    }

    const dropdownStyles = cx(styles.dropdown, {
      [styles.categoryList]: !customPickers[activeCustomPicker.key] && filteredCategories > 0,
      [styles.hidden]: !active,
    });

    return (
      <div ref={this.selector} className={styles.selector} data-tid={`comp-combobox-${tid}`}>
        <Input {...inputProps} />
        {dropdownElem ? (
          <div
            className={dropdownStyles}
            onKeyDown={this.handleKeyDown}
            data-tid="comp-select-results-list"
            aria-live="polite"
            aria-busy={loading || debouncing}
          >
            {dropdownElem}
          </div>
        ) : null}
        <TypedMessages key="status" gap="gapXSmall">
          {[
            !disableErrorMessage && typeof errorMessage === 'string'
              ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid: `${tid}-errorMessage`}
              : null,
          ]}
        </TypedMessages>
      </div>
    );
  }
}

// active category can't be hidden
function findActiveCategoryKey(categories, activeCategoryKey) {
  const active = activeCategoryKey && categories.find(({categoryKey}) => categoryKey === activeCategoryKey);

  const category = categories.find(category => !category.hidden);

  return active && !active.hidden ? activeCategoryKey : category && category.categoryKey;
}

function getFilteredCategoriesByItems(categories, items = [], displayAllCategories = false) {
  return categories.map(category => ({
    ...category,
    hidden: !displayAllCategories && items.some(item => item.categoryKey === category.categoryKey),
  }));
}
