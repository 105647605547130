/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getAdvancedRulesetDisplay} from 'containers/App/AppState';
import {
  getAllUsersMap,
  isUserReadOnlyAll,
  isUserReadOnlyOrProvisioner,
  isUserScoped,
  getUserPermissions,
} from 'containers/User/UserState';
import {getGridSelector, getUrlScopeValue} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings} from './RulesetListConfig';
import {getId} from 'utils/href';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getLabelsHrefMap} from 'containers/Label/List/LabelListState';
import {getComputedCurrentStep} from 'antman/containers/Onboarding/OnboardingStepsState';
import {getScopeLabelByHref} from '../../Selectors/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'RULESET_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getRulesets = state => state.ruleset.list;
export const getRulesetsCount = state => state.ruleset.count;

const getRulesetsRows = createSelector(
  [getRulesets, getAllUsersMap, isUserReadOnlyAll],
  (rulesets, usersMap, userIsReadOnlyAll) =>
    rulesets.map(item => ({
      key: item.href,
      // Make Ruleset not selectable for read only user, since it cannot be removed, provisioned, or updated
      selectable: !userIsReadOnlyAll && Boolean(item.caps.length),
      // Ruleset state can be enabled or disabled only if user has write caps and item is not deletion pending
      writable:
        !userIsReadOnlyAll && item.caps.includes('write') && (!item.update_type || item.update_type !== 'delete'),
      // Ruleset can only if provisioned if user has provision caps
      provisionable: !userIsReadOnlyAll && item.caps.includes('provision') && item.update_type,
      // Fill each ruleset with user object
      data: {...item, updated_by: fillUserInfo(usersMap, item.updated_by)},
    })),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, gridSettings, getAdvancedRulesetDisplay, isUserScoped],
  (userIsReadOnlyAll, gridSettings, advancedRulesetDisplay, userIsScoped) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnlyAll;

    if (!__ANTMAN__) {
      columns.scope.optional = !userIsScoped && !advancedRulesetDisplay;
    }

    return {...gridSettings, columns};
  },
);

export const getScopeItems = createSelector(
  [getUrlScopeValue, getLabelsHrefMap, getSelectorSettings],
  (scope, labelsMap, selectorSettingsObject) => {
    if (scope.isEmpty) {
      return [];
    }

    return scope.valid.scope
      .filter(({key}) => key !== 'role')
      .map(({href}) => {
        const label = labelsMap[href] || getScopeLabelByHref(href, true) || {};

        return {
          href,
          value: label.value,
          key: label.key,
          categoryKey: label.key,
          scope: true,
          categoryName: _.get(selectorSettingsObject.filterMap[label.key], 'value'),
        };
      });
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getRulesetsRows,
    filterMap: getSelectorSettings().filterMap,
  });

export const isScopeRequired = createSelector(getUserPermissions, userPermissions => {
  const permissionsWithScopesAll = userPermissions.filter(permission => permission.scope.length === 0);

  return !permissionsWithScopesAll.some(permission => {
    const role = getId(permission.role.href);

    //Scope is optional for owner, admin or a full ruleset manager with 'All' scopes
    return role === 'owner' || role === 'admin' || role === 'ruleset_manager';
  });
});

export const getRulesetsPage = createSelector(
  [
    getRulesetsCount,
    getGrid,
    isUserReadOnlyOrProvisioner,
    isUserScoped,
    isScopeRequired,
    getScopeItems,
    getUrlScopeValue,
    getSelectorSettings,
    getAdvancedRulesetDisplay,
    getComputedCurrentStep,
  ],
  (
    count,
    grid,
    userIsReadOnlyOrProvisioner,
    userIsScoped,
    scopeIsRequired,
    scopeItems,
    scope,
    selectorSettingsObject,
    advancedRulesetDisplay,
    currentStep,
  ) => {
    // Selector parameters based on filter and config
    const selector = {
      initialItems: scopeItems.concat(
        Object.entries(grid.filter).map(([categoryKey]) => ({
          categoryKey,
          value: grid.filter[categoryKey][0],
          categoryName:
            selectorSettingsObject.filterMap[categoryKey].value || selectorSettingsObject.filterMap[categoryKey],
        })),
      ),
      objects: Object.values(selectorSettingsObject.objectMap),
      categories: Object.entries(selectorSettingsObject.filterMap).map(([categoryKey, value]) => ({
        categoryKey,
        value: value.value || value,
        object: value.object,
        scope: value.scope,
        statics: value.statics,
      })),
      facets: Object.keys(selectorSettingsObject.facetMap),
      partials: Object.keys(selectorSettingsObject.facetMap),
      statics: Object.entries(selectorSettingsObject.staticValues).reduce((result, [key, values]) => {
        result[key] = Object.keys(values);

        return result;
      }, {}),
    };

    return {
      grid,
      count,
      selector,
      userIsReadOnlyOrProvisioner,
      userIsScoped,
      scopeIsRequired,
      scope,
      advancedRulesetDisplay,
      ...(__ANTMAN__ && {
        currentStep,
      }),
    };
  },
);
